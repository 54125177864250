import { ContentItem } from 'bridge/news-post';

export class DescriptiveContent implements ContentItem {
  tag: 'body' | 'bullet';
  index: number;
  marginBottom: number;

  text: string;
  fontWeight: 'regular' | 'bold';
  size: 'regular' | 'small' | 'x-small';
  textDecoration: 'underline' | 'none';

  constructor(tag: 'body' | 'bullet', index: number,
              marginBottom = 0,
              text = '',
              fontWeight: 'regular' | 'bold' = 'regular',
              size: 'regular' | 'small' | 'x-small' = 'regular',
              textDecoration: 'underline' | 'none' = 'none') {
    this.tag = tag;
    this.index = index;
    this.marginBottom = marginBottom;
    this.text = text;
    this.fontWeight = fontWeight;
    this.size = size;
    this.textDecoration = textDecoration;
  }
}

export class HeadingContent implements ContentItem {
  tag: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  index: number;
  marginBottom: number;

  text: string;
  textDecoration: 'underline' | 'none';

  constructor(tag: 'h1' | 'h2' | 'h3' | 'h4' | 'h5', index: number, marginBottom: number = 0, text: string = '', textDecoration: 'underline' | 'none' = 'none') {
    this.tag = tag;
    this.index = index;
    this.marginBottom = marginBottom;
    this.text = text;
    this.textDecoration = textDecoration;
  }
}

export class ButtonContent implements ContentItem {
  tag: 'button' = 'button';
  index: number;
  marginBottom: number;

  title: string;
  icon: string;
  navigation: {
    routerName: string,
    screenName: string,
    params: {
      [key: string]: string
    }
  };

  constructor(index: number, marginBottom: number = 0, title: string = '', icon: string = '', navigation: {
    routerName: string;
    screenName: string;
    params: { [p: string]: string }
  } = { routerName: '', screenName: '', params: {} }) {
    this.index = index;
    this.marginBottom = marginBottom;
    this.title = title;
    this.icon = icon;
    this.navigation = navigation;
  }
}

export class ImageContent implements ContentItem {
  tag: 'image' = 'image';
  index: number;
  marginBottom: number;

  ratio: number;
  width: number;
  src: string;

  constructor(index: number, marginBottom = 0, src = '', ratio = 0, width = 100) {
    this.index = index;
    this.marginBottom = marginBottom;
    this.src = src;
    this.ratio = ratio;
    this.width = width;
  }
}

export class LinkContent implements ContentItem {
  tag: 'link' = 'link';
  index: number;
  marginBottom: number;

  href: string;
  title: string;

  constructor(index: number, marginBottom: number = 0, href: string = '', title: string = '') {
    this.index = index;
    this.marginBottom = marginBottom;
    this.href = href;
    this.title = title;
  }
}

export class AccordionContent implements ContentItem {
  tag: 'accordion' = 'accordion';
  index: number;
  marginBottom: number = 0;

  header: string;
  content: ContentItem[];

  constructor(index: number, marginBottom?: number, header: string = '', content: ContentItem[] = []) {
    this.index = index;
    this.marginBottom = marginBottom || 0;
    this.header = header;
    this.content = content;
  }
}

import { NewsPost, NewsPostFilterEssentialData } from 'bridge/news-post';
import React, { useEffect, useState } from 'react';
import { Alert, Badge, Button, Divider, Modal, Select, Slider, Typography } from 'antd';
import { underLinedInputClass } from '../../../components/inputs/editable-attribute.component';
import httpClient from '../../../utils/http-client.util';
import { popMessage } from '../../../utils/pop-message.util';
import { Gender } from 'bridge/general';
import { BackendAPI } from '../../../constants/backend-api.enum';

type Prop = {
  existingFilter: NewsPost['audienceFilter'] | undefined;
  title: string;
  onTargetCheck?: (target: number) => void;
  lazyLoad?: boolean;
  disabled?: boolean;
  children?: (targetingNumber: number | undefined) => React.ReactNode;
  onConfirm?: (newFilter: NewsPost['audienceFilter']) => void;
};

export const TargetDonorFilter = ({
  existingFilter,
  disabled,
  onConfirm,
  onTargetCheck,
  title,
  children,
  lazyLoad,
}: Prop) => {
  const [filter, setFilter] = useState<Partial<NewsPost['audienceFilter']>>();
  const [open, setOpen] = useState(false);
  const [filterEssentialData, setFilterEssentialData] = useState<NewsPostFilterEssentialData>();
  const [targetingNumber, setTargetingNumber] = useState<number | undefined>();
  const [checkingTargetingNumber, setCheckingTargetingNumber] = useState<boolean>(false);
  const [changeQueryDebouncing, setChangeQueryDebouncing] = useState<NodeJS.Timeout>();
  const [debouncingTimeoutMs, setDebouncingTimeoutMs] = useState<number>(0);
  const [lazyLoading, setLazyLoading] = useState<boolean>(!!lazyLoad);

  useEffect(() => {
    setFilter(existingFilter);
  }, [existingFilter]);

  useEffect(() => {
    (async () => {
      const essentialDataResponse = await httpClient.get<NewsPostFilterEssentialData>(
        `${BackendAPI.DONOR_FILTER}/essential-data`
      );
      setFilterEssentialData(essentialDataResponse.data);
    })();
  }, []);

  useEffect(() => {
    if (!lazyLoading) {
      setCheckingTargetingNumber(true);
      if (debouncingTimeoutMs === 0) {
        setDebouncingTimeoutMs(1000);
      }
      if (filter) {
        if (changeQueryDebouncing) {
          clearTimeout(changeQueryDebouncing);
          setChangeQueryDebouncing(undefined);
        }
        setChangeQueryDebouncing(
          setTimeout(async () => {
            try {
              const result = await httpClient.post<{
                count: number;
              }>(`${BackendAPI.DONOR_FILTER}`, { ...filter });
              setTargetingNumber(result.data.count);
            } catch (e) {
              popMessage.error({ content: 'Failed to check targeting number', duration: 2 });
              setTargetingNumber(-1);
            } finally {
              setCheckingTargetingNumber(false);
            }
          }, debouncingTimeoutMs)
        );
      }
    }
  }, [filter, lazyLoading]);

  useEffect(() => {
    if (targetingNumber !== undefined && onTargetCheck) {
      onTargetCheck(targetingNumber);
    }
  }, [targetingNumber]);

  useEffect(() => {
    if (open) {
      setLazyLoading(false);
    } else {
      setLazyLoading(!!lazyLoad);
    }
  }, [open]);

  const confirmFilter = () => {
    if (filter && !disabled && onConfirm) {
      onConfirm(filter as NewsPost['audienceFilter']);
    }
    setOpen(false);
  };

  return (
    <>
      <div className={'cursor-pointer'} onClick={() => setOpen(true)}>
        {children ? (
          <>{children(targetingNumber)}</>
        ) : (
          <>
            <div>
              <Typography.Text type={'secondary'}>
                {title} <i className={'ri-edit-line text-blue-500'} />
              </Typography.Text>
            </div>
            <div>
              <div className={'inline-flex items-center'}>
                {filter?.genders?.length ? (
                  <>
                    <Typography.Text className="text-xs text-gray-400">Gender:</Typography.Text>
                    {filter.genders.map((g) => (
                      <Badge
                        key={g}
                        className={'ml-1'}
                        color={g === 'male' ? 'cyan' : 'red'}
                        size={'default'}
                        count={g[0].toUpperCase()}
                      />
                    ))}
                  </>
                ) : (
                  <span className={'text-amber-500 text-xs'}>Omit gender</span>
                )}
              </div>
              <Divider type={'vertical'} />
              <div className={'inline-block'}>
                {filter?.ageRange ? (
                  <>
                    <Typography.Text className="text-xs text-gray-400 mr-1">Age:</Typography.Text>
                    {
                      <>
                        [{filter.ageRange[0]}, {filter.ageRange[1]}]
                      </>
                    }
                  </>
                ) : (
                  <span className="animate-bounce inline-block text-red-500 text-xs">Need Age Setting</span>
                )}
              </div>
            </div>
            <div>
              <Typography.Text className="text-xs text-gray-400 mr-1">
                {filter?.donorGroups?.length ? (
                  `${filter.donorGroups.length} donor group(s)`
                ) : (
                  <span className={'text-amber-500'}>Omit donor group</span>
                )}
              </Typography.Text>
              <Divider type={'vertical'} />
              <Typography.Text className="text-xs text-gray-400">
                {filter?.donorTypes?.length ? (
                  `${filter.donorTypes.length} donor type(s)`
                ) : (
                  <span className={'text-amber-500'}>Omit donor type</span>
                )}
              </Typography.Text>
            </div>
            <div>
              <Typography.Text className="text-xs text-gray-400 mr-1">
                {filter?.locations?.length ? (
                  `${filter.locations.length} location(s)`
                ) : (
                  <span className={'text-amber-500'}>Omit location</span>
                )}
              </Typography.Text>
              <Divider type={'vertical'} />
              <Typography.Text className={`text-xs ${targetingNumber === -1 ? 'text-red-500' : 'text-blue-500'}`}>
                Target:
                {targetingNumber !== undefined ? (
                  <span className={'mx-1'}>{targetingNumber}</span>
                ) : (
                  <>
                    {checkingTargetingNumber ? (
                      <i className={'ri-loader-3-line inline-block animate-spin mx-1'} />
                    ) : (
                      <i className={'ri-question-mark mx-1'} />
                    )}
                  </>
                )}
                donor(s)
              </Typography.Text>
            </div>
          </>
        )}
      </div>
      <Modal
        destroyOnClose
        open={open}
        onCancel={confirmFilter}
        title={`${title} Setting`}
        footer={null}
        maskClosable={false}
        closeIcon={null}
      >
        {disabled && <Alert type={'warning'} message={'You cannot adjust the filters'} />}
        <div className={'mb-3'}>
          <Typography.Text className="text-xs text-gray-400 mr-1 block">Gender:</Typography.Text>
          <Select
            placeholder={'Omit Gender Filter'}
            mode={'multiple'}
            variant="borderless"
            disabled={disabled}
            value={filter?.genders}
            showSearch={false}
            className={`${underLinedInputClass}`}
            style={{ borderBottomStyle: 'solid', width: '100%' }}
            options={[
              { label: 'Male', value: 'male' as Gender },
              { label: 'Female', value: 'female' as Gender },
            ]}
            onChange={(selected) => {
              setFilter({ ...filter, genders: selected as Array<Gender> });
            }}
          />
        </div>
        <div className={'mb-8'}>
          <Typography.Text className="text-xs text-gray-400 mr-1 block">Age:</Typography.Text>
          <Slider
            range
            step={10}
            min={0}
            max={100}
            marks={{
              0: 0,
              10: 10,
              20: 20,
              30: 30,
              40: 40,
              50: 50,
              60: 60,
              70: 70,
              80: 80,
              90: 90,
              100: 100,
            }}
            onChange={(range) => {
              setFilter({ ...filter, ageRange: [range[0], range[1]] });
            }}
            disabled={disabled}
            className={'!my-3 min-w-[120px] flex-grow-[1]'}
            value={filter?.ageRange}
          />
        </div>
        <div className={'mb-3'}>
          <Typography.Text className="text-xs text-gray-400 mr-1 block">Donor Type:</Typography.Text>
          <Select
            placeholder={'Omit Donor Type Filter'}
            mode={'multiple'}
            variant="borderless"
            disabled={disabled}
            value={filter?.donorTypes}
            showSearch={false}
            className={`${underLinedInputClass}`}
            style={{ borderBottomStyle: 'solid', width: '100%' }}
            options={filterEssentialData?.donorTypes?.map((d) => ({ value: d.typeId, label: d.name }))}
            onChange={(selected) => {
              setFilter({ ...filter, donorTypes: selected });
            }}
          />
        </div>
        <div className={'mb-3'}>
          <Typography.Text className="text-xs text-gray-400 mr-1 block">Donor Group:</Typography.Text>
          <Select
            placeholder={'Omit Donor Group Filter'}
            mode={'multiple'}
            variant="borderless"
            disabled={disabled}
            value={filter?.donorGroups}
            showSearch={false}
            className={`${underLinedInputClass}`}
            style={{ borderBottomStyle: 'solid', width: '100%' }}
            options={filterEssentialData?.donorGroups?.map((d) => ({ value: d.groupId, label: d.name }))}
            onChange={(selected) => {
              setFilter({ ...filter, donorGroups: selected });
            }}
          />
        </div>
        <div className={'mb-3'}>
          <Typography.Text className="text-xs text-gray-400 mr-1 block">Location:</Typography.Text>
          <Select
            placeholder={'Omit Location Filter'}
            mode={'multiple'}
            variant="borderless"
            disabled={disabled}
            value={filter?.locations}
            showSearch={false}
            className={`${underLinedInputClass}`}
            style={{ borderBottomStyle: 'solid', width: '100%' }}
            options={filterEssentialData?.locations?.map((l) => ({ value: l.locationId, label: l.name }))}
            onChange={(selected) => {
              setFilter({ ...filter, locations: selected });
            }}
          />
        </div>
        <Divider />
        <div className={'flex justify-between items-center'}>
          {checkingTargetingNumber ? (
            <i className={'ri-loader-3-line animate-spin'} />
          ) : (
            <Typography.Text className={targetingNumber === -1 ? 'text-red-500' : 'text-blue-500'}>
              {targetingNumber !== undefined ? (
                <>
                  Targeting
                  <span className={'mx-1'}>{targetingNumber}</span>
                  donor(s) as of now
                </>
              ) : (
                'Change Filter to check targeting number'
              )}
            </Typography.Text>
          )}
          <Button type={'primary'} onClick={confirmFilter}>
            OK
          </Button>
        </div>
      </Modal>
    </>
  );
};

import { Survey } from 'bridge/survey';
import { Button, Modal, Space, Tabs, Typography } from 'antd';
import { supportingLanguageMap } from '../../../../constants/language.constant';
import { PropsWithChildren, useEffect, useState } from 'react';
import { QuestionPreview } from './question-preview.component';
import dayjs from 'dayjs';
import { GeneralDateDisplayFormat } from 'bridge/date-format';
import { TargetDonorFilter } from '../../shared-component/target-donor-filter.component';

type Prop = {
  survey: Partial<Survey>;
  confirmWithAction?: Function;
  confirmButtonText?: string;
  confirmButtonType?: 'primary' | 'danger';
  disabled?: boolean;
} & PropsWithChildren;
export const SurveyPreview = ({
  survey,
  children,
  confirmWithAction,
  disabled,
  confirmButtonText,
  confirmButtonType,
}: Prop) => {
  const [currentLanguage, setCurrentLanguage] = useState<string>('');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (survey.title) {
      setCurrentLanguage(Object.keys(survey.title)[0]);
    }
  }, [survey]);

  const onOk = async () => {
    if (confirmWithAction) {
      setLoading(true);
      await confirmWithAction();
      setLoading(false);
    }
    setOpen(false);
  };
  return (
    <>
      {
        <span
          onClick={() => {
            if (disabled) {
              return;
            }
            setOpen(true);
          }}
        >
          {children}
        </span>
      }
      {open && (
        <Modal
          title={`Survey Preview - ${survey.surveyId || 'New Survey'} `}
          open={open}
          onCancel={() => setOpen(false)}
          width={600}
          style={{ top: 20 }}
          onOk={onOk}
          footer={
            <Space className={'flex justify-between'}>
              <TargetDonorFilter disabled existingFilter={survey.participantFilter} title={'Participant Filter'}>
                {(donorCount) => (
                  <Space.Compact direction={'vertical'} className={'text-left'}>
                    <Typography.Text>
                      Targeting:
                      {donorCount !== undefined ? (
                        <Typography.Text className={'text-blue-500 mx-1'} strong>
                          {donorCount}
                        </Typography.Text>
                      ) : (
                        <i className={'ri-loader-3-line inline-block animate-spin text-blue-500 mx-1'} />
                      )}
                      Donor(s)
                    </Typography.Text>
                    <Typography.Link>Check Participant Filter</Typography.Link>
                  </Space.Compact>
                )}
              </TargetDonorFilter>
              <Space.Compact direction={'vertical'}>
                <Typography.Text type={'secondary'}>Reward with Points:</Typography.Text>
                <Typography.Text>{survey.rewardWithPoints ? survey.rewardWithPoints : 'No Reward'}</Typography.Text>
              </Space.Compact>
              <Space>
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <Button
                  type={'primary'}
                  danger={confirmButtonType === 'danger' ? true : undefined}
                  onClick={onOk}
                  disabled={disabled}
                  loading={loading}
                >
                  {confirmButtonText || 'OK'}
                </Button>
              </Space>
            </Space>
          }
        >
          <div className={'absolute right-4 top-[50px]'}>
            <p>
              <Typography.Text type={'secondary'} className={'mr-2'}>
                Publish Date:
              </Typography.Text>
              {survey.publishDate ? dayjs(survey.publishDate).format(GeneralDateDisplayFormat) : 'Not Published'}
            </p>
            <p>
              <Typography.Text type={'secondary'} className={'mr-2'}>
                Expiration Date:
              </Typography.Text>
              {survey.expirationDate ? dayjs(survey.expirationDate).format(GeneralDateDisplayFormat) : 'Never Expires'}
            </p>
          </div>
          <Tabs onChange={(key) => setCurrentLanguage(key)}>
            {Object.keys(survey.title || {}).map((lang) => (
              <Tabs.TabPane tab={supportingLanguageMap.get(lang)} key={lang}>
                <div className={'h-[500px] overflow-y-auto bg-secondary-background px-4'}>
                  <Typography.Title level={3}>{(survey.title || {})[lang]}</Typography.Title>
                  {(survey.questions || []).map((q, qIndex) => (
                    <QuestionPreview q={q} currentEditLanguage={currentLanguage} qIndex={qIndex} />
                  ))}
                </div>
              </Tabs.TabPane>
            ))}
          </Tabs>
        </Modal>
      )}
    </>
  );
};

import { Button, Modal } from 'antd';
import { PropsWithChildren, useEffect, useState } from 'react';
import httpClient from '../../../utils/http-client.util';
import { BackendAPI } from '../../../constants/backend-api.enum';
import { popMessage } from '../../../utils/pop-message.util';
import {
  EditableAttribute,
  EditableAttributeDateType,
  TextWithIcon,
} from '../../../components/inputs/editable-attribute.component';

export const LocationServiceConfig = ({ children, onChange }: PropsWithChildren & {
  onChange?: (newServices: TextWithIcon[]) => void
}) => {
  const [open, setOpen] = useState(false);
  const [allServices, setAllServices] = useState<TextWithIcon[]>([]);
  const [addingService, setAddingService] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (open) {
      fetchData();
    }
  }, [open]);

  const fetchData = async () => {
    try {
      const allServicesResponse = await httpClient.get<TextWithIcon[]>(`${BackendAPI.LOCATION}/services`);
      setAllServices(allServicesResponse.data);
      if (onChange) {
        onChange(allServicesResponse.data);
      }
    } catch (e) {
      popMessage.error('unable to load location services');
    }
  };

  const toggleModal = () => {
    setOpen(!open);
    setAddingService(false);
  };

  const remove = (index: number) => {
    allServices.splice(index, 1);
    setAllServices([...allServices]);
  };

  const add = (newValue: EditableAttributeDateType | EditableAttributeDateType[], continuous?: boolean) => {
    if (newValue) {
      setAllServices([...allServices, newValue as TextWithIcon]);
    }
    setAddingService(false);
    setTimeout(() => setAddingService(!!continuous));
  };

  const modify = (newValue: EditableAttributeDateType | EditableAttributeDateType[], index: number) => {
    if (newValue) {
      const allServicesArray = [...allServices];
      allServicesArray[index] = newValue as TextWithIcon;
      setAllServices(allServicesArray);
    }
  };

  const onOk = () => {
    setLoading(true);
    setTimeout(async () => {
      // wrap in time out to make sure update is the last step to execute
      try {
        await httpClient.post(`${BackendAPI.LOCATION}/services`, [...allServices]);
        popMessage.success('Successfully updated location services');
        if (onChange) {
          onChange(allServices);
        }
        setOpen(false);
      } catch (e) {
        popMessage.error('Unable to update location services');
      } finally {
        setLoading(false);
      }
    });
  };

  return (
    <>
      {
        <span className="cursor-pointer" onClick={toggleModal}>
          {children}
        </span>
      }
      <Modal open={open} onCancel={toggleModal} onOk={onOk}
             confirmLoading={loading} okText={'Save'}
             destroyOnClose title={'Location Services Configuration'}
             maskClosable={false}>
        <h5 className="text-gray-500">Available Services:</h5>
        {
          allServices.map((s, i) =>
            <div className={'flex justify-start items-center'} key={`location-service-${i}`}>
              <EditableAttribute indent canEdit={{ onSave: (newValue) => modify(newValue, i) }}
                                 value={s} label={''}
                                 type={'text-with-icon'} />
              <i className="ri-delete-bin-3-line ms-6 text-red-500 hover:text-red-400 cursor-pointer mt-1"
                 onClick={() => remove(i)} />
            </div>,
          )
        }
        {
          addingService &&
          <EditableAttribute canEdit={{
            onSave: add, defaultInEditing: true,
            onEnterKey: (newValue) => add(newValue, true),
            onCancel: () => setAddingService(false),
          }} value={{ icon: '', value: '' }} label={'New Service (press Enter for fast input)'}
                             type={'text-with-icon'} />
        }
        <Button className="mt-2" onClick={() => setAddingService(true)}>+ New Service</Button>
      </Modal>
    </>
  );
};
import React, { useEffect, useState } from 'react';
import { Badge, List, Modal, Switch, Tabs, Typography } from 'antd';
import { BoardContent } from '../../../components/layout/board-content.component';
import { EntityFeeStructure } from 'bridge/fee-structure';
import { popMessage } from '../../../utils/pop-message.util';
import httpClient from '../../../utils/http-client.util';
import { FacilityLocation } from 'bridge/location';
import { BackendAPI } from '../../../constants/backend-api.enum';
import { DataTable, DataTableColumn, DataTableSetting } from '../../../components/data-table/data-table.component';
import { useNavigate } from 'react-router-dom';
import "./fee-structure.css"
import { ExclamationCircleFilled } from '@ant-design/icons';
import { FiltersType } from '../functions/utils';
import { FeeStructureDurationsDisplay } from '../promotion-bonus/components/donations-display';
import { APPLICANT, APPLICANTDONORS, QUALIFIED } from '../../../utils/enum';
import { TableauView } from '../components/tableau-view';

let route = {
  title: 'Create',
  route: '/fee-structure/create',
};

const { confirm } = Modal;

export const FeeStructureListPage = () => {

  const [appliedCenter, setAppliedCenter] = useState<FiltersType[]>([]);
  const [feeStructureData, setFeeStructureData] = useState<EntityFeeStructure[]>([])
  const [pageLoading, setPageLoading] = useState<boolean>(false)
  const [heatMapOpen, setHeatMapOpen] = useState(false)
  const [tabActive, setTabActive] = useState<string>('0');
  const navigate = useNavigate()

  useEffect(() => {
    (async () => {
      try {
        const allLocationsRequest = await httpClient.get<FacilityLocation[]>(BackendAPI.LOCATION);
        if (allLocationsRequest.data) {
          let newLocation: FiltersType[] = allLocationsRequest.data.map((item) => {
            return {
              text: item.name,
              value: item.name,
            }
          })
          setAppliedCenter(newLocation)
        }
      } catch (e) {
        popMessage.error('Unable to fetch locations data');
      }
    })();
  }, []);

  const fetchStructureData = async () => {
    try {
      setPageLoading(true);
      let res = await httpClient.get(`${BackendAPI.FEESTRUCTURE}/lists`);
      if (res && res.status === 200) {
        setFeeStructureData(res.data.data)
      }
    } catch (e) {
      popMessage.error('Unable to fetch Fee Structure data');
    } finally {
      setPageLoading(false);
    }
  }

  useEffect(() => {
    fetchStructureData()
  }, []);

  const handleDelete = (feeStructureData: EntityFeeStructure) => {
    confirm({
      title: 'Are you sure delete this Fee Structure?',
      icon: <ExclamationCircleFilled />,
      content: (
        <div>
          <p className="font-bold">{feeStructureData.feeStructureName}</p>
        </div>
      ),
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        httpClient
          .post(`${BackendAPI.FEESTRUCTURE}/delete/${feeStructureData.feeStructureId}`)
          .then((res) => {
            if (res.status === 200) {
              popMessage.success('Deleted Successfully');
              fetchStructureData()
            }
          })
          .catch((err) => {
            return false;
          });
      },
      onCancel() { },
    });
  };

  const updateStatus = async (feeStructureData: EntityFeeStructure) => {
    try {
      setPageLoading(true);
      let res = await httpClient.post(`${BackendAPI.FEESTRUCTURE}/status/${feeStructureData.feeStructureId}`, { status: feeStructureData.status === "active" ? "inactive" : "active" });
      if (res && res.status === 200) {
        popMessage.success('Updated Successfully');
        fetchStructureData()
      }
    } catch (e) {
      popMessage.error('Unable to fetch Fee Structure data');
    } finally {
      setPageLoading(false);
    }
  }

  const currentTableSettings: DataTableSetting<EntityFeeStructure> = {
    numbered: true,
    numberedWidth: 100,
    pagination: {
      pageSize: 10,
    },
    scroll: { x: 2000, y: 450 },
    bordered: true,
    size: 'large',
    operationColumnWidth: 180,
    customizedOperations: [
      {
        element: (feeStructureData) => {
          return (
            <Switch
              size="small"
              checked={feeStructureData.status === 'active' ? true : false}
              onChange={(checked) => {
                updateStatus(feeStructureData);
              }}
            />
          )
        },
      },
      {
        element: () => {
          return (
            <i className="ri-map-pin-line cursor-pointer text-blue-500" onClick={() => setHeatMapOpen(true)}></i>
          )
        }
      },
      {
        element: (feeStructureData) => {
          return (
            <i className="ri-eye-line cursor-pointer text-blue-500" onClick={() => navigate(`../${feeStructureData.feeStructureId}`, { state: { feeStructureData: feeStructureData } })}></i>
          )
        },
      },
      {
        element: (feeStructureData) => {
          return (
            <i className="ri-delete-bin-line text-red-500 cursor-pointer" onClick={() => handleDelete(feeStructureData)}></i>
          )
        },
      }
    ]
  }

  const expiredTableSettings: DataTableSetting<EntityFeeStructure> = {
    numbered: true,
    numberedWidth: 100,
    pagination: {
      pageSize: 10,
    },
    scroll: { x: 2000, y: 450 },
    bordered: true,
    size: 'large',
    operationColumnWidth: 180,
    customizedOperations: [
      {
        element: () => {
          return (
            <i className="ri-map-pin-line cursor-pointer text-blue-500" onClick={() => setHeatMapOpen(true)}></i>
          )
        }
      },
      {
        element: (feeStructureData) => {
          return (
            <i className="ri-eye-line cursor-pointer text-blue-500" onClick={() => navigate(`../${feeStructureData.feeStructureId}`, { state: { feeStructureData: feeStructureData } })}></i>
          )
        },
      }
    ]
  }

  const currentData: EntityFeeStructure[] = feeStructureData.filter((item) => item.status !== "expired")
  const expiryData: EntityFeeStructure[] = feeStructureData.filter((item) => item.status === "expired")
  const columns: DataTableColumn<EntityFeeStructure>[] = [
    {
      title: 'Name',
      dataIndex: 'feeStructureName',
      filterable: true,
      key: 'feeStructureName',
      width: 200,
      filterSearch: true,
    },
    {
      title: 'From Date',
      dataIndex: 'startDate',
      key: 'startDate',
      width: 130,
      filterable: true,
    },
    {
      title: 'To Date',
      dataIndex: 'endDate',
      key: 'endDate',
      width: 130,
      filterable: true,
    },
    {
      title: 'Target Group',
      dataIndex: 'feeStructureType',
      key: 'feeStructureType',
      width: 200,
      filters: [
        {
          text: APPLICANTDONORS,
          value: APPLICANT
        },
        {
          text: 'Qualified Donors',
          value: QUALIFIED
        }
      ],
      onFilter: (value: React.Key | boolean, record: EntityFeeStructure) => record.feeStructureType.startsWith(String(value)),
      render: (feeStructureType: EntityFeeStructure["feeStructureType"]) => {
        return (
          <div>
            {feeStructureType === APPLICANT ? "Applicant Donors" : "Qualified Donors"}
          </div>
        )
      }
    },
    {
      title: 'Durations',
      dataIndex: 'feeDonationDuration',
      key: 'feeDonationDuration',
      width: 250,
      filterable: true,
      render: (feeDonationDuration: EntityFeeStructure["feeDonationDuration"]) => {
        return (
          <FeeStructureDurationsDisplay feeDonationDuration={feeDonationDuration} />
        )
      }
    },
    {
      title: 'Fees',
      children: [
        {
          title: '690ml',
          dataIndex: 'fees',
          key: 'fees',
          width: 200,
          render: (fees: EntityFeeStructure["fees"]) => {
            return (
              <List size="small">
                {
                  fees['690'] !== undefined ? (
                    Object.entries(fees['690']).length > 0 ? (
                      Object.entries(fees['690']).map(([childKey], index) => (
                        <List.Item style={{ padding: '8px 0' }}>Donation #{index + 1}: ${fees["690"][childKey]}</List.Item>
                      ))
                    ) : null
                  ) : null
                }
              </List>
            )
          }
        },
        {
          title: '825ml',
          dataIndex: 'fees',
          key: 'fees',
          width: 200,
          render: (fees: EntityFeeStructure["fees"]) => {
            return (
              <List size="small">
                {
                  fees["825"] !== undefined ? (
                    Object.entries(fees['825']).length > 0 ? (
                      Object.entries(fees["825"]).map(([childKey], index) => (
                        <List.Item style={{ padding: '8px 0' }}>Donation #{index + 1}: ${fees["825"][childKey]}</List.Item>
                      ))
                    ) : null
                  ) : null
                }
              </List>
            )
          }
        },
        {
          title: '880ml',
          dataIndex: 'fees',
          key: 'fees',
          width: 200,
          render: (fees: EntityFeeStructure["fees"]) => {
            return (
              <List size="small">
                {
                  fees["880"] !== undefined ? (
                    Object.entries(fees['880']).length > 0 ? (
                      Object.entries(fees["880"]).map(([childKey], index) => (
                        <List.Item style={{ padding: '8px 0' }}>Donation #{index + 1}: ${fees["880"][childKey]}</List.Item>
                      ))
                    ) : null
                  ) : null
                }
              </List>

            )
          }
        },
      ],
    },
    {
      title: 'Applied Centers',
      dataIndex: 'appliedCenter',
      key: 'appliedCenter',
      width: 200,
      filters: appliedCenter,
      onFilter: (value: React.Key | boolean, record: EntityFeeStructure) => {
        return record.appliedCenter.includes(String(value))
      },
      render: (centers: EntityFeeStructure["appliedCenter"]) => {
        return (
          <List size="small">
            {
              centers.length > 0 ? (
                centers.map((item, index) => (
                  <List.Item style={{ padding: '8px 0' }}>{item}</List.Item>
                ))
              ) : null
            }
          </List>
        )
      }
    },
  ]

  const listTabs = [
    {
      key: '0',
      label: (
        <Typography.Text>
          Current Program
          <Badge
            className="site-badge-count-109 ms-1"
            count={currentData.length ? currentData.length : 0}
            style={{ backgroundColor: '#52c41a' }}
          />
        </Typography.Text>
      ),
      children: <DataTable style={{ margin: '0' }} data={currentData} setting={currentTableSettings} loading={pageLoading} columnsDef={columns} />,
    },
    {
      key: '1',
      label: (
        <Typography.Text>
          Expired Program
          <Badge
            className="site-badge-count-109 ms-1"
            count={expiryData.length ? expiryData.length : 0}
          />
        </Typography.Text>
      ),
      children: <DataTable style={{ margin: '0' }} data={expiryData} setting={expiredTableSettings} loading={pageLoading} columnsDef={columns} />,
    },
  ]

  return (
    <BoardContent title="Fee Structure" loading={false} hasButton={true} redirect={route}>
      <Tabs
        activeKey={String(tabActive)}
        items={listTabs}
        onChange={(key: string) => setTabActive(key)}
      />
      <Modal
        centered
        open={heatMapOpen}
        onCancel={() => setHeatMapOpen(false)}
        width="fit-content"
        footer={null}
      >
        <TableauView />
      </Modal>
    </BoardContent>
  );
};
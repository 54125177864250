import { Button, Checkbox, Input, Space, Tooltip, Upload } from 'antd';
import { PictureOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import Viewer from 'react-viewer';
import httpClient from '../../../utils/http-client.util';
import { NewMessage } from 'bridge/chat-message';
import { BackendAPI } from '../../../constants/backend-api.enum';
import { popMessage } from '../../../utils/pop-message.util';
import { fileToBase64 } from '../../../helpers/image-helper';
import { useBoundStore } from '../../../states/bound.store';
import Compressor from 'compressorjs';

export const MessageSendingComponent = () => {
  const [inputText, setInputText] = useState('');
  const [messageSending, setMessageSending] = useState(false);
  const [enterToSend, setEnterToSend] = useState(true);
  const [attachedImage, setAttachedImage] = useState<{ file: File; base64: string; fileName: string }>();
  const [inputStatus, setInputStatus] = useState('');
  const [previewOpen, setPreviewOpen] = useState(false);

  const conversationChosen = useBoundStore((state) => !!state.currentConversationDonorId);
  const addNewMessage = useBoundStore((state) => state.addNewMessage);
  const currentConversation = useBoundStore((state) =>
    state.conversations.find((c) => c.donor.donorId === state.currentConversationDonorId)
  );

  const attachAnImage = (file: File) => {
    new Compressor(file, {
      quality: (1024 * 1024 * 2) / file.size > 1 ? 1 : (1024 * 1024 * 2) / file.size,
      success: async (compressedResult) => {
        // compressedResult has the compressed file.
        // Use the compressed file to upload the images to your server.
        setAttachedImage({
          file: compressedResult as File,
          base64: await fileToBase64(compressedResult as File),
          fileName: file.name || 'screenshot.png',
        });
      },
    });
  };

  const handlePreview = async () => {
    setPreviewOpen(true);
  };

  const sendMessage = async () => {
    if (currentConversation) {
      try {
        setMessageSending(true);

        const postData = new FormData();

        if (attachedImage) {
          postData.append('file', attachedImage.file, attachedImage.fileName);
        }
        if (inputText) {
          postData.append('text', inputText);
        }

        postData.append('receiver', currentConversation.donor.donorId);

        const replyMessageReq = await httpClient.post<NewMessage[]>(
          `${BackendAPI.CUSTOMER_SERVICE}/messages`,
          postData
        );

        replyMessageReq.data.forEach((m) => addNewMessage(m));

        setInputText('');
        setAttachedImage(undefined);
      } catch (e) {
        popMessage.error({
          content: 'unable to send message',
          duration: 30,
        });
      } finally {
        setMessageSending(false);
      }
    }
  };

  const handlePaste = async (event: React.ClipboardEvent<HTMLTextAreaElement>) => {
    const items = event.clipboardData.items;

    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf('image') !== -1) {
        const blob = items[i].getAsFile() as File;
        attachAnImage(blob);
        break;
      }
    }
  };

  useEffect(() => {
    if (inputText.trim().length || attachedImage) {
      setInputStatus('');
    }
  }, [inputText, attachedImage]);

  return (
    <div className={`message-sending ${inputStatus} flex flex-col rounded-md border`}>
      <Input.TextArea
        autoSize={{ minRows: 1 }}
        placeholder="Type your message or directly paste your screenshot..."
        value={inputText}
        onChange={(e) => {
          setInputText(e.target.value);
        }}
        className={'bg-gray-50 hover:bg-gray-50 focus:bg-gray-50 hover:disabled:bg-[rgba(0,0,0,0.04)]'}
        style={{
          border: 0,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }}
        disabled={!conversationChosen || messageSending}
        onPaste={handlePaste}
        onPressEnter={async (e) => {
          if (e.key === 'Enter') {
            if (!enterToSend) {
              return;
            }
            if (!(attachedImage || inputText.trim().length)) {
              if (!e.shiftKey) {
                setInputStatus('error');
                e.preventDefault();
                return;
              }
            }
            if (!e.shiftKey && enterToSend) {
              await sendMessage();
            }
          }
        }}
      />
      {!!attachedImage && (
        <Space className={'bg-gray-50 pl-2.5'}>
          <Upload
            listType={'picture'}
            className={'w-[200]'}
            maxCount={1}
            fileList={[
              {
                uid: '1',
                name: attachedImage.fileName,
                url: attachedImage.base64,
              },
            ]}
            disabled={messageSending}
            onPreview={handlePreview}
            onRemove={() => setAttachedImage(undefined)}
          />
        </Space>
      )}
      <Space
        style={{
          background: !conversationChosen || messageSending ? 'rgba(0,0,0, 0.04)' : '#f9f9f9f9',
          justifyContent: 'space-between',
          padding: 4,
          borderRadius: 6,
          borderTop: 0,
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }}
      >
        <Upload
          beforeUpload={async (file) => {
            attachAnImage(file);
            return false;
          }}
          fileList={[]}
        >
          <Button
            title={attachedImage?.base64 ? 'Only 1 image is allowed' : 'Attach image'}
            disabled={!conversationChosen || messageSending || !!attachedImage}
            icon={<PictureOutlined />}
            size={'small'}
          />
        </Upload>
        <Space>
          <Tooltip title="Shift + Enter for new line" placement={'left'}>
            <Checkbox defaultChecked checked={enterToSend} onChange={() => setEnterToSend(!enterToSend)}>
              ENTER to send
            </Checkbox>
          </Tooltip>
          <Button
            disabled={!conversationChosen || messageSending || (!inputText.trim().length && !attachedImage)}
            loading={messageSending}
            onClick={sendMessage}
            size={'small'}
            className={'text-blue-500'}
            icon={<i className={'ri-send-plane-2-fill'} />}
          />
        </Space>
      </Space>
      <Viewer
        visible={previewOpen}
        onClose={() => setPreviewOpen(false)}
        images={[{ src: attachedImage?.base64 || '' }]}
        onMaskClick={() => setPreviewOpen(false)}
        rotatable={false}
        scalable={false}
        noNavbar
      />
    </div>
  );
};

import React, { useEffect } from 'react';
import { DataTable, DataTableColumn, DataTableSetting } from '../../../../components/data-table/data-table.component';
import { Announcement } from 'bridge/announcement';
import httpClient from '../../../../utils/http-client.util';
import { BackendAPI } from '../../../../constants/backend-api.enum';
import { popMessage } from '../../../../utils/pop-message.util';
import { Modal } from 'antd';
import AnnouncementPreview from '../component/announcement-preview.component';
import dayjs from 'dayjs';
import { GeneralDateDisplayFormat } from 'bridge/date-format';

export const AnnouncementHistory = () => {
  const [announcements, setAnnouncements] = React.useState<Announcement[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [showPreview, setShowPreview] = React.useState<boolean>(false);
  const [selectedAnnouncement, setSelectedAnnouncement] = React.useState<Announcement | null>(null);

  const columnsDef: DataTableColumn<Announcement>[] = [
    {
      title: 'Send Date',
      dataIndex: 'sendDate',
      render: (value) => dayjs(value).format(GeneralDateDisplayFormat),
    },
    {
      title: 'Announcement ID',
      dataIndex: 'announcementId',
    },
    {
      title: 'Title',
      dataIndex: 'title',
    },
    {
      title: 'Subtitle',
      dataIndex: 'subtitle',
    },
    {
      title: 'Validated Date Range',
      children: [
        {
          title: 'From',
          dataIndex: ['validateDateRange', 0],
          render: (value) => dayjs(value).format(GeneralDateDisplayFormat),
        },
        {
          title: 'To',
          dataIndex: ['validateDateRange', 1],
          render: (value) => dayjs(value).format(GeneralDateDisplayFormat),
        },
      ],
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Created By',
      dataIndex: 'createdBy',
      key: 'createdBy',
    },
  ];

  const dataTableSetting: DataTableSetting<Announcement> = {
    numbered: true,
    customizedOperations: [
      {
        text: <i className={'ri-eye-2-line cursor-pointer text-blue-500'} />,
        whenPerform: async (record) => {
          setShowPreview(true);
          setSelectedAnnouncement(record);
        },
      },
      {
        text: (_, disabled) => (
          <i
            className={`ri-arrow-go-back-line ${disabled ? 'text-gray-300 cursor-not-allowed' : 'text-red-500 cursor-pointer'}`}
          />
        ),
        disabled: (record) => (record.status !== 'active' ? { message: 'Announcement has been revoked' } : false),
        whenPerform: async (record) => {
          try {
            setLoading(true);
            await httpClient.put(`${BackendAPI.ANNOUNCEMENT}/${record.announcementId}/revoke`);
            popMessage.success('Announcement has been revoked');
            record.status = 'inactive';
            setAnnouncements([...announcements]);
          } catch (error) {
            popMessage.error('Failed to revoke announcement');
          } finally {
            setLoading(false);
          }
        },
      },
    ],
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const announcements = await httpClient.get<Announcement[]>(BackendAPI.ANNOUNCEMENT);
        setAnnouncements(announcements.data);
      } catch (error) {
        popMessage.error('Failed to fetch announcements.');
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <div>
      <DataTable data={announcements} columnsDef={columnsDef} setting={dataTableSetting} loading={loading} />
      {showPreview && selectedAnnouncement && (
        <Modal
          title={`Announcement ${selectedAnnouncement.announcementId}`}
          open={showPreview}
          onCancel={() => {
            setShowPreview(false);
            setSelectedAnnouncement(null);
          }}
          width={360}
          style={{ top: 20 }}
          footer={null}
        >
          <div className={'h-[600px]'}>
            <AnnouncementPreview announcement={selectedAnnouncement} />
          </div>
        </Modal>
      )}
    </div>
  );
};

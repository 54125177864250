import { DataTable, DataTableColumn, DataTableSetting } from '../../components/data-table/data-table.component';
import { useNavigate } from 'react-router-dom';
import { Avatar, Button, Input, Modal, Popconfirm, Row, Select, Space, Table, Tabs, Typography, DatePicker, TimePicker, Spin } from 'antd';
import type * as React from 'react';
import { useEffect, useState } from 'react';
import httpClient from '../../utils/http-client.util';
import { BackendAPI } from '../../constants/backend-api.enum';
import { popMessage } from '../../utils/pop-message.util';
import TabPane from 'antd/es/tabs/TabPane';
import moment from 'moment';
import { formatAppointmentType, formatDonorName, formatPhone } from '../../helpers/formatHelper';
import dayjs from 'dayjs'
import { FacilityLocation } from 'bridge/location';
import "./appointment.css"
const DefStart = dayjs(Date.now()).subtract(1, 'weeks').format("MM/DD/YYYY")
const DefEnd = dayjs(Date.now()).add(2, 'weeks').format("MM/DD/YYYY")
const ALL_CENTER = "_ALL_CENTER_";

const apptTypeOptions = [
    {
        value: 'predonor_visit',
        label: "Pre-donor / Medical",
    },
    {
        value: 'donation_visit',
        label: "Standard Donation",
    },
    {
        value: 'medical_visit',
        label: "Donation / Medical",
    },
]

const searchOptions = [
    {
        value: 'donorId',
        label: 'Donor ID',
    },
    {
        value: 'name',
        label: 'Name',
    },
    {
        value: 'phone',
        label: 'Phone',
    },
    {
        value: 'email',
        label: 'Email',
    },
];

const pageSize = 3;

const { Option } = Select;

export const AppointmentListPage = () => {
    const [preData, setPreData] = useState<any>([]);
    const [doData, setDoData] = useState<any>([]);
    const [meData, setMeData] = useState<any>([]);

    const [preTotal, setPreTotal] = useState<number>(0);
    const [doTotal, setDoTotal] = useState<number>(0);
    const [meTotal, setMeTotal] = useState<number>(0);

    const [loading, setLoading] = useState<boolean>(false);
    const [locations, setLocations] = useState<any>([]);

    const [preLoding, setPreLoading] = useState<boolean>(true);
    const [doLoading, setDoLoading] = useState<boolean>(true);
    const [meLoading, setMeLoading] = useState<boolean>(true);

    const [preRange, setPreRange] = useState<any>([DefStart, DefEnd]);
    const [preSearchType, setPreSearchType] = useState<string>('donorId')
    const [preInput, setPreInput] = useState<string>("");

    const [doRange, setDoRange] = useState<any>([DefStart, DefEnd]);
    const [doSearchType, setDoSearchType] = useState<string>('donorId')
    const [doInput, setDoInput] = useState<string>("");

    const [meRange, setMeRange] = useState<any>([DefStart, DefEnd]);
    const [meSearchType, setMeSearchType] = useState<string>('donorId')
    const [meInput, setMeInput] = useState<string>("");

    const [tabkey, setTabkey] = useState<string>("list");
    const [showCreate, setShowCreate] = useState<boolean>(false);

    const [searchDonor, setSearchDonor] = useState<any>(null)
    const [selectDonor, setSelectDonor] = useState<any>(null);
    const [prePage, setPrePage] = useState<number>(1);
    const [doPage, setDoPage] = useState<number>(1);
    const [mePage, setMepage] = useState<number>(1);

    const [selectLoc, setSelectLoc] = useState<any>(null);
    const [timezone, setTimezone] = useState<string>('');
    

    const navigate = useNavigate();

    const getFormatDate = (appt: any) => {
        let dateStr = appt.date.substr(0, 10);
        let dateArr = dateStr.split("-")
        let hourStr = appt.time.substr(0, 2);
        let minStr = appt.time.substr(-2);
        let fixStr = parseInt(hourStr) - 12 > 0 ? 'pm' : 'am'
        let timeStr = (parseInt(hourStr) - 12 > 0 ? parseInt(hourStr) - 12 : parseInt(hourStr)).toString() + ":" + minStr + fixStr

        return `${dateArr[1]}/${dateArr[2]}/${dateArr[0]}` + " " + timeStr
    }

    const getDonorFullName = (donor: any) => {
        if (!donor) return "";
        let fullName = "";
        if (donor.firstName && donor.lastName) {
            let middleName = donor.middleName ? donor.middleName + " " : "";
            fullName = donor.firstName + " " + middleName + donor.lastName;
        }
        return fullName;
    }

    const switchTab = async (key: string) => {
        setTabkey(key);

        setPreInput("")
        setPreSearchType("donorId");
        setPreRange([DefStart, DefEnd]);

        setDoInput("")
        setDoSearchType("donorId");
        setDoRange([DefStart, DefEnd]);

        setMeInput("")
        setMeSearchType("donorId");
        setMeRange([DefStart, DefEnd]);

        setPrePage(1);
        setDoPage(1);
        setMepage(1)
        fetchApptList(key, 'predonor_visit', 1)
        fetchApptList(key, 'donation_visit', 1)
        fetchApptList(key, 'medical_visit', 1)
    }

    const handleLocationSwitch = async(key: string) => {
        setSelectLoc(key)
        fetchApptList(tabkey, 'donation_visit', 1, preRange, key)
        fetchApptList(tabkey, 'predonor_visit', 1, preRange, key)
        fetchApptList(tabkey, 'medical_visit', 1, preRange, key)
    }

    const fetchApptList = async(listType: string, appointmentType: string, page: number = 1, range: any = [DefStart, DefEnd], locationId: string = selectLoc) => {
        try {
            let payload = { 
                listType, 
                page, 
                appointmentType, 
                limit: pageSize,
                startDate: dayjs(range[0], "MM/DD/YYYY").format("YYYY-MM-DD"),
                endDate: dayjs(range[1], "MM/DD/YYYY").format("YYYY-MM-DD")
            }

            if (appointmentType === 'predonor_visit'){
                if (preInput !== ""){
                    payload = {
                        ...payload,
                        ...
                        {
                            searchType: preSearchType,
                            searchParam: preInput.trim()
                        }
                    }
                }
                setPreLoading(true);
            } else if (appointmentType === 'donation_visit'){
                if (doInput !== ""){
                    payload = {
                        ...payload,
                        ...
                        {
                            searchType: doSearchType,
                            searchParam: doInput.trim()
                        }
                    }
                }
                setDoLoading(true);
            } else {
                if (meInput !== ""){
                    payload = {
                        ...payload,
                        ...
                        {
                            searchType: meSearchType,
                            searchParam: meInput.trim()
                        }
                    }
                }
                setMeLoading(true);
            }

            if (locationId && locationId !== ALL_CENTER){
                payload = {
                    ...payload,
                    ...
                    {
                        locationId: locationId
                    }
                }
            }

            const res = await httpClient.post(BackendAPI.APPOINTMENT + '/pagination', payload).then(res => res.data).catch(reason => ({ status: 400, message: "Network Error" }));
            if (res.status !== 200) {
                popMessage.error(res.message)
            } else {
                if (appointmentType === 'predonor_visit') {
                    setPreData(res.data)
                    setPreTotal(res.totalCount)
                    setPrePage(page)
                } else if (appointmentType === 'donation_visit') {
                    setDoData(res.data)
                    setDoTotal(res.totalCount)
                    setDoPage(page)
                } else {
                    setMeData(res.data)
                    setMeTotal(res.totalCount)
                    setMepage(page)
                }
            }
        } catch (e) {
            popMessage.error('Unable to fetch locations data');
        } finally {
            setPreLoading(false);
            setDoLoading(false);
            setMeLoading(false);
        }
    }

    const cancelAppt = async (appointmentId: string, appointmentType: String) => {
        if (appointmentType === 'predonor_visit') {
            setPreLoading(true);
        } else if (appointmentType === 'donation_visit') {
            setDoLoading(true);
        } else {
            setMeLoading(true);
        }
        const res = await httpClient.post(BackendAPI.APPOINTMENT + '/cancel', { appointmentId }).then(res => res.data).catch(reason => ({ status: 400, message: "Network Error" }));
        if (res.status === 200) {
            popMessage.success('Success')
        } else {
            popMessage.error('Fail to cancel');
        }
        if (appointmentType === 'predonor_visit'){
            fetchApptList(tabkey, 'predonor_visit', 1, preRange)
        } else if (appointmentType === 'donation_visit'){
            fetchApptList(tabkey, 'donation_visit', 1, doRange)
        } else {
            fetchApptList(tabkey, 'medical_visit', 1, meRange)
        }
    }

    const fetchLocations = async () => {
        setLoading(true);
        const res = await httpClient.get(BackendAPI.LOCATION);
        if (res.status === 200) {
            setLocations(res.data);
        } else {
            popMessage.error('Fail to get location names');
        }
        setLoading(false);
    }

    const fetchDonor = async () => {
        if (!searchDonor || !searchDonor.donorId){
            setSearchDonor(null)
            return null;
        }
        setLoading(true);
        const res = await httpClient.post(BackendAPI.APPOINTMENT + '/searchDonorAccess', { donorId: searchDonor.donorId, searchType: searchDonor?.appointmentId ? "edit" : "new" }).then(res => res.data).catch(reason => ({ status: 400, message: "Network Error" }));
        if (res.status === 200) {
            setSearchDonor({
                ...searchDonor,
                donor: res?.donor
            })
            popMessage.success('Success')
        } else {
            setSearchDonor({
                donorId: null
            })
            if (res.message) {
                popMessage.error(res.message);
            } else {
                popMessage.error('Fail to search donor');
            }
        }
        setLoading(false);
    }

    const fetchWeekDaysRange = async (donorId:string, locationId:string, forceType: string | null) => {
        setLoading(true);
        setSelectDonor({...selectDonor, disableCalendar: false, bookingDate: null, timeslots: null, bookingTimeslot: null})
        const res = await httpClient.post(BackendAPI.APPOINTMENT + '/weekdaysValidRange', {donorId: donorId, locationId: locationId, forceType: forceType}).then(res => res.data).catch(reason => ({ status: 400, message: "Network Error" }));
        if (res.status === 200) {
            let disableCalendar = true;
            for (let key of Object.keys(res.data.weekDaysRange)){
                if (res.data.weekDaysRange[key]){
                    disableCalendar = false;
                    break;
                }
            }
            setSelectDonor({
                ...selectDonor,
                locationId: locationId,
                weekDaysRange: res.data.weekDaysRange,
                closeDays: res.data.closeDays,
                overriedDays: res.data.overriedDays,
                timeslots: null,
                appointmentType: res.data.appointmentType,
                bookingDate: null,
                bookingTimeslot: null,
                forceType: forceType,
                disableCalendar: disableCalendar,
            });
        } else {
            setSelectDonor({
                ...selectDonor,
                weekDaysRange: null,
                closeDays: [],
                overriedDays: [],
                timeslots: null,
            });
            popMessage.error('Fail to fetch calendar');
        }
        setLoading(false);
    }

    const fetchCalendar = async (donorId:string, locationId:string) => {
        setLoading(true);
        const res = await httpClient.post(BackendAPI.APPOINTMENT + '/calendarDates', {donorId: donorId, locationId: locationId}).then(res => res.data).catch(reason => ({ status: 400, message: "Network Error" }));
        if (res.status === 200) {
            setSelectDonor({
                ...selectDonor,
                locationId: locationId,
                calendars: res.data,
                bookingDate: null,
                bookingTimeslot: null
            });
        } else {
            popMessage.error('Fail to fetch calendar');
        }
        setLoading(false);
    }

    const fetchEditCalendarAndTimeslot = async (data:any) => {
        setLoading(true);
        setSearchDonor({
            donorId: data?.donorId
        })
        setShowCreate(true);
        const res = await httpClient.post(BackendAPI.APPOINTMENT + '/weekdaysValidRange', {donorId: data?.donorId, locationId: data?.locationId, forceType: data.isForcedType ? data.appointmentType : null}).then(res => res.data).catch(reason => ({ status: 400, message: "Network Error" }));
        if (res.status === 200) {
            let disableCalendar = true;
            for (let key of Object.keys(res.data.weekDaysRange)){
                if (res.data.weekDaysRange[key]){
                    disableCalendar = false;
                    break;
                }
            }
            const res1 = await httpClient.post(BackendAPI.APPOINTMENT + '/dateTimeslots', {donorId: data?.donorId, locationId: data?.locationId, bookingDate: data?.bookingDate, appointmentType: data?.appointmentType}).then(res => res.data).catch(reason => ({ status: 400, message: "Network Error" }));
            if (res1.status === 200) {
                setSelectDonor({
                    ...data,
                    weekDaysRange: res.data.weekDaysRange,
                    closeDays: res.data.closeDays,
                    overriedDays: res.data.overriedDays,
                    disableCalendar: disableCalendar,
                    timeslots: res1.data
                });
            } else {
                popMessage.error('Fail to get timeslot');
            }
        } else {
            popMessage.error('Fail to get calendars');
        }
        setLoading(false);
    }

    const fetchTimeslot = async (donorId:string, locationId:string, date:string, appointmentType: string) => {
        setLoading(true);
        const res = await httpClient.post(BackendAPI.APPOINTMENT + '/dateTimeslots', {donorId: donorId, locationId: locationId, bookingDate: date, appointmentType: appointmentType}).then(res => res.data).catch(reason => ({ status: 400, message: "Network Error" }));
        if (res.status === 200) {
            setSelectDonor({
                ...selectDonor,
                bookingDate: date,
                bookingTimeslot: null,
                timeslots: res.data
            });
        } else {
            popMessage.error('Fail to get timeslots');
        }
        setLoading(false);
    }

    const bookAppointment = async () => {
        setLoading(true);
        let formData:any = {
            locationId: selectDonor?.locationId, 
            bookingDate: selectDonor?.bookingDate,
            bookingTimeslot: selectDonor?.bookingTimeslot,
            donorId: selectDonor?.donor?.donorId,
            forceType: selectDonor?.forceType
        };
        if (selectDonor?.appointmentId) {
            formData.appointmentId = selectDonor?.appointmentId
        }
        const res = await httpClient.post(selectDonor?.appointmentId ? BackendAPI.APPOINTMENT + '/edit' : BackendAPI.APPOINTMENT + '/create', formData).then(res => res.data).catch(reason => ({ status: 400, message: "Network Error" }));
        if (res.status === 200) {
            popMessage.success('Successful');
            setShowCreate(false);
            setSelectDonor(null);
            setSearchDonor(null);
            
            fetchApptList(tabkey, res.data.appointmentType, 1)
            if (res.data.originApptType && res.data.originApptTyp !== res.data.appointmentType){
                fetchApptList(tabkey, res.data.originApptType, 1)
            }
        } else {
            popMessage.error('Fail to book');
        }
        setLoading(false);
    }

    const disabledDate = (current: any) => {
        let location = null;
        for (let loc of locations){
            if (loc.locationId === selectDonor.locationId){
                location = loc;
                break;
            }
        }

        if (selectDonor.disableCalendar){
            return true;
        }

        if (!location) return false;
        let closeWeekDays = [];
        for (let key of Object.keys(location.operationHours)){
            if (!location.operationHours[key]) closeWeekDays.push(key);
        }
        
        let closeDays: [] = Array.isArray(selectDonor.closeDays) ? selectDonor.closeDays : [];
        let overriedDays = Array.isArray(selectDonor.overriedDays) ? selectDonor.overriedDays : [];

        let weekDaysRange = selectDonor.weekDaysRange;

        let currentDate = dayjs(current)
        let currentDateStr = currentDate.format("YYYY-MM-DD");
        let currentWeekDay = currentDate.format("ddd").toLocaleLowerCase();

        if (overriedDays.includes(currentDateStr)){
            return false;
        }

        if (
            current < dayjs().endOf('day') ||
            closeDays.find(date => date === currentDateStr) ||
            !weekDaysRange[currentWeekDay]
            ){
            return true
        }


        for (let range of weekDaysRange[currentWeekDay]){
            let {startDate, endDate} = range;
            if (!startDate || !endDate || new Date(startDate) >= new Date(endDate)){
                continue;
            }
            if (new Date(startDate) > new Date(currentDateStr) || new Date(endDate) < new Date(currentDateStr)){
                return true;
            }
        }

        return false;
    };

    function getApptTypeName(typeValue: string){
        let label = ""
        apptTypeOptions.find(data => {
            if (typeValue === data.value){
                label = data.label;
            }
        })
        return label;
    }

    useEffect(() => {
        (async () => {
            fetchLocations();
            setSelectLoc(ALL_CENTER);

            setPreInput("")
            setPreSearchType("donorId");
            setPreRange([DefStart, DefEnd]);

            setDoInput("")
            setDoSearchType("donorId");
            setDoRange([DefStart, DefEnd]);

            setMeInput("")
            setMeSearchType("donorId");
            setMeRange([DefStart, DefEnd]);

            fetchApptList(tabkey, 'predonor_visit', 1)
            fetchApptList(tabkey, 'donation_visit', 1)
            fetchApptList(tabkey, 'medical_visit', 1)
        })();
    }, []);

    let columns: any = [
        {
            title: 'Visit Time',
            render: (tdata: any) => {
                return (
                    <Typography.Text>{getFormatDate(tdata)}</Typography.Text>
                );
            }
        },
        {
            title: 'Donor Name',
            render: (tdata: any) => {
                return (
                    <Typography.Text>{formatDonorName(tdata.donor)}</Typography.Text>
                );
            }
        },
        {
            title: 'Donor ID/App ID',
            render: (tdata: any) => {
                return (
                    <Typography.Text>{tdata.donorId}</Typography.Text>
                );
            }
        },
        {
            title: 'Phone/Email',
            render: (tdata: any) => {
                return (
                    <Typography.Text>{tdata?.donor?.phone ? "+" + tdata.donor.phone.countryCode + " " + tdata.donor.phone.phoneNumber : tdata?.donor?.email ? tdata.donor.email : ""}</Typography.Text>
                );
            }
        },
        {
            title: 'Center',
            render: (tdata: any) => {
                return (
                    <Typography.Text>{tdata?.location?.name || ""}</Typography.Text>
                );
            }
        },
        {
            title: 'Status',
            render: (tdata: any) => {
                return (
                    <Typography.Text>{new Date() > new Date(tdata.reserveDate) && tdata.status === 'active' ? 'Expired' : tdata.status.substr(0, 1).toUpperCase() + tdata.status.substr(1) }</Typography.Text>
                );
            }
        },
    ]

    if (tabkey !== 'cancelled') {
        columns.push({
            title: 'Action',
            render: (tdata: any) => {
                return (
                    <div className='flex'>
                        {new Date() < new Date(tdata.reserveDate) ? (
                            <div className='flex'>
                            <Button type="primary" onClick={() => {
                            fetchEditCalendarAndTimeslot({
                                appointmentId: tdata?.appointmentId,
                                locationId: tdata?.locationId,
                                donorId: tdata?.donorId,
                                donor: tdata?.donor,
                                bookingDate: tdata?.date.substring(0, 10),
                                bookingTimeslot: tdata?.time,
                                appointmentType: tdata?.appointmentType,
                                forceType: tdata.isForcedType === true ? tdata.appointmentType : null 
                            })                            
                            }}>Modify</Button>
                            <Button style={{ marginLeft: 10 }} onClick={() => cancelAppt(tdata.appointmentId, tdata.appointmentType)}>Cancel</Button>
                            </div>
                        ) : null}
                    </div>
                );
            }
        })
    }

    return (
        <>
            <div className="flex justify-between items-center">
                <Typography.Title level={3}>Appointment Management</Typography.Title>
                <Space>
                    <Select className='appt-select' value={selectLoc} onChange={handleLocationSwitch}>
                        <Option key={ALL_CENTER} value={ALL_CENTER}>All Centers</Option>
                        {locations && locations.length > 0 && locations.map((litem:any, lindex:number) => (
                            <Option key={"locations" + lindex} value={litem.locationId}>{litem.name}</Option>
                        ))}
                    </Select>
                    <Button type={'primary'} onClick={() => setShowCreate(true)}>New Appointment</Button>
                </Space>
            </div>
            <Tabs activeKey={tabkey} onChange={switchTab} type="card" style={{ width: "100%" }}>
                <TabPane tab="List" key="list">
                    <div className="predonor_visit" style={{ width: "100%" }}>
                        <Typography.Title level={5}>{getApptTypeName('predonor_visit')}</Typography.Title>
                        <div className='flex ' style={{marginBottom: 8}}>
                            <DatePicker.RangePicker className='appt-date-picker' allowEmpty={[false, false]} allowClear={false} defaultValue={[dayjs(preRange[0], "MM/DD/YYYY"), dayjs(preRange[1], "MM/DD/YYYY")]} format={"MM/DD/YYYY"} onChange={(dates, dateStrings) => {
                                setPreRange(dateStrings)
                            }} />
                            <Space.Compact className='appt-input' style={{marginLeft: 5}}>
                                <Select options={searchOptions} value={preSearchType} onChange={e => setPreSearchType(e.valueOf())} />
                                <Input value={preInput} onChange={e => setPreInput(e.target.value)}/>
                                <Button type="primary" onClick={() => fetchApptList(tabkey, 'predonor_visit', 1, preRange)}>Search</Button>
                            </Space.Compact>
                        </div>
                        <DataTable
                            loading={preLoding}
                            columnsDef={columns}
                            data={preData}
                            setting={{
                                pagination: {
                                    total: preTotal,
                                    current: prePage,
                                    defaultPageSize: pageSize,
                                    onChange: (page) => {
                                        fetchApptList(tabkey, 'predonor_visit', page, preRange);
                                    },
                                }
                            }}
                        ></DataTable>
                    </div>
                    <div className="donation_visit">
                        <Typography.Title level={5}>{getApptTypeName('donation_visit')}</Typography.Title>
                        <div className='flex ' style={{marginBottom: 8}}>
                            <DatePicker.RangePicker className='appt-date-picker' allowEmpty={[false, false]} allowClear={false} defaultValue={[dayjs(doRange[0], "MM/DD/YYYY"), dayjs(doRange[1], "MM/DD/YYYY")]} format={"MM/DD/YYYY"} onChange={(dates, dateStrings) => {
                                setDoRange(dateStrings)
                            }} />
                            <Space.Compact className='appt-input' style={{marginLeft: 5}}>
                                <Select options={searchOptions} value={doSearchType} onChange={e => setDoSearchType(e.valueOf())} />
                                <Input value={doInput} onChange={e => setDoInput(e.target.value)}/>
                                <Button type="primary" onClick={() => fetchApptList(tabkey, 'donation_visit', 1, doRange)}>Search</Button>
                            </Space.Compact>
                        </div>
                        <DataTable 
                            loading={doLoading} 
                            columnsDef={columns} 
                            data={doData}
                            setting={{
                                pagination: {
                                    total: doTotal,
                                    current: doPage,
                                    defaultPageSize: pageSize,
                                    onChange: (page) => {
                                        fetchApptList(tabkey, 'donation_visit', page, doRange);
                                    },
                                }
                            }}
                        ></DataTable>
                    </div>
                    <div className="medical_visit">
                        <Typography.Title level={5}>{getApptTypeName('medical_visit')}</Typography.Title>
                        <div className='flex ' style={{marginBottom: 8}}>
                            <DatePicker.RangePicker className='appt-date-picker' allowEmpty={[false, false]} allowClear={false} defaultValue={[dayjs(meRange[0], "MM/DD/YYYY"), dayjs(meRange[1], "MM/DD/YYYY")]} format={"MM/DD/YYYY"} onChange={(dates, dateStrings) => {
                                setMeRange(dateStrings)
                            }} />
                            <Space.Compact className='appt-input' style={{marginLeft: 5}}>
                                <Select options={searchOptions} value={meSearchType} onChange={e => setMeSearchType(e.valueOf())} />
                                <Input value={meInput} onChange={e => setMeInput(e.target.value)}/>
                                <Button type="primary" onClick={() => fetchApptList(tabkey, 'medical_visit', 1, meRange)}>Search</Button>
                            </Space.Compact>
                        </div>
                        <DataTable 
                            loading={meLoading} 
                            columnsDef={columns} 
                            data={meData}
                            setting={{
                                pagination: {
                                    total: meTotal,
                                    current: mePage,
                                    defaultPageSize: pageSize,
                                    onChange: (page) => {
                                        fetchApptList(tabkey, 'medical_visit', page, meRange);
                                    },
                                }
                            }}
                        ></DataTable>
                    </div>
                </TabPane>
                <TabPane tab="Cancelled" key="cancelled">
                    <div className="predonor_visit">
                        <Typography.Title level={5}>{getApptTypeName('predonor_visit')}</Typography.Title>
                        <div className='flex ' style={{marginBottom: 8}}>
                            <DatePicker.RangePicker className='appt-date-picker' allowEmpty={[false, false]} allowClear={false} defaultValue={[dayjs(preRange[0], "MM/DD/YYYY"), dayjs(preRange[1], "MM/DD/YYYY")]} format={"MM/DD/YYYY"} onChange={(dates, dateStrings) => {
                                setPreRange(dateStrings)
                            }} />
                            <Space.Compact className='appt-input' style={{marginLeft: 5}}>
                                <Select className='appt-select' options={searchOptions} value={preSearchType} onChange={e => setPreSearchType(e.valueOf())} />
                                <Input value={preInput} onChange={e => setPreInput(e.target.value)}/>
                                <Button type="primary" onClick={() => fetchApptList(tabkey, 'predonor_visit', 1, preRange)}>Search</Button>
                            </Space.Compact>
                        </div>
                        <DataTable
                            loading={preLoding}
                            columnsDef={columns}
                            data={preData}
                            setting={{
                                pagination: {
                                    total: preTotal,
                                    current: prePage,
                                    defaultPageSize: pageSize,
                                    onChange: (page) => {
                                        fetchApptList(tabkey, 'predonor_visit', page, preRange);
                                    },
                                }
                            }}
                        ></DataTable>
                    </div>
                    <div className="donation_visit">
                        <Typography.Title level={5}>{getApptTypeName('donation_visit')}</Typography.Title>
                        <div className='flex ' style={{marginBottom: 8}}>
                            <DatePicker.RangePicker className='appt-date-picker' allowEmpty={[false, false]} allowClear={false} defaultValue={[dayjs(doRange[0], "MM/DD/YYYY"), dayjs(doRange[1], "MM/DD/YYYY")]} format={"MM/DD/YYYY"} onChange={(dates, dateStrings) => {
                                setDoRange(dateStrings)
                            }} />
                            <Space.Compact className='appt-input' style={{marginLeft: 5}}>
                                <Select className='appt-select' options={searchOptions} value={doSearchType} onChange={e => setDoSearchType(e.valueOf())} />
                                <Input value={doInput} onChange={e => setDoInput(e.target.value)}/>
                                <Button type="primary" onClick={() => fetchApptList(tabkey, 'donation_visit', 1, doRange)}>Search</Button>
                            </Space.Compact>
                        </div>
                        <DataTable 
                            loading={doLoading} 
                            columnsDef={columns} 
                            data={doData}
                            setting={{
                                pagination: {
                                    total: doTotal,
                                    current: doPage,
                                    defaultPageSize: pageSize,
                                    onChange: (page) => {
                                        fetchApptList(tabkey, 'donation_visit', page);
                                    },
                                }
                            }}
                        ></DataTable>
                    </div>
                    <div className="medical_visit">
                        <Typography.Title level={5}>{getApptTypeName('medical_visit')}</Typography.Title>
                        <div className='flex ' style={{marginBottom: 8}}>
                            <DatePicker.RangePicker className='appt-date-picker' allowEmpty={[false, false]} allowClear={false} defaultValue={[dayjs(meRange[0], "MM/DD/YYYY"), dayjs(meRange[1], "MM/DD/YYYY")]} format={"MM/DD/YYYY"} onChange={(dates, dateStrings) => {
                                setMeRange(dateStrings)
                            }} />
                            <Space.Compact className='appt-input' style={{marginLeft: 5}}>
                                <Select className='appt-select' options={searchOptions} value={meSearchType} onChange={e => setMeSearchType(e.valueOf())} />
                                <Input value={meInput} onChange={e => setMeInput(e.target.value)}/>
                                <Button type="primary" onClick={() => fetchApptList(tabkey, 'medical_visit', 1, meRange)}>Search</Button>
                            </Space.Compact>
                        </div>
                        <DataTable 
                            loading={meLoading} 
                            columnsDef={columns} 
                            data={meData}
                            setting={{
                                pagination: {
                                    total: meTotal,
                                    current: mePage,
                                    defaultPageSize: pageSize,
                                    onChange: (page) => {
                                        fetchApptList(tabkey, 'medical_visit', page);
                                    },
                                }
                            }}
                        ></DataTable>
                    </div>
                </TabPane>
            </Tabs>

            <Modal
                style={{borderRadius: 6}}
                confirmLoading={loading}
                open={showCreate}
                title="Search Donor"
                onOk={() => {
                    setShowCreate(false)
                    setSelectDonor(null);
                    setSearchDonor(null);
                }}
                onCancel={() => {
                    setShowCreate(false)
                    setSelectDonor(null);
                    setSearchDonor(null);
                }}
                footer={[]}
            >
                {loading ? (
                    <div style={{backgroundColor: "rgba(0,0,0,0.5)", zIndex: 999, borderRadius: 6, display: "flex", alignItems: "center", justifyContent: "center", position: "absolute", top: 0, left: 0, right: 0, bottom: 0}}>
                        <Spin tip="Loading...">
                        </Spin>
                    </div>
                ) : null}

                {selectDonor ? (
                    <>
                        <div style={{ border: "1px solid #000000", marginTop: 10 }}>
                            <div className='flex' style={{ alignItems: "center" }}>
                                <Avatar style={{ margin: 10 }} size="large" src="https://img0.baidu.com/it/u=453688311,3051734486&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500" />
                                <div style={{ margin: 10, marginRight: 20 }}>
                                    <Typography.Text style={{ fontSize: 12, fontWeight: 700 }}>{formatDonorName(selectDonor?.donor)}</Typography.Text>
                                    <br />
                                    <Typography.Text style={{ fontSize: 12 }}>{selectDonor?.donor?.donorId}</Typography.Text>
                                    <br />
                                    <Typography.Text style={{ fontSize: 12 }}>&nbsp;</Typography.Text>
                                </div>
                                <div style={{ margin: 10, marginLeft: 20, flex: 1 }}>
                                    <Typography.Text style={{ fontSize: 12 }}>{selectDonor?.donor?.email}</Typography.Text>
                                    <br />
                                    <Typography.Text style={{ fontSize: 12 }}>{formatPhone(selectDonor?.donor?.phone)}</Typography.Text>
                                    <br />
                                    <Typography.Text style={{ fontSize: 12 }}>&nbsp;</Typography.Text>
                                </div>
                            </div>
                        </div>
                        <div className='flex appt-select' style={{marginTop: 10, alignItems: "center"}}>
                            <Typography.Text style={{width: 140}}>Location</Typography.Text>
                            <Select style={{flex: 1}} value={selectDonor?.locationId} onChange={value => {
                                for (let location of locations){
                                    if (location.locationId === value){
                                        if (location.appointmentPolicy && location.appointmentPolicy.acceptAppt){
                                            setSelectDonor({
                                                ...selectDonor,
                                                locationId: value,
                                                bookingDate: null,
                                                bookingTimeslot: null,
                                            })
                                            setTimezone(location.tz)
                                            fetchWeekDaysRange(selectDonor?.donorId, value, null)
                                        } else {
                                            setSelectDonor({
                                                ...selectDonor,
                                                locationId: value,
                                                weekDaysRange: null,
                                                appointmentType: null,
                                                forceType: null,
                                                closeDays: [],
                                                overriedDays: [],
                                            })
                                            setTimezone(location.tz)
                                            popMessage.error("Appointment not available for this center")
                                        }
                                        break;
                                    }
                                }
                            }}>
                                {locations && locations.length > 0 && locations.map((litem:any, lindex:number) => (
                                    <Option key={"locations" + lindex} value={litem.locationId}>{litem.name}</Option>
                                ))}
                            </Select>
                        </div>
                        {selectDonor?.weekDaysRange && ( 
                            <>
                                <div className='flex' style={{marginTop: 10, alignItems: "center"}}>
                                    <Typography.Text style={{width: 140}}>Visit Date</Typography.Text>
                                    <DatePicker
                                        className='appt-date-picker'
                                        value={selectDonor.bookingDate ? dayjs.tz(selectDonor.bookingDate, timezone) : null}
                                        disabledDate={disabledDate}
                                        onChange={value => {
                                            if (value){
                                                let bookingDate = value.format("YYYY-MM-DD")
                                                setSelectDonor({
                                                    ...selectDonor,
                                                    bookingDate: bookingDate,
                                                    bookingTimeslot: null,
                                                    timeslots: null
                                                })
                                                fetchTimeslot(selectDonor?.donorId, selectDonor?.locationId, bookingDate, selectDonor?.appointmentType);
                                            }
                                        }}
                                    />
                                    {selectDonor?.forceType && (
                                        <Typography.Text style={{flex: 1, marginLeft: 10}}>{"Force: " + getApptTypeName(selectDonor?.appointmentType)}</Typography.Text>
                                    )}

                                </div>
                                {selectDonor?.bookingDate && (
                                    <div className='flex appt-select' style={{marginTop: 10, alignItems: "center"}}>
                                        <Typography.Text style={{width: 140}}>Appointment Type</Typography.Text>
                                        <Select style={{flex: 1}} options={apptTypeOptions} value={getApptTypeName(selectDonor?.appointmentType)} onChange={value => {
                                            setSelectDonor({
                                                ...selectDonor,
                                                appointmentType: value,
                                                weekDaysRange: null,
                                                bookingDate: null,
                                                timeslots: null,
                                                forceType: value
                                            })
                                            fetchWeekDaysRange(selectDonor.donorId, selectDonor.locationId, value)
                                        }}>
                                        </Select>
                                    </div>
                                )}
                                {selectDonor?.timeslots && (
                                    <div className='flex appt-select' style={{marginTop: 10, alignItems: "center"}}>
                                        <Typography.Text style={{width: 140}}>Time Slot</Typography.Text>
                                        <Select style={{flex: 1}} value={selectDonor?.bookingTimeslot} onChange={value => setSelectDonor({
                                            ...selectDonor,
                                            bookingTimeslot: value
                                        })}>
                                            {selectDonor?.timeslots.map((titem:any, tindex:number) => {
                                                return <Option key={"times" + tindex} value={titem.time}>{moment.utc(titem.time, "HHmm").format("hh:mm a")}</Option>
                                            })}
                                        </Select>
                                    </div>
                                )}
                            </>
                        )}
                        <div className='flex' style={{marginTop: 20, justifyContent: "center"}}>
                            <Button disabled={!selectDonor?.timeslots} type="primary" onClick={bookAppointment}>Book</Button>
                        </div>
                    </>
                ) : (
                    <>
                        <div className='flex appt-input' style={{ alignItems: "center", marginTop: 10 }}>
                            <Typography.Text style={{ width: 140 }}>Donor ID / App ID</Typography.Text>
                            <Input style={{ flex: 1 }} value={searchDonor?.donorId} onChange={e => {
                                let value = e.target.value;
                                setSearchDonor({
                                    ...searchDonor,
                                    donorId: value
                                })
                            }} />
                            {/* <Input style={{ flex: 1 }} value={"testdnr01"} onClick={e => {
                                // let value = e.target.value;
                                setSearchDonor({
                                    ...searchDonor,
                                    donorId: "testdnr01"
                                })
                            }} /> */}
                        </div>
                        {searchDonor?.donor?.firstName && searchDonor?.donor?.lastName  && (
                            <div className='flex' style={{ alignItems: "center", marginTop: 10 }}>
                                <Typography.Text style={{ width: 140 }}>Donor Name</Typography.Text>
                                <Typography.Text style={{ flex: 1 }}>{formatDonorName(searchDonor?.donor)}</Typography.Text>
                            </div>
                        )}
                        {searchDonor?.donor?.phone && (
                            <div className='flex' style={{ alignItems: "center", marginTop: 10 }}>
                                <Typography.Text style={{ width: 140 }}>Phone Number</Typography.Text>
                                <Typography.Text style={{ flex: 1 }}>{formatPhone(searchDonor?.donor?.phone)}</Typography.Text>
                            </div>
                        )}
                        {searchDonor?.donor?.email && (
                            <div className='flex' style={{ alignItems: "center", marginTop: 10 }}>
                                <Typography.Text style={{ width: 140 }}>Email Address</Typography.Text>
                                <Typography.Text style={{ flex: 1 }}>{searchDonor?.donor?.email}</Typography.Text>
                            </div>
                        )}
                        <div className='flex' style={{ alignItems: "center", marginTop: 30, justifyContent: "space-around" }}>
                            <Button type="primary" loading={loading} onClick={fetchDonor}>Search</Button>
                            {searchDonor?.donor && <Button onClick={() => {
                                setSelectDonor(searchDonor);
                            }}>Book</Button>}
                        </div>
                    </>
                )}
            </Modal>
        </>
    );
};
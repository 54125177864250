import type * as React from 'react';
import { useEffect, useState } from 'react';
import { Survey } from 'bridge/survey';
import {
  DataTable,
  DataTableColumn,
  DataTableSetting,
  RowRecord,
} from '../../../components/data-table/data-table.component';
import { Badge, Button, Divider, Modal, Space, Tooltip, Typography } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import httpClient from '../../../utils/http-client.util';
import { BackendAPI } from '../../../constants/backend-api.enum';
import { popMessage } from '../../../utils/pop-message.util';
import dayjs from 'dayjs';
import { GeneralDateDisplayFormat } from 'bridge/date-format';
import { SurveyPreview } from './component/survey-preview.component';
import { QuestionLibrary } from './component/question-library.component';
import { TargetDonorFilter } from '../shared-component/target-donor-filter.component';

const SurveyStatisticLoader = ({
  survey,
  onLoad,
}: {
  survey: Survey;
  onLoad: (statistics: Survey['statistics']) => void;
}) => {
  const [loading, setLoading] = useState(false);
  const [statistics, setStatistics] = useState<Survey['statistics']>(survey.statistics);

  const loadStatistics = async () => {
    setLoading(true);
    try {
      const response = await httpClient.get(`${BackendAPI.SURVEY}/${survey.surveyId}/statistics`);
      setStatistics(response.data);
      onLoad(response.data);
    } catch (e) {
      popMessage.error({ content: 'Failed to load statistics for survey ' + survey.surveyId });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {statistics ? (
        <div>
          <Typography.Text>
            {statistics.completions.length} / {statistics.handouts}
          </Typography.Text>
        </div>
      ) : (
        <Button size={'small'} onClick={loadStatistics} loading={loading}>
          Load Statistic
        </Button>
      )}
    </div>
  );
};

export const SurveyListPage = () => {
  const [surveys, setSurveys] = useState<Survey[]>([]);

  const [draftSurveys, setDraftSurveys] = useState<Survey[]>([]);
  const [ongoingSurveys, setOngoingSurveys] = useState<Survey[]>([]);
  const [expiredSurveys, setExpiredSurveys] = useState<Survey[]>([]);

  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const expireSurvey = async (record: RowRecord<Survey>) => {
    // Expire survey
    try {
      setLoading(true);
      await httpClient.post(`${BackendAPI.SURVEY}/${record.surveyId}/expire`);
      popMessage.success({ content: `Survey ${record.surveyId} is expired now` });
      setSurveys(surveys.map((s) => (s.surveyId === record.surveyId ? { ...s, expirationDate: new Date() } : s)));
    } catch (e) {
      popMessage.error({ content: 'Failed to expire survey' + ': ' + record.surveyId });
    } finally {
      setLoading(false);
    }
  };
  const publishSurvey = async (record: RowRecord<Survey>) => {
    // Publish survey
    try {
      setLoading(true);
      await httpClient.post(`${BackendAPI.SURVEY}/${record.surveyId}/publish`);
      popMessage.success({ content: `Survey ${record.surveyId} is published now` });
      setSurveys(surveys.map((s) => (s.surveyId === record.surveyId ? { ...s, publishDate: new Date() } : s)));
    } catch (e) {
      popMessage.error({ content: 'Failed to publish survey' + ': ' + record.surveyId });
    } finally {
      setLoading(false);
    }
  };

  const columns: DataTableColumn<Survey>[] = [
    {
      title: 'Survey ID',
      dataIndex: 'surveyId',
    },
    {
      title: 'Title',
      dataIndex: ['title'],
      render: (titleObj: Survey['title'], record: RowRecord<Survey>) => (
        <div className={'flex justify-between items-center'}>
          <div>{titleObj.en || titleObj[Object.keys(titleObj)[0]]}</div>
          <Badge
            color={'#3b82f6'}
            count={Object.keys(record.title)
              .map((t) => t.toUpperCase())
              .join(' | ')}
          />
        </div>
      ),
      filters: surveys.map((s) => ({
        text: s.title.en || s.title[Object.keys(s.title)[0]],
        value: s.title.en || s.title[Object.keys(s.title)[0]],
      })),
    },
    {
      title: 'Dates of Survey',
      children: [
        {
          title: 'Generate',
          dataIndex: 'generatedDate',
          render: (date: Date) => dayjs(date).format(GeneralDateDisplayFormat),
          sorter: (a, b) => new Date(a.generatedDate).getTime() - new Date(b.generatedDate).getTime(),
        },
        {
          title: 'Publish',
          dataIndex: 'publishDate',
          render: (date: Date) => (date ? dayjs(date).format(GeneralDateDisplayFormat) : 'Not Published'),
        },
        {
          title: 'Expire',
          dataIndex: 'expirationDate',
          render: (date: Date) =>
            date ? (
              !dayjs(date).isAfter(dayjs(), 'day') ? (
                <span className={'line-through'}>{dayjs(date).format(GeneralDateDisplayFormat)}</span>
              ) : (
                dayjs(date).format(GeneralDateDisplayFormat)
              )
            ) : (
              'Never Expire'
            ),
        },
      ],
    },
    {
      title: 'Completed / Handouts',
      dataIndex: ['statistics'],
      render: (_, record) => (
        <SurveyStatisticLoader
          survey={record}
          onLoad={(statistics) => {
            record.statistics = statistics;
            setSurveys([...surveys]);
          }}
        />
      ),
    },
  ];

  const expiredTableSettings: DataTableSetting<Survey> = {
    customizedOperations: [
      {
        element: (record: RowRecord<Survey>, _: RowRecord<Survey>[]) => (
          <SurveyPreview survey={record}>
            <Tooltip title={'Survey Preview'}>
              <i className={'ri-eye-line cursor-pointer text-blue-500'} />
            </Tooltip>
          </SurveyPreview>
        ),
      },
      {
        text: <i className={'ri-file-copy-line'} />,
        whenPerform: async (record) => {
          navigate(`template/${record.surveyId}`, { state: { survey: record } });
        },
        tooltip: { title: 'Use as template' },
      },
      {
        text: <i className="ri-bar-chart-box-line" />,
        whenPerform: async (record) => {
          navigate(`${record.surveyId}/statistics`, { state: { survey: record } });
        },
        tooltip: { title: 'View statistics' },
      },
    ],
  };

  const ongoingTableSettings: DataTableSetting<Survey> = {
    customizedOperations: [
      {
        element: (record: RowRecord<Survey>, _: RowRecord<Survey>[]) => (
          <SurveyPreview survey={record}>
            <Tooltip title={'Survey Preview'}>
              <i className={'ri-eye-line cursor-pointer text-blue-500'} />
            </Tooltip>
          </SurveyPreview>
        ),
      },
      {
        text: <i className={'ri-file-copy-line'} />,
        whenPerform: async (record) => {
          navigate(`template/${record.surveyId}`, { state: { survey: record } });
        },
        tooltip: { title: 'Use as template' },
      },
      {
        text: <i className="ri-bar-chart-box-line" />,
        whenPerform: async (record) => {
          navigate(`${record.surveyId}/statistics`, { state: { survey: record } });
        },
        tooltip: { title: 'View statistics' },
      },
      {
        element: (record: RowRecord<Survey>, _: RowRecord<Survey>[]) => {
          return (
            <Tooltip title={'Expire this survey right now'}>
              <i
                className="ri-calendar-close-line text-red-500 cursor-pointer"
                title={'Expire this survey'}
                onClick={() =>
                  Modal.confirm({
                    title: `Expire Survey ${record.surveyId}`,
                    content: (
                      <div>
                        <Typography.Paragraph strong>Are you sure you want to expire this survey?</Typography.Paragraph>
                        <Typography.Paragraph>
                          After expiration, donors who haven't completed this survey won't be able to do so.
                        </Typography.Paragraph>
                        <Typography.Paragraph>
                          This survey will also not be pushed to later registered donors who meet the filter criteria.
                        </Typography.Paragraph>
                      </div>
                    ),
                    okText: 'Expire This Survey',
                    closable: true,
                    okButtonProps: { danger: true },
                    onOk: () => expireSurvey(record),
                  })
                }
              />
            </Tooltip>
          );
        },
      },
    ],
  };
  const draftTableSettings: DataTableSetting<Survey> = {
    customizedOperations: [
      {
        element: (record: RowRecord<Survey>, _: RowRecord<Survey>[]) => (
          <SurveyPreview survey={record}>
            <Tooltip title={'Survey Preview'}>
              <i className={'ri-eye-line cursor-pointer text-blue-500'} />
            </Tooltip>
          </SurveyPreview>
        ),
      },
      {
        text: <i className={'ri-file-copy-line'} />,
        whenPerform: async (record) => {
          navigate(`template/${record.surveyId}`, { state: { survey: record } });
        },
        tooltip: { title: 'Use as template' },
      },
      {
        text: <i className="ri-bar-chart-box-line" />,
        whenPerform: async (record) => {
          navigate(`${record.surveyId}/statistics`, { state: { survey: record } });
        },
        tooltip: { title: 'View statistics' },
      },
      {
        text: <i className={'ri-edit-line'} />,
        whenPerform: async (record) => {
          navigate(`edit/${record.surveyId}`, { state: { survey: record } });
        },
        tooltip: { title: 'Edit survey' },
      },
      {
        element: (record: RowRecord<Survey>, _: RowRecord<Survey>[]) => (
          <Tooltip title={'Publish this survey'}>
            <i
              className="ri-mail-send-line cursor-pointer text-red-500"
              onClick={() =>
                Modal.confirm({
                  title: `Publish Survey ${record.surveyId}`,
                  content: (
                    <div>
                      <Typography.Paragraph>
                        Publishing this survey will make it available to the public.
                      </Typography.Paragraph>
                      <Typography.Paragraph>
                        Target donors will be able to see and respond to this survey.
                      </Typography.Paragraph>
                      <Typography.Paragraph>
                        Later registered donors will also be able to see and respond to this survey if they meet the
                        filter criteria.
                      </Typography.Paragraph>
                      <div className={'my-3'}>
                        <TargetDonorFilter
                          existingFilter={record.participantFilter}
                          disabled
                          title={'Participant Filter'}
                        >
                          {(donorCount) => (
                            <Space>
                              <Typography.Text className={'inline-block'}>
                                Targeting:
                                {donorCount !== undefined ? (
                                  <Typography.Text className={'text-blue-500 mx-1'} strong>
                                    {donorCount}
                                  </Typography.Text>
                                ) : (
                                  <i className={'ri-loader-3-line inline-block animate-spin text-blue-500 mx-1'} />
                                )}
                                Donor(s)
                              </Typography.Text>
                              <Typography.Link>Check Participant Filter</Typography.Link>
                            </Space>
                          )}
                        </TargetDonorFilter>
                      </div>
                    </div>
                  ),
                  okText: 'Publish This Survey',
                  closable: true,
                  onOk: () => publishSurvey(record),
                })
              }
            />
          </Tooltip>
        ),
      },
      {
        text: <i className={'ri-delete-bin-3-line text-red-500'} />,
        whenPerform: async (record) => {
          try {
            setLoading(true);
            await httpClient.delete(`${BackendAPI.SURVEY}/${record.surveyId}`);
            popMessage.success({ content: `Survey ${record.surveyId} is deleted` });
            setSurveys(surveys.filter((s) => s.surveyId !== record.surveyId));
          } catch (e) {
            popMessage.error({ content: 'Failed to delete survey' + ': ' + record.surveyId });
          } finally {
            setLoading(false);
          }
        },
        confirmWithMessage: (record) => `Are you sure you want to delete survey ${record.surveyId}?`,
        tooltip: { title: 'Delete survey' },
      },
    ],
  };

  const scrollTo = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    // Fetch surveys
    (async () => {
      setLoading(true);
      try {
        const response = await httpClient.get(`${BackendAPI.SURVEY}`);
        setSurveys(response.data);
      } catch (e) {
        popMessage.error({ content: 'Failed to fetch surveys' });
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (surveys) {
      const expiredList: Survey[] = surveys.filter((s) => s.publishDate && dayjs(s.expirationDate).isBefore(dayjs()));
      const ongoingList: Survey[] = surveys.filter((s) => s.publishDate && !dayjs(s.expirationDate).isBefore(dayjs()));
      const draftList: Survey[] = surveys.filter((s) => !s.publishDate);

      setDraftSurveys(draftList);
      setOngoingSurveys(ongoingList);
      setExpiredSurveys(expiredList);
    }
  }, [surveys]);

  return (
    <div className={'relative'}>
      <div className={'flex justify-between items-center'}>
        <div className={'flex items-baseline'}>
          <Typography.Title level={3} className={'mr-4'}>
            Surveys
          </Typography.Title>
          <Typography.Link type={'secondary'} onClick={() => scrollTo('draft-surveys')}>
            Draft
          </Typography.Link>
          <Divider type={'vertical'} className={'transform rotate-12'} />
          <Typography.Link type={'secondary'} onClick={() => scrollTo('ongoing-surveys')}>
            Ongoing
          </Typography.Link>
          <Divider type={'vertical'} className={'transform rotate-12'} />
          <Typography.Link type={'secondary'} onClick={() => scrollTo('expired-surveys')}>
            Expired
          </Typography.Link>
        </div>
        <Space>
          <QuestionLibrary>
            <Button>Question Library</Button>
          </QuestionLibrary>
          <Link to={'new'}>
            <Button type={'primary'}>Create New Survey</Button>
          </Link>
        </Space>
      </div>
      <div id="draft-surveys">
        <Typography.Title level={4}>Draft Surveys</Typography.Title>
        <DataTable data={draftSurveys} setting={draftTableSettings} loading={loading} columnsDef={columns} />
      </div>
      <div id="ongoing-surveys">
        <Typography.Title level={4}>Ongoing Surveys</Typography.Title>
        <DataTable data={ongoingSurveys} setting={ongoingTableSettings} loading={loading} columnsDef={columns} />
      </div>
      <div id="expired-surveys">
        <Typography.Title level={4}>Expired Surveys</Typography.Title>
        <DataTable data={expiredSurveys} setting={expiredTableSettings} loading={loading} columnsDef={columns} />
      </div>
    </div>
  );
};

import { Button, Card, Input, Radio, Select, Space, Typography } from 'antd';
import { EntityCondition } from 'bridge/promotion-bonus';
import React, { useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { RadioChangeEvent } from 'antd/lib';

export const CustomGroup = ({ onChangeData, conditionData }: { onChangeData?: (data: EntityCondition[]) => void, conditionData: EntityCondition[] }) => {
  const [customGroupConditions, setCustomGroupConditions] = useState<EntityCondition[]>([
    {
      duration: [''],
      donations: [
        {
          type: '',
          value: '',
        },
      ],
    },
  ]);
  let donationType = ["money", "point"]

  useEffect(() => {
    if (onChangeData) {
      onChangeData(customGroupConditions)
    }
  }, [customGroupConditions])

  useEffect(() => {
    if (conditionData && conditionData.length > 0) {
      setCustomGroupConditions(conditionData)
    }
  }, [conditionData])

  const handleAddCondition = () => {
    setCustomGroupConditions([...customGroupConditions, {
      duration: [''],
      donations: [
        {
          type: '',
          value: '',
        },
      ]
    }])
  };

  const handleDeleteCondition = (index: number) => {
    if (customGroupConditions.length > 1) {
      customGroupConditions.splice(index, 1)
      setCustomGroupConditions([...customGroupConditions])
    }
  }

  const handleAddDonation = (index: number) => {
    let newCondition = [...customGroupConditions];
    let targetField = { ...newCondition[index] }
    targetField = {
      ...targetField, donations: [...targetField.donations, {
        type: "",
        value: ""
      }]
    }
    newCondition[index] = targetField
    setCustomGroupConditions(newCondition)
  }

  const handleSelectDonation = (event: RadioChangeEvent, indexCondition: number, indexDonation: number) => {
    let newCondition = [...customGroupConditions];
    newCondition[indexCondition].donations[indexDonation].value = ""
    newCondition[indexCondition].donations[indexDonation].type = event.target.value
    setCustomGroupConditions(newCondition)
  }

  const handleDeleteDonation = (indexCondition: number, indexDonation: number) => {
    if (customGroupConditions[indexCondition].donations.length > 1) {
      customGroupConditions[indexCondition].donations.splice(indexDonation, 1)
      setCustomGroupConditions([...customGroupConditions])
    }
  }

  return (
    <div>
      <div className="flex flex-col gap-4" style={{ maxWidth: 600 }}>
        {
          customGroupConditions.map((item, index) => (
            <Card
              size="small"
              title={`Condition #${index + 1}`}
              key={index}
              extra={
                customGroupConditions.length > 1 ? <CloseOutlined onClick={() => handleDeleteCondition(index)} /> : null
              }
              className='condition-item'
              style={{ backgroundColor: '#fafafa' }}
            >
              <div className='flex flex-col gap-4'>
                <Typography.Text className="flex flex-col gap-2 items-start span font-semibold">
                  Donor Group
                  <Select
                    className='custom-select w-full'
                    mode="multiple"
                  />
                </Typography.Text>
                {
                  item.donations.map((it, ind) => (
                    <div className='d-flex flex-row align-items-center gap-2'>
                      <Card
                        size="small"
                        title={`Donation #${ind + 1}`}
                        key={ind}
                        extra={
                          item.donations.length > 1 ? <CloseOutlined onClick={() => handleDeleteDonation(index, ind)} /> : null
                        }
                      >
                        <Space direction="vertical" className='flex flex-row'>
                          {
                            donationType.map((itemDonation, indexDonation) => (
                              <Radio checked={it.type === itemDonation} onChange={(event) => handleSelectDonation(event, index, ind)} value={itemDonation} className='flex flex-row items-center'>
                                <div className='flex flex-row items-center gap-4'>
                                  <Input
                                    name={itemDonation}
                                    disabled={item.donations[ind].type === itemDonation ? false : true}
                                    value={item.donations[ind].type === itemDonation ? customGroupConditions[index].donations[ind].value : ""}
                                    onChange={(event) => {
                                      let condition = [...customGroupConditions];
                                      let targetField = { ...condition[index] }
                                      let donation = [...targetField.donations]
                                      let target = { ...donation[ind] }
                                      target = { ...target, value: event.target.value }
                                      donation[ind] = target
                                      condition[index].donations = donation
                                      setCustomGroupConditions(condition)
                                    }} />
                                  <span>{itemDonation === "money" ? "$" : "pts"}</span>
                                </div>
                              </Radio>
                            ))
                          }
                        </Space>
                      </Card>
                    </div>
                  ))
                }
                <Button type="dashed" onClick={() => handleAddDonation(index)} block>
                  + Add Donation
                </Button>
              </div>
            </Card>
          ))
        }
      </div>
    </div>
  );
};

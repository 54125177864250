import {
  Alert,
  Badge,
  Button,
  Checkbox,
  Divider,
  Drawer,
  Input,
  List,
  Modal,
  Popconfirm,
  Radio,
  Select,
  Space,
  Switch,
  Tabs,
  Typography,
} from 'antd';
import { underLinedInputClass } from '../../../../components/inputs/editable-attribute.component';
import React, { PropsWithChildren, useEffect } from 'react';
import { Choice, Question } from 'bridge/survey';
import httpClient from '../../../../utils/http-client.util';
import { BackendAPI } from '../../../../constants/backend-api.enum';
import { popMessage } from '../../../../utils/pop-message.util';
import { supportingLanguages } from '../../../../constants/language.constant';
import { DragDropContext, Draggable, DropResult } from '@erom/react-beautiful-dnd';
import { StrictModeDroppable } from '../../../../utils/strict-mode-dnd.util';

type Prop = {
  onAddToSurvey?: (q: Question) => void;
} & PropsWithChildren;
export const QuestionLibrary = ({ children, onAddToSurvey }: Prop) => {
  const [questions, setQuestions] = React.useState<Question[]>([]);
  const [openQuestionDrawer, setOpenQuestionDrawer] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [openQuestionEditor, setOpenQuestionEditor] = React.useState(false);
  const [editingQuestion, setEditingQuestion] = React.useState<Question>();
  const [currentEditLanguage, setCurrentEditingLanguage] = React.useState<string>('');
  const [savingQuestionChange, setSavingQuestionChange] = React.useState(false);

  const renderQuestion = (q: Question) => {
    const firstLanguage = q.question.en ? 'en' : Object.keys(q.question)[0];
    return (
      <>
        {q.type === 'free-input' ? (
          <Input.TextArea
            disabled
            className={'w-[90%]'}
            placeholder={q.placeholder ? q.placeholder[firstLanguage] : ''}
          />
        ) : (
          <>
            {q.type === 'choose-one' ? (
              <>
                {q.choices?.map((c, cIndex) => (
                  <div className={'inline-block'}>
                    <Radio key={cIndex} checked={false} value={cIndex}>
                      {c.content[firstLanguage]}
                      <sup>{c.withInput && <i className={'ml-1 ri-input-field text-xs text-amber-500'} />}</sup>
                    </Radio>
                  </div>
                ))}
              </>
            ) : (
              <div>
                {q.choices?.map((c, cIndex) => (
                  <div className={'inline-block'}>
                    <Checkbox key={cIndex} checked={false}>
                      {c.content[firstLanguage]}
                      <sup>
                        {c.withInput && <i className={'ml-1 ri-input-field text-xs text-amber-500'} />}
                        {c.exclusive && (
                          <i className={'ml-1 ri-checkbox-indeterminate-line text-xs text-amber-500'}></i>
                        )}
                      </sup>
                    </Checkbox>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
        <div className={'mt-1'}>
          <Badge
            color={'#3b82f6'}
            count={Object.keys(q.question)
              .map((t) => t.toUpperCase())
              .join(' | ')}
          />
        </div>
      </>
    );
  };

  const droppableStyle = (isDraggingOver: boolean) => ({
    border: isDraggingOver ? '2px dashed lightgreen' : 'none',
    width: '100%',
    padding: 8,
    minHeight: 225,
  });

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    if (editingQuestion) {
      const newChoices = Array.from(editingQuestion.choices || []);
      const [reorderedItem] = newChoices.splice(result.source.index, 1);
      newChoices.splice(result.destination.index, 0, reorderedItem);
      editingQuestion.choices = newChoices;
      setEditingQuestion({ ...editingQuestion });
    }
  };

  const addChoice = () => {
    if (editingQuestion) {
      editingQuestion.choices = editingQuestion.choices || [];
      editingQuestion.choices.push({ content: {} });
      setEditingQuestion({ ...editingQuestion });
    }
  };

  const onDragStart = () => {};

  const saveQuestion = async () => {
    setSavingQuestionChange(true);
    try {
      if (editingQuestion) {
        await httpClient.put(`${BackendAPI.SURVEY}/question/${editingQuestion._id}`, editingQuestion);
        popMessage.success({ content: 'Question saved successfully' });
        questions[questions.findIndex((q) => q._id === editingQuestion._id)] = editingQuestion;
        setQuestions(questions);
        setOpenQuestionEditor(false);
      }
    } catch (e) {
      popMessage.error({ content: 'Failed to save question' });
    } finally {
      setSavingQuestionChange(false);
    }
  };
  const deleteQuestion = async (tobeDeleted: Question) => {
    try {
      await httpClient.delete(`${BackendAPI.SURVEY}/question/${tobeDeleted._id}`);
      popMessage.success({ content: 'Question deleted successfully' });
      setQuestions(questions.filter((q) => q._id !== tobeDeleted._id));
      setOpenQuestionEditor(false);
    } catch (e) {
      popMessage.error({ content: 'Failed to delete question' });
    }
  };

  useEffect(() => {
    (async () => {
      try {
        if (openQuestionDrawer) {
          // fetch questions
          const pullQuestions = await httpClient.get<Question[]>(`${BackendAPI.SURVEY}/questions`);
          setQuestions(pullQuestions.data);
        }
      } catch (e) {
        popMessage.error({ content: 'Failed to fetch questions', duration: 5 });
      } finally {
        setLoading(false);
      }
    })();
  }, [openQuestionDrawer]);

  return (
    <div>
      <span className={'cursor-pointer'} onClick={() => setOpenQuestionDrawer(true)}>
        {children}
      </span>
      <Drawer title="Question Library" onClose={() => setOpenQuestionDrawer(false)} open={openQuestionDrawer}>
        <Input
          placeholder={'Search Question Library'}
          className={`${underLinedInputClass} w-full`}
          variant="borderless"
          style={{ borderBottomStyle: 'solid' }}
          suffix={<i className={'ri-search-line'} />}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <i className={'ml-1 ri-input-field text-amber-500'}> Extra Text Input</i>
        <Divider type={'vertical'} />
        <i className={'ml-1 ri-checkbox-indeterminate-line text-amber-500'}> Exclusive</i>
        <List
          loading={loading}
          style={{ overflowY: 'auto', height: 'calc(100% - 60px)', paddingRight: '10px' }}
          dataSource={questions.filter((q) =>
            (q.question.en || q.question[Object.keys(q.question)[0]]).toUpperCase().includes(searchQuery.toUpperCase())
          )}
          itemLayout="horizontal"
          renderItem={(q) => (
            <List.Item>
              <List.Item.Meta
                title={q.question.en || q.question[Object.keys(q.question)[0]]}
                description={renderQuestion(q)}
              />
              <Space direction={'vertical'}>
                {onAddToSurvey && (
                  <i className={'ri-add-circle-line text-blue-500 cursor-pointer'} onClick={() => onAddToSurvey(q)}></i>
                )}
                <i
                  className={'ri-edit-line text-blue-500 cursor-pointer'}
                  onClick={() => {
                    setOpenQuestionEditor(true);
                    setEditingQuestion(JSON.parse(JSON.stringify(q)));
                  }}
                />
                <Popconfirm
                  title={'Are you sure to delete this question?'}
                  onConfirm={() => deleteQuestion(q)}
                  placement={'left'}
                >
                  <i className={'ri-delete-bin-3-line text-red-500 cursor-pointer'} />
                </Popconfirm>
              </Space>
            </List.Item>
          )}
        />
      </Drawer>
      <Modal
        title={'Edit Question'}
        open={openQuestionEditor}
        onCancel={() => setOpenQuestionEditor(false)}
        onOk={saveQuestion}
        okText={'Save'}
        confirmLoading={savingQuestionChange}
      >
        <Alert type={'info'} message={'Modify question does not affect existing survey'} />
        <div className={'mb-2 flex justify-between items-center'}>
          <div className={'w-[49%]'}>
            <div>
              <Typography.Text type="secondary">Languages</Typography.Text>
            </div>
            <Select
              mode={'multiple'}
              showSearch
              variant="borderless"
              value={Object.keys(editingQuestion?.question || {})}
              className={`${underLinedInputClass} w-[100%]`}
              style={{ borderBottomStyle: 'solid', flex: 1 }}
              options={supportingLanguages}
              onChange={(selected) => {
                if (!currentEditLanguage) {
                  setCurrentEditingLanguage(selected[0]);
                }

                if (editingQuestion) {
                  const existingQuestion = editingQuestion.question || {};
                  const newQuestion: Question['question'] = {};
                  selected.forEach((s: string) => {
                    if (!existingQuestion[s]) {
                      newQuestion[s] = '';
                    } else {
                      newQuestion[s] = existingQuestion[s];
                    }
                  });
                  editingQuestion.question = newQuestion;
                  editingQuestion.choices?.forEach((c) => {
                    const existingChoice = c.content || {};
                    const newChoice: Choice['content'] = {};
                    selected.forEach((s: string) => {
                      if (!existingChoice[s]) {
                        newChoice[s] = '';
                      } else {
                        newChoice[s] = existingChoice[s];
                      }
                    });
                    c.content = newChoice;
                  });
                  setEditingQuestion({ ...editingQuestion });
                }
              }}
            />
          </div>
          <div className={'w-[49%]'}>
            <div>
              <Typography.Text type="secondary">Type</Typography.Text>
            </div>
            <Select
              variant="borderless"
              className={`${underLinedInputClass} w-[100%] -mt-0.5`}
              style={{ borderBottomStyle: 'solid', flex: 1 }}
              value={editingQuestion?.type}
              onChange={(type) => {
                if (editingQuestion) {
                  editingQuestion.type = type;
                  setEditingQuestion({ ...editingQuestion });
                }
              }}
            >
              <Select.Option value={'free-input'}>Text-based Answer</Select.Option>
              <Select.Option value={'choose-one'}>Only One Answer Allowed</Select.Option>
              <Select.Option value={'choose-many'}>Many Answers Allowed</Select.Option>
            </Select>
          </div>
        </div>
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
          <StrictModeDroppable droppableId={'question-editor'} direction={'vertical'}>
            {(droppableProvided, droppableSnapshot) => (
              <Tabs>
                {editingQuestion && (
                  <>
                    {Object.keys(editingQuestion.question || {}).map((lang) => (
                      <Tabs.TabPane key={lang} tab={lang.toUpperCase()}>
                        <div>
                          <Typography.Text type={'secondary'} className={'block'}>
                            Title:
                          </Typography.Text>
                          <Input
                            value={editingQuestion?.question[lang]}
                            variant={'borderless'}
                            className={`${underLinedInputClass} w-full`}
                            style={{ borderBottomStyle: 'solid', flex: 1 }}
                            onChange={(e) => {
                              if (editingQuestion) {
                                editingQuestion.question[lang] = e.target.value;
                                setEditingQuestion({ ...editingQuestion });
                              }
                            }}
                          />
                        </div>
                        <Divider />
                        <div>
                          {editingQuestion.type === 'free-input' ? (
                            <Space>
                              <Typography.Text type={'secondary'}>Input Placeholder:</Typography.Text>
                              <Input
                                value={editingQuestion.placeholder?.[lang]}
                                variant={'borderless'}
                                className={`${underLinedInputClass} w-full`}
                                style={{ borderBottomStyle: 'solid', flex: 1 }}
                                onChange={(e) => {
                                  if (editingQuestion) {
                                    editingQuestion.placeholder = {
                                      ...editingQuestion.placeholder,
                                      [lang]: e.target.value,
                                    };
                                    setEditingQuestion({ ...editingQuestion });
                                  }
                                }}
                              />
                            </Space>
                          ) : (
                            <Space
                              direction={'vertical'}
                              ref={droppableProvided.innerRef}
                              {...droppableProvided.droppableProps}
                              style={droppableStyle(droppableSnapshot.isDraggingOver)}
                            >
                              {editingQuestion?.choices?.map((c, cIndex) => (
                                <Draggable draggableId={`${lang}-c-${cIndex}`} index={cIndex}>
                                  {(draggableProvided, _) => (
                                    <div
                                      key={`${lang}-c-${cIndex}`}
                                      className={'ml-4 relative'}
                                      ref={draggableProvided.innerRef}
                                      {...draggableProvided.draggableProps}
                                    >
                                      <i
                                        className={'ri-draggable absolute -left-4 top-2'}
                                        {...draggableProvided.dragHandleProps}
                                      />
                                      <Input
                                        value={c.content[lang]}
                                        variant={'borderless'}
                                        className={`${underLinedInputClass} w-full`}
                                        style={{ borderBottomStyle: 'solid', flex: 1 }}
                                        onChange={(e) => {
                                          if (editingQuestion) {
                                            c.content[lang] = e.target.value;
                                            setEditingQuestion({ ...editingQuestion });
                                          }
                                        }}
                                        addonBefore={
                                          editingQuestion.type === 'choose-one' ? (
                                            <i className={'ri-radio-button-line'} />
                                          ) : (
                                            <i className={'ri-checkbox-line'} />
                                          )
                                        }
                                        suffix={
                                          <Popconfirm
                                            title={'Are you sure to delete this choice?'}
                                            onConfirm={() => {
                                              if (editingQuestion) {
                                                editingQuestion.choices = editingQuestion.choices?.filter(
                                                  (_, i) => i !== cIndex
                                                );
                                                setEditingQuestion({ ...editingQuestion });
                                              }
                                            }}
                                          >
                                            <i className={'ri-delete-bin-3-line text-red-500 cursor-pointer'} />
                                          </Popconfirm>
                                        }
                                      />
                                      <Space>
                                        <Space>
                                          <Typography.Text type={'secondary'}>With Text Input:</Typography.Text>
                                          <Switch
                                            size={'small'}
                                            checked={!!c.withInput}
                                            onChange={(checked) => {
                                              if (editingQuestion) {
                                                c.withInput = checked ? { placeholder: { [lang]: '' } } : undefined;
                                                setEditingQuestion({ ...editingQuestion });
                                              }
                                            }}
                                          />
                                        </Space>
                                        {editingQuestion.type === 'choose-many' && (
                                          <Space>
                                            <Typography.Text type={'secondary'}>Exclusive:</Typography.Text>
                                            <Switch
                                              size={'small'}
                                              checked={c.exclusive}
                                              onChange={(checked) => {
                                                if (editingQuestion) {
                                                  c.exclusive = checked;
                                                  setEditingQuestion({ ...editingQuestion });
                                                }
                                              }}
                                            />
                                          </Space>
                                        )}
                                      </Space>
                                      <div>
                                        {c.withInput && (
                                          <Space>
                                            <Typography.Text type={'secondary'}>Input Placeholder:</Typography.Text>
                                            <Input
                                              value={c.withInput.placeholder[lang]}
                                              variant={'borderless'}
                                              size={'small'}
                                              className={`${underLinedInputClass} w-full`}
                                              style={{ borderBottomStyle: 'solid', flex: 1 }}
                                              onChange={(e) => {
                                                if (editingQuestion && c.withInput) {
                                                  c.withInput.placeholder[lang] = e.target.value;
                                                  setEditingQuestion({ ...editingQuestion });
                                                }
                                              }}
                                            />
                                          </Space>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {droppableProvided.placeholder}
                              <Button block size={'small'} className={'mt-4'} onClick={addChoice}>
                                Add Choice
                              </Button>
                            </Space>
                          )}
                        </div>
                      </Tabs.TabPane>
                    ))}
                  </>
                )}
              </Tabs>
            )}
          </StrictModeDroppable>
        </DragDropContext>
      </Modal>
    </div>
  );
};

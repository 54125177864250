import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Checkbox,
  Collapse,
  Empty,
  Form,
  Input,
  Modal,
  Popconfirm,
  Space,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import { DeleteColumnOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { useBoundStore } from '../../states/bound.store';
import { ApplicationRight, ApplicationRole } from 'bridge/authentication';
import {
  DataTable,
  DataTableColumn,
  DataTableSetting,
  RowRecord,
} from '../../components/data-table/data-table.component';
import httpClient from '../../utils/http-client.util';
import { BackendAPI } from '../../constants/backend-api.enum';
import { popMessage } from '../../utils/pop-message.util';
import { ColumnType } from 'antd/lib/table';

type RoleTableRightGroup = {
  _id: string;
  key: string;
  displayName: string;
  groupName: string;
  children: RoleTableIndividualRight[];
};

type RoleTableIndividualRight = {
  _id: string;
  key: string;
  displayName: string;
  right: ApplicationRight;
  roleInclusion: {
    [roleName: string]: boolean;
  };
};

type RoleTableData = RoleTableRightGroup | RoleTableIndividualRight;

export const RightsAndRolesPage = () => {
  const [allRights, setAllRights] = useState<ApplicationRight[]>([]);
  const [allRoles, setAllRoles] = useState<ApplicationRole<ApplicationRight>[]>([]);
  const [originalAllRoles, setOriginalAllRoles] = useState<ApplicationRole<ApplicationRight>[]>([]);
  const [rightLoading, setRightLoading] = useState(true);
  const [roleLoading, setRoleLoading] = useState(true);
  const [roleTableColumns, setRoleTableColumns] = useState<ColumnType<RoleTableData>[]>([]);
  const [roleTableData, setRoleTableData] = useState<RoleTableData[]>([]);
  const [defaultExpandedRowKeys, setDefaultExpandedRowKeys] = useState<string[]>([]);
  const [noRoleIsChanged, setNoRoleIsChanged] = useState(true);
  const [applyingRoleChange, setApplyingRoleChange] = useState(false);
  const user = useBoundStore((state) => state.user);

  const rightTableSetting: DataTableSetting<ApplicationRight> = {
    editable:
      user && user.role.canCUDRight
        ? {
            onEditDone: async (_id, newData) => {
              setRightLoading(true);
              const index = allRights.findIndex((item) => _id === item._id);

              const item = allRights[index];
              try {
                await httpClient.put(`${BackendAPI.RIGHT_ROLE_MANAGEMENT}/right`, {
                  name: item.name,
                  group: newData.group.trim(),
                  description: newData.description.trim(),
                });
                const newRights = [...allRights];
                newRights.splice(index, 1, {
                  group: newData.group.trim(),
                  description: newData.description.trim(),
                  name: item.name,
                  _id: item._id,
                });
                setAllRights([...newRights]);
                return {
                  content: `Right [${item.name}] has been updated!`,
                };
              } catch (reason) {
                throw new Error(`Failed to update Right [${item.name}]`);
              } finally {
                setRightLoading(false);
              }
            },
          }
        : false,
    removable:
      user && user.role.canCUDRight
        ? {
            onRemove: async (_id) => {
              setRightLoading(true);
              try {
                await httpClient.delete(`${BackendAPI.RIGHT_ROLE_MANAGEMENT}/right/${_id}`);
                setAllRights(allRights.filter((item) => item._id !== _id));
                return {
                  content: `Right [${allRights.find((item) => item._id === _id)?.name}] has been removed!`,
                };
              } catch (reason) {
                throw new Error(`Failed to remove Right [${allRights.find((item) => item._id === _id)?.name}]`);
              } finally {
                setRightLoading(false);
              }
            },
          }
        : false,
    appendable:
      user && user.role.canCUDRight
        ? {
            onAppend: async (row) => {
              setRightLoading(true);
              try {
                const res = await httpClient.post(`${BackendAPI.RIGHT_ROLE_MANAGEMENT}/right`, {
                  name: row.newName.trim(),
                  group: row.newGroup.trim(),
                  description: row.newDescription.trim(),
                });
                const newRightObj: RowRecord<ApplicationRight> = {
                  _id: res.data._id,
                  name: row.newName.trim(),
                  group: row.newGroup.trim(),
                  description: row.newDescription.trim(),
                  __newRecord__: true,
                };
                setAllRights([...allRights, newRightObj]);
                return {
                  content: `New Right [${row.newName.trim()}] has been added!`,
                };
              } catch (reason) {
                throw new Error(`Failed to add new Right [${row.newName.trim()}]`);
              } finally {
                setRightLoading(false);
              }
            },
          }
        : false,
  };

  const appRightTableColumns: DataTableColumn<ApplicationRight>[] = [
    {
      title: 'Right Name',
      dataIndex: 'name',
      width: '20%',
      nameWhenAppending: ['newName'],
      newRecordInputRender: 'input',
    },
    {
      title: 'Description',
      width: '45%',
      dataIndex: 'description',
      nameWhenAppending: ['newDescription'],
      editRender: 'input',
      render: (text: string) => <Typography.Text type={'secondary'}>{text}</Typography.Text>,
    },
    {
      title: 'Group',
      width: '20%',
      dataIndex: 'group',
      nameWhenAppending: ['newGroup'],
      editRender: 'input',
      render: (text: string) => <Typography.Text type={'secondary'}>{text}</Typography.Text>,
      filterable: true,
      sorter: (a: ApplicationRight, b: ApplicationRight) => (a.group === b.group ? 0 : a.group > b.group ? 1 : -1),
    },
  ];

  function areArraysEqualIgnoreOrder(
    arr1: ApplicationRole<ApplicationRight>[],
    arr2: ApplicationRole<ApplicationRight>[]
  ) {
    const sortArraysRecursively: (obj: Object) => Object = (obj) => {
      if (_.isArray(obj)) {
        return _.sortBy(obj.map(sortArraysRecursively));
      } else if (_.isObject(obj)) {
        return _.map(obj, (value, key) => ({ [key]: sortArraysRecursively(value) }));
      }
      return obj;
    };
    return _.isEqual(sortArraysRecursively(arr1), sortArraysRecursively(arr2));
  }

  const confirmDeleteRole = (e: string) => {
    setAllRoles(allRoles.filter((role) => role.name !== e));
  };

  const addNewRole = async (value: string) => {
    if (!value) {
      popMessage.error('Role name cannot be empty');
      return;
    }
    if (allRoles.find((role) => role.name.toLowerCase() === value.toLowerCase())) {
      popMessage.error('Role name already exists or not valid');
      return;
    }
    allRoles.push({ name: value.trim(), rights: [] } as ApplicationRole<ApplicationRight>);
    setAllRoles([...allRoles]);
  };

  const roleChange = (role: ApplicationRole<ApplicationRight>, right: ApplicationRight, checked: boolean) => {
    const targetingRole = allRoles.find((r) => r.name === role.name);
    if (targetingRole) {
      if (checked) {
        const associatedRights = targetingRole.rights;
        if (!associatedRights.find((r) => r.name === right.name)) {
          targetingRole.rights.push(right);
          setAllRoles([...allRoles]);
        }
      } else {
        const toRemovedIndex = targetingRole.rights.findIndex((r) => r.name === right.name);
        if (toRemovedIndex !== -1) {
          targetingRole.rights.splice(toRemovedIndex, 1);
          setAllRoles([...allRoles]);
        }
      }
    }
  };

  const selectOrUnselectAll = (checked: boolean, role: ApplicationRole<ApplicationRight>, groupName: string) => {
    const thisGroupRights = allRights.filter((ar) => ar.group === groupName);
    if (checked) {
      role.rights = _.unionBy(role.rights, thisGroupRights, 'name');
    } else {
      role.rights = role.rights.filter((r) => !thisGroupRights.some((ar) => ar.name === r.name));
    }
    setAllRoles([...allRoles]);
  };

  const confirmRoleChange = () => {
    const newRoles = _.differenceBy(allRoles, originalAllRoles, (a) => a.name);
    const deletedRoles = _.differenceBy(originalAllRoles, allRoles, (a) => a.name);
    const changedRoles: ApplicationRole<ApplicationRight>[] = [];
    const allRolesStayed = allRoles.filter((role) => originalAllRoles.find((or) => or.name === role.name));
    allRolesStayed.forEach((role) => {
      const correspondingOriginalRole = originalAllRoles.find(
        (or) => or.name === role.name
      ) as ApplicationRole<ApplicationRight>;
      if (!_.isEqual(_.sortBy(correspondingOriginalRole.rights), _.sortBy(role.rights))) {
        changedRoles.push(role);
      }
    });

    const payload = {
      newRoles,
      deletedRoles,
      changedRoles,
    };

    Modal.confirm({
      title: 'Confirm your changes',
      content: (
        <Collapse defaultActiveKey={['1', '2', '3']}>
          <Collapse.Panel
            header={
              <>
                Role(s) to be <span style={{ color: '#3f8600' }}>created:</span>
              </>
            }
            key="1"
          >
            <div>
              {newRoles.map((r) => r.name).join(', ') || (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={false} />
              )}
            </div>
          </Collapse.Panel>
          <Collapse.Panel
            header={
              <>
                Role(s) to be <span style={{ color: '#cf1322' }}>deleted:</span>
              </>
            }
            key="2"
          >
            <div>
              {deletedRoles.map((r) => r.name).join(', ') || (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={false} />
              )}
            </div>
          </Collapse.Panel>
          <Collapse.Panel
            header={
              <>
                Role(s) to be <span style={{ color: '#16c1f6' }}>modified:</span>
              </>
            }
            key="3"
          >
            <div>
              {changedRoles.map((r) => r.name).join(', ') || (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={false} />
              )}
            </div>
          </Collapse.Panel>
        </Collapse>
      ),
      okText: 'Confirm Role Change',
      onOk: async () => {
        setApplyingRoleChange(true);
        try {
          await httpClient.put(`${BackendAPI.RIGHT_ROLE_MANAGEMENT}/role`, payload);
          setOriginalAllRoles(JSON.parse(JSON.stringify(allRoles)));
          popMessage.success({ content: 'Role changes have been applied' });
        } catch (e) {
          popMessage.error({ content: 'Unable to apply role changes!' });
        } finally {
          setApplyingRoleChange(false);
        }
      },
    });
  };

  useEffect(() => {
    (async () => {
      try {
        const rightsResponse = await httpClient.get<ApplicationRight[]>(BackendAPI.RIGHT_ROLE_MANAGEMENT + '/rights');
        const rolesResponse = await httpClient.get<ApplicationRole<ApplicationRight>[]>(
          BackendAPI.RIGHT_ROLE_MANAGEMENT + '/roles'
        );
        setAllRights(rightsResponse.data);
        setAllRoles(rolesResponse.data);
        setOriginalAllRoles(JSON.parse(JSON.stringify(rolesResponse.data)));
      } catch (e) {
        console.error('Unable to fetch rights');
      } finally {
        setRightLoading(false);
        setRoleLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    const roleTableData: RoleTableData[] = [];
    const rowKeys: string[] = [];
    allRights.forEach((right) => {
      allRoles
        .filter((role) => !role.devOnly)
        .forEach((role) => {
          const thisRoleContainsThisRight = !!role.rights.find((r) => r.name === right.name);
          const thisRightGroup = right.group;
          const existingGroup = roleTableData.find(
            (data): data is RoleTableRightGroup => 'groupName' in data && data.groupName === thisRightGroup
          );
          if (existingGroup) {
            const existingRight = existingGroup.children.find((d) => d._id === right._id);
            if (existingRight) {
              existingRight.roleInclusion[role.name] = thisRoleContainsThisRight;
            } else {
              existingGroup.children.push({
                _id: right._id,
                key: right._id,
                displayName: right.name,
                right: right,
                roleInclusion: {
                  [role.name]: thisRoleContainsThisRight,
                },
              });
            }
          } else {
            roleTableData.push({
              _id: 'GROUP_' + thisRightGroup.replaceAll(' ', ''),
              key: 'GROUP_' + thisRightGroup.replaceAll(' ', ''),
              groupName: thisRightGroup,
              displayName: thisRightGroup,
              children: [
                {
                  _id: right._id,
                  key: right._id,
                  displayName: right.name,
                  right: right,
                  roleInclusion: {
                    [role.name]: thisRoleContainsThisRight,
                  },
                },
              ],
            });
            rowKeys.push('GROUP_' + thisRightGroup.replaceAll(' ', ''));
          }
        });
    });
    setDefaultExpandedRowKeys(rowKeys);
    setRoleTableData(roleTableData);
  }, [allRights, allRoles]);

  useEffect(() => {
    setNoRoleIsChanged(areArraysEqualIgnoreOrder(allRoles, originalAllRoles));
  }, [allRoles, originalAllRoles]);

  useEffect(() => {
    const roleTableColumns: ColumnType<RoleTableData>[] = [
      {
        title: (
          <>
            {allRoles.length ? (
              <Form layout={'vertical'}>
                <Form.Item label="Add New Role">
                  <Space.Compact>
                    <Input.Search
                      placeholder="new role name"
                      enterButton="Add Role"
                      size="middle"
                      onSearch={addNewRole}
                    />
                  </Space.Compact>
                </Form.Item>
              </Form>
            ) : (
              <></>
            )}
          </>
        ),
        dataIndex: ['displayName'],
        width: 320,
      },
    ];
    allRoles
      .filter((role) => !role.devOnly)
      .forEach((role) => {
        roleTableColumns.push({
          dataIndex: role.name,
          title: (
            <div>
              <Tooltip
                color={'grey'}
                title={
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div>{role.name}</div>
                    <Popconfirm
                      title="Delete Role"
                      description="Are you sure to delete this role?"
                      onConfirm={() => confirmDeleteRole(role.name)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button danger type="text" shape="circle" icon={<DeleteColumnOutlined />} />
                    </Popconfirm>
                  </div>
                }
              >
                <div
                  style={{
                    writingMode: 'vertical-lr',
                    textOrientation: 'mixed',
                    whiteSpace: 'nowrap',
                    transformOrigin: 'center',
                    transform: 'rotateZ(225deg)',
                  }}
                >
                  {role.name}
                </div>
              </Tooltip>
            </div>
          ),
          render: (text: string, row: RoleTableData) => {
            if (row.key.includes('GROUP_')) {
              row = row as RoleTableRightGroup;
              return (
                <Checkbox
                  indeterminate={(() => {
                    const group = row.groupName;
                    const thisGroupRights = allRights.filter((ar) => ar.group === group).map((r) => r.name);
                    const intersection = _.intersection(
                      role.rights.map((r) => r.name),
                      thisGroupRights
                    );
                    if (intersection.length) {
                      return !_.isEqual(_.sortBy(intersection), _.sortBy(thisGroupRights));
                    }
                    return false;
                  })()}
                  checked={(() => {
                    const group = row.groupName;
                    const thisGroupRights = allRights.filter((ar) => ar.group === group).map((r) => r.name);
                    const intersection = _.intersection(
                      role.rights.map((r) => r.name),
                      thisGroupRights
                    );
                    if (intersection.length) {
                      return _.isEqual(_.sortBy(intersection), _.sortBy(thisGroupRights));
                    }
                    return false;
                  })()}
                  onChange={(e) => selectOrUnselectAll(e.target.checked, role, (row as RoleTableRightGroup).groupName)}
                />
              );
            } else {
              row = row as RoleTableIndividualRight;
              return (
                <Checkbox
                  checked={row.roleInclusion[role.name]}
                  onChange={() =>
                    roleChange(
                      role,
                      (row as RoleTableIndividualRight).right,
                      !(row as RoleTableIndividualRight).roleInclusion[role.name]
                    )
                  }
                />
              );
            }
          },
        });
      });
    setRoleTableColumns(roleTableColumns);
  }, [allRoles.length, noRoleIsChanged]);

  return (
    <div className="pt-3 pr-3 pl-3 pb-0">
      <Card title="Right Management" style={{ marginBottom: 8 }}>
        <DataTable
          data={allRights}
          columnsDef={appRightTableColumns}
          setting={rightTableSetting}
          loading={rightLoading}
          style={{ marginBottom: 0 }}
        />
      </Card>
      <Card
        title="Role Configuration"
        extra={
          <Button disabled={noRoleIsChanged || applyingRoleChange} onClick={confirmRoleChange}>
            Confirm Changes
          </Button>
        }
      >
        <Table
          className={'role-table'}
          dataSource={roleTableData}
          columns={roleTableColumns}
          pagination={false}
          scroll={{ y: 480 }}
          loading={roleLoading || applyingRoleChange}
          expandable={{
            defaultExpandedRowKeys: defaultExpandedRowKeys,
          }}
          locale={{ emptyText: 'No Role' }}
        />
      </Card>
    </div>
  );
};

import React, { ReactNode, useEffect, useRef, useState } from 'react';
import {
  AccordionContent,
  ButtonContent,
  DescriptiveContent,
  HeadingContent,
  ImageContent,
  LinkContent,
} from '../type-def/content-classes';
import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  DragStart,
  DropResult,
  NotDraggingStyle,
} from '@erom/react-beautiful-dnd';
import { StrictModeDroppable } from '../../../../utils/strict-mode-dnd.util';
import {
  Alert,
  Button,
  Card,
  DatePicker,
  Divider,
  Input,
  Modal,
  Result,
  Select,
  Tabs,
  Tooltip,
  Tree,
  TreeDataNode,
  Typography,
  Upload,
} from 'antd';
import { supportingLanguageMap, supportingLanguages } from '../../../../constants/language.constant';
import { EditorRenderer } from '../content-renderers/when-editing';
import { ContentItem, NewsPost } from 'bridge/news-post';
import { underLinedInputClass } from '../../../../components/inputs/editable-attribute.component';
import { BackendAPI } from '../../../../constants/backend-api.enum';
import { popMessage } from '../../../../utils/pop-message.util';
import NewsPostsPreviewComponent from '../content-renderers/when-preview';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import httpClient from '../../../../utils/http-client.util';
import { TargetDonorFilter } from '../../shared-component/target-donor-filter.component';
import { NewsPostTagSetting } from '../component/tag-setting.component';
import dayjs from 'dayjs';

type Prop = {
  mode: 'new' | 'template' | 'edit';
};

export const NewsPostCompose = ({ mode }: Prop) => {
  const [thePost, setThePost] = useState<Partial<NewsPost>>({});
  const [disableContentTypeListDropzone, setDisableContentTypeListDropzone] = useState(false);
  const [currentEditLanguage, setCurrentEditingLanguage] = useState('');
  const [wireframeData, setWireframeData] = useState<TreeDataNode[]>();
  const [previewScale, setPreviewScale] = useState(0);
  const [confirmModal, setConfirmModal] = useState<{ open: boolean; closable: boolean; content: ReactNode }>({
    open: false,
    closable: true,
    content: null,
  });
  const [submitting, setSubmitting] = useState(false);
  const [disableSubmitButton, setDisableSubmitButton] = useState(true);
  const [newsPostTags, setNewsPostTags] = useState<NewsPost['tags']>([]);
  const [targetingNumber, setTargetingNumber] = useState<number | null>(null);

  const contentSectionRef = useRef<HTMLDivElement>(null);
  const previewSectionRef = useRef<HTMLDivElement>(null);

  const { newsPostId } = useParams();
  const routerLocation = useLocation();
  const navigator = useNavigate();

  const reorder = (contents: ContentItem[], fromIndex: number, toIndex: number) => {
    const [removed] = contents.splice(fromIndex, 1);
    contents.splice(toIndex, 0, removed);
    contents.forEach((v, i) => (v.index = i));
  };

  const moveInAndOut = (
    sourceContents: ContentItem[],
    destinationContents: ContentItem[],
    sourceIndex: number,
    destinationIndex: number
  ) => {
    const [removed] = sourceContents.splice(sourceIndex, 1);
    destinationContents.splice(destinationIndex, 0, removed);

    sourceContents.forEach((v, i) => (v.index = i));
    destinationContents.forEach((v, i) => (v.index = i));
  };

  const addContent = (destinationContents: ContentItem[], destinationIndex: number, contentToAdd: ContentItem) => {
    destinationContents.splice(destinationIndex, 0, contentToAdd);
    destinationContents.forEach((v, i) => (v.index = i));
  };

  const onDragStart = (_: DragStart) => {
    setDisableContentTypeListDropzone(true);
  };

  const onDragEnd = (result: DropResult) => {
    if (!thePost.localizedContent) {
      return;
    }

    setDisableContentTypeListDropzone(false);
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const sourceId = source.droppableId;
    const destinationId = destination.droppableId;

    const accordionIndexList = destinationId.split('@')[1]?.split('-');

    let destinationContents: ContentItem[] = thePost.localizedContent[currentEditLanguage].layout;
    if (accordionIndexList) {
      const destinationDepth = accordionIndexList.length;

      for (let i = 0; i < destinationDepth; i++) {
        destinationContents = (destinationContents[parseInt(accordionIndexList[i])] as AccordionContent).content;
      }
    }

    if (sourceId === destinationId) {
      reorder(destinationContents, source.index, destination.index);
    } else {
      if (sourceId === 'content-type-list') {
        const contentToAdd = contentTypes[source.index].whenDrop(destination.index);
        addContent(destinationContents, destination.index, contentToAdd);
      } else {
        const accordionIndexList = sourceId.split('@')[1]?.split('-');

        let sourceContents: ContentItem[] = thePost.localizedContent[currentEditLanguage].layout;
        if (accordionIndexList) {
          const sourceDepth = accordionIndexList.length;

          for (let i = 0; i < sourceDepth; i++) {
            sourceContents = (sourceContents[parseInt(accordionIndexList[i])] as AccordionContent).content;
          }
        }
        moveInAndOut(sourceContents, destinationContents, source.index, destination.index);
      }
    }

    setThePost({ ...thePost });
  };

  const contentTypes = [
    {
      name: 'Body',
      icon: <i className="ri-text-snippet"></i>,
      whenDrop: (index: number) => new DescriptiveContent('body', index),
    },
    {
      name: 'Bullet',
      icon: <i className="ri-list-unordered"></i>,
      whenDrop: (index: number) => new DescriptiveContent('bullet', index),
    },
    {
      name: 'H1',
      icon: <i className="ri-heading"></i>,
      whenDrop: (index: number) => new HeadingContent('h1', index),
    },
    {
      name: 'H2',
      icon: <i className="ri-heading"></i>,
      whenDrop: (index: number) => new HeadingContent('h2', index),
    },
    {
      name: 'H3',
      icon: <i className="ri-heading"></i>,
      whenDrop: (index: number) => new HeadingContent('h3', index),
    },
    {
      name: 'H4',
      icon: <i className="ri-heading"></i>,
      whenDrop: (index: number) => new HeadingContent('h4', index),
    },
    {
      name: 'H5',
      icon: <i className="ri-heading"></i>,
      whenDrop: (index: number) => new HeadingContent('h5', index),
    },
    {
      name: 'Link',
      icon: <i className="ri-link"></i>,
      whenDrop: (index: number) => new LinkContent(index),
    },
    {
      name: 'Button',
      icon: <i className="ri-crosshair-line"></i>,
      whenDrop: (index: number) => new ButtonContent(index),
    },
    {
      name: 'Image',
      icon: <i className="ri-image-2-line"></i>,
      whenDrop: (index: number) => new ImageContent(index),
    },
    {
      name: 'Accordion',
      icon: <i className="ri-stack-line"></i>,
      whenDrop: (index: number) => new AccordionContent(index),
    },
  ];

  const typeStaticStyle = {
    userSelect: 'none' as const,
    padding: '14px',
    margin: `0 0 10px 0`,
    textAlign: 'center' as const,
    width: '100%',
  };

  const typeDraggingStyle = (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined) => ({
    ...draggableStyle,
    width: isDragging ? '150px' : undefined,
    background: isDragging ? '#a6f1a6' : undefined,
    transform: isDragging ? draggableStyle?.transform : 'translate(0px, 0px)',
  });

  const scaleRatioCalculation = () => {
    if (previewSectionRef && previewSectionRef.current) {
      const horizontalScaleRatio = Math.min(previewSectionRef.current.getBoundingClientRect().width, 390) / 390;
      const verticalScaleRatio = Math.min(previewSectionRef.current.getBoundingClientRect().height, 844) / 844;
      let finalRatio = horizontalScaleRatio;
      if (horizontalScaleRatio * 844 > previewSectionRef.current.getBoundingClientRect().height) {
        finalRatio = verticalScaleRatio;
      }
      setPreviewScale(finalRatio);
    }
  };

  const savePost = async () => {
    setSubmitting(true);
    let action: Promise<any>;
    if (mode === 'edit') {
      action = httpClient.post(`${BackendAPI.NEWSPOST}/${newsPostId}`, thePost as NewsPost);
    } else {
      // new and template
      action = httpClient.put(`${BackendAPI.NEWSPOST}/`, thePost as NewsPost);
    }
    try {
      await action;
      setConfirmModal({
        open: true,
        closable: false,
        content: (
          <div className={'text-center'}>
            <Result
              status={'success'}
              title={'Good!'}
              subTitle={
                mode === 'edit' ? 'You have successfully updated the post' : 'You have successfully created the post'
              }
            />
            <Button
              type={'primary'}
              onClick={() => {
                navigator('../');
                setConfirmModal({ open: false, content: null, closable: true });
              }}
            >
              Go back to post list
            </Button>
          </div>
        ),
      });
    } catch (e) {
      popMessage.error('Failed to save/ edit post');
    } finally {
      setSubmitting(false);
    }
  };

  const postPreview = () => {
    const beforeSubmitting = (
      <>
        {mode !== 'edit' && (
          <div className={'text-center'}>
            <Divider />
            <p className={'mb-3'}>Based on the target audience filter setting, this post will be sent to</p>
            <Typography.Title level={3}>{targetingNumber}</Typography.Title>
            <p className={'mb-3'}>donor(s)</p>
            <p>It will be published and pushed immediately</p>
          </div>
        )}
        <Divider />
        <div className={'flex items-center'}>
          <Typography.Text type={'secondary'} className={'mr-2'}>
            Please confirm your action:
          </Typography.Text>
          <Button className={'flex-grow-[1]'} onClick={savePost} loading={submitting} type={'dashed'}>
            {mode === 'edit' ? 'Update This Post' : 'Create New Post'}
          </Button>
        </div>
      </>
    );

    setConfirmModal({ open: true, content: beforeSubmitting, closable: true });
  };

  useEffect(() => {
    if (mode !== 'new') {
      const inStateNewsPost: NewsPost = routerLocation.state?.newsPost || null;
      if (!inStateNewsPost || inStateNewsPost.newsId !== newsPostId) {
        (async () => {
          try {
            const sourcingNewsPost = await httpClient.get<NewsPost>(`${BackendAPI.NEWSPOST}/${newsPostId}`);
            setThePost(sourcingNewsPost.data);
            setCurrentEditingLanguage(sourcingNewsPost.data.defaultLanguage);
          } catch (e) {
            popMessage.error('Unable to fetch news post');
          }
        })();
      } else {
        setThePost(inStateNewsPost);
        setCurrentEditingLanguage(inStateNewsPost.defaultLanguage);
        window.history.replaceState({}, '');
      }
    }

    if (mode === 'edit') {
      setDisableSubmitButton(false);
    }

    const observer = new ResizeObserver(scaleRatioCalculation);

    if (previewSectionRef.current) {
      observer.observe(previewSectionRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (thePost.localizedContent && thePost.localizedContent[currentEditLanguage]) {
      const generateTree = (elements: ContentItem[], previousIndexes: number[]): TreeDataNode[] => {
        return elements.map((el, i) => {
          const indexChain = previousIndexes.join('-');
          const draggableId = `draggable@${indexChain ? `${indexChain}-${i}` : `${i}`}`;
          return {
            title: el.tag,
            key: draggableId,
            icon: contentTypes.find((type) => type.name.toLowerCase() === el.tag.toLowerCase())?.icon,
            children: 'content' in el ? generateTree(el.content as ContentItem[], [...previousIndexes, i]) : [],
          };
        });
      };
      const newWire = generateTree(thePost.localizedContent[currentEditLanguage].layout, []);

      setWireframeData(newWire);
    }

    if (!!thePost.audienceFilter) {
      setDisableSubmitButton(false);
    }
  }, [thePost, currentEditLanguage]);

  useEffect(scaleRatioCalculation, [previewSectionRef]);

  return (
    <div className={'bg-primary-foreground p-3'}>
      <div>
        <Card className="news-settings rounded-none">
          <Card.Grid className={'!w-[calc((100%-371px)/2)]'}>
            <div className={'inline-block w-[calc(100%-120px)]'}>
              <div>
                <Typography.Text type="secondary">Languages</Typography.Text>
              </div>
              <Select
                mode={'multiple'}
                showSearch
                variant="borderless"
                value={Object.keys(thePost?.localizedContent || {})}
                className={`${underLinedInputClass} w-[100%]`}
                style={{ borderBottomStyle: 'solid', flex: 1 }}
                options={supportingLanguages}
                onChange={(selected) => {
                  if (!thePost.localizedContent) {
                    thePost.defaultLanguage = selected[0];
                    setCurrentEditingLanguage(selected[0]);
                  }
                  const existingLocalizedContent = thePost.localizedContent || {};
                  const newLocalizedContent: NewsPost['localizedContent'] = {};
                  selected.forEach((s: string) => {
                    if (!existingLocalizedContent[s]) {
                      newLocalizedContent[s] = { title: '', subtitle: '', layout: [] };
                    } else {
                      newLocalizedContent[s] = existingLocalizedContent[s];
                    }
                  });
                  thePost.localizedContent = newLocalizedContent;
                  setThePost({ ...thePost });
                }}
              />
            </div>
            <Divider type={'vertical'}></Divider>
            <div className={'inline-block w-[100px]'}>
              <div>
                <Typography.Text type="secondary">Default</Typography.Text>
              </div>
              <Select
                variant="borderless"
                value={thePost.defaultLanguage}
                className={`${underLinedInputClass} w-[100%]`}
                style={{ borderBottomStyle: 'solid', flex: 1 }}
                options={Object.keys(thePost.localizedContent || {}).map((k) => ({
                  value: k,
                  label: supportingLanguageMap.get(k),
                }))}
                onChange={(selected) => {
                  thePost.defaultLanguage = selected;
                  setThePost({ ...thePost });
                }}
              />
            </div>
          </Card.Grid>
          <Card.Grid className={'!w-[184px]'}>
            <div>
              <Typography.Text type="secondary">Expiration Date</Typography.Text>
            </div>
            <DatePicker
              variant="borderless"
              placeholder="Never Expires"
              className={`${underLinedInputClass}`}
              style={{ borderBottomStyle: 'solid', flex: 1 }}
              allowClear
              value={thePost.expiryTS ? dayjs(thePost.expiryTS) : undefined}
              onChange={(selected) => {
                thePost.expiryTS = selected ? selected.toDate() : undefined;
                setThePost({ ...thePost });
              }}
            />
          </Card.Grid>
          <Card.Grid className={'!w-[187px] flex items-center justify-center'}>
            <Upload
              className={'!max-w-[147px] flex flex-col justify-center items-center overflow-ellipsis'}
              accept="image/*"
              action={`${BackendAPI.NEWSPOST}/upload-image`}
              fileList={
                thePost.coverImage
                  ? [
                      {
                        url: thePost.coverImage,
                        name: 'post_cover.jpg',
                        uid: '1',
                      },
                    ]
                  : undefined
              }
              onChange={async ({ file }) => {
                if (file.status === 'done') {
                  thePost.coverImage = file.response;
                } else if (file.status === 'removed') {
                  thePost.coverImage = '';
                } else if (file.status === 'error') {
                  popMessage.error('Unable to upload news post image');
                }
                setThePost({ ...thePost });
              }}
              maxCount={1}
            >
              <Button size={'small'} icon={<i className={'ri-upload-line'} />}>
                Cover Image
              </Button>
            </Upload>
          </Card.Grid>
          <Card.Grid className={'!w-[calc((100%-371px)*3/10)] !py-1 !px-1 flex items-center justify-center'}>
            <TargetDonorFilter
              existingFilter={thePost.audienceFilter}
              title={'Target Audience Filter'}
              disabled={mode === 'edit'}
              onConfirm={(newFilter) => {
                setThePost({ ...thePost, audienceFilter: newFilter });
              }}
              onTargetCheck={setTargetingNumber}
            />
          </Card.Grid>
          <Card.Grid className={'!w-[calc((100%-371px)/5)] !py-1 !px-1 flex items-center justify-center'}>
            <div>
              <div>
                <Typography.Text type={'secondary'} className={'flex items-center'}>
                  <span className={'mr-1'}>Associated Tags</span>
                  <NewsPostTagSetting onChange={setNewsPostTags}>
                    <i className={'ri-settings-3-line hover:text-blue-500 text-sm'}></i>
                  </NewsPostTagSetting>
                </Typography.Text>
              </div>
              <Select
                mode={'multiple'}
                maxTagCount={'responsive'}
                variant="borderless"
                size={'small'}
                showSearch={true}
                value={thePost.tags}
                popupMatchSelectWidth={false}
                placement={'bottomRight'}
                className={`${underLinedInputClass}`}
                style={{ borderBottomStyle: 'solid', width: '100%' }}
                options={newsPostTags.map((tag) => ({ value: tag, label: tag }))}
                onChange={(selected) => {
                  thePost.tags = selected;
                  setThePost({ ...thePost });
                }}
              />
            </div>
          </Card.Grid>
        </Card>
      </div>
      <Divider className={'my-2'} />
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <div className={'flex h-[calc(100vh-100px)]'}>
          <div className={'w-[220px] overflow-y-auto'}>
            <StrictModeDroppable
              key={'content-type-list'}
              droppableId={'content-type-list'}
              isDropDisabled={disableContentTypeListDropzone}
            >
              {(typesProvided, _) => (
                <>
                  <div className="!me-5" ref={typesProvided.innerRef} {...typesProvided.droppableProps}>
                    {contentTypes.map((type, index) => (
                      <Draggable key={type.name} draggableId={type.name} index={index}>
                        {(provided, snapshot) => (
                          <>
                            <div
                              className="rounded-lg shadow-lg text-center text-sm bg-secondary-foreground"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                ...typeStaticStyle,
                                ...typeDraggingStyle(snapshot.isDragging, provided.draggableProps.style),
                              }}
                            >
                              <div className="flex justify-between items-center">
                                {type.icon}
                                {type.name}
                              </div>
                            </div>
                            {snapshot.isDragging && (
                              <div className="" style={{ ...typeStaticStyle }}>
                                <div className="flex justify-between items-center">
                                  {type.icon}
                                  {type.name}
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </Draggable>
                    ))}
                  </div>
                </>
              )}
            </StrictModeDroppable>
          </div>
          <div className={'w-full'}>
            {thePost.localizedContent ? (
              <Tabs activeKey={currentEditLanguage} onChange={(key) => setCurrentEditingLanguage(key)}>
                {Object.entries(thePost.localizedContent).map(([lang, content]) => (
                  <Tabs.TabPane tab={supportingLanguageMap.get(lang)} key={lang}>
                    <Card className={'border-none mb-1'}>
                      <Card.Grid className={'!w-[40%] !p-4 hover:!rounded'}>
                        <div>
                          <Typography.Text type="secondary">
                            {supportingLanguageMap.get(currentEditLanguage)} Title
                          </Typography.Text>
                        </div>
                        <Input
                          value={content.title}
                          variant="borderless"
                          className={`${underLinedInputClass} w-[100%]`}
                          style={{ borderBottomStyle: 'solid', flex: 1 }}
                          onChange={(event) => {
                            if (thePost.localizedContent) {
                              thePost.localizedContent[currentEditLanguage].title = event.target.value;
                              setThePost({ ...thePost });
                            }
                          }}
                        />
                      </Card.Grid>
                      <Card.Grid className={'!w-[60%] !p-4 !border-t-0 hover:!rounded'}>
                        <div>
                          <Typography.Text type="secondary">
                            {supportingLanguageMap.get(currentEditLanguage)} Subtitle
                          </Typography.Text>
                        </div>
                        <Input
                          value={content.subtitle}
                          variant="borderless"
                          className={`${underLinedInputClass} w-[100%]`}
                          style={{ borderBottomStyle: 'solid', flex: 1 }}
                          onChange={(event) => {
                            if (thePost.localizedContent) {
                              thePost.localizedContent[currentEditLanguage].subtitle = event.target.value;
                              setThePost({ ...thePost });
                            }
                          }}
                        />
                      </Card.Grid>
                    </Card>
                    <div className={'flex justify-between'}>
                      <div className={'w-[82%] h-[calc(100vh-240px)] overflow-y-auto pr-2'} ref={contentSectionRef}>
                        {EditorRenderer(
                          content.layout,
                          [],
                          lang,
                          (newContent, locators) => {
                            if (thePost.localizedContent) {
                              let destinationArray = thePost.localizedContent[currentEditLanguage].layout;
                              for (let i = 0; i < locators.length - 1; i++) {
                                destinationArray = (destinationArray[locators[i]] as AccordionContent).content;
                              }
                              destinationArray[locators[locators.length - 1]] = newContent;
                              setThePost({ ...thePost });
                            }
                          },
                          (locators) => {
                            if (thePost.localizedContent) {
                              let destinationArray = thePost.localizedContent[currentEditLanguage].layout;
                              for (let i = 0; i < locators.length - 1; i++) {
                                destinationArray = (destinationArray[locators[i]] as AccordionContent).content;
                              }
                              destinationArray.splice(locators[locators.length - 1], 1);
                              setThePost({ ...thePost });
                            }
                          }
                        )}
                      </div>
                      <div className={'w-[18%] text-center'}>
                        <div className={'flex justify-between'}>
                          <div>
                            <i className={'ri-node-tree'} />
                            Wireframe
                          </div>
                          <i
                            className="ri-skip-up-line cursor-pointer"
                            onClick={() => {
                              if (contentSectionRef.current) {
                                contentSectionRef.current.scrollTo(0, 0);
                              }
                            }}
                          />
                        </div>
                        <Tree
                          showLine={{ showLeafIcon: false }}
                          showIcon={true}
                          treeData={wireframeData}
                          onSelect={(selectedKeys) => {
                            const el = document.querySelector(`div[data-rbd-draggable-id="${selectedKeys[0]}"]`);
                            if (el) {
                              el.scrollIntoView({ behavior: 'smooth' });
                            }
                          }}
                        />
                      </div>
                    </div>
                  </Tabs.TabPane>
                ))}
              </Tabs>
            ) : (
              <Alert message={'Choose language to start'} type={'info'} />
            )}
          </div>
          <div className={'w-[calc((100vh-100px)*0.46)] ml-2'} ref={previewSectionRef}>
            <NewsPostsPreviewComponent
              currentLanguage={currentEditLanguage}
              currentPost={thePost}
              scaleRatio={previewScale}
            ></NewsPostsPreviewComponent>
          </div>
        </div>
      </DragDropContext>

      <Tooltip open={disableSubmitButton ? undefined : false} placement={'left'} title={'Audience Filter not set'}>
        <Button
          className={`animate-bounce fixed bottom-6 right-6 shadow-2xl disabled:!bg-gray-300 disabled:!text-gray-400 disabled:!border-none`}
          type={'primary'}
          shape={'round'}
          danger={mode !== 'edit'}
          size={'large'}
          onClick={postPreview}
          disabled={disableSubmitButton}
        >
          {mode === 'edit' ? 'Update This Post' : 'Create New Post'}
        </Button>
      </Tooltip>

      <Modal
        open={confirmModal.open}
        destroyOnClose
        footer={null}
        closable={confirmModal.closable}
        onCancel={() => setConfirmModal({ open: false, content: null, closable: true })}
        title={mode === 'edit' ? 'Update This Post' : 'Create New Post'}
      >
        {confirmModal.content}
      </Modal>
    </div>
  );
};

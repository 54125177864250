import { useLocation, useNavigate } from 'react-router-dom';
import { Steps } from 'antd';
import { useEffect, useState } from 'react';

export const NewsPost = () => {
  const navigate = useNavigate();
  const appUrl = useLocation();

  const [step, setStep] = useState(0);

  useEffect(() => {
    if (!appUrl.pathname.split('/')[2]) {
      setStep(0);
    } else {
      setStep(1);
    }
  }, [appUrl]);

  return (
    <Steps
      className={'!pt-0'}
      type="navigation"
      current={step}
      onChange={(newCurrent) => {
        setStep(newCurrent);
        if (newCurrent === 0) {
          navigate('/news-posts');
        }
      }}
    >
      <Steps.Step key="choose" title="Posts" subTitle="Create new post or Choose the post you want to modify" />
      <Steps.Step key="modify" title="Content" subTitle={`Compose the post content`} disabled={step !== 1} />
    </Steps>
  );
};
import { Button, Col, Divider, Input, Radio, Row, Tooltip, Typography } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FromToDate } from '../components/between-date';
import { RadioChangeEvent } from 'antd/lib';
import { EntityCondition, EntityPromotionBonus } from 'bridge/promotion-bonus';
import { PreDonorsToNew } from './components/preDonorsToNew';
import { ApplicantDonors } from './components/applicantDonor';
import { RetentionDonors } from './components/retentionDonors';
import { LapsedDonors } from './components/lapsedDonors';
import { CustomGroup } from './components/customGroup';
import { AppliedCenters } from '../components/applied-centers';
import httpClient from '../../../utils/http-client.util';
import { BackendAPI } from '../../../constants/backend-api.enum';
import { popMessage } from '../../../utils/pop-message.util';
import { APPLICANTDONORS, CUSTOMEGROUP, DEFERRALDONORS, LAPSEDDONORS, PREDONORSTONEWDONORS, RETENTIONDONORS } from '../../../utils/enum';
import { fieldErrors } from '../functions/utils';
import { DeferralDonors } from './components/defferalDonors';
const { TextArea } = Input;

export const PromotionBonusCreatePage = () => {
  const defaultPromotionBonus: EntityPromotionBonus = {
    _id: '',
    campaignId: '',
    campaignName: '',
    appliedCenter: [],
    campaignDescription: '',
    startDate: '',
    endDate: '',
    campaignType: '',
    status: '',
    donorGroup: [
      {
        name: '',
        donorGroupId: '',
      }
    ],
    condition: [
      {
        duration: [''],
        deferralType: '',
        donations: [
          {
            type: '',
            value: '',
          },
        ],
      }
    ],
  };
  const navigate = useNavigate();
  const [targetGroup, setTargetGroup] = useState<string>(PREDONORSTONEWDONORS);
  const [promotionBonusForm, setPromotionBonusForm] = useState<EntityPromotionBonus>(defaultPromotionBonus); // Gonna do next
  const [buttonLoading, setButtonLoading] = useState<boolean>(false)

  const targetGroupOptions = [
    { label: PREDONORSTONEWDONORS, value: PREDONORSTONEWDONORS },
    { label: APPLICANTDONORS, value: APPLICANTDONORS },
    { label: RETENTIONDONORS, value: RETENTIONDONORS },
    { label: LAPSEDDONORS, value: LAPSEDDONORS },
    { label: DEFERRALDONORS, value: DEFERRALDONORS },
    { label: CUSTOMEGROUP, value: CUSTOMEGROUP },
  ];

  const handleSubmit = async () => {
    promotionBonusForm.campaignType = targetGroup;
    try {
      setButtonLoading(true);
      let res = await httpClient.post(`${BackendAPI.PROMOTIONBONUS}/create`, promotionBonusForm);
      if (res.data?.status === 200) {
        popMessage.success('Promotion Bonus Created Successfully');
        navigate(-1)
      } else {
        popMessage.warning(res.data?.message);
      }
    } catch (e) {
      popMessage.error('Unable to create Fee Structure');
    } finally {
      setButtonLoading(false);
    }
  }

  return (
    <div>
      <div className="flex justify-between items-center">
        <Typography.Title level={3}>
          <span onClick={() => navigate(-1)}>Promotion Bonus</span>
          <Divider type={'vertical'} />
          <span>Create</span>
        </Typography.Title>
      </div>
      <div
        className="flex flex-col gap-[30px]"
        style={{ backgroundColor: 'var(--primary-foreground)', borderRadius: '5px', padding: '20px 40px' }}
      >
        <Row gutter={[30, 30]}>
          <Col xs={24} xl={16}>
            <div className='flex flex-col gap-4'>
              <Typography.Text className="flex flex-col gap-1">
                <span className="font-semibold">Campaign Name</span>
                <Input value={promotionBonusForm.campaignName} onChange={(event) => setPromotionBonusForm({ ...promotionBonusForm, campaignName: event.target.value })} />
              </Typography.Text>
              <Typography.Text className="flex flex-col gap-1">
                <span className="font-semibold">Campaign Description</span>
                <TextArea value={promotionBonusForm.campaignDescription} onChange={(event) => setPromotionBonusForm({ ...promotionBonusForm, campaignDescription: event.target.value })} />
              </Typography.Text>
              <Typography.Text className="flex flex-col gap-1">
                <span className="font-semibold">Campaign Time</span>
                <FromToDate
                  onChangeFromDate={(date: string) => {
                    setPromotionBonusForm({ ...promotionBonusForm, startDate: date })
                  }}
                  onChangeToDate={(date: string) => {
                    setPromotionBonusForm({ ...promotionBonusForm, endDate: date })
                  }}
                  existFromDate=""
                  existToDate=""
                />
              </Typography.Text>
            </div>
          </Col>
          <Col xs={24} xl={8}>
            <Typography.Text className="flex flex-col gap-1">
              <span className="font-semibold">Applied Centers</span>
              <AppliedCenters
                onChangeCenter={(locations: string[]) => {
                  setPromotionBonusForm({ ...promotionBonusForm, appliedCenter: locations })
                }}
                existCenters={null}
              />
            </Typography.Text>
          </Col>
          <Col xs={24} xl={8}>
            <Typography.Text className="flex flex-col gap-1">
              <span className="font-semibold">Donor Group</span>
              <Radio.Group className="flex flex-col" style={{ width: 'fit-content' }} options={targetGroupOptions} onChange={(e: RadioChangeEvent) => {
                setTargetGroup(e.target.value);
              }} value={targetGroup} />
            </Typography.Text>
          </Col>
        </Row>
        <div>
          {(() => {
            switch (targetGroup) {
              case PREDONORSTONEWDONORS:
                return <PreDonorsToNew conditionData={[]} onChangeData={(data: EntityCondition[]) => {
                  setPromotionBonusForm({ ...promotionBonusForm, condition: data })
                }} />;
              case APPLICANTDONORS:
                return <ApplicantDonors conditionData={[]} onChangeData={(data: EntityCondition[]) => {
                  setPromotionBonusForm({ ...promotionBonusForm, condition: data })
                }} />;
              case RETENTIONDONORS:
                return <RetentionDonors conditionData={[]} onChangeData={(data: EntityCondition[]) => {
                  setPromotionBonusForm({ ...promotionBonusForm, condition: data })
                }} />;
              case LAPSEDDONORS:
                return <LapsedDonors conditionData={[]} onChangeData={(data: EntityCondition[]) => {
                  setPromotionBonusForm({ ...promotionBonusForm, condition: data })
                }} />;
              case DEFERRALDONORS:
                return <DeferralDonors conditionData={[]} onChangeData={(data: EntityCondition[]) => {
                  setPromotionBonusForm({ ...promotionBonusForm, condition: data })
                }} />;
              case CUSTOMEGROUP:
                return <CustomGroup conditionData={[]} onChangeData={(data: EntityCondition[]) => {
                  setPromotionBonusForm({ ...promotionBonusForm, condition: data })
                }} />;
              default:
                return null;
            }
          })()}
        </div>
        <div className="flex items-center justify-center">
          <Tooltip title={fieldErrors(promotionBonusForm, [], targetGroup).join(', ')} defaultOpen={!!fieldErrors(promotionBonusForm, [], targetGroup).length} color={'#ef5350'}
            placement={'right'}>
            <Button size={'large'} disabled={!!fieldErrors(promotionBonusForm, [], targetGroup).length} onClick={handleSubmit} loading={buttonLoading}>
              Create
            </Button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

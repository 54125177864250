import { create } from 'zustand';
import { AuthenticationSlice, createAuthenticationSlice } from './authentication.slice';
import { createSideNavDisplaySlice, SideNavDisplaySlice } from './side-nav-display.slice';
import { devtools, persist } from 'zustand/middleware';
import {
  createCustomerServiceConversationSlice,
  CustomerServiceConversationSlice,
} from './customer-service-conversations.slice';
import { createSideNavItemBadgeSlice, SideNavItemBadgeSlice } from './side-nav-item-badge.slice';

export const useBoundStore = create<
  AuthenticationSlice & SideNavDisplaySlice & CustomerServiceConversationSlice & SideNavItemBadgeSlice
>()(
  devtools(
    persist(
      (...a) => ({
        ...createAuthenticationSlice(...a),
        ...createSideNavDisplaySlice(...a),
        ...createCustomerServiceConversationSlice(...a),
        ...createSideNavItemBadgeSlice(...a),
      }),
      {
        name: 'persisted-application-state',
        partialize: (state) => ({ user: state.user }),
      }
    )
  )
);

import React, { useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { Button, Checkbox, DatePicker, Divider, Input, InputNumber, Select, Space, Tag, Typography } from 'antd';
import { MessageProperty, popMessage } from '../../utils/pop-message.util';
import dayjs from 'dayjs';
import { MaterialIconNames } from './material-icon-list';

interface Option {
  label: string,
  value: string
}

export type TextWithIcon = { icon: string, value: string };

export type EditableAttributeDateType = string | number | Date | boolean | TextWithIcon | undefined;

export type EditableAttributeElementType =
  'text'
  | 'number'
  | 'date'
  | 'select'
  | 'checkbox'
  | 'multi-select'
  | 'map'
  | 'text-with-icon'
  | 'multi-text';

type SaveFunction = (newValue: EditableAttributeDateType | EditableAttributeDateType[]) => (Promise<MessageProperty | string> | void);

export type EditableAttributeProp = {
  label: string,
  value: EditableAttributeDateType | EditableAttributeDateType[],
  type: EditableAttributeElementType,
  indent?: boolean,
  canEdit?: false | {
    onSave: SaveFunction,
    onEnterKey?: SaveFunction,
    onCancel?: Function,
    defaultInEditing?: boolean,
    messageDuration?: number
  },
  mapLabels?: string[],
  selectOptions?: Option[],
  dateFormat?: string,
}

export const underLinedInputClass = 'border-b border-b-gray-700 rounded-none';

export const EditableAttribute = ({
                                    label,
                                    value,
                                    canEdit,
                                    type,
                                    selectOptions,
                                    dateFormat,
                                    mapLabels,
                                    indent,
                                  }: EditableAttributeProp) => {
  const [isEditing, setIsEditing] = useState(canEdit && canEdit.defaultInEditing);
  const [currentValue, setCurrentValue] = useState(value);
  const [loading, setLoading] = useState(false);

  const cancel = () => {
    if (canEdit && canEdit.onCancel) {
      canEdit.onCancel();
    }
    setIsEditing(false);
    setCurrentValue(value);
  };

  const confirm = async () => {
    if (!!canEdit) {
      let noChange = false;
      if (currentValue instanceof Array) {
        noChange = JSON.stringify(value) === JSON.stringify(currentValue.filter(v => !!v));
      }
      if (value instanceof Date) {
        noChange = dayjs(value).isSame(currentValue as Date, 'day');
      } else if (currentValue === value) {
        noChange = true;
      }

      if (noChange) {
        cancel();
        return;
      }

      try {
        setLoading(true);
        const execution = await canEdit.onSave(currentValue);
        if (execution) {
          popMessage.success(execution);
        }
      } catch (e) {
        setCurrentValue(value);
        popMessage.error(`Unable to update ${label}: ${(e as Error).message}`);
      } finally {
        setLoading(false);
        setIsEditing(false);
      }
    }
  };

  const enterKeyEvent = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (canEdit && canEdit.onEnterKey) {
        await canEdit.onEnterKey(currentValue);
      } else {
        await confirm();
      }
    }
  };

  const renderKeyValueElement = () => {
    switch (type) {
      case 'text':
        return [
          <Input name={label} value={`${currentValue}`} autoFocus
                 variant="borderless" size="middle"
                 className={`${underLinedInputClass} w-[100%]`}
                 style={{ borderBottomStyle: 'solid' }}
                 onChange={(e) => {
                   setCurrentValue(e.target.value);
                 }}
                 onKeyUp={enterKeyEvent}
                 disabled={loading}
                 suffix={loading && <i className="ri-loader-4-line animate-spin"></i>}
          />,
          <Typography.Text>{`${currentValue || ''}`}</Typography.Text>,
        ];
      case 'number':
        return [
          <InputNumber name={label} autoFocus
                       value={currentValue !== undefined && currentValue !== null ? parseInt(currentValue.toString()) : undefined}
                       variant="borderless" size="middle"
                       className={`${underLinedInputClass} w-[100%]`}
                       style={{ borderBottomStyle: 'solid' }}
                       onChange={(e) => {
                         setCurrentValue(e?.toString() || '');
                       }}
                       onKeyUp={enterKeyEvent}
                       disabled={loading}
                       suffix={loading && <i className="ri-loader-4-line animate-spin"></i>}
          />,
          <Typography.Text>{`${currentValue || ''}`}</Typography.Text>,
        ];
      case 'select':
      case 'multi-select':
        if (type === 'multi-select' && !(currentValue instanceof Array)) {
          return [
            <></>,
            <Typography.Text type={'danger'}>Multi Select only processes array value</Typography.Text>,
          ];
        }
        if (type === 'select' && typeof currentValue !== 'string') {
          return [
            <></>,
            <Typography.Text type={'danger'}>Select only processes string value</Typography.Text>,
          ];
        }

        return [
          <Select
            mode={type === 'select' ? undefined : 'multiple'}
            defaultValue={currentValue}
            showSearch
            variant="borderless" disabled={loading}
            className={`${underLinedInputClass} w-[100%]`}
            style={{ borderBottomStyle: 'solid', flex: 1 }}
            loading={loading}
            options={selectOptions}
            onChange={(selected) => setCurrentValue(selected)}
          />,
          <div>
            {
              !(currentValue instanceof Array) ?
                <Typography.Text>{`${currentValue}`}</Typography.Text> :
                (currentValue as Array<EditableAttributeDateType>).map((v, i) => <Tag
                  key={`chosen-${i}`}>{`${v}`}</Tag>)
            }
          </div>,
        ];
      case 'checkbox':
        return [
          <Checkbox
            checked={currentValue as boolean}
            style={{ width: '100%' }}
            onChange={(e) => {
              setCurrentValue(e.target.checked);
            }}
          />,
          <Checkbox checked={currentValue as boolean} style={{ padding: 0 }} disabled={true} />,
        ];
      case 'date':
        if (typeof currentValue === 'string' || typeof currentValue === 'number' || currentValue instanceof Date) {
          const format = dateFormat || 'MM/DD/YYYY';
          const displayingDate = dayjs(currentValue).isValid() ? dayjs(currentValue) : dayjs();
          return [
            <div style={{ width: '100%' }}>
              <DatePicker
                defaultValue={displayingDate} format={format}
                variant="borderless" disabled={loading}
                className={`${underLinedInputClass}`}
                style={{ borderBottomStyle: 'solid', flex: 1 }}
                allowClear={false} autoFocus
                onChange={(selected) => setCurrentValue(selected.toDate())}
              />
            </div>,
            <Typography.Text>{dayjs(currentValue).isValid() ? displayingDate.format(format) : 'Select a date'}</Typography.Text>,
          ];
        } else {
          return [
            <></>,
            <Typography.Text type={'danger'}>Cannot parse boolean or array to date</Typography.Text>,
          ];
        }
      case 'map':
        if (!mapLabels) {
          return [
            <></>,
            <Typography.Text type={'danger'}>labels are required for map attribute</Typography.Text>,
          ];
        }
        if (!(currentValue instanceof Array)) {
          return [
            <></>,
            <Typography.Text type={'danger'}>map attribute can only process array values</Typography.Text>,
          ];
        }
        if (currentValue.length !== mapLabels.length) {
          return [
            <></>,
            <Typography.Text type={'danger'}>value array length must match mapLabels array length</Typography.Text>,
          ];
        }
        return [
          <div className="flex items-center w-[100%]">
            {
              mapLabels.map((l, i) =>
                <div key={`editable-label-kv-${i}`}>
                  <div className="inline-block">
                    {
                      <div className="mb-1">
                        <Typography.Text className="text-xs text-gray-400">{l}</Typography.Text>
                      </div>
                    }
                    <Input name={`${label}-${l}`} value={`${(currentValue[i] || '')}`}
                           variant="borderless" size="small"
                           className={`${underLinedInputClass} w-[100%]`}
                           style={{ borderBottomStyle: 'solid' }}
                           onChange={(e) => {
                             const newValue = [...currentValue];
                             newValue[i] = e.target.value;
                             setCurrentValue(newValue);
                           }}
                           disabled={loading}
                           suffix={i === mapLabels.length - 1 && loading &&
                             <i className="ri-loader-4-line animate-spin"></i>}
                    />
                  </div>
                  {
                    i !== mapLabels.length - 1 && <Divider type={'vertical'} />
                  }
                </div>,
              )
            }
          </div>,
          <div>
            {
              mapLabels.map((l, i) =>
                <div key={`editable-label-kv-display-${i}`} className={'inline-block'}>
                  <div className="inline-block">
                    <div className="mb-0">
                      <Typography.Text className="text-xs text-gray-400">{l}</Typography.Text>
                    </div>
                    <span>{currentValue ? (currentValue as Array<string>)[i] : ''}</span>
                  </div>
                  {
                    i !== mapLabels.length - 1 && <Divider type={'vertical'} />
                  }
                </div>,
              )
            }
          </div>,
        ];
      case 'multi-text':
        if (!(currentValue instanceof Array)) {
          return [
            <></>,
            <Typography.Text type={'danger'}>multi-text attribute can only process array values</Typography.Text>,
          ];
        }
        return [
          <>
            <div className="w-[100%]">
              {
                currentValue.map((v, i) =>
                  <div key={`editable-multi-text-input-${i}`}>
                    <Input name={`${label}-${i}`} value={`${v}`}
                           variant="borderless" size="small"
                           className={`${underLinedInputClass} mb-2 mr-4 w-[calc(100%-32px)]`}
                           style={{ borderBottomStyle: 'solid' }}
                           onChange={(e) => {
                             const newValue = [...currentValue];
                             newValue[i] = e.target.value;
                             setCurrentValue(newValue);
                           }}
                           disabled={loading}
                           suffix={loading ? <i className="ri-loader-4-line animate-spin"></i> :
                             <i className="ri-delete-bin-3-line text-red-500 cursor-pointer hover:text-red-300"
                                onClick={() => {
                                  const newValue = [...currentValue];
                                  newValue.splice(i, 1);
                                  setCurrentValue(newValue);
                                }} />}
                    />
                  </div>)
              }
              <Button className="w-[calc(100%-32px)]" onClick={() => setCurrentValue([...currentValue, ''])}>Add
                New {label}</Button>
            </div>
          </>
          ,
          <div>
            {currentValue.length ?
              currentValue.map((v, i) => <Tag key={`editable-multi-text-display-${i}`}>{`${v}`}</Tag>) :
              <div className="h-[22px]" />
            }
          </div>,
        ];
      case 'text-with-icon':
        if (typeof currentValue !== 'object' || !currentValue) {
          return [
            <></>,
            <Typography.Text type={'danger'}>text-with-icon attribute can only process data
              structure {`{icon: '', value: ''}`}</Typography.Text>,
          ];
        }
        const iconOptions = MaterialIconNames.map((iconName) => ({
          label: <span className={'material-icons'}>{iconName}</span>,
          value: iconName,
        }));
        const iconSelect =
          <Select className={'w-[140px]'} value={(currentValue as TextWithIcon).icon}
                  placeholder={'Select Icon'} showSearch options={iconOptions}
                  onChange={(selected) => {
                    setCurrentValue({ ...currentValue, icon: selected });
                  }}
                  optionRender={(option) =>
                    (
                      <div className={'flex items-center'}>
                        <span className={'material-icons text-[20px] mr-1'}>{option.value}</span>
                        {option.value}
                      </div>
                    )}
          />;
        return [
          <div className="flex items-center w-[100%]">
            <Input name={label} value={(currentValue as TextWithIcon).value}
                   variant="borderless" size="middle" autoFocus
                   className={`${underLinedInputClass} w-[100%] pl-0 mr-1`}
                   style={{ borderBottomStyle: 'solid' }}
                   onChange={(e) => {
                     setCurrentValue({ ...currentValue, value: e.target.value });
                   }}
                   onKeyUp={enterKeyEvent}
                   disabled={loading}
                   addonBefore={iconSelect}
                   suffix={loading && <i className="ri-loader-4-line animate-spin"></i>}
            />
          </div>,
          <Typography.Text className={'flex items-center'}>
            <span className={'material-icons text-[20px] mr-1'}>{(currentValue as TextWithIcon)?.icon}</span>
            <span> {(currentValue as TextWithIcon)?.value || ''}</span>
          </Typography.Text>,
        ];
      default:
        return [
          <></>,
          <>{currentValue?.toString()}</>,
        ];
    }
  };

  const outsideClick = async (e: MouseEvent) => {
    const { target } = e;
    let currentElement = target;

    while (currentElement) {
      if (currentElement instanceof HTMLElement) {
        if (currentElement.classList) {
          const classList = currentElement.classList;

          if (classList.contains('ant-picker-dropdown') || classList.contains('ant-select-dropdown')) {
            return;
          }
          currentElement = currentElement.parentElement; // Move up to the parent element
        } else {
          currentElement = null;
        }
      } else {
        currentElement = null;
      }
    }

    await confirm();
  };

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <div
      className={`flex-col items-center justify-between pb-0.5 mt-1 border-b border-b-gray-300 rounded-none  ${indent ? 'w-[calc(100%-24px)] ml-[24px]' : ''}`}>
      <div className={`mb-1`}>
        <Typography.Text type="secondary">{label}</Typography.Text>
      </div>
      <div className="flex justify-between items-end">
        {
          (isEditing && !!canEdit) ? (
            <OutsideClickHandler onOutsideClick={outsideClick} display="contents">
              <div className="flex items-center w-[100%]">
                {
                  renderKeyValueElement()[0]
                }
                {canEdit && (
                  <>
                    {isEditing ? (
                      <Space direction="vertical" size={0}>
                        <i
                          className={`ri-check-double-line ${loading ? 'text-gray-400 pointer-events-none' : 'text-green-700 hover:text-green-600 cursor-pointer'}`}
                          onClick={confirm}></i>
                        <i
                          className={`ri-close-line ${loading ? 'text-gray-400 pointer-events-none' : 'text-red-600 hover:text-red-400 cursor-pointer'}`}
                          onClick={cancel}></i>
                      </Space>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>
            </OutsideClickHandler>
          ) : (
            renderKeyValueElement()[1]
          )
        }
        {
          <Typography.Text strong type="secondary">
            {canEdit ? (
                <>
                  {isEditing ? <></> : <i className="ri-edit-line text-gray-700 hover:text-gray-500 cursor-pointer"
                                          onClick={() => setIsEditing(true)}></i>}
                </>
              ) :
              <div className="h-[22px]" />
            }
          </Typography.Text>
        }
      </div>
    </div>
  );
};
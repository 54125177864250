import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import httpClient from '../../../utils/http-client.util';
import { BackendAPI } from '../../../constants/backend-api.enum';
import { EditableAttribute, EditableAttributeDateType } from '../../../components/inputs/editable-attribute.component';
import { popMessage } from '../../../utils/pop-message.util';

export const UnexpectedClosureReasonSetting = ({ children, onChange }: PropsWithChildren & {
  onChange?: (newUnexpectedCloseReasons: string[]) => void,
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [unexpectedCloseReasons, setUnexpectedCloseReasons] = useState<string[]>([]);
  const [addingReason, setAddingReason] = useState(false);

  const remove = (index: number) => {
    unexpectedCloseReasons.splice(index, 1);
    setUnexpectedCloseReasons([...unexpectedCloseReasons]);
  };

  const add = (newValue: EditableAttributeDateType | EditableAttributeDateType[], continuous?: boolean) => {
    setAddingReason(false);
    if (newValue) {
      setUnexpectedCloseReasons([...unexpectedCloseReasons, newValue.toString().trim()]);
    }
    setTimeout(() => setAddingReason(!!continuous));
  };

  const modify = (newValue: EditableAttributeDateType | EditableAttributeDateType[], index: number) => {
    if (newValue) {
      const reasonArray = [...unexpectedCloseReasons];
      reasonArray[index] = newValue.toString();
      setUnexpectedCloseReasons(reasonArray);
    }
  };

  const toggleModal = () => {
    setOpen(!open);
  };

  const onOk = async () => {
    setLoading(true);
    setTimeout(async () => {
      try {
        await httpClient.post(`${BackendAPI.LOCATION}/unexpected-close-reason`, unexpectedCloseReasons);
        setUnexpectedCloseReasons(unexpectedCloseReasons);
        if (onChange) {
          onChange(unexpectedCloseReasons);
        }
        popMessage.success('Successfully updated unexpected close reason');
        setOpen(false);
      } catch (e) {
        popMessage.error('Unable to updated unexpected close reason');
      } finally {
        setLoading(false);
      }
    }, 0);
  };

  const fetchData = async () => {
    try {
      const unexpectedCloseReasonResponse = await httpClient.get<string[]>(`${BackendAPI.LOCATION}/unexpected-close-reason`);
      setUnexpectedCloseReasons(unexpectedCloseReasonResponse.data);
      if (onChange) {
        onChange(unexpectedCloseReasonResponse.data);
      }
    } catch (e) {
      popMessage.error('Unable to load unexpected close reason');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (open) {
      fetchData();
    }
  }, [open]);

  return (
    <>
      {
        <span className="cursor-pointer" onClick={toggleModal}>
          {children}
        </span>
      }
      <Modal open={open} onCancel={toggleModal} onOk={onOk}
             confirmLoading={loading}
             okText={'Save'}
             destroyOnClose title={`Unexpected Closure Reason Setting`}
             maskClosable={false}>
        <h5 className="text-gray-500">Unexpected Close Reasons:</h5>
        {
          unexpectedCloseReasons.map((s, i) =>
            <div className={'flex justify-start items-baseline'} key={`location-service-${i}`}>
              <EditableAttribute indent canEdit={{ onSave: (newValue) => modify(newValue, i) }} value={s} label={''}
                                 type={'text'} />
              <i className="ri-delete-bin-3-line ms-6 text-red-500 hover:text-red-400 cursor-pointer"
                 onClick={() => remove(i)} />
            </div>,
          )
        }
        {
          addingReason &&
          <EditableAttribute canEdit={{
            onSave: add, defaultInEditing: true,
            onEnterKey: (newValue) => add(newValue, true),
            onCancel: () => setAddingReason(false),
          }} value={''} label={'New Reason (press Enter for fast input)'} type={'text'} />
        }
        <Button className="mt-2" onClick={() => setAddingReason(true)}>+ Reason</Button>
      </Modal>
    </>
  );
};
import { useEffect, useState } from 'react';
import { useBoundStore } from '../../../states/bound.store';
import { Affix, Avatar, Collapse, Divider, Space, Spin, Typography } from 'antd';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import { ChatMessage, ChatParticipant } from 'bridge/chat-message';
import dayjs from 'dayjs';
import 'react-photo-view/dist/react-photo-view.css';

type Prop = {
  chatBoxContainer: HTMLElement | null;
};

interface MessageDisplay {
  _id: string;
  sender: ChatParticipant;
  content: string;
  timestamp: number;
  type: 'text' | 'image_url';
  attachment?: MessageDisplay;
}

interface ChatBoxMessageGroup {
  name: string;
  nameSuffix: string;
  avatar: string;
  avatarBg: string;
  time: number;
  timeDisplay: string;
  type: 'conversation' | 'date';
  messages: MessageDisplay[];
}

interface ChatBoxDateDivider {
  type: 'date';
  date: string;
  entryIndex: number;
}

type ChatBoxContent = (ChatBoxMessageGroup | ChatBoxDateDivider)[];

export const MessageStreamDisplayComponent = ({ chatBoxContainer }: Prop) => {
  const [chatBoxContent, setChatBoxContent] = useState<ChatBoxContent>([]);
  const [affixedKey, setAffixedKey] = useState(0);
  const [loadingMessage, setLoadingMessage] = useState(false);

  const selectedConversation = useBoundStore((state) =>
    state.conversations.find((c) => c.donor.donorId === state.currentConversationDonorId)
  );

  const getFileNameFromURL = (url: string) => {
    // Split the URL by '/'
    const urlParts = url.split('/');

    // Get the last part of the URL (likely the file name)
    const lastPart = urlParts[urlParts.length - 1];

    const staffIdAndTimeRemoved = lastPart.split('___');

    // If the last part contains a query string, remove it
    return staffIdAndTimeRemoved[staffIdAndTimeRemoved.length - 1].split('?')[0];
  };

  const renderText = (message: MessageDisplay) => {
    return (
      <>
        <div>{message.content}</div>
        {message.attachment && <div>{renderImage(message.attachment)}</div>}
      </>
    );
  };
  const renderImage = (message: MessageDisplay) => {
    return (
      <Space>
        <PhotoProvider maskOpacity={0.9}>
          <Collapse
            defaultActiveKey={['1']}
            expandIcon={(panelProps) =>
              panelProps.isActive ? (
                <i className={'ri-arrow-up-circle-line'} />
              ) : (
                <i className={'ri-arrow-down-circle-line'} />
              )
            }
            size={'small'}
            ghost
            items={[
              {
                key: '1',
                label: <span>{getFileNameFromURL(message.content)}</span>,
                children: (
                  <PhotoView key={1} src={message.content}>
                    <img
                      src={message.content}
                      alt=""
                      loading="lazy"
                      height={window.innerHeight * 0.2}
                      style={{ borderRadius: 8, cursor: 'pointer' }}
                    />
                  </PhotoView>
                ),
              },
            ]}
          />
        </PhotoProvider>
      </Space>
    );
  };

  useEffect(() => {
    setChatBoxContent([]);
    const convertToConversationRender: (messages: MessageDisplay[]) => ChatBoxMessageGroup[] = (messages) => {
      const name = messages[0].sender.name;
      const avatar = messages[0].sender.avatar;
      const nameSuffix = `(${messages[0].sender.role})`;
      const avatarBg = messages[0].sender.role === 'donor' ? '#f56a00' : '#1677ff';

      let baseTime = messages[0].timestamp;
      let startIndex = 0;
      let currentIndex = 1;
      const groups: ChatBoxMessageGroup[] = [];
      while (currentIndex <= messages.length - 1) {
        if (messages[currentIndex].timestamp > baseTime + 10 * 60 * 1000) {
          // 10 minutes
          const group: ChatBoxMessageGroup = {
            name,
            nameSuffix,
            avatar,
            avatarBg,
            time: baseTime,
            timeDisplay: dayjs(baseTime).format('hh:mmA'),
            type: 'conversation',
            messages: messages.slice(startIndex, currentIndex),
          };
          groups.push(group);
          startIndex = currentIndex;
          baseTime = messages[currentIndex].timestamp;
        }
        currentIndex += 1;
      }
      if (startIndex <= currentIndex) {
        const group: ChatBoxMessageGroup = {
          name,
          nameSuffix,
          avatar,
          avatarBg,
          time: baseTime,
          timeDisplay: dayjs(baseTime).format('hh:mmA'),
          type: 'conversation',
          messages: messages.slice(startIndex, currentIndex + 1),
        };
        groups.push(group);
      }
      return groups;
    };

    const insertDateStrings: (sourceGroupedSortedMessage: ChatBoxMessageGroup[]) => ChatBoxContent = (
      sourceGroupedSortedMessage
    ) => {
      const resultArray = [];

      let dateEntryIndex = 0;
      for (let i = 0; i < sourceGroupedSortedMessage.length; i++) {
        const currentTimestamp = sourceGroupedSortedMessage[i].time;
        const currentDate = dayjs(currentTimestamp).format('MMM DD, YYYY');

        // Insert date string at the first position of each new date
        if (i === 0 || dayjs(sourceGroupedSortedMessage[i - 1].time).format('MMM DD, YYYY') !== currentDate) {
          resultArray.push({
            type: 'date',
            date: currentDate,
            entryIndex: dateEntryIndex,
          } as ChatBoxDateDivider);
          dateEntryIndex++;
        }

        // Insert the sorted element
        resultArray.push(sourceGroupedSortedMessage[i]);
      }

      return resultArray;
    };

    if (selectedConversation) {
      if (!selectedConversation.messages) {
        setLoadingMessage(true);
      } else {
        setLoadingMessage(false);
        if (!!selectedConversation.messages.length) {
          const chatMessageToDisplayMapping: (m: ChatMessage) => MessageDisplay = (m) => ({
            _id: m._id,
            sender: m.sender,
            content: m.content,
            type: m.contentType,
            timestamp: new Date(m.timestamp).getTime(),
          });

          const allMessages: (MessageDisplay | null)[] = selectedConversation.messages.map(chatMessageToDisplayMapping);

          const messagesDependsOnOthers = selectedConversation.messages.filter((m) => m.attachedTo);

          messagesDependsOnOthers.forEach((dependentMessage) => {
            if (selectedConversation.messages) {
              const associatedMessageIndex = allMessages.findIndex((m) => !!m && m._id === dependentMessage.attachedTo);
              const dependentMessageIndex = allMessages.findIndex((m) => !!m && m._id === dependentMessage._id);
              if (associatedMessageIndex > -1) {
                //attach dependency message to its dependent message
                const newAssociatedMessage = {
                  _id: selectedConversation.messages[associatedMessageIndex]._id,
                  sender: selectedConversation.messages[associatedMessageIndex].sender,
                  content: selectedConversation.messages[associatedMessageIndex].content,
                  timestamp: new Date(selectedConversation.messages[associatedMessageIndex].timestamp).getTime(),
                  type: selectedConversation.messages[associatedMessageIndex].contentType,
                  attachment: chatMessageToDisplayMapping(selectedConversation.messages[dependentMessageIndex]),
                };
                allMessages.push(newAssociatedMessage);
                allMessages[associatedMessageIndex] = null;
                allMessages[dependentMessageIndex] = null;
              }
            }
          });

          const attachmentProcessedMessages: MessageDisplay[] = allMessages.filter((m) => !!m) as MessageDisplay[];

          const sourceGroupedMessagesInTimeSequence = [];
          const sortedMessages = [...attachmentProcessedMessages].sort((a, b) => a.timestamp - b.timestamp);

          let startIndex = 0;
          let currentIndex = 0;
          while (currentIndex < sortedMessages.length - 1) {
            if (
              sortedMessages[currentIndex].sender.role !== sortedMessages[currentIndex + 1].sender.role ||
              (sortedMessages[currentIndex].sender.role === 'staff' &&
                sortedMessages[currentIndex].sender.id !== sortedMessages[currentIndex + 1].sender.id)
            ) {
              sourceGroupedMessagesInTimeSequence.push(
                ...convertToConversationRender(sortedMessages.slice(startIndex, currentIndex + 1))
              );
              startIndex = currentIndex + 1;
            }
            currentIndex += 1;
          }
          if (startIndex <= currentIndex) {
            sourceGroupedMessagesInTimeSequence.push(
              ...convertToConversationRender(sortedMessages.slice(startIndex, currentIndex + 1))
            );
          }

          const dateAddedRenderings = insertDateStrings(sourceGroupedMessagesInTimeSequence);

          setChatBoxContent(dateAddedRenderings);
        } else {
          setChatBoxContent([]);
        }
      }
    }
  }, [selectedConversation]);

  useEffect(() => {
    if (chatBoxContent) {
      const interval = setInterval(() => {
        const chatBox = document.querySelector('.chatBox');
        if (chatBox) {
          chatBox.scrollIntoView({ behavior: 'instant', block: 'end' });
          clearInterval(interval);
        }
      }, 150);
    }
  }, [chatBoxContent]);

  useEffect(() => {
    if (!chatBoxContainer) {
      return;
    }
    let scrollBarAtBottom: boolean;
    const handleScroll = () => {
      const element = chatBoxContainer;

      // Check if the scrollbar is at the bottom
      scrollBarAtBottom = element.scrollTop + element.clientHeight + 16 >= element.scrollHeight;
    };

    const observer = new ResizeObserver((entries) => {
      if (entries[0] && scrollBarAtBottom) {
        const chatBox = document.querySelector('.chatBox');
        if (chatBox) {
          chatBox.scrollIntoView({ behavior: 'auto', block: 'end' });
        }
      }
    });

    // Attach the event listener when the component mounts
    const scrollableElement = chatBoxContainer;
    if (scrollableElement) {
      scrollableElement.addEventListener('scroll', handleScroll);
      observer.observe(scrollableElement);
    }

    // Clean up the event listener when the component unmounts
    return () => {
      if (scrollableElement) {
        scrollableElement.removeEventListener('scroll', handleScroll);
        observer.unobserve(scrollableElement);
      }
    };
  }, [chatBoxContainer]);

  return (
    <div
      className={'chatBox'}
      style={{
        padding: '0 8px',
      }}
    >
      {loadingMessage && (
        <div className={'flex flex-col items-center justify-center'}>
          <Spin />
          <span>Loading Conversation Message</span>
        </div>
      )}
      {chatBoxContent.map((c, i) => {
        if (c.type === 'conversation') {
          const content = c as ChatBoxMessageGroup;
          return (
            <Space key={`c-${i}`} style={{ display: 'flex', alignItems: 'start', marginBlock: 4, columnGap: 10 }}>
              {content.avatar ? (
                <Avatar shape={'square'} style={{ marginTop: 8 }} src={<img src={content.avatar} alt="avatar" />} />
              ) : (
                <Avatar shape={'square'} style={{ marginTop: 8, backgroundColor: content.avatarBg }}>
                  {content.name
                    .split(' ')
                    .map((n) => n.slice(0, 1))
                    .join('')
                    .toUpperCase()}
                </Avatar>
              )}

              <div style={{ width: '100%' }}>
                {content.messages.map((message, mi) => {
                  return (
                    <div
                      key={`c-${i}-${mi}`}
                      className={mi !== 0 ? 'message-display-entry with-time' : 'message-display-entry'}
                      data-entry-time={dayjs(message.timestamp).format('hh:mmA')}
                    >
                      {mi === 0 && (
                        <div>
                          <Typography.Text strong style={{ marginRight: 4, fontSize: 16 }}>
                            {content.name}
                            <Typography.Text style={{ marginLeft: 2 }} type={'secondary'}>
                              {content.nameSuffix}
                            </Typography.Text>
                          </Typography.Text>
                          <Typography.Text style={{ fontSize: 12 }} type={'secondary'}>
                            {content.timeDisplay}
                          </Typography.Text>
                        </div>
                      )}
                      {message.type === 'text' && renderText(message)}
                      {message.type === 'image_url' && renderImage(message)}
                    </div>
                  );
                })}
              </div>
            </Space>
          );
        } else if (c.type === 'date') {
          const content = c as ChatBoxDateDivider;
          return (
            <Affix
              key={`d-${i}`}
              target={() => chatBoxContainer}
              onChange={(affixed) => {
                if (affixed) {
                  setAffixedKey(content.entryIndex);
                } else {
                  setAffixedKey(content.entryIndex - 1);
                }
              }}
              offsetTop={-12}
            >
              <Divider
                style={{
                  opacity: affixedKey <= content.entryIndex ? '100' : '0',
                  position: 'relative',
                  margin: 0,
                }}
              >
                <Typography.Text type={'secondary'}>{content.date}</Typography.Text>
              </Divider>
            </Affix>
          );
        }
      })}
    </div>
  );
};

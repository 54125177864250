import React, { useEffect, useState } from 'react';
import { Badge, List, Modal, Switch, Tabs, Typography } from 'antd';
import { BoardContent } from '../../../components/layout/board-content.component';
import { popMessage } from '../../../utils/pop-message.util';
import httpClient from '../../../utils/http-client.util';
import { FacilityLocation } from 'bridge/location';
import { BackendAPI } from '../../../constants/backend-api.enum';
import { EntityPromotionBonus } from 'bridge/promotion-bonus';
import { DataTable, DataTableColumn, DataTableSetting } from '../../../components/data-table/data-table.component';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { DonationsDisplay } from './components/donations-display';
import { TableauView } from '../components/tableau-view';

let route = {
  title: 'Create',
  route: '/promotion-bonus/create',
};

export type appliedCenter = {
  text: string;
  value: string;
}

const { confirm } = Modal;

export const PromotionBonusListPage = () => {

  const [appliedCenter, setAppliedCenter] = useState<appliedCenter[]>([]);
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [promotionBonusData, setPromotionBonusData] = useState<EntityPromotionBonus[]>([]);
  const [heatMapOpen, setHeatMapOpen] = useState(false)
  const [tabActive, setTabActive] = useState<string>('0');
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const allLocationsRequest = await httpClient.get<FacilityLocation[]>(BackendAPI.LOCATION);
        if (allLocationsRequest.data) {
          let newLocation: appliedCenter[] = allLocationsRequest.data.map((item) => {
            return {
              text: item.name,
              value: item.name,
            }
          })
          setAppliedCenter(newLocation)
        }
      } catch (e) {
        popMessage.error('Unable to fetch locations data');
      }
    })();
  }, []);

  const fetchPromotionBonusData = async () => {
    try {
      setPageLoading(true);
      let res = await httpClient.get(`${BackendAPI.PROMOTIONBONUS}/lists`);
      if (res && res.status === 200) {
        setPromotionBonusData(res.data.data)
      }
    } catch (e) {
      popMessage.error('Unable to fetch Fee Structure data');
    } finally {
      setPageLoading(false);
    }
  }

  useEffect(() => {
    fetchPromotionBonusData()
  }, [])

  const handleDelete = (promotionBonusData: EntityPromotionBonus) => {
    confirm({
      title: 'Are you sure delete this Promotion Bonus?',
      icon: <ExclamationCircleFilled />,
      content: (
        <div>
          <p className="font-bold">{promotionBonusData.campaignName}</p>
        </div>
      ),
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        httpClient
          .post(`${BackendAPI.PROMOTIONBONUS}/delete/${promotionBonusData.campaignId}`)
          .then((res) => {
            if (res.status === 200) {
              popMessage.success('Deleted Successfully');
              fetchPromotionBonusData()
            }
          })
          .catch((err) => {
            return false;
          });
      },
      onCancel() { },
    });
  };

  const updateStatus = async (promotionBonusData: EntityPromotionBonus) => {
    try {
      setPageLoading(true);
      let res = await httpClient.post(`${BackendAPI.PROMOTIONBONUS}/status/${promotionBonusData.campaignId}`, { status: promotionBonusData.status === "active" ? "inactive" : "active" });
      if (res && res.status === 200) {
        popMessage.success('Updated Successfully');
        fetchPromotionBonusData()
      }
    } catch (e) {
      popMessage.error('Unable to fetch Fee Structure data');
    } finally {
      setPageLoading(false);
    }
  }

  const currentTableSettings: DataTableSetting<EntityPromotionBonus> = {
    numbered: true,
    numberedWidth: 100,
    pagination: {
      pageSize: 10,
    },
    scroll: { x: 2000, y: 500 },
    bordered: true,
    size: 'large',
    operationColumnWidth: 180,
    customizedOperations: [
      {
        element: (promotionBonusData) => {
          return (
            <Switch
              size="small"
              checked={promotionBonusData.status === 'active' ? true : false}
              onChange={(checked) => {
                updateStatus(promotionBonusData);
              }}
            />
          )
        },
      },
      {
        element: () => {
          return (
            <i className="ri-map-pin-line cursor-pointer text-blue-500" onClick={() => setHeatMapOpen(true)}></i>
          )
        }
      },
      {
        element: (promotionBonusData) => {
          return (
            <i className="ri-eye-line cursor-pointer text-blue-500" onClick={() => navigate(`../${promotionBonusData.campaignId}`, { state: { promotionBonusData: promotionBonusData } })}></i>
          )
        },
      },
      {
        element: (promotionBonusData) => {
          return (
            <i className="ri-delete-bin-line text-red-500 cursor-pointer" onClick={() => handleDelete(promotionBonusData)}></i>
          )
        },
      }
    ]
  }

  const expiredTableSettings: DataTableSetting<EntityPromotionBonus> = {
    numbered: true,
    numberedWidth: 100,
    pagination: {
      pageSize: 10,
    },
    scroll: { x: 2000, y: 500 },
    bordered: true,
    size: 'large',
    operationColumnWidth: 180,
    customizedOperations: [
      {
        element: () => {
          return (
            <i className="ri-map-pin-line cursor-pointer text-blue-500" onClick={() => setHeatMapOpen(true)}></i>
          )
        }
      },
      {
        element: (promotionBonusData) => {
          return (
            <i className="ri-eye-line cursor-pointer text-blue-500" onClick={() => navigate(`../${promotionBonusData.campaignId}`, { state: { promotionBonusData: promotionBonusData } })}></i>
          )
        },
      }
    ]
  }

  const currentData: EntityPromotionBonus[] = promotionBonusData.filter((item) => item.status !== "expired")
  const expiryData: EntityPromotionBonus[] = promotionBonusData.filter((item) => item.status === "expired")
  const columns: DataTableColumn<EntityPromotionBonus>[] = [
    {
      title: 'Campaign Name',
      dataIndex: 'campaignName',
      key: 'campaignName',
      width: 200,
      filterable: true,
    },
    {
      title: 'Campaign Description',
      dataIndex: 'campaignDescription',
      key: 'campaignDescription',
      width: 250,
      filterable: true,
    },
    {
      title: 'From Date',
      dataIndex: 'startDate',
      key: 'startDate',
      width: 130,
      filterable: true,
    },
    {
      title: 'To Date',
      dataIndex: 'endDate',
      key: 'endDate',
      width: 130,
      filterable: true,
    },
    {
      title: 'Target Group',
      dataIndex: 'campaignType',
      width: 250,
      filterable: true,
      key: 'type',
    },
    {
      title: 'Donations',
      width: 250,
      key: 'condition',
      render: (promotionBonusData, __, index: number) => {
        return (
          <div>
            <DonationsDisplay promotionBonusData={promotionBonusData} />
          </div>
        )
      }
    },
    {
      title: 'Applied Centers',
      dataIndex: 'appliedCenter',
      key: 'appliedCenter',
      width: 200,
      filters: appliedCenter,
      onFilter: (value: React.Key | boolean, record: EntityPromotionBonus) => {
        return record.appliedCenter.includes(String(value))
      },
      render: (centers: EntityPromotionBonus["appliedCenter"]) => {
        return (
          <List size="small">
            {
              centers.length > 0 ? (
                centers.map((item, index) => (
                  <List.Item style={{ padding: '8px 0' }}>{item}</List.Item>
                ))
              ) : null
            }
          </List>
        )
      }
    },
  ]

  const listTabs = [
    {
      key: '0',
      label: (
        <Typography.Text>
          Current Program
          <Badge
            className="site-badge-count-109 ms-1"
            count={currentData.length ? currentData.length : 0}
            style={{ backgroundColor: '#52c41a' }}
          />
        </Typography.Text>
      ),
      children: <DataTable style={{ margin: '0' }} data={currentData} setting={currentTableSettings} loading={pageLoading} columnsDef={columns} />,
    },
    {
      key: '1',
      label: (
        <Typography.Text>
          Expired Program
          <Badge
            className="site-badge-count-109 ms-1"
            count={expiryData.length ? expiryData.length : 0}
          />
        </Typography.Text>
      ),
      children: <DataTable style={{ margin: '0' }} data={expiryData} setting={expiredTableSettings} loading={pageLoading} columnsDef={columns} />,
    },
  ]

  return (
    <BoardContent title="Promotion Bonus" loading={false} hasButton={true} redirect={route}>
      <Tabs
        activeKey={String(tabActive)}
        items={listTabs}
        onChange={(key: string) => setTabActive(key)}
      />
      <Modal
        centered
        open={heatMapOpen}
        onCancel={() => setHeatMapOpen(false)}
        width="auto"
        footer={null}
      >
        <TableauView />
      </Modal>
    </BoardContent>
  );
};
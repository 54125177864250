import { Switch } from 'antd';
import React, { useState } from 'react';
import '../chatbox.css';
import { defaultLanguage } from '../../../../constants/language.constant';
import { MinimalTreeItemData } from 'bridge/chatboxpreset';
import { itemsPayload } from '../function/utils';
import httpClient from '../../../../utils/http-client.util';
import { BackendAPI } from '../../../../constants/backend-api.enum';
import { popMessage } from '../../../../utils/pop-message.util';
import { TreeItemComponentProps } from 'dnd-kit-sortable-tree';

type Prop = {
  data: TreeItemComponentProps<MinimalTreeItemData>;
  isItemsChanges: boolean;
  dragMoveChanges: boolean;
  language: string;
  fetchChatbox: Function;
};

export const ActionService = ({ data, isItemsChanges, dragMoveChanges, language, fetchChatbox }: Prop) => {
  const { additionalService, children } = data.item;
  const hasChildren = children && children.length > 0 ? true : false;
  const [isService, setIsService] = useState(additionalService);

  const updateItemById = async (entityChatbox: MinimalTreeItemData, value: string) => {
    entityChatbox.additionalService = value;
    await httpClient
      .post(`${BackendAPI.CHATBOX}/updatePreset`, itemsPayload(entityChatbox))
      .then((res) => {
        if (res.status === 200) {
          fetchChatbox();
          popMessage.success('Updated Successfully');
        }
      })
      .catch((err) => {
        return false;
      });
  };

  return (
    <div
      className={`flex flex-row items-end gap-4 ${dragMoveChanges || isItemsChanges || hasChildren || language !== defaultLanguage ? 'disabledDiv' : ''}`}
    >
      <div className="chatbox-switch">
        <label>ChatGPT</label>
        <Switch
          size="small"
          checked={isService === 'ChatGPT' ? true : false}
          onChange={(checked) => {
            setIsService(checked ? 'ChatGPT' : null);
            updateItemById(data.item, checked ? 'ChatGPT' : '');
          }}
        />
      </div>
      <div className="chatbox-switch">
        <i className="ri-customer-service-line text-[24px]"></i>
        <Switch
          size="small"
          checked={isService === 'CustomerService' ? true : false}
          onChange={(checked) => {
            setIsService(checked ? 'CustomerService' : null);
            updateItemById(data.item, checked ? 'CustomerService' : '');
          }}
        />
      </div>
    </div>
  );
};

import { Button, Card, Input, Radio, Select, Space, Typography } from 'antd';
import { EntityCondition } from 'bridge/promotion-bonus';
import React, { useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { RadioChangeEvent } from 'antd/lib';

export const DeferralDonors = ({ onChangeData, conditionData }: { onChangeData?: (data: EntityCondition[]) => void, conditionData: EntityCondition[] }) => {
  const [deferralGroupConditions, setDeferralGroupConditions] = useState<EntityCondition[]>([{
    duration: [''],
    deferralType: '',
    donations: [
      {
        type: '',
        value: '',
      },
    ],
  }]);
  let donationType = ["money", "point"]

  useEffect(() => {
    if (onChangeData) {
      onChangeData(deferralGroupConditions)
    }
  }, [deferralGroupConditions])

  useEffect(() => {
    if (conditionData && conditionData.length > 0) {
      setDeferralGroupConditions(conditionData)
    }
  }, [conditionData])

  const handleAddCondition = () => {
    setDeferralGroupConditions([...deferralGroupConditions, {
      duration: [''],
      deferralType: '',
      donations: [
        {
          type: '',
          value: '',
        },
      ],
    }])
  };

  const handleDeleteCondition = (index: number) => {
    if (deferralGroupConditions.length > 1) {
      deferralGroupConditions.splice(index, 1)
      setDeferralGroupConditions([...deferralGroupConditions])
    }
  }

  const handleAddDonation = (index: number) => {
    let newCondition = [...deferralGroupConditions];
    let targetField = { ...newCondition[index] }
    targetField = {
      ...targetField, donations: [...targetField.donations, {
        type: "",
        value: ""
      }]
    }
    newCondition[index] = targetField
    setDeferralGroupConditions(newCondition)
  }

  const handleSelectDonation = (event: RadioChangeEvent, indexCondition: number, indexDonation: number) => {
    let newCondition = [...deferralGroupConditions];
    newCondition[indexCondition].donations[indexDonation].value = ""
    newCondition[indexCondition].donations[indexDonation].type = event.target.value
    setDeferralGroupConditions(newCondition)
  }

  const handleDeleteDonation = (indexCondition: number, indexDonation: number) => {
    if (deferralGroupConditions[indexCondition].donations.length > 1) {
      deferralGroupConditions[indexCondition].donations.splice(indexDonation, 1)
      setDeferralGroupConditions([...deferralGroupConditions])
    }
  }

  let selectedDeferralTypes = deferralGroupConditions?.filter(value => value.deferralType !== '' && value.deferralType !== undefined).map((item) => item.deferralType);
  const validateAllTypes = () => {
    return selectedDeferralTypes?.length > 0 && selectedDeferralTypes.every((value) => ['1 week deferral', '1 day deferral', '2 weeks deferral'].includes(`${value}`))
  }
  const selectedAllTypes = (value: string) => {
    return selectedDeferralTypes?.includes('All Types')
  }

  let deferralTypesOptions = [
    { value: 'All Types', label: 'All Types', disabled: validateAllTypes() },
    { value: '1 week deferral', label: '1 week deferral', disabled: selectedAllTypes('1 week deferral') },
    { value: '1 day deferral', label: '1 day deferral', disabled: selectedAllTypes('1 day deferral') },
    { value: '2 weeks deferral', label: '2 weeks deferral', disabled: selectedAllTypes('2 weeks deferral') }
  ];

  return (
    <div>
      <div className="flex flex-col gap-4" style={{ maxWidth: 600 }}>
        {
          deferralGroupConditions.map((item, index) => (
            <Card
              size="small"
              title={`Condition #${index + 1}`}
              key={index}
              extra={
                deferralGroupConditions.length > 1 ? <CloseOutlined onClick={() => handleDeleteCondition(index)} /> : null
              }
              className='condition-item'
              style={{ backgroundColor: '#fafafa' }}
            >
              <div className='flex flex-col gap-4'>
                <Typography.Text className='flex flex-row text-nowrap items-center gap-4 font-semibold'>
                  Deferral Types
                  <Select
                    allowClear
                    value={item.deferralType}
                    style={{ width: '100%' }}
                    options={deferralTypesOptions}
                    onChange={(value: string) => {
                      let condition = [...deferralGroupConditions];
                      let targetField = { ...condition[index] }
                      targetField.deferralType = value;
                      condition[index] = targetField
                      setDeferralGroupConditions(condition)
                    }}
                  />
                </Typography.Text>
                <Typography.Text className="flex flex-row gap-4 items-center span font-semibold">
                  <span>Make donations in</span>
                  <Input
                    style={{ width: '50px' }}
                    value={item.duration[0]}
                    onChange={(event) => {
                      let condition = [...deferralGroupConditions];
                      let targetField = { ...condition[index] }
                      targetField.duration[0] = event.target.value;
                      condition[index] = targetField
                      setDeferralGroupConditions(condition)
                    }} />
                  <span>-</span>
                  <Input
                    style={{ width: '50px' }}
                    value={item.duration[1]}
                    onChange={(event) => {
                      let condition = [...deferralGroupConditions];
                      let targetField = { ...condition[index] }
                      targetField.duration[1] = event.target.value;
                      condition[index] = targetField
                      setDeferralGroupConditions(condition)
                    }} />
                  <span>days since deferral end date</span>
                </Typography.Text>
                {
                  item.donations.map((it, ind) => (
                    <div className='d-flex flex-row align-items-center gap-2'>
                      <Card
                        size="small"
                        title={`Donation #${ind + 1}`}
                        key={ind}
                        extra={
                          item.donations.length > 1 ? <CloseOutlined onClick={() => handleDeleteDonation(index, ind)} /> : null
                        }
                      >
                        <Space direction="vertical" className='flex flex-row'>
                          {
                            donationType.map((itemDonation, indexDonation) => (
                              <Radio checked={it.type === itemDonation} onChange={(event) => handleSelectDonation(event, index, ind)} value={itemDonation} className='flex flex-row items-center'>
                                <div className='flex flex-row items-center gap-4'>
                                  <Input
                                    name={itemDonation}
                                    disabled={item.donations[ind].type === itemDonation ? false : true}
                                    value={item.donations[ind].type === itemDonation ? deferralGroupConditions[index].donations[ind].value : ""}
                                    onChange={(event) => {
                                      let condition = [...deferralGroupConditions];
                                      let targetField = { ...condition[index] }
                                      let donation = [...targetField.donations]
                                      let target = { ...donation[ind] }
                                      target = { ...target, value: event.target.value }
                                      donation[ind] = target
                                      condition[index].donations = donation
                                      setDeferralGroupConditions(condition)
                                    }} />
                                  <span>{itemDonation === "money" ? "$" : "pts"}</span>
                                </div>
                              </Radio>
                            ))
                          }
                        </Space>
                      </Card>
                    </div>
                  ))
                }
                <Button type="dashed" onClick={() => handleAddDonation(index)} block>
                  + Add Donation
                </Button>
              </div>
            </Card>
          ))
        }
        <Button type="dashed" onClick={handleAddCondition} block style={{ maxWidth: 600 }}>
          + Add Item
        </Button>
      </div>
    </div>
  );
};
import React, { PropsWithChildren } from 'react';
import { Button, Space, Spin, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';

export const BoardContent = ({
  title,
  children,
  loading,
  hasButton,
  redirect,
}: PropsWithChildren & {
  title: string;
  loading: boolean;
  hasButton: boolean;
  redirect: {
    title: string;
    route: string;
  };
}) => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="flex justify-between items-center">
        <Typography.Title level={3}>{title}</Typography.Title>
        {hasButton && (
          <Space>
            <Button type={'primary'} onClick={() => navigate(redirect.route)}>
              {redirect.title}
            </Button>
          </Space>
        )}
      </div>
      <Spin spinning={loading}>
        <div style={boardStyle}>{children}</div>
      </Spin>
    </div>
  );
};

const boardStyle = {
  color: 'white',
  backgroundColor: 'var(--primary-foreground)',
  borderRadius: '5px',
  padding: '10px 20px'
};

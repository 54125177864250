import { Button, Form, Input, Select, Space, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { BoardContent } from '../../../components/layout/board-content.component';
import { EntityContactUs } from 'bridge/contact-us';
import { popMessage } from '../../../utils/pop-message.util';
import httpClient from '../../../utils/http-client.util';
import { BackendAPI } from '../../../constants/backend-api.enum';
import { validateEmail } from '../../../utils/validations.util';
import { dialCode } from '../../../constants/country-code.constant';

export const ContactSetupPage = () => {
  const contactSetupForm: EntityContactUs = {
    email: '',
    phone: {
      countryCode: '',
      number: '',
    },
  };

  let route = {
    title: '',
    route: '',
  };

  const [contactSetupData, setContactSetupData] = useState<EntityContactUs>(contactSetupForm);
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        setPageLoading(true);
        let fetchContactUs = await httpClient.get(`${BackendAPI.CONTACTUS}/get`);
        if (fetchContactUs) {
          let data = fetchContactUs.data.data.shift();
          let newObject: EntityContactUs = {
            email: data.email,
            phone: {
              countryCode: data.phone.countryCode,
              number: data.phone.number,
            },
          };
          setContactSetupData(newObject);
        }
      } catch (e) {
        popMessage.error('Unable to fetch Contact Us data');
      } finally {
        setPageLoading(false);
      }
    })();
  }, []);

  const fieldErrors = () => {
    const errors = [];
    if (!contactSetupData.email.length) {
      errors.push('Email not allow to be empty');
    }
    if (!contactSetupData.phone.number.length) {
      errors.push('Phone Number not allow to be empty');
    }
    if (!contactSetupData.phone.countryCode.length) {
      errors.push('Country Code not allow to be empty');
    }
    if (!validateEmail(contactSetupData.email)) {
      errors.push('Email is invalid!');
    }
    return errors;
  };

  const submitContactSetup = async () => {
    try {
      setButtonLoading(true);
      let res = await httpClient.post(`${BackendAPI.CONTACTUS}/create`, contactSetupData);
      if (res.status === 200) {
        popMessage.success('Contact Set Up Successfully');
      }
    } catch (e) {
      popMessage.error('Unable to create contact us');
    } finally {
      setButtonLoading(false);
    }
  };

  return (
    <BoardContent title="Contact Us" loading={pageLoading} hasButton={false} redirect={route}>
      <div className="p-[40px] grid gap-4">
        <div className="grid grid-cols-6">
          <div className="col-span-1">
            <Typography.Text type="secondary">Email:</Typography.Text>
          </div>
          <div className="col-span-3">
            <Form.Item
              rules={[
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                },
              ]}
            >
              <Input
                value={contactSetupData.email}
                onChange={(event) => {
                  setContactSetupData({ ...contactSetupData, email: event.target.value });
                }}
              />
            </Form.Item>
          </div>
        </div>
        <div className="grid grid-cols-6">
          <div className="col-span-1">
            <Typography.Text type="secondary">Phone Number:</Typography.Text>
          </div>
          <div className="col-span-3">
            <Space direction="vertical" size="middle" className="w-full">
              <Space.Compact className="w-full">
                <Select
                  showSearch
                  style={{ width: '20%' }}
                  value={contactSetupData.phone.countryCode}
                  onChange={(key: string) => {
                    console.log('value', key);
                    const phone: EntityContactUs['phone'] = {
                      ...contactSetupData.phone,
                      countryCode: key,
                    };
                    setContactSetupData({ ...contactSetupData, phone });
                  }}
                  options={dialCode.map((item, index) => ({
                    value: `${item.dial_code}`,
                    key: `${item.dial_code + index}`,
                    label: `${item.flag} ${item.dial_code}`,
                  }))}
                />
                <Input
                  style={{ width: '80%' }}
                  type="number"
                  value={contactSetupData.phone.number}
                  onChange={(event) => {
                    const phone: EntityContactUs['phone'] = {
                      ...contactSetupData.phone,
                      number: event.target.value,
                    };
                    setContactSetupData({ ...contactSetupData, phone });
                  }}
                />
              </Space.Compact>
            </Space>
          </div>
        </div>
        <div className="flex flex-row items-center justify-center">
          <Button size={'large'} disabled={!!fieldErrors().length} onClick={submitContactSetup} loading={buttonLoading}>
            Submit
          </Button>
        </div>
      </div>
    </BoardContent>
  );
};

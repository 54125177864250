import React, { forwardRef, useEffect, useState } from 'react';
import './chatbox.css';
import { Button, Modal, Select, Space, Spin, Typography } from 'antd';
import { BackendAPI } from '../../../constants/backend-api.enum';
import httpClient from '../../../utils/http-client.util';
import { addIndexProperty, buildTree, sortArrayByIndex } from './function/utils';
import {
  flattenTree,
  SimpleTreeItemWrapper,
  SortableTree,
  TreeItem,
  TreeItemComponentProps,
} from 'dnd-kit-sortable-tree';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { defaultLanguage, supportingLanguages } from '../../../constants/language.constant';
import { ActionService } from './components/action-service.component';
import { popMessage } from '../../../utils/pop-message.util';
import { ChatBox } from './components/chatbox';
import { EntityChatbox, MinimalTreeItemData } from 'bridge/chatboxpreset';
import { FlattenedItem } from 'dnd-kit-sortable-tree/dist/types';

const { confirm } = Modal;

export const ChatBoxPresetPage = () => {
  const [chatBoxData, setChatBoxData] = useState<TreeItem<MinimalTreeItemData>[]>([]);
  const [languageChatbox, setLanguageChatBox] = useState<string>(defaultLanguage);
  const [isItemsChanges, setIsItemsChanges] = useState(false);
  const [dragMoveChanges, setDragMoveChanges] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetchChatbox = async () => {
    setIsLoading(true);
    let res = await httpClient.get(`${BackendAPI.CHATBOX}/lists`);
    if (res.status === 200) {
      setIsLoading(false);
      let resData = res.data.data;
      setChatBoxData(sortArrayByIndex(buildTree(resData)));
    }
  };

  useEffect(() => {
    fetchChatbox();
  }, []);

  const handleSaveChatbox = async () => {
    setIsLoading(true);
    const payload = flattenTree(chatBoxData);
    await httpClient
      .post(`${BackendAPI.CHATBOX}/update`, payload)
      .then((res) => {
        if (res.status === 200) {
          setIsLoading(false);
          setIsItemsChanges(false);
          setDragMoveChanges(false);
          popMessage.success('Updated Chatbox Preset Successfully');
        }
      })
      .catch((err) => {
        return false;
      });
  };

  const handleDelete = (entityChatbox: EntityChatbox) => {
    confirm({
      title: 'Are you sure delete this Chatbox?',
      icon: <ExclamationCircleFilled />,
      content: (
        <div>
          <p className="font-bold">{entityChatbox.text[languageChatbox]}</p>
          <p>{entityChatbox.description[languageChatbox]}</p>
        </div>
      ),
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        httpClient
          .post(`${BackendAPI.CHATBOX}/delete`, { id: entityChatbox.id })
          .then((res) => {
            if (res.status === 200) {
              fetchChatbox();
              popMessage.success('Deleted Successfully');
            }
          })
          .catch((err) => {
            return false;
          });
      },
      onCancel() { },
    });
  };

  const handleCancelDrag = () => {
    fetchChatbox();
    setIsItemsChanges(false);
    setDragMoveChanges(false);
  };

  const handleDrag = (data: TreeItem<MinimalTreeItemData>[]) => {
    setChatBoxData(addIndexProperty(data));
    setDragMoveChanges(true);
  };

  const handleAddChatbox = (id: EntityChatbox['_id']) => {
    let preventData = flattenTree(chatBoxData);
    let fetchItem = preventData.find((item) => item._id === id);

    let newObj = {
      id: 1,
      parentId: id ? id : null,
      depth: fetchItem ? fetchItem.depth + 1 : 0,
    } as FlattenedItem<MinimalTreeItemData>;
    preventData.push(newObj);
    setChatBoxData(buildTree(preventData));
    setIsItemsChanges(true);
  };

  return (
    <>
      <div className="flex justify-between items-center">
        <Typography.Title level={3}>Chatbox Preset</Typography.Title>
        <Space>
          <Select
            style={{ width: '100px' }}
            value={languageChatbox}
            onChange={(value) => setLanguageChatBox(value)}
            options={supportingLanguages}
          />
          {dragMoveChanges && (
            <>
              <Button onClick={handleCancelDrag}>Cancel</Button>
              <Button type={'primary'} onClick={handleSaveChatbox}>
                Save
              </Button>
            </>
          )}
        </Space>
      </div>
      <Spin spinning={isLoading}>
        <div className="chatbox-list">
          <SortableTree
            indentationWidth={50}
            items={chatBoxData}
            onItemsChanged={handleDrag}
            TreeItemComponent={forwardRef<HTMLDivElement, TreeItemComponentProps<MinimalTreeItemData>>((props, ref) => (
              <div className="flex flex-row items-center gap-4">
                <div className="chatbox-item">
                  <SimpleTreeItemWrapper disableSelection={true} disableCollapseOnItemClick={true} manualDrag={true} showDragHandle={false} className="custom-tree" {...props} ref={ref}>
                    <div className={`${isItemsChanges || languageChatbox !== defaultLanguage ? 'disabledDiv' : ''}`}>
                      <i className="ri-draggable" style={{ fontSize: '28px' }} {...props.handleProps}></i>
                    </div>
                    <ChatBox
                      data={props}
                      language={languageChatbox}
                      handleDelete={handleDelete}
                      isItemsChanges={isItemsChanges}
                      handleAddChatbox={handleAddChatbox}
                      handleCancelDrag={handleCancelDrag}
                      dragMoveChanges={dragMoveChanges}
                      fetchChatbox={fetchChatbox}
                    />
                    <ActionService
                      data={props}
                      language={languageChatbox}
                      fetchChatbox={fetchChatbox}
                      isItemsChanges={isItemsChanges}
                      dragMoveChanges={dragMoveChanges}
                    />
                  </SimpleTreeItemWrapper>
                </div>
              </div>
            ))}
          />
        </div>
      </Spin>
    </>
  );
};

import { useEffect, useState } from 'react';
import { popMessage } from '../../utils/pop-message.util';
import { Conversation, NewMessage } from 'bridge/chat-message';
import { useBoundStore } from '../../states/bound.store';
import { BackendAPI } from '../../constants/backend-api.enum';

type ChatEvent =
  | {
      type: 'initialize';
      data: Conversation[];
    }
  | {
      type: 'newMessage';
      data: NewMessage;
    };
export const ChatSSEComponent = () => {
  const [connectionStatus, setConnectionStatus] = useState({ connected: false, exponentialBackoff: 0 });
  const [unexpectedDisconnect, setUnexpectedDisconnect] = useState(false);
  const [askedToLeave, setAskedToLeave] = useState(false);
  const conversations = useBoundStore((state) => state.conversations);

  const initializeConversationList = useBoundStore((state) => state.initializeConversationList);
  const addNewMessage = useBoundStore((state) => state.addNewMessage);
  const addBadgedPath = useBoundStore((state) => state.addBadgedPath);
  const removeBadgedPath = useBoundStore((state) => state.removeBadgedPath);

  useEffect(() => {
    if (!connectionStatus.connected && connectionStatus.exponentialBackoff < 5) {
      if (askedToLeave) {
        return;
      }
      let url = BackendAPI.CUSTOMER_SERVICE + '/sse';
      if (unexpectedDisconnect) {
        url = url + '?reconnect=true';
      }

      setTimeout(
        async () => {
          const eventSource = new EventSource(url, {
            withCredentials: true,
          });
          eventSource.onmessage = async (event: MessageEvent<string>) => {
            try {
              const incomingEvent: ChatEvent = JSON.parse(event.data);
              if (incomingEvent.type === 'initialize') {
                initializeConversationList(incomingEvent.data);
              } else if (incomingEvent.type === 'newMessage') {
                setTimeout(() => {
                  // in case new message from db listener comes earlier than reply record from db saving
                  addNewMessage(incomingEvent.data);
                }, 500);
              }
            } catch (_) {
              if (['Connected', 'Reconnected'].includes(event.data)) {
                setUnexpectedDisconnect(false);
                setConnectionStatus({ connected: true, exponentialBackoff: 1 });
                if (event.data === 'Reconnected') {
                  popMessage.success({
                    content: 'Reconnected',
                    duration: 5,
                  });
                }
                return;
              }

              if (event.data === 'Heartbeat') {
                return;
              }

              if (event.data === 'Disconnect') {
                setAskedToLeave(true);
                eventSource.close();
              }
            }
          };

          eventSource.onerror = (error) => {
            const proactiveLogout = useBoundStore.getState().proactiveLoggingOut;

            if (proactiveLogout) {
              eventSource.close();
              return;
            }
            setUnexpectedDisconnect(true);
            setConnectionStatus({ connected: false, exponentialBackoff: connectionStatus.exponentialBackoff + 1 });
            console.log(error);
            popMessage.info({
              content: `Reconnecting Customer Service Message Server Exponentially x ${connectionStatus.exponentialBackoff + 1}`,
              duration: Math.pow(connectionStatus.exponentialBackoff + 1, 2),
              onCountdownEnd: () => {
                if (connectionStatus.exponentialBackoff === 4)
                  popMessage.error({
                    content: 'Unable to re-establish connection to message server',
                    duration: 0,
                  });
              },
            });
            eventSource.close();
          };
        },
        Math.pow(connectionStatus.exponentialBackoff, 2) * 1000
      );
    }
  }, [connectionStatus]);

  useEffect(() => {
    if (conversations.some((c) => c.hasUnreadMessage)) {
      addBadgedPath('/customer-service');
    } else {
      removeBadgedPath('/customer-service');
    }
  }, [conversations]);

  return <div style={{ display: 'none' }} />;
};

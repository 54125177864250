import { ChatMessage, Conversation, NewMessage } from 'bridge/chat-message';
import { Donor } from 'bridge/donor';
import { StateCreator } from 'zustand';
import httpClient from '../utils/http-client.util';
import { BackendAPI } from '../constants/backend-api.enum';

export interface CustomerServiceConversationSlice {
  conversations: Array<Conversation>;
  currentConversationDonorId: string | null;
  initializeConversationList: (conversations: Conversation[]) => void;
  startConversation: (donor: Donor) => void;
  addNewMessage: (newMessage: NewMessage) => void;
}

export const createCustomerServiceConversationSlice: StateCreator<
  CustomerServiceConversationSlice,
  [['zustand/devtools', never]],
  [],
  CustomerServiceConversationSlice
> = (set, get) => ({
  conversations: [],
  currentConversationDonorId: null,
  initializeConversationList: (conversations) => {
    set({ conversations }, false, 'initializeConversationList');
  },
  startConversation: async (donor) => {
    // load message to conversation if it exists, otherwise start new conversation
    set(
      {
        currentConversationDonorId: donor.donorId,
      },
      false,
      'setCurrentConversationDonorId'
    );
    const existingConversation = get().conversations.find((c) => c.donor.donorId === donor.donorId);
    let newConversation: Conversation;
    if (existingConversation) {
      if (existingConversation.messages) {
        // if messages is already loaded, then just mark as current
        newConversation = { ...existingConversation, hasUnreadMessage: false };
      } else {
        // if messages is not loaded, then load the messages
        const userMessages = await httpClient.get<ChatMessage[]>(
          `${BackendAPI.CUSTOMER_SERVICE}/messages/${donor.donorId}`
        );
        newConversation = {
          ...existingConversation,
          messages: userMessages.data,
          hasUnreadMessage: false,
        };
      }

      // call and mark the conversation as read
      await httpClient.post(`${BackendAPI.CUSTOMER_SERVICE}/messages/mark-as-read/${donor.donorId}`);
    } else {
      // start new conversation with donor has never been talked to
      newConversation = {
        donor,
        messages: [],
        hasUnreadMessage: false,
      };
    }

    const otherConversations = get().conversations.filter((c) => c.donor.donorId !== donor.donorId);

    set(
      {
        conversations: [newConversation, ...otherConversations],
      },
      false,
      'loadConversationMessages'
    );
  },
  addNewMessage: async (newMessage) => {
    let newConversation;

    const index = get().conversations.findIndex((c) => c.donor.donorId === newMessage.donorId);

    let updateState = false;
    if (!(get().conversations[index].messages || []).find((m) => m._id === newMessage.message._id)) {
      updateState = true;
    }

    if (index !== -1) {
      // existing conversation
      if (updateState) {
        const hasUnreadMessage =
          get().currentConversationDonorId !== newMessage.donorId ||
          !window.location.pathname.includes('/customer-service');
        newConversation = [
          ...get().conversations.slice(0, index),
          {
            ...get().conversations[index],
            hasUnreadMessage,
            latestMessageTime: newMessage.message.timestamp,
            // if messages is not null, then add new message, if null, then just mark unread
            messages: get().conversations[index].messages
              ? [...(get().conversations[index].messages || []), newMessage.message]
              : undefined,
          },
          ...get().conversations.slice(index + 1),
        ];
      }
    } else {
      // new conversation, get donor info first
      const donor = await httpClient.get<Donor>(`${BackendAPI.DONOR}/${newMessage.donorId}`);
      newConversation = [
        {
          donor: donor.data,
          hasUnreadMessage: true,
          latestMessageTime: newMessage.message.timestamp,
          messages: [newMessage.message],
        },
        ...get().conversations,
      ];
    }

    if (newConversation) {
      set({ conversations: newConversation }, false, 'addNewMessage');
    }
  },
});

import { DatePicker } from 'antd';
import React, { useEffect, useState } from 'react';
import type { GetProps } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { ExceptionDayDateFormat } from 'bridge/date-format';

type RangePickerProps = GetProps<typeof DatePicker.RangePicker>;

type Prop = {
  onChangeFromDate?: (data: string) => void;
  onChangeToDate?: (data: string) => void;
  existFromDate: string;
  existToDate: string;
}

export const FromToDate = ({ onChangeFromDate, onChangeToDate, existFromDate, existToDate }: Prop) => {
  const [fromDate, setFromDate] = useState<Dayjs | string>('');
  const [toDate, setToDate] = useState<Dayjs | string>('');

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current < fromDate;
  };

  useEffect(() => {
    if (existFromDate && existToDate) {
      setFromDate(existFromDate)
      setToDate(existToDate)
    }
  }, [existFromDate, existToDate])

  return (
    <div className='flex flex-row items-center gap-4'>
      <div className="flex flex-row items-center gap-3">
        <label className='text-nowrap'>From</label>
        <DatePicker
          size="large"
          value={fromDate ? dayjs(fromDate, ExceptionDayDateFormat) : fromDate}
          onChange={(date, dateString) => {
            setToDate('');
            setFromDate(date);
            if (onChangeFromDate) {
              onChangeFromDate(String(dateString))
            }
          }}
          format={ExceptionDayDateFormat}
        />
      </div>
      <div className="flex flex-row items-center gap-3">
        <label className='text-nowrap'>To</label>
        <DatePicker
          size="large"
          disabledDate={disabledDate}
          value={toDate ? dayjs(toDate, ExceptionDayDateFormat) : toDate}
          onChange={(date, dateString) => {
            setToDate(date);
            if (onChangeToDate) {
              onChangeToDate(String(dateString))
            }
          }}
          format={ExceptionDayDateFormat}
        />
      </div>
    </div>
  );
};

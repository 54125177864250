import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import httpClient from '../../utils/http-client.util';
import { BackendAPI } from '../../constants/backend-api.enum';
import { FacilityLocation } from 'bridge/location';
import {
  Button,
  DatePicker,
  Input,
  InputNumber,
  Modal,
  Result,
  Row,
  Select,
  Spin,
  Switch,
  Table,
  Tabs,
  TimePicker,
  Typography,
} from 'antd';
import { popMessage } from '../../utils/pop-message.util';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import './appointment-queue-setting.css';

dayjs.extend(utc);
dayjs.extend(timezone);

const { Option } = Select;

const options = [
  {
    lable: 'sun',
    value: 'sun',
  },
  {
    lable: 'mon',
    value: 'mon',
  },
  {
    lable: 'tue',
    value: 'tue',
  },
  {
    lable: 'wed',
    value: 'wed',
  },
  {
    lable: 'thu',
    value: 'thu',
  },
  {
    lable: 'fri',
    value: 'fri',
  },
  {
    lable: 'sat',
    value: 'sat',
  },
];

const { TabPane } = Tabs;

type ErrorMessage = {
  title: string;
  message: string;
};

export const LocationSettingPage = () => {
  const navigate = useNavigate();
  const { locationId } = useParams();

  const [typeName, setTypeName] = useState<string>('');

  const [historySettings, setHistorySettings] = useState<any>(null);

  const [showNameModal, setShowNameModal] = useState<any>(null);

  const [tabkey, setTabkey] = useState<string>('Appointment');
  const [extension, setExtension] = useState<any>(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<ErrorMessage | undefined>(undefined);

  const [showHistoryArr, setShowHistoryArr] = useState<any>([]);

  useEffect(() => {
    (async () => {
      const locationResponse = await httpClient.get<FacilityLocation>(`${BackendAPI.LOCATION}/${locationId}`);
      let locExt = locationResponse.data;

      let historySettings: any = {
        appointmentPolicy: { predonor_visit: [], donation_visit: [], medical_visit: [] },
        queuePolicy: { new_donor: [], return_donor: [], others: [] },
      };

      let apptTypeKeys = Object.keys(locExt.appointmentPolicy.policyDetail);
      for (let apptTypeKey of apptTypeKeys) {
        let validApptSettings = [];
        let hisApptSettings = [];
        for (let j = 0; j < locExt.appointmentPolicy.policyDetail[apptTypeKey].settings.length; j++) {
          locExt.appointmentPolicy.policyDetail[apptTypeKey].policyDetailIndex = apptTypeKey;
          locExt.appointmentPolicy.policyDetail[apptTypeKey].settings[j].policyDetailIndex = apptTypeKey;
          let setting = locExt.appointmentPolicy.policyDetail[apptTypeKey].settings[j];
          if (new Date() <= dayjs.tz(setting.endDate, locExt.tz).endOf('day').toDate()) {
            setting.isHistory = false;
            validApptSettings.push(setting);
          } else {
            setting.isHistory = true;
            hisApptSettings.push(setting);
          }
          if (j === locExt.appointmentPolicy.policyDetail[apptTypeKey].settings.length - 1) {
            locExt.appointmentPolicy.policyDetail[apptTypeKey].settings = validApptSettings;
            historySettings.appointmentPolicy[apptTypeKey] = hisApptSettings;
          }
        }
      }

      let queueTypeKeys = Object.keys(locExt.queuePolicy.policyDetail);
      for (let queueTypeKey of queueTypeKeys) {
        let validQueueSettings = [];
        let hisQueueSettings = [];
        for (let j = 0; j < locExt.queuePolicy.policyDetail[queueTypeKey].settings.length; j++) {
          locExt.queuePolicy.policyDetail[queueTypeKey].policyDetailIndex = queueTypeKey;
          locExt.queuePolicy.policyDetail[queueTypeKey].settings[j].policyDetailIndex = queueTypeKey;
          let setting = locExt.queuePolicy.policyDetail[queueTypeKey].settings[j];
          if (new Date() <= dayjs.tz(setting.endDate, locExt.tz).endOf('day').toDate()) {
            setting.isHistory = false;
            validQueueSettings.push(setting);
          } else {
            setting.isHistory = true;
            hisQueueSettings.push(setting);
          }
          if (j === locExt.queuePolicy.policyDetail[queueTypeKey].settings.length - 1) {
            locExt.queuePolicy.policyDetail[queueTypeKey].settings = validQueueSettings;
            historySettings.queuePolicy[queueTypeKey] = hisQueueSettings;
          }
        }
      }

      setExtension(locExt);
      setHistorySettings(historySettings);
      setLoading(false);
    })();
  }, []);

  const handleModelOk = () => {
    updatePolicyTypeName(showNameModal.policyDetailIndex, showNameModal.policyValue);
    setShowNameModal(null);
  };

  const switchTab = async (key: string) => {
    setTabkey(key);
    setShowHistoryArr([]);
  };

  const updatePolicyTypeName = async (typeValue: string, policyValue: string) => {
    setLoading(true);
    let mdata: any = { ...extension };
    let result = await httpClient.post(`${BackendAPI.LOCATION}/setting/save-policy-type-name`, {
      locationId: mdata.locationId,
      policyValue,
      typeValue,
      name: { en: typeName },
    });
    if (result.status === 200) {
      mdata[policyValue].policyDetail[typeValue].name.en = typeName;
      setExtension(mdata);
      popMessage.success('Saved success!');
    } else {
      popMessage.error('Saved failed!');
    }
    setLoading(false);
  };

  const updateDBApptRecord = async (newData: any) => {
    setLoading(true);
    let apptTypeKeys = Object.keys(newData.policyDetail);
    for (let apptTypeKey of apptTypeKeys) {
      newData.policyDetail[apptTypeKey].settings = [
        ...newData.policyDetail[apptTypeKey].settings,
        ...historySettings.appointmentPolicy[apptTypeKey],
      ];
    }
    let result = await httpClient
      .post(`${BackendAPI.LOCATION}/setting/save-appt-policy`, { locationId: locationId, appointmentPolicy: newData })
      .then((res) => res.data);
    if (result.status === 200) {
      popMessage.success('Saved success!');
      setTimeout(() => {
        navigate('../');
      }, 1000);
    } else {
      if (result.message) {
        popMessage.error(result.message);
      } else {
        popMessage.error('Saved failed!');
      }
    }
    setLoading(false);
  };

  const updateDBQueueRecord = async (newData: any) => {
    setLoading(true);
    let queueTypeKeys = Object.keys(newData.policyDetail);
    for (let queueTypeKey of queueTypeKeys) {
      newData.policyDetail[queueTypeKey].settings = [
        ...newData.policyDetail[queueTypeKey].settings,
        ...historySettings.queuePolicy[queueTypeKey],
      ];
    }
    let result = await httpClient
      .post(`${BackendAPI.LOCATION}/setting/save-queue-policy`, { locationId: locationId, queuePolicy: newData })
      .then((res) => res.data);
    if (result.status === 200) {
      popMessage.success('Saved success!');
      setTimeout(() => {
        navigate('../');
      }, 1000);
    } else {
      if (result.message) {
        popMessage.error(result.message);
      } else {
        popMessage.error('Saved failed!');
      }
    }
    setLoading(false);
  };

  let columns: any = [
    {
      title: 'Date',
      render: (tdata: any, record: any, tindex: number) => {
        let datearray: any = [
          tdata.startDate ? dayjs.tz(tdata.startDate, 'YYYY-MM-DD', extension.tz) : undefined,
          tdata.endDate ? dayjs.tz(tdata.endDate, 'YYYY-MM-DD', extension.tz) : undefined,
        ];
        return (
          <div style={{ marginBottom: 'auto' }}>
            <DatePicker.RangePicker
              className="setting-date-picker"
              value={datearray}
              format={'MM/DD/YYYY'}
              onChange={(dates, dateStrings) => {
                let mdata: any = { ...extension };
                let startDate = dayjs.tz(dateStrings[0], 'MM/DD/YYYY', extension.tz).format('YYYY-MM-DD');
                let endDate = dayjs.tz(dateStrings[1], 'MM/DD/YYYY', extension.tz).format('YYYY-MM-DD');

                tdata.startDate = startDate;
                tdata.endDate = endDate;

                setExtension(mdata);
              }}
            />
          </div>
        );
      },
    },
    {
      title: 'Weekday',
      render: (tdata: any) => {
        return (
          <Select
            className="setting-select"
            mode="multiple"
            allowClear
            placeholder="Select day(s)"
            options={options}
            value={tdata.weekDays}
            onChange={(value) => {
              let mdata: any = { ...extension };
              tdata.weekDays = value;
              setExtension(mdata);
            }}
            style={{ width: '100%' }}
          ></Select>
        );
      },
    },
  ];
  if (tabkey !== 'Queue') {
    columns.push(
      {
        title: 'Visit Time',
        render: (tdata: any) => {
          return (
            <>
              {tdata.periods.map((item: any, index: number) => {
                let timearray: any = [
                  item.duration[0] ? dayjs.utc(item.duration[0], 'HHmm') : undefined,
                  item.duration[1] ? dayjs.utc(item.duration[1], 'HHmm') : undefined,
                ];
                return (
                  <div className="flex" style={{ alignItems: 'center', marginBottom: 10 }}>
                    <TimePicker.RangePicker
                      className="setting-date-picker"
                      format={'hh:mm a'}
                      use12Hours={true}
                      minuteStep={1}
                      allowEmpty={[false, false]}
                      needConfirm={false}
                      allowClear={false}
                      onChange={(dates, dateStrings) => {
                        let starttime = dayjs.utc(dateStrings[0], 'HH:mm a').format('HHmm');
                        let endtime = dayjs.utc(dateStrings[1], 'HH:mm a').format('HHmm');
                        item.duration = [starttime, endtime];
                        let mdata: any = { ...extension };
                        setExtension(mdata);
                      }}
                      value={timearray}
                    />
                  </div>
                );
              })}
            </>
          );
        },
      },
      {
        title: 'Time Slot',
        render: (tdata: any) => {
          return (
            <>
              {tdata.periods.map((item: any, index: number) => (
                <div className="flex setting-input" style={{ alignItems: 'center', marginBottom: 10 }}>
                  <InputNumber
                    value={item.interval}
                    onChange={(value) => {
                      item.interval = value;
                      let mdata: any = { ...extension };
                      setExtension(mdata);
                    }}
                  ></InputNumber>
                  <Typography.Text type={'secondary'} style={{ width: 35 }}>
                    &nbsp;mins
                  </Typography.Text>
                </div>
              ))}
            </>
          );
        },
      },
      {
        title: 'Max Appt each slot',
        render: (tdata: any, record: any, tindex: number) => {
          return (
            <>
              {tdata.periods.map((item: any, index: number) => (
                <div className="flex setting-input" style={{ alignItems: 'center', marginBottom: 10 }}>
                  <InputNumber
                    value={item.maxPerSlot}
                    onChange={(value) => {
                      item.maxPerSlot = value;
                      let mdata: any = { ...extension };
                      setExtension(mdata);
                    }}
                  ></InputNumber>
                  <Typography.Text type={'secondary'} style={{ width: 51 }}>
                    &nbsp;appt(s)
                  </Typography.Text>
                  <Button
                    shape="circle"
                    style={{ padding: 0, margin: 0, marginLeft: 10 }}
                    onClick={() => {
                      let mdata = { ...extension };
                      let hisdata = { ...historySettings };
                      let settings = tdata.isHistory
                        ? [...hisdata.appointmentPolicy[tdata.policyDetailIndex]]
                        : [...mdata.appointmentPolicy.policyDetail[tdata.policyDetailIndex].settings];

                      if (tdata.periods.length === 1) {
                        settings.splice(tindex, 1);
                      } else {
                        settings[tindex].periods.splice(index, 1);
                      }

                      if (tdata.isHistory) {
                        hisdata.appointmentPolicy[tdata.policyDetailIndex] = settings;
                        setHistorySettings(hisdata);
                      } else {
                        mdata.appointmentPolicy.policyDetail[tdata.policyDetailIndex].settings = settings;
                        setExtension(mdata);
                      }
                    }}
                  >
                    <i className="ri-subtract-line text-primary"></i>
                  </Button>
                  {tdata.periods.length - 1 === index && (
                    <Button
                      shape="circle"
                      style={{ padding: 0, margin: 0, marginLeft: 10 }}
                      onClick={() => {
                        let mdata: any = { ...extension };
                        let hisdata = { ...historySettings };
                        let settings = tdata.isHistory
                          ? [...hisdata.appointmentPolicy[tdata.policyDetailIndex]]
                          : [...mdata.appointmentPolicy.policyDetail[tdata.policyDetailIndex].settings];
                        settings[tindex].periods.push({
                          duration: [undefined, undefined],
                          interval: undefined,
                          maxPerSlot: undefined,
                        });
                        if (tdata.isHistory) {
                          hisdata.appointmentPolicy[tdata.policyDetailIndex] = settings;
                          setHistorySettings(hisdata);
                        } else {
                          mdata.appointmentPolicy.policyDetail[tdata.policyDetailIndex].settings = settings;
                          setExtension(mdata);
                        }
                      }}
                    >
                      <i className="ri-add-line text-primary"></i>
                    </Button>
                  )}
                </div>
              ))}
            </>
          );
        },
      }
    );
  } else {
    columns.push({
      title: 'Visit Time',
      render: (tdata: any, record: any, tindex: number) => {
        return (
          <>
            {tdata.periods.map((item: any, index: number) => {
              let timearray: any = [
                item.duration[0] ? dayjs.utc(item.duration[0], 'HHmm') : undefined,
                item.duration[1] ? dayjs.utc(item.duration[1], 'HHmm') : undefined,
              ];
              return (
                <div className="flex" style={{ alignItems: 'center', marginBottom: 10 }}>
                  <TimePicker.RangePicker
                    className="setting-date-picker"
                    format={'hh:mm a'}
                    use12Hours={true}
                    minuteStep={1}
                    allowEmpty={[false, false]}
                    needConfirm={false}
                    allowClear={false}
                    onChange={(dates, dateStrings) => {
                      let starttime = dayjs.utc(dateStrings[0], 'HH:mm a').format('HHmm');
                      let endtime = dayjs.utc(dateStrings[1], 'HH:mm a').format('HHmm');
                      item.duration = [starttime, endtime];
                      let mdata: any = { ...extension };
                      setExtension(mdata);
                    }}
                    value={timearray}
                  />
                  <Button
                    shape="circle"
                    style={{ padding: 0, margin: 0, marginLeft: 10 }}
                    onClick={() => {
                      let mdata = { ...extension };
                      let hisdata = { ...historySettings };
                      let settings = tdata.isHistory
                        ? [...hisdata.queuePolicy[tdata.policyDetailIndex]]
                        : [...mdata.queuePolicy.policyDetail[tdata.policyDetailIndex].settings];

                      if (tdata.periods.length === 1) {
                        settings.splice(tindex, 1);
                      } else {
                        settings[tindex].periods.splice(index, 1);
                      }

                      if (tdata.isHistory) {
                        hisdata.queuePolicy[tdata.policyDetailIndex] = settings;
                        setHistorySettings(hisdata);
                      } else {
                        mdata.queuePolicy.policyDetail[tdata.policyDetailIndex].settings = settings;
                        setExtension(mdata);
                      }
                    }}
                  >
                    <i className="ri-subtract-line text-primary"></i>
                  </Button>
                  {tdata.periods.length - 1 === index && (
                    <Button
                      shape="circle"
                      style={{ padding: 0, margin: 0, marginLeft: 10 }}
                      onClick={() => {
                        let mdata: any = { ...extension };
                        let hisdata = { ...historySettings };
                        let settings = tdata.isHistory
                          ? [...hisdata.queuePolicy[tdata.policyDetailIndex]]
                          : [...mdata.queuePolicy.policyDetail[tdata.policyDetailIndex].settings];
                        settings[tindex].periods.push({
                          duration: [undefined, undefined],
                          interval: undefined,
                          maxPerSlot: undefined,
                        });
                        if (tdata.isHistory) {
                          hisdata.queuePolicy[tdata.policyDetailIndex] = settings;
                          setHistorySettings(hisdata);
                        } else {
                          mdata.queuePolicy.policyDetail[tdata.policyDetailIndex].settings = settings;
                          setExtension(mdata);
                        }
                      }}
                    >
                      <i className="ri-add-line text-primary"></i>
                    </Button>
                  )}
                </div>
              );
            })}
          </>
        );
      },
    });
  }

  return (
    <>
      <Typography.Title level={3}>Location Setting View</Typography.Title>
      <div className="bg-primary-foreground p-3">
        <div className="flex" style={{ width: '100%' }}>
          <Tabs activeKey={tabkey} onChange={switchTab} type="card" style={{ width: '100%' }}>
            <TabPane tab="Appointment" key="Appointment">
              <div>
                {loading ? <Spin tip="Loading..." fullscreen></Spin> : null}
                {extension ? (
                  <div>
                    <div
                      className="flex"
                      style={{ borderBottomColor: 'rgba(5, 5, 5, 0.15)', borderBottomWidth: 1, paddingBottom: 20 }}
                    >
                      <Typography.Text strong>Open or close "Appointment" for your center:</Typography.Text>
                      <Switch
                        disabled={extension && extension.appointmentPolicy ? false : true}
                        value={
                          extension && extension.appointmentPolicy ? extension.appointmentPolicy.acceptAppt : false
                        }
                        onChange={(value) => {
                          if (extension && extension.appointmentPolicy) {
                            extension.appointmentPolicy.acceptAppt = value;
                            let mdata: any = { ...extension };
                            setExtension(mdata);
                          }
                        }}
                        style={{ marginLeft: 20 }}
                      />
                    </div>
                    <div style={{ marginTop: 20 }}>
                      <Typography.Text strong>Please set available appointment time:</Typography.Text>
                    </div>
                    <div>
                      <Typography.Text type={'secondary'} style={{ fontSize: 13 }}>
                        (if no valid appointment time is set, center is not able to book appointment for current donor
                        type. Available appointment time should be include in center operating time)
                      </Typography.Text>
                    </div>
                    {extension &&
                      extension.appointmentPolicy &&
                      extension.appointmentPolicy.policyDetail &&
                      Object.keys(extension.appointmentPolicy.policyDetail).length > 0 &&
                      Object.keys(extension.appointmentPolicy.policyDetail).map((key: string, index: number) => {
                        let item: any = extension?.appointmentPolicy?.policyDetail[key];
                        return (
                          <div
                            style={{ borderWidth: 1, borderColor: 'rgba(5, 5, 5, 0.15)', padding: 15, marginTop: 20 }}
                          >
                            <div className="flex">
                              <Typography.Title level={5}>{item.name.en}</Typography.Title>
                              <Button
                                onClick={() => {
                                  setShowNameModal({
                                    ...item,
                                    policyValue: 'appointmentPolicy',
                                    policyDetailIndex: key,
                                  });
                                  setTypeName(item.name.en);
                                }}
                                type="link"
                                style={{ height: 'auto', margin: 0, padding: 0, marginLeft: 20 }}
                              >
                                <i className="ri-eye-line text-primary"></i>&nbsp;Edit name
                              </Button>
                            </div>
                            <Typography.Text type={'secondary'} style={{ fontSize: 13 }}>
                              {item.desc.en}
                            </Typography.Text>
                            <Modal
                              title="Edit type name"
                              okText="Save"
                              open={showNameModal}
                              onOk={handleModelOk}
                              onCancel={() => {
                                setShowNameModal(null);
                              }}
                            >
                              <Input
                                className="setting-input"
                                addonBefore={'Name'}
                                value={typeName}
                                onChange={(e) => {
                                  let value: any = e.target.value;
                                  setTypeName(value);
                                }}
                              />
                            </Modal>
                            <Row style={{ overflowX: 'scroll', marginTop: 20 }}>
                              <Table
                                style={{ minWidth: 768, width: '100%' }}
                                key={index}
                                columns={columns}
                                dataSource={item.settings}
                                pagination={false}
                              />
                            </Row>
                            <Row style={{ justifyContent: 'space-between', marginTop: 10 }}>
                              <Button
                                type="primary"
                                onClick={() => {
                                  let settings: any = [...item.settings];
                                  let data: any = {
                                    weekDays: [],
                                    startDate: undefined,
                                    endDate: undefined,
                                    periods: [
                                      {
                                        duration: [undefined, undefined],
                                        interval: undefined,
                                        maxPerSlot: undefined,
                                      },
                                    ],
                                    appointmentType: key,
                                    policyDetailIndex: key,
                                  };
                                  settings.push(data);
                                  item.settings = settings;
                                  setExtension({ ...extension });
                                }}
                              >
                                Add Weekday
                              </Button>
                              <Button
                                disabled={historySettings.appointmentPolicy[key].length === 0}
                                onClick={() => {
                                  let array = [...showHistoryArr];
                                  let aIndex = array.indexOf(key);
                                  if (aIndex > -1) {
                                    array.splice(aIndex, 1);
                                  } else {
                                    array.push(key);
                                  }

                                  setShowHistoryArr(array);
                                }}
                              >
                                Show History Record
                                <i style={{ marginLeft: 5 }} className="ri-eye-line text-primary"></i>
                              </Button>
                            </Row>
                            {showHistoryArr.indexOf(key) > -1 ? (
                              <Row style={{ overflowX: 'scroll', marginTop: 20 }}>
                                <Table
                                  style={{ minWidth: 768, width: '100%' }}
                                  columns={columns}
                                  dataSource={historySettings.appointmentPolicy[key]}
                                  pagination={false}
                                />
                              </Row>
                            ) : null}
                          </div>
                        );
                      })}
                    <Row style={{ justifyContent: 'center', paddingTop: 20, paddingBottom: 20 }}>
                      <Button
                        type="primary"
                        onClick={() => {
                          if (extension && extension.appointmentPolicy) {
                            updateDBApptRecord(extension.appointmentPolicy);
                          }
                        }}
                      >
                        Submit
                      </Button>
                    </Row>
                  </div>
                ) : null}
                {error ? (
                  <Result
                    status="error"
                    title={error.title}
                    subTitle={error.message}
                    extra={[<Button type="primary">Try Again</Button>]}
                  ></Result>
                ) : null}
              </div>
            </TabPane>
            <TabPane tab="Queue" key="Queue">
              <div>
                {loading ? <Spin tip="Loading..." fullscreen></Spin> : null}
                {extension ? (
                  <div>
                    <div
                      className="flex"
                      style={{ borderBottomColor: 'rgba(5, 5, 5, 0.15)', borderBottomWidth: 1, paddingBottom: 20 }}
                    >
                      <Typography.Text strong>Open or close "Queue" for your center in app:</Typography.Text>
                      <Switch
                        disabled={extension && extension.queuePolicy ? false : true}
                        value={extension && extension.queuePolicy ? extension.queuePolicy.acceptAppShow : false}
                        onChange={(value) => {
                          if (extension && extension.queuePolicy) {
                            extension.queuePolicy.acceptAppShow = value;
                            let mdata: any = { ...extension };
                            setExtension(mdata);
                          }
                        }}
                        style={{ marginLeft: 20 }}
                      />
                    </div>
                    <div
                      className="flex"
                      style={{
                        borderBottomColor: 'rgba(5, 5, 5, 0.15)',
                        borderBottomWidth: 1,
                        paddingTop: 20,
                        paddingBottom: 20,
                      }}
                    >
                      <Typography.Text strong>
                        Open or close "Queue Management" for your center in webadmin:
                      </Typography.Text>
                      <Switch
                        disabled={extension && extension.queuePolicy ? false : true}
                        value={extension && extension.queuePolicy ? extension.queuePolicy.acceptWebPageShow : false}
                        onChange={(value) => {
                          if (extension && extension.queuePolicy) {
                            extension.queuePolicy.acceptWebPageShow = value;
                            let mdata: any = { ...extension };
                            setExtension(mdata);
                          }
                        }}
                        style={{ marginLeft: 20 }}
                      />
                    </div>
                    <div
                      style={{
                        borderBottomColor: 'rgba(5, 5, 5, 0.15)',
                        borderBottomWidth: 1,
                        paddingTop: 20,
                        paddingBottom: 20,
                      }}
                    >
                      <Typography.Text strong>Link URL for center public screen:</Typography.Text>
                      <div className="flex setting-input">
                        <Input
                          value={extension.queuePolicy.webPageUrl}
                          onChange={(e) => {
                            let value: string = e.target.value;
                            if (extension && extension.queuePolicy) {
                              extension.queuePolicy.webPageUrl = value;
                              let mdata: any = { ...extension };
                              setExtension(mdata);
                            }
                          }}
                        />
                        <Button
                          onClick={() => navigator.clipboard.writeText(extension.queuePolicy.webPageUrl)}
                          type="primary"
                          style={{ marginLeft: 10 }}
                        >
                          Copy
                        </Button>
                      </div>
                    </div>
                    <div style={{ marginTop: 20 }}>
                      <Typography.Text strong>
                        Please set available time to get queue number for each visit reason:
                      </Typography.Text>
                    </div>
                    <div>
                      <Typography.Text type={'secondary'} style={{ fontSize: 13 }}>
                        (if no valid visit time is set, center or app user cant't get queue number for current visit
                        reason. Available visit time should be included in center operating time)
                      </Typography.Text>
                    </div>
                    {extension &&
                      extension.queuePolicy &&
                      extension.queuePolicy.policyDetail &&
                      Object.keys(extension.queuePolicy.policyDetail).map((key: string, index: number) => {
                        let item: any = extension.queuePolicy.policyDetail[key];
                        return (
                          <div
                            style={{ borderWidth: 1, borderColor: 'rgba(5, 5, 5, 0.15)', padding: 15, marginTop: 20 }}
                          >
                            <div className="flex">
                              <Typography.Title level={5}>{item.name.en}</Typography.Title>
                              <Button
                                onClick={() => {
                                  setShowNameModal({ ...item, policyValue: 'queuePolicy', policyDetailIndex: key });
                                  setTypeName(item.name.en);
                                }}
                                type="link"
                                style={{ height: 'auto', margin: 0, padding: 0, marginLeft: 20 }}
                              >
                                <i className="ri-eye-line text-primary"></i>&nbsp;Edit name
                              </Button>
                            </div>
                            <Typography.Text type={'secondary'} style={{ fontSize: 13 }}>
                              {item.desc.en}
                            </Typography.Text>
                            <Modal
                              okText="Save"
                              title="Edit type name"
                              open={showNameModal}
                              onOk={handleModelOk}
                              onCancel={() => {
                                setShowNameModal(null);
                              }}
                            >
                              <Input
                                addonBefore={'Name'}
                                value={typeName}
                                onChange={(e) => {
                                  let value: any = e.target.value;
                                  setTypeName(value);
                                }}
                              />
                            </Modal>
                            <Row style={{ overflowX: 'scroll', marginTop: 20 }}>
                              <Table
                                style={{ minWidth: 768, width: '100%' }}
                                columns={columns}
                                dataSource={item.settings}
                                pagination={false}
                              />
                            </Row>
                            <Row style={{ justifyContent: 'space-between', marginTop: 10 }}>
                              <Button
                                type="primary"
                                onClick={() => {
                                  let settings: any = [...item.settings];
                                  let data: any = {
                                    weekDays: [],
                                    startDate: undefined,
                                    endDate: undefined,
                                    periods: [
                                      {
                                        duration: [undefined, undefined],
                                        interval: undefined,
                                        maxPerSlot: undefined,
                                      },
                                    ],
                                    queueType: key,
                                    policyDetailIndex: key,
                                  };
                                  settings.push(data);
                                  item.settings = settings;
                                  setExtension({ ...extension });
                                }}
                              >
                                Add Weekday
                              </Button>
                              <Button
                                disabled={historySettings.queuePolicy[key].length === 0}
                                onClick={() => {
                                  let array = [...showHistoryArr];
                                  let aIndex = array.indexOf(key);
                                  if (aIndex > -1) {
                                    array.splice(aIndex, 1);
                                  } else {
                                    array.push(key);
                                  }

                                  setShowHistoryArr(array);
                                }}
                              >
                                Show History Record
                                <i style={{ marginLeft: 5 }} className="ri-eye-line text-primary"></i>
                              </Button>
                            </Row>
                            {showHistoryArr.indexOf(key) > -1 ? (
                              <Row style={{ overflowX: 'scroll', marginTop: 20 }}>
                                <Table
                                  style={{ minWidth: 768, width: '100%' }}
                                  columns={columns}
                                  dataSource={historySettings.queuePolicy[key]}
                                  pagination={false}
                                />
                              </Row>
                            ) : null}
                          </div>
                        );
                      })}
                    <div
                      style={{
                        borderBottomColor: 'rgba(5, 5, 5, 0.15)',
                        borderBottomWidth: 1,
                        paddingTop: 20,
                        paddingBottom: 20,
                      }}
                    >
                      <Typography.Text type={'secondary'} strong>
                        Please set Queue(s) for your center:
                      </Typography.Text>
                      <br />
                      <Typography.Text type={'secondary'} style={{ fontSize: 13 }}>
                        (Only the visit reason which is added into one Queue line can be displayed in frontend and allow
                        center & app users to select, but each visit reason only can be assigned to one Queue line)
                      </Typography.Text>
                    </div>
                    {extension &&
                      extension.queuePolicy &&
                      extension.queuePolicy.listSettings &&
                      extension.queuePolicy.listSettings.length > 0 &&
                      extension.queuePolicy.listSettings.map((item: any, index: number) => {
                        return (
                          <div
                            style={{ borderWidth: 1, borderColor: 'rgba(5, 5, 5, 0.15)', padding: 15, marginTop: 20 }}
                          >
                            <div className="flex" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                              <Typography.Text type={'secondary'} strong>
                                Queue {index + 1}:
                              </Typography.Text>
                              <Button
                                onClick={() => {
                                  extension.queuePolicy.listSettings.splice(index, 1);
                                  let mdata: any = { ...extension };
                                  setExtension(mdata);
                                }}
                              >
                                <i className="ri-delete-bin-6-line"></i>
                              </Button>
                            </div>
                            <div className="flex" style={{ marginTop: 10 }}>
                              <div className="flex" style={{ flex: 1, marginRight: 10 }}>
                                <Typography.Text type={'secondary'} style={{ width: 100, lineHeight: '32px' }}>
                                  Queue name:
                                </Typography.Text>
                                <Input
                                  style={{ flex: 1 }}
                                  value={item.name.en}
                                  onChange={(e) => {
                                    let value: any = e.target.value;
                                    item.name.en = value;
                                    let mdata: any = { ...extension };
                                    setExtension(mdata);
                                  }}
                                />
                              </div>
                              <div className="flex" style={{ flex: 1, marginLeft: 10 }}>
                                <Typography.Text type={'secondary'} style={{ width: 100, lineHeight: '32px' }}>
                                  Queue code:
                                </Typography.Text>
                                <Input
                                  style={{ flex: 1 }}
                                  value={item.code}
                                  onChange={(e) => {
                                    let value: any = e.target.value;
                                    item.code = value;
                                    let mdata: any = { ...extension };
                                    setExtension(mdata);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="flex" style={{ marginTop: 10 }}>
                              <Typography.Text type={'secondary'} style={{ width: 100, lineHeight: '32px' }}>
                                Visit reason(s) included:
                              </Typography.Text>
                              <Select
                                mode="tags"
                                placeholder=""
                                value={item.selectTypes}
                                onChange={(value) => {
                                  item.selectTypes = value;
                                  let mdata: any = { ...extension };
                                  setExtension(mdata);
                                }}
                                style={{ flex: 1 }}
                              >
                                {Object.keys(extension.queuePolicy.policyDetail).map((key, index) => (
                                  <Option key={index.toString(36) + index} value={key}>
                                    {extension.queuePolicy.policyDetail[key].name.en}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                          </div>
                        );
                      })}
                    <div style={{ paddingTop: 20, paddingBottom: 20 }}>
                      <Button
                        onClick={() => {
                          let settings: any = [...extension.queuePolicy.listSettings];
                          settings.push({
                            name: { en: '' },
                            code: '',
                            desc: { en: '' },
                            selectTypes: [],
                          });
                          extension.queuePolicy.listSettings = settings;
                          let mdata: any = { ...extension };
                          setExtension(mdata);
                        }}
                      >
                        Add Queue
                      </Button>
                      <br />
                      <Typography.Text type={'secondary'} style={{ fontSize: 13 }}>
                        (Max 4 Queue Lines can be added.)
                      </Typography.Text>
                    </div>
                    <Row style={{ justifyContent: 'center', paddingTop: 20, paddingBottom: 20 }}>
                      <Button
                        type="primary"
                        onClick={() => {
                          if (extension && extension.queuePolicy) {
                            updateDBQueueRecord(extension.queuePolicy);
                          }
                        }}
                      >
                        Submit
                      </Button>
                    </Row>
                  </div>
                ) : null}
                {error ? (
                  <Result
                    status="error"
                    title={error.title}
                    subTitle={error.message}
                    extra={[<Button type="primary">Try Again</Button>]}
                  ></Result>
                ) : null}
              </div>
            </TabPane>
          </Tabs>
        </div>
      </div>
    </>
  );
};

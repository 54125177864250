import { DataTable, DataTableColumn, DataTableSetting } from '../../components/data-table/data-table.component';
import { useNavigate } from 'react-router-dom';
import { Button, Popconfirm, Space, Typography } from 'antd';
import type * as React from 'react';
import { useEffect, useState } from 'react';
import httpClient from '../../utils/http-client.util';
import { BackendAPI } from '../../constants/backend-api.enum';
import { ExceptionDay, FacilityLocation } from 'bridge/location';
import { Phone } from 'bridge/general';
import { popMessage } from '../../utils/pop-message.util';
import { LocationServiceConfig } from './components/location-service-config.component';
import { UnexpectedClosureReasonSetting } from './components/unexpected-closure-reason-setting.component';
import { ExceptionDaySetting } from './components/exception-day-setting.component';
import { HolidayConfig } from './components/holiday-config.component';

export const LocationListPage = () => {
  const [data, setData] = useState<FacilityLocation[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const navigate = useNavigate();

  const exceptionDayChange = async (facilityInfo: FacilityLocation, date: string, newExceptionDay?: ExceptionDay) => {
    const theDay = (facilityInfo.exceptionDays || {})[date];
    if (theDay) {
      if (!newExceptionDay) {
        delete facilityInfo.exceptionDays[date];
      } else {
        facilityInfo.exceptionDays[date] = newExceptionDay;
      }
    } else {
      if (newExceptionDay) {
        facilityInfo.exceptionDays = {
          ...facilityInfo.exceptionDays,
          [date]: newExceptionDay,
        };
      }
    }
    setData([...data]);
  };

  const toggleLocationStatus = async (facility: FacilityLocation) => {
    const payload = {
      status: facility.status === 'active' ? 'inactive' : 'active' as 'inactive' | 'active',
    };
    try {
      await httpClient.post(`${BackendAPI.LOCATION}/${facility.locationId}`, payload);
      facility.status = payload.status;
      setData([...data]);
      popMessage.success(`Successfully ${payload.status === 'active' ? 'Unlocked' : 'Locked'} Location: ${facility.name}`);
    } catch (e) {
      popMessage.error(`Unable to ${payload.status === 'active' ? 'Unlock' : 'Lock'} Location: ${facility.name}`);
    }
  };

  const deleteLocation = async (facility: FacilityLocation) => {
    try {
      await httpClient.delete(`${BackendAPI.LOCATION}/${facility.locationId}`);
      setData([...data.filter(d => d.locationId !== facility.locationId)]);
      popMessage.success(`Successfully deleted Location: ${facility.name}`);
    } catch (e) {
      popMessage.error(`Unable to delete Location: ${facility.name}`);
    }
  };

  const setting: DataTableSetting<FacilityLocation> = {
    numbered: true,
    pagination: {
      pageSize: 25,
    },
    customizedOperations: [
      {
        text: <i className="ri-eye-line text-primary" title={'Location Detail'}></i>,
        whenPerform: async (location) =>
          navigate(`../${location.locationId}`, { state: { facilityLocation: location } }),
      },
      {
        element: (location) => <ExceptionDaySetting location={location}
                                                    onChange={(date, newExceptionDay) => exceptionDayChange(location, date, newExceptionDay)}>
          <i className="ri-calendar-2-line text-blue-500" title={'Set Holiday and Exception Days'}></i>
        </ExceptionDaySetting>,
      },
      {
        element: (location) => {
          const icons = location.status === 'inactive' ?
            <>
              <i className="ri-lock-line text-red-600 group-hover:text-red-400"></i>
              <i className="ri-arrow-right-s-line text-blue-500 group-hover:text-blue-400"></i>
              <i className="ri-lock-unlock-line text-blue-500 group-hover:text-blue-400"></i>
            </> :
            <>
              <i className="ri-lock-unlock-line text-blue-500 group-hover:text-blue-400"></i>
              <i className="ri-arrow-right-s-line text-blue-500 group-hover:text-blue-400"></i>
              <i className="ri-lock-line text-red-600 group-hover:text-red-400"></i>
            </>;

          return (
            <Popconfirm
              title={<>Are you sure to {location.status === 'inactive' ? <span className="text-red-500">UNLOCK</span> :
                <span className="text-red-500">LOCK</span>} the location {location.name}</>}
              onConfirm={() => toggleLocationStatus(location)}
              okText={location.status === 'inactive' ? 'Unlock it' : 'Lock it'}
            >
              <Typography.Link className="group">
                {
                  icons
                }
              </Typography.Link>
            </Popconfirm>
          );
        },
      },
      {
        text: <i className="ri-delete-bin-line text-red-500" title={'Delete Location'}></i>,
        confirmWithMessage: (location) => <>Are you sure to <span className="text-red-500">DELETE</span> the
          location {location.name}</>,
        whenPerform: deleteLocation,
      },
      {
        text: <i className="ri-settings-3-line text-primary" title={'Extension Setting'}></i>,
        whenPerform: async(location) => {
          navigate(`../setting/${location.locationId}`)
        }
      }
    ],
  };

  const columns: DataTableColumn<FacilityLocation>[] = [
    {
      title: 'State',
      dataIndex: ['address', 'state'],
      filterable: true,
    },
    {
      title: 'City',
      dataIndex: ['address', 'city'],
      filterable: true,
    },
    {
      title: 'Zipcode',
      dataIndex: ['address', 'zipcode'],
      filterable: true,
    },
    {
      title: 'Location Name',
      dataIndex: 'name',
      filterable: true,
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone',
      render: (phone: Phone) => {
        if (phone) {
          let country = ``;
          if (phone.countryCode) {
            country = `+${phone.countryCode} `;
          }
          return `${country}${phone.number}`;
        } else {
          return '';
        }
      },
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        const allLocationsRequest = await httpClient.get<FacilityLocation[]>(BackendAPI.LOCATION);
        setData(allLocationsRequest.data);
      } catch (e) {
        popMessage.error('Unable to fetch locations data');
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <>
      <div className="flex justify-between items-center">
        <Typography.Title level={3}>Location Management</Typography.Title>
        <Space>
          <UnexpectedClosureReasonSetting>
            <Button>Unexpected Closure Reason Setting</Button>
          </UnexpectedClosureReasonSetting>
          <LocationServiceConfig>
            <Button>Location Service Setting</Button>
          </LocationServiceConfig>
          <HolidayConfig>
            <Button>Holiday Configuration</Button>
          </HolidayConfig>
          <Button type={'primary'} onClick={() => navigate('../new')}>Create Location</Button>
        </Space>
      </div>
      <DataTable data={data} setting={setting} loading={loading} columnsDef={columns} />
    </>

  );
};
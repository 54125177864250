import { FacilityLocation } from 'bridge/location';
import React, { ReactNode, useEffect, useState } from 'react';
import { Switch, TimePicker, Tooltip, Typography } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { OperationHourFormat } from 'bridge/date-format';

type Prop = {
  currentHours: FacilityLocation['operationHours'] | undefined,
  onChange: (day: keyof FacilityLocation['operationHours'], newHours: [string, string][] | false) => void
}

type DateNameEntry = {
  date: 'Sunday' | 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday',
  name: keyof FacilityLocation['operationHours']
}

export const OperationHoursSetting = ({ currentHours, onChange }: Prop) => {
  const initialHours: FacilityLocation['operationHours'] = {
    sun: false,
    mon: false,
    tue: false,
    wed: false,
    thu: false,
    fri: false,
    sat: false,
  };
  const [newHours, setNewHours] = useState(currentHours || initialHours);
  const [invalidRangePickers, setInvalidRangePickers] = useState<string[]>([]);

  useEffect(() => {
    if (currentHours) {
      setNewHours({ ...currentHours });
    }
  }, [currentHours]);

  const toggleCenter = (date: keyof FacilityLocation['operationHours']) => {
    if (!!newHours[date]) {
      setNewHours({ ...newHours, [date]: false });
      onChange(date, false);
    } else {
      setNewHours({ ...newHours, [date]: [['', '']] });
    }
  };

  const dateNameEntries: DateNameEntry[] = [
    {
      date: 'Sunday',
      name: 'sun',
    },
    {
      date: 'Monday',
      name: 'mon',
    },
    {
      date: 'Tuesday',
      name: 'tue',
    },
    {
      date: 'Wednesday',
      name: 'wed',
    },
    {
      date: 'Thursday',
      name: 'thu',
    },
    {
      date: 'Friday',
      name: 'fri',
    },
    {
      date: 'Saturday',
      name: 'sat',
    },
  ];

  const hoursDisplay = ([from, to]: [string, string], i: number, name: 'sun' | 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat') => {
    let wrapper;
    const key = `${name}-timepicker-${i}`;
    if (invalidRangePickers.includes(key)) {
      wrapper = (children: ReactNode) => <Tooltip placement={'top'} open={true} color={`#EF4444`}
                                                  title={'Overlaps with existing hour'}>{children}</Tooltip>;
    } else {
      wrapper = (children: ReactNode) => children;
    }

    return (
      <span className={'inline-block w-[210px] m-1 relative'} key={`${name}-timepicker-${i}`}>
      {
        wrapper(<>
          <TimePicker.RangePicker format={'hh:mm a'} use12Hours minuteStep={15} allowClear={false}
                                  allowEmpty={[false, false]} needConfirm={false}
                                  status={invalidRangePickers.includes(`${name}-timepicker-${i}`) ? 'error' : ''}
                                  onChange={([newFrom, newTo]) => setHour([newFrom, newTo], i, name)}
                                  defaultValue={[from ? dayjs(from, OperationHourFormat) : undefined, to ? dayjs(to, OperationHourFormat) : undefined]} />
          <i
            className={`ri-close-circle-fill text-red-600 hover:text-red-400 cursor-pointer absolute right-[-6px] top-[-10px]`}
            onClick={() => removeHour(i, name)} />
        </>)
      }

    </span>
    );
  };

  const addHour = (name: 'sun' | 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat') => {
    if (newHours[name]) {
      (newHours[name] as Array<[string, string]>).push(['', '']);
      setNewHours({ ...newHours });
    }
  };

  const setHour = ([from, to]: [Dayjs | null, Dayjs | null], i: number, name: 'sun' | 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat') => {
    if (newHours[name] && from && to) {
      const thisPickerKey = `${name}-timepicker-${i}`;
      let isOverlapping = false;
      const today = dayjs().format('YYYY-MM-DD');
      for (const existingHour of (newHours[name] as [string, string][]).filter((_, index) => index !== i)) {
        const [start, end] = existingHour.map(time => dayjs(`${today}T${time}:00`, 'YYYY-MM-DDTHHmm:ss'));

        if ((start.isBefore(from) && from.isBefore(end)) || (from.isBefore(start) && to.isAfter(start))) {
          isOverlapping = true;
          setInvalidRangePickers([...invalidRangePickers, thisPickerKey]);
        }
      }
      (newHours[name] as [string, string][]).splice(i, 1, [from.format(OperationHourFormat), to.format(OperationHourFormat)]);
      setNewHours({ ...newHours });
      if (!isOverlapping) {
        setInvalidRangePickers([...invalidRangePickers.filter(p => p !== thisPickerKey)]);
        onChange(name, (newHours[name] as [string, string][]));
      }
    }
  };

  const removeHour = (i: number, name: 'sun' | 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat') => {
    if (newHours[name]) {
      let removingEmptyRanger = false;
      const removingHour = (newHours[name] as [string, string][])[i];
      if (!removingHour[0] || !removingHour[1]) {
        removingEmptyRanger = true;
      }
      const thisPickerKey = `${name}-timepicker-${i}`;

      (newHours[name] as Array<[string, string]>).splice(i, 1);
      if (!(newHours[name] as Array<[string, string]>).length) {
        newHours[name] = false;
      }
      setNewHours({ ...newHours });
      if (!invalidRangePickers.includes(thisPickerKey) && !removingEmptyRanger) {
        onChange(name, (newHours[name] as [string, string][] | false));
      } else {
        setInvalidRangePickers([...invalidRangePickers.filter(p => p !== thisPickerKey)]);
      }
    }
  };

  const enableAddHourButton = (name: 'sun' | 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat') => {
    if (newHours[name]) {
      if (!!(newHours[name] as [string, string][]).find(h => !h[0] || !h[1]) || !!invalidRangePickers.find(p => p.includes(name))) {
        return 'text-gray-300 cursor-disabled pointer-events-none';
      } else {
        return 'text-blue-500 hover:text-blue-300 cursor-pointer';
      }
    }
    return 'text-gray-300 cursor-disabled pointer-events-none';
  };

  return (
    <table className="w-full table-auto border-collapse border-spacing-0">
      <tbody>
      {
        dateNameEntries.map(dne => (
          <tr className={'hover:bg-stone-200'} key={dne.name + '-tr'}>
            <td className={'py-3 px-4 w-[140px] rounded-bl'}>{dne.date}</td>
            <td className="w-[80px]"><Switch checked={!!newHours[dne.name]}
                                             onChange={() => toggleCenter(dne.name)} /></td>
            <td className="rounded-br">
              {
                newHours[dne.name] && (newHours[dne.name] as Array<[string, string]>).map((v, i) => hoursDisplay(v, i, dne.name))
              }
              {
                newHours[dne.name] &&
                <i className={`ri-add-circle-line ml-2 ${enableAddHourButton(dne.name)}`}
                   onClick={() => addHour(dne.name)} />
              }
              {
                !newHours[dne.name] && <Typography.Text type={'secondary'}>Closed</Typography.Text>
              }
            </td>
          </tr>
        ))
      }
      </tbody>
    </table>
  );
};
import { Button, Card, Form, Input, Tooltip, Typography } from 'antd';
import { underLinedInputClass } from '../../../../components/inputs/editable-attribute.component';
import React from 'react';
import { Notification } from 'bridge/notification';
import httpClient from '../../../../utils/http-client.util';
import { BackendAPI } from '../../../../constants/backend-api.enum';
import { popMessage } from '../../../../utils/pop-message.util';
import NotificationPreview from '../component/notification-preview.component';
import { TargetDonorFilter } from '../../shared-component/target-donor-filter.component';

type Props = {
  afterNewNotification?: () => void;
};
export const PushNotification = ({ afterNewNotification }: Props) => {
  const [sendingNotification, setSendingNotification] = React.useState<Partial<Notification>>({});
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [targetDonorFilter, setTargetDonorFilter] = React.useState<Partial<Notification['targetFilter']>>({});

  const onFinish = async () => {
    setSubmitting(true);
    try {
      const payload: Partial<Notification> = {
        ...sendingNotification,
        targetFilter: targetDonorFilter as Notification['targetFilter'],
      };
      await httpClient.post(BackendAPI.NOTIFICATION, payload);
      popMessage.success('Notification created successfully');
      if (afterNewNotification) {
        afterNewNotification();
      }
    } catch (e) {
      popMessage.error('Failed to create Notification');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className={'flex justify-between space-x-6 h-fill'}>
      <div className={'w-[72%]'}>
        <Form<Notification> onFinish={onFinish} disabled={submitting}>
          <div>
            <Typography.Text type="secondary">Notification Title</Typography.Text>
          </div>
          <Form.Item name={'title'} rules={[{ required: true, message: 'Please provide title!' }]}>
            <Input
              variant="borderless"
              className={`${underLinedInputClass} w-[100%]`}
              style={{ borderBottomStyle: 'solid' }}
              onChange={(e) => setSendingNotification({ ...sendingNotification, title: e.target.value })}
            />
          </Form.Item>

          <div>
            <Typography.Text type="secondary">Message</Typography.Text>
          </div>
          <Form.Item name={'message'} rules={[{ required: true, message: 'Please provide message!' }]}>
            <Input
              variant="borderless"
              className={`${underLinedInputClass} w-[100%]`}
              style={{ borderBottomStyle: 'solid' }}
              onChange={(e) => setSendingNotification({ ...sendingNotification, message: e.target.value })}
            />
          </Form.Item>

          <div className={'flex justify-between items-end'}>
            <Card type={'inner'} hoverable className={'w-fit'}>
              <TargetDonorFilter
                existingFilter={targetDonorFilter as Notification['targetFilter']}
                onConfirm={setTargetDonorFilter}
                title={'App User Filter'}
              />
            </Card>

            <div className={'flex justify-end'}>
              <Tooltip
                open={!targetDonorFilter.ageRange ? undefined : false}
                placement={'left'}
                title={'Filter is not set properly'}
              >
                <Button type="primary" htmlType={'submit'} disabled={!targetDonorFilter.ageRange}>
                  Push Notification
                </Button>
              </Tooltip>
            </div>
          </div>
        </Form>
      </div>
      <div className={'w-[28%]'}>
        <NotificationPreview notification={sendingNotification} />
      </div>
    </div>
  );
};

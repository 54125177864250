import React from 'react';
import appBg from './app-bg.png';
import { Announcement } from 'bridge/announcement';
import { Button, Card, Divider, Radio } from 'antd';

type Props = {
  announcement: Partial<Announcement>;
};
const AnnouncementPreview = ({ announcement }: Props) => {
  return (
    <div
      className="w-[310px] h-full max-h-[610px] rounded-2xl py-4 relative"
      style={{ backgroundImage: `url(${appBg})`, backgroundSize: 'cover' }}
    >
      <div className="flex justify-between items-center h-3 text-white mb-1">
        <div className={'mt-0.5 opacity-0'}>10:45</div>

        <div className={'flex justify-between items-center rounded-3xl p-2 h-6 w-28 ml-10 mr-2 bg-black'}>
          <div className="h-3 w-3 bg-gray-900 rounded-full" />
          <div className="h-3 w-3 bg-gray-900 rounded-full" />
        </div>

        <div className={'flex space-x-1 items-end opacity-0'}>
          <div className="h-1 w-0.5 bg-white"></div>
          <div className="h-2 w-0.5 bg-white"></div>
          <div className="h-3 w-0.5 bg-gray-300"></div>
          <div className="h-4 w-0.5 bg-gray-300"></div>
          <div className="h-5 w-0.5 bg-gray-300"></div>
        </div>
        <div>
          <i className="ri-wifi-line text-white text-xl opacity-0"></i>
        </div>
        <div>
          <i className="ri-battery-low-line text-white text-xl opacity-0"></i>
        </div>
      </div>
      <div
        id="announcement"
        className="h-full flex items-center justify-center rounded-xl mt-4 relative"
        style={{ backgroundColor: 'rgb(255 255 255 / 30%)', backdropFilter: 'blur(5px)' }}
      >
        <Card
          title={announcement.title || 'announcement'}
          size={'small'}
          bordered={false}
          className={'w-3/4 max-h-1/2'}
        >
          {announcement?.details &&
            announcement.details.map((detail, index) => (
              <div key={index} className={'flex items-center'}>
                {detail.type === 'bullet' && <div className={'w-1 h-1 bg-black rounded-full'} />}
                <div className={'text-sm ml-1'}>{detail.content}</div>
              </div>
            ))}
          <Divider className={'!my-4'} />
          <div className={'flex justify-end'}>
            <Radio value="noshow">don't show again</Radio>
            <Button size={'small'}>Close</Button>
          </div>
        </Card>
      </div>
      <div className={'absolute left-1/2 transform -translate-x-1/2 bottom-0.5 w-[125px] h-1 rounded-full bg-white'} />
    </div>
  );
};

export default AnnouncementPreview;

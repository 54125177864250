import { FacilityLocation } from 'bridge/location';
import { Button, Image, Popconfirm, Typography, Upload, UploadFile } from 'antd';
import { BackendAPI } from '../../../constants/backend-api.enum';
import { popMessage } from '../../../utils/pop-message.util';
import { useEffect, useState } from 'react';
import httpClient from '../../../utils/http-client.util';

type Prop = {
  location?: FacilityLocation,
  onCoverImageChange?: (newImageUrl: string) => void
  onThumbnailImagesChange?: (newThumbnails: string[]) => void
}

const CoverImageAndThumbnailsUpload = ({ location, onCoverImageChange, onThumbnailImagesChange }: Prop) => {
  const [coverImage, setCoverImage] = useState<string | undefined>(location?.coverImage);
  const [coverImageUploading, setCoverImageUploading] = useState<boolean>(false);
  const [thumbnailFileList, setThumbnailFileList] = useState<UploadFile[]>([]);

  const toggleCoverImage = async (imageUrl?: string) => {
    setCoverImageUploading(true);
    setCoverImage(imageUrl);
    popMessage.success(`Successfully ${!imageUrl ? 'removed' : 'uploaded'} cover image`);
    if (onCoverImageChange) {
      onCoverImageChange(imageUrl || '');
    }
    setCoverImageUploading(false);
  };

  const thumbnailImageChanged = (newThumbnails: string[]) => {
    if (onThumbnailImagesChange) {
      onThumbnailImagesChange(newThumbnails);
    }
  };

  const removeThumbnailImage = async (file: UploadFile) => {
    if (location && file.status === 'done' && file.url) {
      await httpClient.post(`${BackendAPI.LOCATION}/${location.locationId}/delete-thumbnail`, { url: file.url });
    }
  };

  useEffect(() => {
    if (location?.thumbnails) {
      setThumbnailFileList(location.thumbnails.map((url, i) => ({
        uid: `${i}`,
        name: `thumbnail-${i}`,
        url,
        status: 'done',
      })));
    }
  }, [location?.thumbnails]);

  return (
    <>
      <fieldset className="border border-solid border-gray-300 rounded p-3 mb-3">
        <legend><Typography.Text type={'secondary'} strong>Cover Image</Typography.Text></legend>
        <div className="flex items-start justify-between gap-4">
          {
            coverImage &&
            <Image
              className="max-h-[480px]"
              src={coverImage}
            />
          }
          <div className="flex flex-col">
            <Upload
              accept="image/*"
              className="!w-auto hide-upload-list-item"
              maxCount={1}
              action={`${BackendAPI.LOCATION}/upload-cover-image/${location?.locationId || ''}`}
              listType="picture-card"
              disabled={coverImageUploading}
              onChange={async ({ file }) => {
                if (file.status === 'uploading') {
                  setCoverImageUploading(true);
                } else if (file.status === 'done') {
                  await toggleCoverImage(file.response);
                } else if (file.status === 'error') {
                  popMessage.error('Unable to upload cover image');
                  setCoverImageUploading(false);
                }
              }}
            >
              {
                !coverImageUploading ?
                  <button style={{ border: 0, background: 'none' }} type="button">
                    <i className={location?.coverImage ? 'ri-exchange-2-line' : 'ri-upload-line'} />
                    <div style={{ marginTop: 8 }}>{location?.coverImage ? 'Replace' : 'Upload'}</div>
                  </button>
                  :
                  <i className="ri-loader-3-line animate-spin text-lg" />
              }

            </Upload>
            {
              location?.coverImage &&
              <Popconfirm title={'Are you sure to remove the cover image'} onConfirm={() => toggleCoverImage()}>
                <Button danger className="mr-[8px]" type="dashed" disabled={coverImageUploading}>
                  <i className="ri-delete-bin-3-line" />
                  <span>Remove</span>
                </Button>
              </Popconfirm>
            }
          </div>
        </div>
      </fieldset>
      <fieldset className="border border-solid border-gray-300 rounded p-3">
        <legend><Typography.Text type={'secondary'} strong>Thumbnails</Typography.Text></legend>
        <Upload
          accept="image/*"
          className="!w-auto"
          action={`${BackendAPI.LOCATION}/upload-thumbnails/${location?.locationId || ''}`}
          listType="picture-card"
          fileList={thumbnailFileList}
          multiple
          onChange={async (uploadingEvent) => {
            const { fileList } = uploadingEvent;
            setThumbnailFileList([...fileList]);
            const fileStillUploading = fileList.find(f => f.status === 'uploading');
            if (!fileStillUploading) {
              thumbnailImageChanged(fileList.filter(f => f.status === 'done').map(f => f.response as string));
            }
          }}
          onRemove={removeThumbnailImage}
        >
          <button style={{ border: 0, background: 'none' }} type="button">
            <i className={'ri-upload-line'} />
            <div style={{ marginTop: 8 }}>{'Upload'}</div>
          </button>
        </Upload>
      </fieldset>
    </>
  );
};

export default CoverImageAndThumbnailsUpload;
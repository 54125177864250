import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Button, Calendar, CalendarProps, Divider, Modal, Radio, Select, TimePicker, Typography } from 'antd';
import { ExceptionDay, FacilityLocation } from 'bridge/location';
import dayjs, { Dayjs } from 'dayjs';
import { UnexpectedClosureReasonSetting } from './unexpected-closure-reason-setting.component';
import { ExceptionDayDateFormat, OperationHourFormat } from 'bridge/date-format';
import { createStyles } from 'antd-style';
import classNames from 'classnames';
import httpClient from '../../../utils/http-client.util';
import { BackendAPI } from '../../../constants/backend-api.enum';
import { popMessage } from '../../../utils/pop-message.util';
import { HolidayConfig } from './holiday-config.component';

enum DayOfWeek {
  sun,
  mon,
  tue,
  wed,
  thu,
  fri,
  sat
}

enum ExceptionDayType {
  none,
  overrideHours,
  unexpectedClosure,
  holiday
}

interface ExceptionDayDetail {
  type: ExceptionDayType,
  extraInfo?: string | [string, string][]
}

const useStyle = createStyles(({ token, css }) => {
  return {
    dateCell: css`
        position: relative;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            max-width: 40px;
            max-height: 40px;
            background: transparent;
            transition: background 300ms;
            border-radius: ${token.borderRadiusOuter}px;
            border: 1px solid transparent;
            box-sizing: border-box;
        }

        &:hover:before {
            background: rgba(0, 0, 0, 0.04);
        }
    `,
    today: css`
        &:before {
            border: 1px solid ${token.colorPrimary};
        }
    `,
    text: css`
        position: relative;
        z-index: 1;
    `,
    current: css`
        color: ${token.colorTextLightSolid};

        &:before {
            background: ${token.colorPrimary};
        }

        &:hover:before {
            background: ${token.colorPrimary};
            opacity: 0.8;
        }
    `,
  };
});

export const ExceptionDaySetting = ({ children, onChange, location }: PropsWithChildren & {
  onChange: (date: string, newExceptionDay?: ExceptionDay) => void,
  location: FacilityLocation
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Dayjs>();
  const [unexpectedCloseReasons, setUnexpectedCloseReasons] = useState<string[]>([]);
  const [holidays, setHolidays] = useState<string[]>([]);
  const [exceptionDayDetail, setExceptionDayDetail] = useState<ExceptionDayDetail>({ type: 0 });

  const { styles } = useStyle();

  const toggleModal = () => {
    setOpen(!open);
  };

  const onOk = async () => {
    if (selectedDate) {
      setLoading(true);
      let payload;
      const date = selectedDate.format(ExceptionDayDateFormat);
      if (exceptionDayDetail.type === ExceptionDayType.none) {
        payload = undefined;
      } else if (exceptionDayDetail.type === ExceptionDayType.overrideHours) {
        payload = { overrideHours: exceptionDayDetail.extraInfo as [string, string][] };
      } else {
        payload = {
          closingDetail: {
            type: ExceptionDayType[exceptionDayDetail.type] as 'unexpectedClosure' | 'holiday',
            reason: exceptionDayDetail.extraInfo?.toString() || '',
          },
        };
      }
      try {
        await httpClient.post(`${BackendAPI.LOCATION}/${location.locationId}/update-exception-day`, {
          date,
          detail: payload,
        });

        onChange(date, payload);

        popMessage.success(`Successfully updated EXCEPTION DAY for ${location.locationId} at ${date}`);
      } catch (e) {
        if (e instanceof Error) {
          popMessage.error(`Unable to update EXCEPTION DAY for ${location.locationId} at ${date}. Error: ${e.message}`);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  const fullCellRender: CalendarProps<Dayjs>['fullCellRender'] = (date, info) => {
    if (info.type === 'date') {
      let dateAttachment;

      if (date.isBefore(dayjs(), 'date')) {
        dateAttachment = <div className={'text-gray-500 bg-gray-300 rounded-lg text-xs my-1 px-2'}>
          Past Date
        </div>;
      } else {
        const thisDay = (location.exceptionDays || {})[date.format(ExceptionDayDateFormat)];
        if (!thisDay) {
          if (location.operationHours) {
            const dayOfWeek = location.operationHours[DayOfWeek[date.get('day')] as keyof FacilityLocation['operationHours']];
            if (!dayOfWeek) {
              dateAttachment = <div className={'text-blue-500 bg-blue-300 rounded-lg text-xs my-1 px-2'}>
                Closed
              </div>;
            } else {
              dateAttachment = dayOfWeek.map(h => (
                <div className={'text-green-950 bg-green-500 rounded-lg text-xs my-1 px-2'}>
                  {dayjs(h[0], OperationHourFormat).format('HH:mm')}-{dayjs(h[1], OperationHourFormat).format('HH:mm')}
                </div>
              ));
            }
          } else {
            dateAttachment = <div className={'text-gray-500 bg-gray-300 rounded-lg text-xs my-1 px-2'}>
              Yet to be configured
            </div>;
          }
        } else {
          const overridingHours = thisDay.overrideHours;
          if (overridingHours) {
            dateAttachment = <div className={'text-yellow-800 bg-yellow-400 rounded-lg text-xs my-1 px-2'}>
              Different Operation Hour
            </div>;
          } else {
            dateAttachment = <div className={'text-red-900 bg-red-400 rounded-lg text-xs my-1 px-2'}>
              {thisDay.closingDetail.type === 'unexpectedClosure' ? 'Unexpected Closure' : 'Holiday'}
            </div>;
          }
        }
      }

      return React.cloneElement(info.originNode, {
        ...info.originNode.props,
        className: classNames(styles.dateCell, {
          [styles.current]: selectedDate ? selectedDate.isSame(date, 'date') : false,
          [styles.today]: date.isSame(dayjs(), 'date'),
        }, '!mb-12 mx-1'),
        onClick: dayjs(date).isBefore(dayjs(), 'date') ? () => {
        } : () => {
          setSelectedDate(date);
        },
        children: (
          <div className={styles.text}>
            <div>{date.get('date')}</div>
            <div className={'absolute left-0 right-0'}>
              {
                dateAttachment
              }
            </div>
          </div>
        ),
      });
    }
    if (info.type === 'month') {
      return info.originNode;
    }
  };

  const onTimePickerValueChange = (newTime: [Dayjs | null, Dayjs | null] | null, i: number) => {
    if (newTime) {
      const [newFrom, newTo] = newTime;
      if (!exceptionDayDetail.extraInfo) {
        setExceptionDayDetail({
          ...exceptionDayDetail,
          extraInfo: [[newFrom ? newFrom.format(OperationHourFormat) : '', newTo ? newTo.format(OperationHourFormat) : '']],
        });
      } else {
        (exceptionDayDetail.extraInfo as [string, string][]).splice(i, 1, [newFrom ? newFrom.format(OperationHourFormat) : '', newTo ? newTo.format(OperationHourFormat) : '']);
        setExceptionDayDetail({ ...exceptionDayDetail });
      }
    } else {
      setExceptionDayDetail({ ...exceptionDayDetail, extraInfo: [['', '']] });
    }
  };

  const removeTimePicker = (i: number) => {
    (exceptionDayDetail.extraInfo as [string, string][]).splice(i, 1);
    setExceptionDayDetail({ ...exceptionDayDetail });
  };

  const addTimePicker = () => {
    (exceptionDayDetail.extraInfo as [string, string][]).push(['', '']);
    setExceptionDayDetail({ ...exceptionDayDetail });
  };

  const disableAddButton: () => boolean = () => {
    if (exceptionDayDetail.type !== 1) {
      return true;
    }
    if (!exceptionDayDetail.extraInfo) {
      return true;
    }

    if (!exceptionDayDetail.extraInfo.length) {
      return true;
    }

    if ((exceptionDayDetail.extraInfo as [string, string][]).find(d => !d[0] || !d[1])) {
      return true;
    }

    return false;
  };

  const disableConfirmButton: () => boolean = () => {
    if (selectedDate) {
      const thisDay = (location.exceptionDays || {})[selectedDate.format(ExceptionDayDateFormat)];
      if (!thisDay && exceptionDayDetail.type !== ExceptionDayType.none) {
        return false;
      }
      if (thisDay) {
        if (!!thisDay.overrideHours) {
          if (exceptionDayDetail.type !== ExceptionDayType.overrideHours) {
            return false;
          }
          if (JSON.stringify(thisDay.overrideHours) !== JSON.stringify(exceptionDayDetail.extraInfo)) {
            return false;
          }
          return true;
        }
        if (!!thisDay.closingDetail) {
          if (ExceptionDayType[exceptionDayDetail.type] !== thisDay.closingDetail.type) {
            return false;
          }
          if (JSON.stringify(thisDay.closingDetail.reason) !== JSON.stringify(exceptionDayDetail.extraInfo)) {
            return false;
          }
        }
      }
    }
    return true;
  };

  useEffect(() => {
    if (selectedDate) {
      const thisDay = (location.exceptionDays || {})[selectedDate.format(ExceptionDayDateFormat)];
      if (thisDay) {
        if (thisDay.overrideHours) {
          setExceptionDayDetail({ type: ExceptionDayType.overrideHours, extraInfo: [...thisDay.overrideHours] });
        } else {
          setExceptionDayDetail({
            type: ExceptionDayType[thisDay.closingDetail.type],
            extraInfo: thisDay.closingDetail.reason,
          });
        }
      } else {
        setExceptionDayDetail({ type: ExceptionDayType.none });
      }
    }
  }, [selectedDate]);

  useEffect(() => {
    setSelectedDate(undefined);
    setExceptionDayDetail({ type: 0 });
  }, [open]);

  return (
    <>
      {
        <span className="cursor-pointer" onClick={toggleModal}>
          {children}
        </span>
      }
      <Modal open={open} onCancel={toggleModal} onOk={onOk}
             width={1050}
             footer={null}
             style={{ top: 20 }}
             destroyOnClose title={`Holidays and Exception days for ${location.name}`}
             maskClosable={false}>
        <div className={'flex'}>
          <Calendar fullscreen={false} fullCellRender={fullCellRender} />
          {
            selectedDate &&
            <div className="ml-2 pl-2 border-0 border-l border-stone-200 border-solid min-w-[275px]">
              <Typography.Title level={5}>
                Exceptions on {selectedDate.format('MMM DD, YYYY')}
              </Typography.Title>
              <Radio.Group className={'w-full'}
                           onChange={(v) => setExceptionDayDetail({ type: v.target.value })}
                           value={exceptionDayDetail.type}>
                <Radio value={0}>None</Radio>
                <Divider />
                <Radio value={1}>Override Hours</Radio>
                <div className={'mt-1'}>
                  {
                    exceptionDayDetail.type === 1 &&
                    (exceptionDayDetail.extraInfo as [string, string][] || [['', '']])
                      .map(([from, to], index) =>
                        <div key={'timepicker-' + index} className={'mb-0.5'}>
                          <TimePicker.RangePicker format={'hh:mm a'} use12Hours minuteStep={15} autoFocus
                                                  allowEmpty={[false, false]} needConfirm={false}
                                                  disabled={exceptionDayDetail.type !== 1}
                                                  onChange={(newTime) => onTimePickerValueChange(newTime, index)}
                                                  value={[from ? dayjs(from, OperationHourFormat) : undefined, to ? dayjs(to, OperationHourFormat) : undefined]}
                          />
                          {
                            index !== 0 &&
                            <Button icon={<i className="ri-delete-bin-3-line" />} size={'small'} danger
                                    onClick={() => removeTimePicker(index)}
                                    disabled={exceptionDayDetail.type !== 1} className={'ml-1'} />
                          }
                        </div>,
                      )
                  }
                  <Button block size={'small'} className={'mt-1'} disabled={disableAddButton()}
                          onClick={addTimePicker}>+</Button>
                </div>
                <Divider />
                <Radio value={2}>Unexpected Closure</Radio>
                <UnexpectedClosureReasonSetting onChange={setUnexpectedCloseReasons}>
                  <Button icon={<i className="ri-settings-3-line" />} size={'small'} />
                </UnexpectedClosureReasonSetting>
                <div className={'mt-1'}>
                  <Select placeholder={'Select Reason'} disabled={exceptionDayDetail.type !== 2}
                          value={exceptionDayDetail.type !== 2 ? '' : exceptionDayDetail.extraInfo}
                          className={'w-[100%]'} options={unexpectedCloseReasons.map(r => ({ label: r, value: r }))}
                          onSelect={(value) => setExceptionDayDetail({
                            ...exceptionDayDetail,
                            extraInfo: value as string,
                          })}
                  />
                </div>
                <Divider />
                <Radio value={3}>Holiday Close</Radio>
                <HolidayConfig onChange={setHolidays}>
                  <Button icon={<i className="ri-settings-3-line" />} size={'small'} />
                </HolidayConfig>
                <div className={'mt-1'}>
                  <Select placeholder={'Select Reason'} disabled={exceptionDayDetail.type !== 3}
                          value={exceptionDayDetail.type !== 3 ? '' : exceptionDayDetail.extraInfo}
                          className={'w-[100%]'} options={holidays.map(r => ({ label: r, value: r }))}
                          onSelect={(value) => setExceptionDayDetail({
                            ...exceptionDayDetail,
                            extraInfo: value as string,
                          })}
                  />
                </div>
              </Radio.Group>
              <Divider />
              <Button loading={loading} type={'primary'} className={'float-end'} onClick={onOk}
                      disabled={disableConfirmButton()}>Confirm</Button>
            </div>
          }
        </div>
      </Modal>
    </>
  );
};
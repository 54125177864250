import { Donor } from 'bridge/donor';

export const formatDonorName = (donor: any) => {
  if (!donor) return '';
  return donor.firstName + ' ' + donor.lastName;
};
export const formatPhone = (phone: any) => {
  if (phone && phone?.phoneNumber && phone?.phoneNumber.length > 0) {
    return '+' + phone?.countryCode + ' ' + phone?.phoneNumber;
  }
  return '';
};
export const formatAppointmentType = (appointmentType: string) => {
  let newtype = appointmentType.trim();
  newtype = newtype.replace('_', ' ');
  return newtype.substring(0, 1).toUpperCase() + newtype.substring(1);
};

export const getDonorFullName = (donor: Donor) => {
  if (!donor) return '';
  let fullName = '';
  if (donor.firstName && donor.lastName) {
    const middleName = donor.middleName ? donor.middleName + ' ' : '';
    fullName = donor.firstName + ' ' + middleName + donor.lastName;
  }
  return fullName;
};

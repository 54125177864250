import { Link, useLocation, useParams } from 'react-router-dom';
import { Divider, Radio, Typography } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { Survey } from 'bridge/survey';
import httpClient from '../../../utils/http-client.util';
import { BackendAPI } from '../../../constants/backend-api.enum';
import { popMessage } from '../../../utils/pop-message.util';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

export const SurveyStatistics = () => {
  const [loading, setLoading] = useState(false);
  const [survey, setSurvey] = useState<Survey>();
  const [currentLanguage, setCurrentLanguage] = useState<string>('');

  const { surveyId } = useParams();
  const routerLocation = useLocation();

  const calculateChoiceStatisticsByQuestion = () => {
    const finalChartDataByQuestionIndex: {
      [questionIndex: number]: { options: ApexOptions; series: ApexOptions['series'] } | 'free-input';
    } = {};
    if (survey) {
      const statisticsByQuestionIndex: {
        [questionIndex: number]: { choiceText: string; chosen: number }[] | 'free-input';
      } = survey.questions.reduce(
        (acc, question, index) => {
          if (question.type === 'free-input') {
            acc[index] = 'free-input';
          } else {
            acc[index] = (question.choices || []).map((choice) => ({
              choiceText: choice.content[currentLanguage],
              chosen: 0,
            }));
          }
          return acc;
        },
        {} as { [questionIndex: number]: { choiceText: string; chosen: number }[] | 'free-input' }
      );

      survey.statistics.completions.forEach((completion) => {
        Object.entries(completion.answers).forEach(([questionIndex, answer]) => {
          const qIndex = parseInt(questionIndex);
          const correspondingQuestion = survey.questions[qIndex];
          if (correspondingQuestion.type !== 'free-input') {
            const correspondingQuestionStatistics = statisticsByQuestionIndex[qIndex] as {
              choiceText: string;
              chosen: number;
            }[];
            Object.entries(answer).forEach(([choiceIndex, choice]) => {
              const correspondingChoice = (correspondingQuestion.choices || [])[parseInt(choiceIndex)];
              const correspondingChoiceStatistic = correspondingQuestionStatistics.find(
                (cqs) => cqs.choiceText === correspondingChoice.content[currentLanguage]
              );
              if (correspondingChoiceStatistic) {
                correspondingChoiceStatistic.chosen++;
              }
            });
          }
        });
      });

      Object.entries(statisticsByQuestionIndex).forEach(([questionIndex, data]) => {
        if (data === 'free-input') {
          finalChartDataByQuestionIndex[parseInt(questionIndex)] = 'free-input';
        } else {
          const chartData = {
            series: [
              {
                name: 'Chosen',
                data: [] as number[],
              },
            ],
            options: {
              chart: {
                type: 'bar' as any,
                height: 430,
              },
              plotOptions: {
                bar: {
                  horizontal: true,
                  dataLabels: {
                    position: 'top',
                  },
                },
              },
              dataLabels: {
                enabled: true,
                offsetX: -6,
                style: {
                  fontSize: '12px',
                  colors: ['#fff'],
                },
              },
              stroke: {
                show: true,
                width: 1,
                colors: ['#fff'],
              },
              tooltip: {
                shared: true,
                intersect: false,
              },
              xaxis: {
                categories: [] as string[],
              },
            },
          };
          data.forEach((choice) => {
            chartData.series[0].data.push(choice.chosen);
            chartData.options.xaxis.categories.push(choice.choiceText);
          });
          finalChartDataByQuestionIndex[parseInt(questionIndex)] = chartData;
        }
      });
    }

    return finalChartDataByQuestionIndex;
  };

  const statisticsByQuestion = useMemo(() => calculateChoiceStatisticsByQuestion(), [survey, currentLanguage]);

  useEffect(() => {
    const inStateSurvey: Survey = routerLocation.state?.survey || null;
    if (!inStateSurvey || inStateSurvey.surveyId !== surveyId || !inStateSurvey.statistics) {
      (async () => {
        setLoading(true);
        try {
          let surveyResponse = { data: inStateSurvey };
          if (!inStateSurvey || inStateSurvey.surveyId !== surveyId) {
            surveyResponse = await httpClient.get<Survey>(`${BackendAPI.SURVEY}/${surveyId}`);
          }
          const statisticsResponse = await httpClient.get(`${BackendAPI.SURVEY}/${surveyId}/statistics`);
          setCurrentLanguage(Object.keys(surveyResponse.data.title)[0]);
          setSurvey({ ...surveyResponse.data, statistics: statisticsResponse.data });
        } catch (e) {
          popMessage.error({ content: 'Failed to load statistics for survey ' + surveyId });
        } finally {
          setLoading(false);
        }
      })();
    } else {
      setCurrentLanguage(Object.keys(inStateSurvey.title)[0]);
      setSurvey(inStateSurvey);
      window.history.replaceState({}, '');
    }
  }, []);

  return (
    <div>
      <Typography.Title level={3} className={'flex items-center'}>
        <Link to={'../'} className={'!text-primary hover:!opacity-55'}>
          Survey
        </Link>
        <Divider type={'vertical'} className={'transform rotate-12'} />
        <span className="text-[#f84525] rounded-md">Statistics</span>
        <Typography.Text type={'secondary'} className={'relative ml-2 -bottom-0.5'}>
          {survey?.surveyId}
        </Typography.Text>
      </Typography.Title>
      <div className={'bg-primary-foreground p-3'}>
        {loading ? (
          <div className={'text-center'}>
            <i className={'ri-loader-3-line animate-spin text-primary text-3xl text-blue-500 inline-block'} />
          </div>
        ) : survey ? (
          survey.publishDate ? (
            <div>
              <div className={'flex items-center justify-between'}>
                <div>
                  <Typography.Title level={4} className={'text-primary'}>
                    {survey.title[currentLanguage]}
                  </Typography.Title>
                  <div>
                    <div className={'flex items-center'}>
                      <Typography.Text type={'secondary'}>Handouts:</Typography.Text>
                      <Typography.Text className={'text-primary ml-2'}>{survey.statistics.handouts}</Typography.Text>
                      <Divider type={'vertical'} />
                      <Typography.Text type={'secondary'}>Completed:</Typography.Text>
                      <Typography.Text className={'text-primary ml-2'}>
                        {survey.statistics.completions.length}
                      </Typography.Text>
                      <Divider type={'vertical'} />
                      <Typography.Text type={'secondary'}>Completion Rate:</Typography.Text>
                      <Typography.Text className={'text-primary ml-2'}>
                        {(survey.statistics.completions.length * 100) / survey.statistics.handouts}%
                      </Typography.Text>
                    </div>
                  </div>
                </div>

                <Radio.Group
                  value={currentLanguage}
                  onChange={(e) => setCurrentLanguage(e.target.value)}
                  defaultValue={Object.keys(survey.title || {})[0]}
                >
                  {Object.entries(survey.title).map(([key, value]) => (
                    <Radio.Button key={key} value={key}>
                      {key.toUpperCase()}
                    </Radio.Button>
                  ))}
                </Radio.Group>
              </div>
              <Divider>Statistics Detail</Divider>
              <div className={'flex flex-wrap'}>
                {Object.entries(statisticsByQuestion).map(([questionIndex, data]) => (
                  <div
                    key={questionIndex}
                    className={
                      'w-[49%] p-4 m-1 border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700'
                    }
                  >
                    {data === 'free-input' ? (
                      <>
                        <Typography.Title level={5} className={'text-primary'}>
                          {survey.questions[parseInt(questionIndex)].question[currentLanguage]}
                        </Typography.Title>
                        <Typography.Text>No Statistic for free input question</Typography.Text>
                      </>
                    ) : (
                      <>
                        <Typography.Title level={5} className={'text-primary'}>
                          {survey.questions[parseInt(questionIndex)].question[currentLanguage]}
                        </Typography.Title>
                        <ReactApexChart options={data.options} series={data.series} type="bar" height={350} />
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <Typography.Text>Survey not published</Typography.Text>
          )
        ) : (
          <Typography.Text>Survey not found</Typography.Text>
        )}
      </div>
    </div>
  );
};

import React, { useEffect } from 'react';
import { DataTable, DataTableColumn, DataTableSetting } from '../../../../components/data-table/data-table.component';
import { Notification } from 'bridge/notification';
import httpClient from '../../../../utils/http-client.util';
import { BackendAPI } from '../../../../constants/backend-api.enum';
import { popMessage } from '../../../../utils/pop-message.util';
import { DatePicker, Modal, Typography } from 'antd';
import NotificationPreview from '../component/notification-preview.component';
import dayjs, { Dayjs } from 'dayjs';
import { GeneralDateDisplayFormat } from 'bridge/date-format';
import { TargetDonorFilter } from '../../shared-component/target-donor-filter.component';

export const NotificationHistory = () => {
  const [notifications, setNotifications] = React.useState<Notification[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [showPreview, setShowPreview] = React.useState<boolean>(false);
  const [selectedNotification, setSelectedNotification] = React.useState<Notification | null>(null);
  const [pushDateFilter, setPushDateFilter] = React.useState<[Dayjs, Dayjs] | undefined>();

  const columnsDef: DataTableColumn<Notification>[] = [
    {
      title: 'Notification ID',
      dataIndex: 'notificationId',
    },
    {
      title: 'Title',
      dataIndex: 'title',
      filterable: true,
      filterSearch: true,
    },
    {
      title: 'Push Date',
      dataIndex: 'createdAt',
      render: (value) => dayjs(value).format(GeneralDateDisplayFormat),
    },
    {
      title: 'Pushed Count',
      dataIndex: 'receivingDonorCount',
      render: (value, record) => (
        <div className={'flex items-center space-x-3'}>
          <span>{value}</span>
          <Typography.Link>
            <TargetDonorFilter
              existingFilter={record.targetFilter}
              title={`Notification ${record.notificationId} Target Donor Filter`}
              disabled
              lazyLoad
            >
              {() => 'Check Filter'}
            </TargetDonorFilter>
          </Typography.Link>
        </div>
      ),
    },
  ];

  const dataTableSetting: DataTableSetting<Notification> = {
    numbered: true,
    customizedOperations: [
      {
        text: <i className={'ri-eye-line cursor-pointer text-blue-500'} />,
        whenPerform: async (record) => {
          setShowPreview(true);
          setSelectedNotification(record);
        },
      },
    ],
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const Notifications = await httpClient.get<Notification[]>(BackendAPI.NOTIFICATION);
        setNotifications(Notifications.data);
      } catch (error) {
        popMessage.error('Failed to fetch Notifications.');
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <div>
      <div className={'flex justify-end items-center mb-2'}>
        <Typography.Text type="secondary" className={'mr-2'}>
          Push Date Range:
        </Typography.Text>
        <DatePicker.RangePicker
          allowClear
          value={pushDateFilter ? [dayjs(pushDateFilter[0]), dayjs(pushDateFilter[1])] : undefined}
          onChange={(dateRange) => {
            if (!dateRange || !dateRange[0] || !dateRange[1]) {
              setPushDateFilter(undefined);
            } else {
              setPushDateFilter([dateRange[0], dateRange[1]]);
            }
          }}
        />
      </div>
      <DataTable
        data={notifications.filter((n) =>
          pushDateFilter
            ? dayjs(n.createdAt).isAfter(pushDateFilter[0]) && dayjs(n.createdAt).isBefore(pushDateFilter[1])
            : true
        )}
        columnsDef={columnsDef}
        setting={dataTableSetting}
        loading={loading}
      />
      {showPreview && selectedNotification && (
        <Modal
          title={`Notification ${selectedNotification.notificationId}`}
          open={showPreview}
          onCancel={() => {
            setShowPreview(false);
            setSelectedNotification(null);
          }}
          width={360}
          style={{ top: 20 }}
          footer={null}
        >
          <div className={'h-[600px]'}>
            <NotificationPreview notification={selectedNotification} />
          </div>
        </Modal>
      )}
    </div>
  );
};

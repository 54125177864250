import { Divider, Typography } from 'antd';
import moment from 'moment/moment';
import React from 'react';
import { ContentItem, NewsPost } from 'bridge/news-post';
import {
  AccordionContent,
  ButtonContent,
  DescriptiveContent,
  HeadingContent,
  ImageContent,
  LinkContent,
} from '../type-def/content-classes';
import './news-posts.simulator.css';

type Prop = {
  currentPost: Partial<NewsPost>,
  currentLanguage: string,
  scaleRatio: number,
}

const NewsPostsPreviewComponent = ({ currentPost, currentLanguage, scaleRatio }: Prop) => {
  const generateRealtimeRender = (contents: ContentItem[]) => {
    const capitalizeFirstLetter = (str: string) => {
      return str ? (str.charAt(0).toUpperCase() + str.slice(1)) : '';
    };

    const fontStyle = (content: DescriptiveContent | HeadingContent) => {
      if (['body', 'bullet'].includes(content.tag)) {
        content = content as DescriptiveContent;
        let size = '16px';
        if (content.size === 'small') {
          size = '14px';
        } else if (content.size === 'x-small') {
          size = '12px';
        }
        return {
          fontWeight: content.fontWeight === 'regular' ? 400 : 800,
          textDecoration: content.textDecoration,
          fontSize: size,
        };
      } else {
        return {
          textDecoration: content.textDecoration,
        };
      }
    };

    const result = [];

    for (let i = 0; i < contents.length; ++i) {
      switch (contents[i].tag) {
        case 'body':
          result.push(
            <div key={i}
              style={{ marginBottom: contents[i].marginBottom, ...fontStyle(contents[i] as DescriptiveContent) }}>
              {(contents[i] as DescriptiveContent).text}
            </div>,
          );
          break;
        case 'bullet':
          const lis = [
            <li key={i}
              style={{ marginBottom: contents[i].marginBottom, ...fontStyle(contents[i] as DescriptiveContent) }}>{capitalizeFirstLetter((contents[i] as DescriptiveContent).text)}</li>,
          ];
          while (contents[i + 1]) {
            if (contents[i + 1].tag === 'bullet') {
              ++i;
              lis.push(
                <li key={i}
                  style={{ marginBottom: contents[i].marginBottom, ...fontStyle(contents[i] as DescriptiveContent) }}>{capitalizeFirstLetter((contents[i] as DescriptiveContent).text)}</li>,
              );
            } else {
              break;
            }
          }
          result.push(
            <ul key={`ul-${i}`} style={{ listStyle: 'initial' }}>
              {lis}
            </ul>,
          );
          break;
        case 'h1':
          result.push(
            <p key={i} style={{
              marginBottom: contents[i].marginBottom,
              fontWeight: 'bold',
              fontSize: '24px',
              ...fontStyle(contents[i] as HeadingContent),
            }}>{capitalizeFirstLetter((contents[i] as HeadingContent).text)}</p>,
          );
          break;
        case 'h2':
          result.push(
            <p key={i} style={{
              marginBottom: contents[i].marginBottom,
              fontWeight: 'bold',
              fontSize: '22px',
              ...fontStyle(contents[i] as HeadingContent),
            }}>{capitalizeFirstLetter((contents[i] as HeadingContent).text)}</p>,
          )
          ;
          break;
        case 'h3':
          result.push(
            <p key={i} style={{
              marginBottom: contents[i].marginBottom,
              fontWeight: 'bold',
              fontSize: '20px',
              ...fontStyle(contents[i] as HeadingContent),
            }}>{capitalizeFirstLetter((contents[i] as HeadingContent).text)}</p>,
          );
          break;
        case 'h4':
          result.push(
            <p key={i} style={{
              marginBottom: contents[i].marginBottom,
              fontSize: '16px',
              ...fontStyle(contents[i] as HeadingContent),
            }}>{capitalizeFirstLetter((contents[i] as HeadingContent).text)}</p>,
          )
          ;
          break;
        case 'h5':
          result.push(
            <p key={i} style={{
              marginBottom: contents[i].marginBottom,
              fontWeight: 'bold',
              fontSize: '16px',
              ...fontStyle(contents[i] as HeadingContent),
            }}>{capitalizeFirstLetter((contents[i] as HeadingContent).text)}</p>,
          );
          break;
        case 'button':
          result.push(
            <div key={i} className="flex justify-center items-center">
              <button style={{
                marginBottom: contents[i].marginBottom,
                border: '1px steelblue solid',
                borderRadius: 5,
                background: 'steelblue',
                color: 'white',
                fontWeight: 'bold',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '5px',
              }}>
                {((contents[i] as ButtonContent).title || '').toUpperCase()}
                <span className="material-icons" style={{ fontSize: '16px', marginLeft: 5 }}>
                  {(contents[i] as ButtonContent).icon}
                </span>
              </button>
            </div>,
          );
          break;
        case 'image':
          result.push(
            <div key={i} className="flex justify-center items-center">
              <img src={(contents[i] as ImageContent).src}
                   alt={'content-image'}
                   style={{
                     marginBottom: (contents[i] as ImageContent).marginBottom,
                     aspectRatio: (contents[i] as ImageContent).ratio ? (contents[i] as ImageContent).ratio : 'auto',
                     width: (contents[i] as ImageContent).width ? (contents[i] as ImageContent).width + '%' : 'auto',
                   }} />
            </div>,
          );
          break;
        case 'accordion':
          const children = generateRealtimeRender((contents[i] as AccordionContent).content);
          const rgn = Math.random();
          const wrapper =
            <div key={i} className="mock-accordion">
              <div>
                <input type="checkbox" id={'section' + rgn} className="mock-accordion-input" />
                <label htmlFor={'section' + rgn}
                       className="mock-accordion-label">{(contents[i] as AccordionContent).header}</label>
                <div className="mock-accordion-content">
                  {children}
                </div>
              </div>
            </div>;
          result.push(
            wrapper,
          );
          break;
        case 'link':
          result.push(
            <div key={i} style={{
              color: 'blue',
              fontWeight: 'bold',
              textDecoration: 'underline',
              cursor: 'pointer',
              marginBottom: contents[i].marginBottom,
            }}>
              {(contents[i] as LinkContent).title}
            </div>,
          );
          break;
      }
    }

    return result;
  };

  return (
    <div style={{
      width: 390,
      height: 844,
      border: 'grey 2px solid',
      borderRadius: '28px',
      overflowY: 'auto',
      transform: `scale(${scaleRatio})`,
    }} className={`origin-top-left`}>
      <div className={'text-center mb-1'}>
        <Typography.Text type={'secondary'}>
          Simulated App Display
        </Typography.Text>
      </div>
      <div className="ml-3">
        <p style={{
          width: '100%',
          position: 'relative',
          textAlign: 'center',
        }}>
          <i className={'ri-arrow-left-s-line text-[1.5em] absolute left-1 bottom-[1px]'} />
          <span style={{
            fontSize: '18px',
            display: 'inline-block',
            width: '80%',
            overflowY: 'hidden',
            fontWeight: 'bold',
            overflowWrap: 'break-word',
            wordBreak: 'break-word',
          }}>{currentPost.localizedContent ? currentPost.localizedContent[currentLanguage]?.title?.toUpperCase() || '' : ''}</span>
        </p>
      </div>
      <div>
        <img src={currentPost.coverImage || 'https://dummyimage.com/386'} style={{ width: 386 }} alt="" />
      </div>
      <div className="m-5">
        <div className={'flex items-start'}>
          <div style={{
            fontSize: '18px',
            fontWeight: 'bold',
            display: 'inline-block',
            width: 300,
          }}>
            <div>{currentPost.localizedContent ? currentPost.localizedContent[currentLanguage]?.title : ''}</div>
            <Typography.Text type="secondary"
                             style={{ fontSize: 'small' }}>{moment(currentPost.publishTS).format('MMMM DD, YYYY')}</Typography.Text>
          </div>
          <i className={'ri-heart-2-line text-[1.5em]'} />
        </div>

        <Divider />
        <div className={'mx-5'}>
          {
            currentPost.localizedContent ?
              generateRealtimeRender(currentPost.localizedContent[currentLanguage]?.layout || [])
              :
              null
          }
        </div>
      </div>
    </div>
  );
};

export default NewsPostsPreviewComponent;

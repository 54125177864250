import { List, Typography } from 'antd'
import { EntityFeeStructure } from 'bridge/fee-structure'
import { EntityCondition, EntityPromotionBonus } from 'bridge/promotion-bonus'
import { APPLICANTDONORS, CUSTOMEGROUP, DEFERRALDONORS, LAPSEDDONORS, PREDONORSTONEWDONORS, RETENTIONDONORS } from '../../../../utils/enum'

export const DonationsDisplay = ({ promotionBonusData }: { promotionBonusData: EntityPromotionBonus }) => {

    let campaignType: EntityPromotionBonus["campaignType"] = promotionBonusData?.campaignType
    let condition: EntityCondition[] = promotionBonusData?.condition

    return (
        <div>
            {
                condition.length > 0 ? (
                    condition.map((item, index) => (
                        <div>
                            <Typography.Text className='flex flex-col font-semibold'>
                                <span className='text-red-500'>
                                    *Condition {index + 1}
                                </span>
                                {(() => {
                                    switch (campaignType) {
                                        case PREDONORSTONEWDONORS:
                                            return <span>Make donations in <span className='text-red-500'>{item.duration[0]}</span> - <span className='text-red-500'>{item.duration[1]}</span> days since registration</span>;
                                        case APPLICANTDONORS:
                                            return <span>Make donations in <span className='text-red-500'>{item.duration[0]}</span> - <span className='text-red-500'>{item.duration[1]}</span>  days since 1st donation</span>;
                                        case RETENTIONDONORS:
                                            return <span>Every <span className='text-red-500'>{item.duration[0]}</span>  donation in a <span className='text-red-500'>{item.duration[1]}</span> </span>;
                                        case LAPSEDDONORS:
                                            return <span>Laps <span className='text-red-500'>{item.duration[0]}</span> - <span className='text-red-500'>{item.duration[1]}</span> days</span>;
                                        case DEFERRALDONORS:
                                            return (
                                                <>
                                                    {
                                                        item.deferralType ? (
                                                            <span>Deferral Type: <span className='text-red-500'>{item.deferralType}</span></span>
                                                        ) : null
                                                    }
                                                    <span>Make donations in <span className='text-red-500'>{item.duration[0]}</span> - <span className='text-red-500'>{item.duration[1]}</span>  days since deferral end date</span>
                                                </>
                                            );
                                        case CUSTOMEGROUP:
                                            return <span>DonorID</span>;
                                        default:
                                            return null;
                                    }
                                })()}
                            </Typography.Text>
                            <List size="small">
                                {
                                    item.donations.length > 0 ? (
                                        item.donations.map((item, index) => (
                                            <List.Item style={{ padding: '8px 0' }}>
                                                {(() => {
                                                    switch (item.type) {
                                                        case 'money':
                                                            return <span>Donation #{index + 1}: <span className='font-semibold'>${item.value}</span></span>;
                                                        case 'point':
                                                            return <span>Donation #{index + 1}: <span className='font-semibold'>{item.value} pts</span></span>;
                                                        default:
                                                            return null;
                                                    }
                                                })()}
                                            </List.Item>
                                        ))
                                    ) : null
                                }
                            </List>
                        </div>
                    ))
                ) : null
            }
        </div>
    )
}

export const FeeStructureDurationsDisplay = ({ feeDonationDuration }: { feeDonationDuration: EntityFeeStructure["feeDonationDuration"] }) => {
    return (
        <div>
            {(() => {
                switch (feeDonationDuration) {
                    case 'week':
                        return <span>Make donations in a {feeDonationDuration}</span>;
                    case 'month':
                        return <span>Make donations in a {feeDonationDuration}</span>;
                    default:
                        return <span>Make donations in {feeDonationDuration} days</span>;;
                }
            })()}
        </div>
    )
}
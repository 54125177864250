import { Button, Card, Input, Radio, Select, Space, Typography } from 'antd';
import { EntityCondition } from 'bridge/promotion-bonus';
import React, { useState, useEffect } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { RadioChangeEvent } from 'antd/lib';

export const RetentionDonors = ({ onChangeData, conditionData }: { onChangeData?: (data: EntityCondition[]) => void, conditionData: EntityCondition[] }) => {
  const [retentionDonorsConditions, setRetentionDonorsConditions] = useState<EntityCondition[]>([
    {
      duration: [''],
      donations: [
        {
          type: '',
          value: '',
        },
      ],
    },
  ]);
  let donationType = ["money", "point"]

  useEffect(() => {
    if (onChangeData) {
      onChangeData(retentionDonorsConditions)
    }
  }, [retentionDonorsConditions])

  useEffect(() => {
    if (conditionData && conditionData.length > 0) {
      setRetentionDonorsConditions(conditionData)
    }
  }, [conditionData])

  const handleAddCondition = () => {
    setRetentionDonorsConditions([...retentionDonorsConditions, {
      duration: [''],
      donations: [
        {
          type: '',
          value: '',
        },
      ]
    }])
  };

  const handleDeleteCondition = (index: number) => {
    if (retentionDonorsConditions.length > 1) {
      retentionDonorsConditions.splice(index, 1)
      setRetentionDonorsConditions([...retentionDonorsConditions])
    }
  }

  const handleSelectDonation = (event: RadioChangeEvent, indexCondition: number, indexDonation: number) => {
    let newCondition = [...retentionDonorsConditions];
    newCondition[indexCondition].donations[indexDonation].value = ""
    newCondition[indexCondition].donations[indexDonation].type = event.target.value
    setRetentionDonorsConditions(newCondition)
  }

  return (
    <div>
      <div className="flex flex-col gap-4" style={{ maxWidth: 600 }}>
        {
          retentionDonorsConditions.map((item, index) => (
            <Card
              size="small"
              title={`Condition #${index + 1}`}
              key={index}
              extra={
                retentionDonorsConditions.length > 1 ? <CloseOutlined onClick={() => handleDeleteCondition(index)} /> : null
              }
              className='condition-item'
              style={{ backgroundColor: '#fafafa' }}
            >
              <div className='flex flex-col gap-4'>
                <Typography.Text className="flex flex-row gap-4 items-center span font-semibold">
                  <span>Every</span>
                  <Input style={{ width: '50px' }}
                    value={item.duration[0]}
                    onChange={(event) => {
                      let condition = [...retentionDonorsConditions];
                      let targetField = { ...condition[index] }
                      targetField.duration[0] = event.target.value;
                      condition[index] = targetField
                      setRetentionDonorsConditions(condition)
                    }} />
                  <span>donation in a</span>
                  <Select
                    value={item.duration[1]}
                    style={{ width: 120 }}
                    options={[
                      { value: 'week', label: 'Week' },
                      { value: 'month', label: 'Month' },
                    ]}
                    onChange={(value: string) => {
                      let condition = [...retentionDonorsConditions];
                      let targetField = { ...condition[index] }
                      targetField.duration[1] = value;
                      condition[index] = targetField
                      setRetentionDonorsConditions(condition)
                    }}
                  />
                </Typography.Text>
                {
                  item.donations.map((it, ind) => (
                    <div className='d-flex flex-row align-items-center gap-2'>
                      <Card
                        size="small"
                        key={ind}
                      >
                        <Space direction="vertical" className='flex flex-row'>
                          {
                            donationType.map((itemDonation, indexDonation) => (
                              <Radio checked={it.type === itemDonation} onChange={(event) => handleSelectDonation(event, index, ind)} value={itemDonation} className='flex flex-row items-center'>
                                <div className='flex flex-row items-center gap-4'>
                                  <Input
                                    name={itemDonation}
                                    disabled={item.donations[ind].type === itemDonation ? false : true}
                                    value={item.donations[ind].type === itemDonation ? retentionDonorsConditions[index].donations[ind].value : ""}
                                    onChange={(event) => {
                                      let condition = [...retentionDonorsConditions];
                                      let targetField = { ...condition[index] }
                                      let donation = [...targetField.donations]
                                      let target = { ...donation[ind] }
                                      target = { ...target, value: event.target.value }
                                      donation[ind] = target
                                      condition[index].donations = donation
                                      setRetentionDonorsConditions(condition)
                                    }} />
                                  <span>{itemDonation === "money" ? "$" : "pts"}</span>
                                </div>
                              </Radio>
                            ))
                          }
                        </Space>
                      </Card>
                    </div>
                  ))
                }
              </div>
            </Card>
          ))
        }
        <Button type="dashed" onClick={handleAddCondition} block style={{ maxWidth: 600 }}>
          + Add Item
        </Button>
      </div>
    </div>
  );
};

import {
  AccordionContent,
  ButtonContent,
  DescriptiveContent,
  HeadingContent,
  ImageContent,
  LinkContent,
} from '../type-def/content-classes';
import { Divider, Input, InputNumber, Popconfirm, Select, Typography, Upload } from 'antd';
import { underLinedInputClass } from '../../../../components/inputs/editable-attribute.component';
import React, { useEffect, useState } from 'react';
import { DefaultOptionType } from 'rc-select/lib/Select';
import { BackendAPI } from '../../../../constants/backend-api.enum';
import { popMessage } from '../../../../utils/pop-message.util';
import JSONInput from 'react-json-editor-ajrm-ts/lib';
import locale from 'react-json-editor-ajrm-ts/lib/locale/en';
import { JSONInputError } from 'react-json-editor-ajrm-ts/lib/types';
import { Draggable, DraggingStyle, DroppableStateSnapshot, NotDraggingStyle } from '@erom/react-beautiful-dnd';
import { StrictModeDroppable } from '../../../../utils/strict-mode-dnd.util';
import { ContentItem } from 'bridge/news-post';

const marginBottomSelect = <T extends ContentItem, >(content: T, onMarginChange: React.Dispatch<React.SetStateAction<T | undefined>>) => (
  <>
    <div>
      <Typography.Text type="secondary">Margin Bottom</Typography.Text>
    </div>
    <InputNumber
      value={content.marginBottom}
      variant="borderless"
      className={`${underLinedInputClass} w-[100%]`}
      style={{ borderBottomStyle: 'solid', flex: 1 }}
      onChange={(selected) => {
        onMarginChange({ ...content, marginBottom: selected || 0 });
      }}
    />
  </>
);

type DraggableWrapperProp<T extends ContentItem> = {
  index: number,
  content: T,
  onTagChangeOrRemove: React.Dispatch<React.SetStateAction<T | undefined>>,
  icon: string,
  selectOptions: DefaultOptionType[],
  previousIndexes: number[],
} & React.PropsWithChildren
const DraggableWrapper = <T extends ContentItem>({
                                                   index,
                                                   content,
                                                   onTagChangeOrRemove,
                                                   icon,
                                                   selectOptions,
                                                   children,
                                                   previousIndexes,
                                                 }: DraggableWrapperProp<T>) => {
  const indexChain = previousIndexes.join('-');
  const draggableId = `draggable@${indexChain ? `${indexChain}-${index}` : `${index}`}`;

  return <Draggable
    key={draggableId}
    draggableId={draggableId}
    index={index}
  >
    {(dragProvided, dragSnapshot) => (
      <div ref={dragProvided.innerRef}
           className={'bg-secondary-foreground p-2 rounded-lg'}
           {...dragProvided.draggableProps}
           style={contentDraggableStyle(dragSnapshot.isDragging, dragProvided.draggableProps.style)}>
        <div className={'flex items-center justify-between'}>
          <div className={'flex items-center'}>
            <i {...dragProvided.dragHandleProps} className={`ri-draggable text-blue-500 text-xl cursor-grab mr-2`}></i>
            <i className={`${icon} text-xl mr-1`}></i>
            {
              selectOptions.length > 1 ?
                <Select
                  value={content.tag}
                  options={selectOptions}
                  className={'w-[120px]'}
                  onChange={(selected) => {
                    content.tag = selected;
                    onTagChangeOrRemove({ ...content });
                  }}
                />
                :
                <Typography.Title level={5} className={'!mb-0'}>{selectOptions[0].label}</Typography.Title>
            }
          </div>
          <Popconfirm title={'Delete?'} onConfirm={() => onTagChangeOrRemove(undefined)}>
            <i className={'ri-delete-bin-3-line text-lg text-red-500 cursor-pointer'} />
          </Popconfirm>
        </div>
        {
          children
        }
      </div>
    )}
  </Draggable>;
};

type EditorProp<T extends ContentItem> = {
  content: T,
  onUpdate: <R extends ContentItem>(newContent: R, locators: number[]) => void,
  onRemove: (locators: number[]) => void,
  index: number,
  previousIndexes: number[],
  language: string,
}
const DescriptiveContentEditor = ({
                                    content,
                                    onUpdate,
                                    onRemove,
                                    index,
                                    previousIndexes,
                                  }: EditorProp<DescriptiveContent>) => {
  const [currentContent, setCurrentContent] = useState<DescriptiveContent>();
  const [initDone, setInitDone] = useState(false);

  useEffect(() => {
    if (currentContent) {
      if (initDone) {
        onUpdate(currentContent, [...previousIndexes, index]);
      } else {
        setInitDone(true);
      }
    } else {
      if (initDone) {
        onRemove([...previousIndexes, index]);
      }
    }
  }, [currentContent]);

  useEffect(() => {
    setCurrentContent(content);
  }, [content]);

  return (
    <>
      {
        !!currentContent &&
        <DraggableWrapper index={index} content={currentContent} onTagChangeOrRemove={setCurrentContent}
                          previousIndexes={previousIndexes}
                          icon={currentContent.tag === 'body' ? 'ri-text-snippet' : 'ri-list-unordered'}
                          selectOptions={[
                            { label: 'Body', value: 'body' },
                            { label: 'Bullet Point', value: 'bullet' },
                          ]}>
          <div>
            <div className="inline-block w-[calc((100%-51px)/4)]">
              <div>
                <Typography.Text type="secondary">Font Weight</Typography.Text>
              </div>
              <Select
                value={currentContent.fontWeight}
                variant="borderless"
                className={`${underLinedInputClass} w-[100%]`}
                style={{ borderBottomStyle: 'solid', flex: 1 }}
                options={[{ label: 'Regular', value: 'regular' }, { label: 'Bold', value: 'bold' }]}
                onChange={(selected) => {
                  setCurrentContent({ ...currentContent, fontWeight: selected });
                }}
              />
            </div>
            <Divider type={'vertical'} orientation={'center'} />
            <div className="inline-block w-[calc((100%-51px)/4)]">
              <div>
                <Typography.Text type="secondary">Font Size</Typography.Text>
              </div>
              <Select
                value={currentContent.size}
                variant="borderless"
                className={`${underLinedInputClass} w-[100%]`}
                style={{ borderBottomStyle: 'solid', flex: 1 }}
                options={[
                  { label: 'Regular', value: 'regular' },
                  { label: 'S', value: 'small' },
                  { label: 'XS', value: 'x-small' },
                ]}
                onChange={(selected) => {
                  setCurrentContent({ ...currentContent, size: selected });
                }}
              />
            </div>
            <Divider type={'vertical'} orientation={'center'} />
            <div className="inline-block w-[calc((100%-51px)/4)]">
              <div>
                <Typography.Text type="secondary">Text Decoration</Typography.Text>
              </div>
              <Select
                value={currentContent.textDecoration}
                variant="borderless"
                className={`${underLinedInputClass} w-[100%]`}
                style={{ borderBottomStyle: 'solid', flex: 1 }}
                options={[
                  { label: 'None', value: 'none' },
                  { label: 'Underline', value: 'underline' },
                ]}
                onChange={(selected) => {
                  setCurrentContent({ ...currentContent, textDecoration: selected });
                }}
              />
            </div>
            <Divider type={'vertical'} orientation={'center'} />
            <div className="inline-block w-[calc((100%-51px)/4)]">
              {
                marginBottomSelect(currentContent, setCurrentContent)
              }
            </div>
          </div>
          <div className={'mt-1'}>
            <div>
              <Typography.Text type="secondary">Content</Typography.Text>
            </div>
            <Input.TextArea
              value={currentContent.text}
              variant="borderless"
              className={`${underLinedInputClass} w-[100%]`}
              style={{ borderBottomStyle: 'solid', flex: 1 }}
              onChange={(event) => {
                setCurrentContent({ ...currentContent, text: event.target.value });
              }}
            />
          </div>
        </DraggableWrapper>
      }
    </>

  );
};
const HeadingContentEditor = ({ content, onUpdate, onRemove, previousIndexes, index }: EditorProp<HeadingContent>) => {
  const [currentContent, setCurrentContent] = useState<HeadingContent>();
  const [initDone, setInitDone] = useState(false);

  useEffect(() => {
    if (currentContent) {
      if (initDone) {
        onUpdate(currentContent, [...previousIndexes, index]);
      } else {
        setInitDone(true);
      }
    } else {
      if (initDone) {
        onRemove([...previousIndexes, index]);
      }
    }
  }, [currentContent]);

  useEffect(() => {
    setCurrentContent(content);
  }, [content]);

  return (
    <>
      {
        !!currentContent &&
        <DraggableWrapper index={index} content={currentContent} onTagChangeOrRemove={setCurrentContent}
                          previousIndexes={previousIndexes} icon={'ri-heading'}
                          selectOptions={
                            [
                              { label: 'H1', value: 'h1' },
                              { label: 'H2', value: 'h2' },
                              { label: 'H3', value: 'h3' },
                              { label: 'H4', value: 'h4' },
                              { label: 'H5', value: 'h5' },
                            ]
                          }>
          <div>
            <div className="inline-block w-[calc((100%-51px)/4)]">
              <div>
                <Typography.Text type="secondary">Text Decoration</Typography.Text>
              </div>
              <Select
                value={currentContent.textDecoration}
                variant="borderless"
                className={`${underLinedInputClass} w-[100%]`}
                style={{ borderBottomStyle: 'solid', flex: 1 }}
                options={[
                  { label: 'None', value: 'none' },
                  { label: 'Underline', value: 'underline' },
                ]}
                onChange={(selected) => {
                  currentContent.textDecoration = selected;
                  setCurrentContent({ ...currentContent });
                }}
              />
            </div>
            <Divider type={'vertical'} orientation={'center'} />
            <div className="inline-block w-[calc((100%-51px)/4)]">
              {
                marginBottomSelect(currentContent, setCurrentContent)
              }
            </div>
          </div>
          <div className={'mt-1'}>
            <div>
              <Typography.Text type="secondary">Content</Typography.Text>
            </div>
            <Input
              value={currentContent.text}
              variant="borderless"
              className={`${underLinedInputClass} w-[100%]`}
              style={{ borderBottomStyle: 'solid', flex: 1 }}
              onChange={(event) => {
                setCurrentContent({ ...currentContent, text: event.target.value });
              }}
            />
          </div>
        </DraggableWrapper>}
    </>
  );
};
const ImageContentEditor = ({ content, onRemove, onUpdate, previousIndexes, index }: EditorProp<ImageContent>) => {
  const [currentContent, setCurrentContent] = useState<ImageContent>();
  const [initDone, setInitDone] = useState(false);

  useEffect(() => {
    if (currentContent) {
      if (initDone) {
        onUpdate(currentContent, [...previousIndexes, index]);
      } else {
        setInitDone(true);
      }
    } else {
      if (initDone) {
        onRemove([...previousIndexes, index]);
      }
    }
  }, [currentContent]);

  useEffect(() => {
    setCurrentContent(content);
  }, [content]);

  return (
    <>
      {
        !!currentContent &&
        <DraggableWrapper index={index} content={currentContent} onTagChangeOrRemove={setCurrentContent}
                          previousIndexes={previousIndexes}
                          icon={'ri-image-line'} selectOptions={[{ label: 'Image' }]}>
          <div className={'flex'}>
            <div className={'min-w-[260px]'}>
              <Upload
                accept="image/*"
                action={`${BackendAPI.NEWSPOST}/upload-image`}
                listType={'picture-card'}
                defaultFileList={currentContent.src.length ? [{
                  url: currentContent.src,
                  name: 'img',
                  uid: '1',
                }] : undefined}
                onChange={async ({ file }) => {
                  if (file.status === 'done') {
                    setCurrentContent({ ...currentContent, src: file.response });
                  } else if (file.status === 'removed') {
                    setCurrentContent({ ...currentContent, src: '' });
                  } else if (file.status === 'error') {
                    popMessage.error('Unable to upload news post image');
                  }
                }}
                maxCount={1}
              >
                <button style={{ border: 0, background: 'none' }} type="button">
                  <i className={'ri-upload-line'} />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </button>
              </Upload>
            </div>
            <div>
              <div>
                <div className="inline-block w-[calc((100%-17px)/2)]">
                  <div>
                    <Typography.Text type="secondary">Ratio</Typography.Text>
                  </div>
                  <InputNumber
                    value={currentContent.ratio}
                    variant="borderless"
                    className={`${underLinedInputClass} w-[100%]`}
                    style={{ borderBottomStyle: 'solid', flex: 1 }}
                    onChange={(selected) => {
                      currentContent.ratio = selected || 0;
                      setCurrentContent({ ...currentContent });
                    }}
                  />
                </div>
                <Divider type={'vertical'} orientation={'center'}></Divider>
                <div className="inline-block w-[calc((100%-17px)/2)]">
                  <div>
                    <Typography.Text type="secondary">Width</Typography.Text>
                  </div>
                  <InputNumber
                    value={currentContent.width}
                    variant="borderless"
                    max={100}
                    placeholder={'percentage'}
                    className={`${underLinedInputClass} w-[100%]`}
                    style={{ borderBottomStyle: 'solid', flex: 1 }}
                    onChange={(value) => {
                      currentContent.width = value || 0;
                      setCurrentContent({ ...currentContent });
                    }}
                    suffix={'%'}
                  />
                </div>
              </div>
              <div>
                {
                  marginBottomSelect(currentContent, setCurrentContent)
                }
              </div>
            </div>
          </div>
        </DraggableWrapper>}
    </>

  );
};
const ButtonContentEditor = ({ content, previousIndexes, index, onUpdate, onRemove }: EditorProp<ButtonContent>) => {
  const [jsonError, setJsonError] = useState<JSONInputError>();
  const [currentContent, setCurrentContent] = useState<ButtonContent>();
  const [initDone, setInitDone] = useState(false);

  useEffect(() => {
    if (currentContent) {
      if (initDone) {
        onUpdate(currentContent, [...previousIndexes, index]);
      } else {
        setInitDone(true);
      }
    } else {
      if (initDone) {
        onRemove([...previousIndexes, index]);
      }
    }
  }, [currentContent]);

  useEffect(() => {
    setCurrentContent(content);
  }, [content]);

  return (
    <>
      {
        !!currentContent &&
        <DraggableWrapper index={index} content={currentContent} onTagChangeOrRemove={setCurrentContent}
                          previousIndexes={previousIndexes}
                          icon={'ri-crosshair-line'} selectOptions={[{ label: 'Button' }]}>
          <Typography.Text type={'secondary'}>
            for icon names, visit <a href="https://fonts.google.com/icons" className={'text-blue-500'}
                                     target="_blank">https://fonts.google.com/icons</a>
          </Typography.Text>
          <div>
            <div className="inline-block w-[calc((100%-34px)*2/5)]">
              <div>
                <Typography.Text type="secondary">Icon Name</Typography.Text>
              </div>
              <Input
                value={currentContent.icon}
                variant="borderless"
                className={`${underLinedInputClass} w-[100%]`}
                style={{ borderBottomStyle: 'solid', flex: 1 }}
                placeholder={'Google Material icon name'}
                onChange={(event) => {
                  setCurrentContent({ ...currentContent, icon: event.target.value });
                }}
              />
            </div>
            <Divider type={'vertical'} orientation={'center'} />
            <div className="inline-block w-[calc((100%-34px)*2/5)]">
              <div>
                <Typography.Text type="secondary">Button Text</Typography.Text>
              </div>
              <Input
                value={currentContent.title}
                variant="borderless"
                className={`${underLinedInputClass} w-[100%]`}
                style={{ borderBottomStyle: 'solid', flex: 1 }}
                onChange={(event) => {
                  setCurrentContent({ ...currentContent, title: event.target.value });
                }}
              />
            </div>
            <Divider type={'vertical'} orientation={'center'} />
            <div className="inline-block w-[calc((100%-34px)/5)]">
              {
                marginBottomSelect(currentContent, setCurrentContent)
              }
            </div>
          </div>
          <div>
            <div>
              <p>
                <span className={'mr-1'}>Navigation (JSON):</span>
                <Typography.Text italic type={'secondary'} className={'text-sm'}>Ask Developer for the
                  value</Typography.Text>
              </p>
            </div>
            <div>
              <JSONInput
                locale={locale}
                placeholder={currentContent.navigation}
                theme={'light_mitsuketa_tribute'}
                width={'100%'}
                height="auto"
                error={jsonError}
                style={{
                  contentBox: { cursor: 'text' },
                  body: { border: '1px lightgrey solid', borderRadius: '8px' },
                }}
                onBlur={(e: any) => {
                  if (!e.error) {
                    currentContent.navigation = e.jsObject;
                    setCurrentContent({ ...currentContent });
                    setJsonError(undefined);
                  } else {
                    setJsonError(e.error);
                  }
                }}
              />
            </div>

          </div>
        </DraggableWrapper>}
    </>

  );
};
const LinkContentEditor = ({ content, previousIndexes, index, onRemove, onUpdate }: EditorProp<LinkContent>) => {
  const [currentContent, setCurrentContent] = useState<LinkContent>();
  const [initDone, setInitDone] = useState(false);

  useEffect(() => {
    if (currentContent) {
      if (initDone) {
        onUpdate(currentContent, [...previousIndexes, index]);
      } else {
        setInitDone(true);
      }
    } else {
      if (initDone) {
        onRemove([...previousIndexes, index]);
      }
    }
  }, [currentContent]);

  useEffect(() => {
    setCurrentContent(content);
  }, [content]);

  return (
    <>
      {
        !!currentContent &&
        <DraggableWrapper index={index} content={currentContent} onTagChangeOrRemove={setCurrentContent}
                          previousIndexes={previousIndexes}
                          icon={'ri-link'} selectOptions={[{ label: 'Link' }]}>
          <div>
            <div className={'mt-1'}>
              <div>
                <Typography.Text type="secondary">Link URL</Typography.Text>
              </div>
              <Input
                defaultValue={currentContent.href}
                variant="borderless"
                className={`${underLinedInputClass} w-[100%]`}
                style={{ borderBottomStyle: 'solid', flex: 1 }}
                onChange={(event) => {
                  setCurrentContent({ ...currentContent, href: event.target.value });
                }}
              />
            </div>
            <div className="inline-block w-[calc((100%-17px)*2/3)]">
              <div>
                <Typography.Text type="secondary">Title</Typography.Text>
              </div>
              <Input
                defaultValue={currentContent.title}
                variant="borderless"
                className={`${underLinedInputClass} w-[100%]`}
                style={{ borderBottomStyle: 'solid', flex: 1 }}
                onChange={(event) => {
                  setCurrentContent({ ...currentContent, title: event.target.value });
                }}
              />
            </div>
            <Divider type={'vertical'} orientation={'center'} />
            <div className="inline-block w-[calc((100%-17px)/3)]">
              {
                marginBottomSelect(currentContent, setCurrentContent)
              }
            </div>
          </div>
        </DraggableWrapper>}
    </>

  );
};
const AccordionContentEditor = ({
                                  content,
                                  previousIndexes,
                                  index,
                                  language,
                                  onUpdate,
                                  onRemove,
                                }: EditorProp<AccordionContent>) => {
  const [currentContent, setCurrentContent] = useState<AccordionContent>();
  const [initDone, setInitDone] = useState(false);

  useEffect(() => {
    if (currentContent) {
      if (initDone) {
        onUpdate(currentContent, [...previousIndexes, index]);
      } else {
        setInitDone(true);
      }
    } else {
      if (initDone) {
        onRemove([...previousIndexes, index]);
      }
    }
  }, [currentContent]);

  useEffect(() => {
    setCurrentContent(content);
  }, [content]);

  return (
    <>
      {
        !!currentContent &&
        <DraggableWrapper index={index} content={currentContent} onTagChangeOrRemove={setCurrentContent}
                          previousIndexes={previousIndexes}
                          icon={'ri-stack-line'} selectOptions={[{ label: 'Accordion' }]}>
          <div>
            <div className="inline-block w-[calc((100%-17px)/4)]">
              <div>
                <Typography.Text type="secondary">Header</Typography.Text>
              </div>
              <Input
                value={(currentContent as AccordionContent).header}
                variant="borderless"
                className={`${underLinedInputClass} w-[100%]`}
                style={{ borderBottomStyle: 'solid', flex: 1 }}
                onChange={(event) => {
                  setCurrentContent({ ...currentContent, header: event.target.value });
                }}
              />
            </div>
            <Divider type={'vertical'} orientation={'center'} />
            <div className="inline-block w-[calc((100%-17px)3/4)]">
              {
                marginBottomSelect(currentContent, setCurrentContent)
              }
            </div>
          </div>
          <div className={`min-h-[50px] p-[8px]`}>
            {
              EditorRenderer(currentContent.content, [...previousIndexes, index], language, onUpdate, onRemove)
            }
          </div>
        </DraggableWrapper>}
    </>

  );
};

const contentDraggableStyle = (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined) => ({
  ...draggableStyle,
  marginBottom: 5,
});

const contentDroppableStyle = (isDraggingOver: boolean) => ({
  minHeight: '150px',
  background: 'var(--secondary-background)',
  width: '100%',
  padding: '8px 8px 32px 8px',
  border: isDraggingOver ? '4px dashed lightgreen' : 'none',
});

const accordionDroppableStyle = (snapshot: DroppableStateSnapshot) => {
  return {
    minHeight: '50px',
    padding: '8px',
    border: snapshot.isDraggingOver ? '2px dashed lightgreen' : 'none',
    background: 'rgba(128, 128, 128, 0.16)',
  };
};

export const EditorRenderer = (contents: ContentItem[], previousIndexes: number[], language: string, onUpdate: EditorProp<ContentItem>['onUpdate'], onRemove: EditorProp<ContentItem>['onRemove']) => {
  const indexChain = previousIndexes.join('-');
  const droppableId = `${language}-droppable${previousIndexes.length ? `@${indexChain}` : ''}`;
  const boilerplateProps = <T extends ContentItem>(index: number, content: T) => ({
    content: content,
    key: `editor-${droppableId}-${index}`,
    onUpdate, onRemove,
    index,
    previousIndexes,
    language,
  });

  const draggableChildren = contents.map((content, i) => {
    switch (content.tag) {
      case 'accordion':
        return <AccordionContentEditor {...boilerplateProps(i, content as AccordionContent)} />;
      case 'body':
      case 'bullet':
        return <DescriptiveContentEditor {...boilerplateProps(i, content as DescriptiveContent)} />;
      case 'link':
        return <LinkContentEditor {...boilerplateProps(i, content as LinkContent)} />;
      case 'h1':
      case 'h2':
      case 'h3':
      case 'h4':
      case 'h5':
        return <HeadingContentEditor {...boilerplateProps(i, content as HeadingContent)} />;
      case 'image':
        return <ImageContentEditor {...boilerplateProps(i, content as ImageContent)} />;
      case 'button':
        return <ButtonContentEditor  {...boilerplateProps(i, content as ButtonContent)} />;
    }
  });

  return <StrictModeDroppable
    key={droppableId}
    droppableId={droppableId}
  >
    {(dropProvided, dropSnapshot) => (
      <div
        ref={dropProvided.innerRef}
        style={
          previousIndexes.length > 0
            ? accordionDroppableStyle(dropSnapshot)
            : contentDroppableStyle(dropSnapshot.isDraggingOver)
        }
        {...dropProvided.droppableProps}
      >
        {draggableChildren}
        {dropProvided.placeholder}
      </div>
    )}
  </StrictModeDroppable>;
};
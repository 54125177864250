import { NewsPost } from 'bridge/news-post';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import {
  EditableAttribute,
  EditableAttributeDateType,
} from '../../../../components/inputs/editable-attribute.component';
import httpClient from '../../../../utils/http-client.util';
import { BackendAPI } from '../../../../constants/backend-api.enum';
import { popMessage } from '../../../../utils/pop-message.util';


type Prop = {
  onChange?: (newTags: string[]) => void
} & PropsWithChildren

export const NewsPostTagSetting = ({ onChange, children }: Prop) => {
  const [newsPostTags, setNewsPostTags] = useState<NewsPost['tags']>([]);
  const [open, setOpen] = useState(false);
  const [addingTag, setAddingTag] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const add = (newValue: EditableAttributeDateType | EditableAttributeDateType[], continuous?: boolean) => {
    if (newValue) {
      setNewsPostTags([...newsPostTags, newValue.toString().trim()]);
    }
    setAddingTag(false);
    setTimeout(() => setAddingTag(!!continuous));
  };

  const remove = (index: number) => {
    setNewsPostTags([...newsPostTags.filter((_, i) => i !== index)]);
  };

  const modify = (newValue: EditableAttributeDateType | EditableAttributeDateType[], index: number) => {
    if (newValue) {
      const newsPostTagsArray = [...newsPostTags];
      newsPostTagsArray[index] = newValue.toString();
      setNewsPostTags(newsPostTagsArray);
    }
  };

  const save = async () => {
    try {
      setSubmitting(true);
      await httpClient.post<string[]>(`${BackendAPI.NEWSPOST}/tags`, newsPostTags);
      if (onChange) {
        onChange(newsPostTags);
      }
      setOpen(false);
      popMessage.success('news post tags updated');
    } catch (e) {
      popMessage.error('unable to update news post tags');
    } finally {
      setSubmitting(false);
    }
  };

  const loadLatestTags = async () => {
    const tags = await httpClient.get<string[]>(`${BackendAPI.NEWSPOST}/tags`);
    setNewsPostTags(tags.data);
    if (onChange) {
      onChange(tags.data);
    }
  };

  useEffect(() => {
    if (open) {
      (async () => await loadLatestTags())();
    }
  }, [open]);

  useEffect(() => {
    (async () => await loadLatestTags())();
  }, []);

  return (
    <>
      <span className={'cursor-pointer'} onClick={() => setOpen(true)}>
        {children}
      </span>

      <Modal open={open} destroyOnClose title={'News Post Tags Configuration'}
        onOk={save} confirmLoading={submitting}
        maskClosable={false} onCancel={() => setOpen(false)} okText={'Save'}>
        <h5 className="text-gray-500">Available Tags:</h5>
        {
          newsPostTags.map((s, i) =>
            <div className={'flex justify-start items-baseline'} key={`location-service-${i}`}>
              <EditableAttribute indent canEdit={{ onSave: (newValue) => modify(newValue, i) }} value={s} label={''}
                type={'text'} />
              <i className="ri-delete-bin-3-line ms-6 text-red-500 hover:text-red-400 cursor-pointer"
                onClick={() => remove(i)} />
            </div>,
          )
        }
        {
          addingTag &&
          <EditableAttribute canEdit={{
            onSave: add, defaultInEditing: true,
            onEnterKey: (newValue) => add(newValue, true),
            onCancel: () => setAddingTag(false),
          }} value={''} label={'New Tag (press Enter for fast input)'} type={'text'} />
        }
        <Button className="mt-2" onClick={() => setAddingTag(true)}>+ New Tag</Button>
      </Modal>
    </>
  );
};
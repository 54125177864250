import React, { useEffect, useState } from 'react';
import './App.css';
import { Route, Routes, useLocation } from 'react-router-dom';
import httpClient from './utils/http-client.util';
import { BackendAPI } from './constants/backend-api.enum';
import Login from './pages/authentication/login';
import ResetPassword from './pages/authentication/reset-password';
import { SideNavigation } from './components/layout/side-navigation.component';
import { ApplicationHeader } from './components/layout/application-header.component';
import { ApplicationContent } from './components/layout/application-content.component';
import dayjs from 'dayjs';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { ChatSSEComponent } from './components/sse/chat-sse.component';
import { useBoundStore } from './states/bound.store';

dayjs.extend(utc);
dayjs.extend(timezone);

function App() {
  const displaySideNav = useBoundStore().displaySideNav;

  const location = useLocation();

  const [authenticated, setAuthenticated] = useState(false);
  const publicRoutes = [
    { url: '/login', element: <Login /> },
    { url: '/password-change', element: <ResetPassword type={'sendLink'} /> },
    { url: '/first-time-login', element: <ResetPassword type={'sendLink'} /> },
    { url: '/reset-password', element: <ResetPassword type={'doReset'} /> },
  ];

  useEffect(() => {
    liveCheck();
  }, [location]);

  const liveCheck = async () => {
    if (publicRoutes.map((pr) => pr.url).includes(location.pathname)) {
      return;
    }

    try {
      await httpClient.post(BackendAPI.PING);
      setAuthenticated(true);
    } catch (e) {
      setAuthenticated(false);
    }
  };

  return (
    <div className="App">
      <Routes>
        {publicRoutes.map((route, i) => (
          <Route key={i} path={route.url} element={route.element} />
        ))}
      </Routes>
      {authenticated && (
        <>
          <ChatSSEComponent />
          <SideNavigation />
          <main
            className={`w-full flex flex-col md:w-[calc(100%-256px)] md:ml-64 bg-gray-200 min-h-screen transition-all ${displaySideNav ? '' : 'active'}`}
          >
            <ApplicationHeader />
            <ApplicationContent />
          </main>
        </>
      )}
    </div>
  );
}

export default App;

import { Button, Col, Divider, Input, Radio, Row, Spin, Tooltip, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FromToDate } from '../components/between-date';
import { RadioChangeEvent } from 'antd/lib';
import { EntityCondition, EntityPromotionBonus } from 'bridge/promotion-bonus';
import { PreDonorsToNew } from './components/preDonorsToNew';
import { ApplicantDonors } from './components/applicantDonor';
import { RetentionDonors } from './components/retentionDonors';
import { LapsedDonors } from './components/lapsedDonors';
import { CustomGroup } from './components/customGroup';
import { AppliedCenters } from '../components/applied-centers';
import httpClient from '../../../utils/http-client.util';
import { BackendAPI } from '../../../constants/backend-api.enum';
import { popMessage } from '../../../utils/pop-message.util';
import {
  APPLICANTDONORS,
  CUSTOMEGROUP,
  DEFERRALDONORS,
  LAPSEDDONORS,
  PREDONORSTONEWDONORS,
  RETENTIONDONORS,
} from '../../../utils/enum';
import { fieldErrors } from '../functions/utils';
import { DeferralDonors } from './components/defferalDonors';

const { TextArea } = Input;

export const PromotionBonusDetailsPage = () => {
  const defaultPromotionBonus: EntityPromotionBonus = {
    _id: '',
    campaignId: '',
    campaignName: '',
    appliedCenter: [],
    campaignDescription: '',
    startDate: '',
    endDate: '',
    campaignType: '',
    status: '',
    donorGroup: [
      {
        name: '',
        donorGroupId: '',
      },
    ],
    condition: [
      {
        duration: [''],
        deferralType: '',
        donations: [
          {
            type: '',
            value: '',
          },
        ],
      },
    ],
  };
  const navigate = useNavigate();
  const location = useLocation();
  const { campaignId } = useParams();
  const [targetGroup, setTargetGroup] = useState<string>(PREDONORSTONEWDONORS);
  const [promotionBonusForm, setPromotionBonusForm] = useState<EntityPromotionBonus>(defaultPromotionBonus); // Gonna do next
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [newConditionData, setNewConditionData] = useState<EntityCondition[]>([]);

  const targetGroupOptions = [
    { label: PREDONORSTONEWDONORS, value: PREDONORSTONEWDONORS },
    { label: APPLICANTDONORS, value: APPLICANTDONORS },
    { label: RETENTIONDONORS, value: RETENTIONDONORS },
    { label: LAPSEDDONORS, value: LAPSEDDONORS },
    { label: DEFERRALDONORS, value: DEFERRALDONORS },
    { label: CUSTOMEGROUP, value: CUSTOMEGROUP },
  ];

  useEffect(() => {
    const stateData: EntityPromotionBonus = location.state?.feeStructureData;
    setPageLoading(true);
    if (!stateData) {
      (async () => {
        const response = await httpClient.get<EntityPromotionBonus>(`${BackendAPI.PROMOTIONBONUS}/get/${campaignId}`);
        let data = response.data;
        setTargetGroup(data.campaignType);
        setPromotionBonusForm(data);
        setPageLoading(false);
      })();
    } else {
      setTargetGroup(stateData.campaignType);
      setPromotionBonusForm(stateData);
      setPageLoading(false);
      window.history.replaceState({}, '');
    }
  }, []);

  const handleSubmit = async () => {
    promotionBonusForm.campaignType = targetGroup;
    promotionBonusForm.condition = newConditionData;
    try {
      setButtonLoading(true);
      let res = await httpClient.post(
        `${BackendAPI.PROMOTIONBONUS}/update/${promotionBonusForm.campaignId}`,
        promotionBonusForm
      );
      if (res.data?.status === 200) {
        popMessage.success('Promotion Bonus Updated Successfully');
        navigate(-1);
      } else {
        popMessage.warning(res.data?.message);
      }
    } catch (e) {
      popMessage.error('Unable to Updates Promotion Bonus');
    } finally {
      setButtonLoading(false);
    }
  };

  return (
    <div>
      <Spin spinning={pageLoading}>
        <div className="flex justify-between items-center">
          <Typography.Title level={3}>
            <span onClick={() => navigate('../list')}>Promotion Bonus</span>
            <Divider type={'vertical'} />
            <span>Details</span>
          </Typography.Title>
        </div>
        <div
          className="flex flex-col gap-[30px]"
          style={{ backgroundColor: 'var(--primary-foreground)', borderRadius: '5px', padding: '20px 40px' }}
        >
          <Row gutter={[30, 30]}>
            <Col xs={24} xl={16}>
              <div className="flex flex-col gap-4">
                <Typography.Text className="flex flex-col gap-1">
                  <span className="font-semibold">Campaign Name</span>
                  <Input
                    value={promotionBonusForm.campaignName}
                    onChange={(event) =>
                      setPromotionBonusForm({ ...promotionBonusForm, campaignName: event.target.value })
                    }
                  />
                </Typography.Text>
                <Typography.Text className="flex flex-col gap-1">
                  <span className="font-semibold">Campaign Description</span>
                  <TextArea
                    value={promotionBonusForm.campaignDescription}
                    onChange={(event) =>
                      setPromotionBonusForm({ ...promotionBonusForm, campaignDescription: event.target.value })
                    }
                  />
                </Typography.Text>
                <Typography.Text className="flex flex-col gap-1">
                  <span className="font-semibold">Campaign Time</span>
                  <FromToDate
                    onChangeFromDate={(date: string) => {
                      setPromotionBonusForm({ ...promotionBonusForm, startDate: date });
                    }}
                    onChangeToDate={(date: string) => {
                      setPromotionBonusForm({ ...promotionBonusForm, endDate: date });
                    }}
                    existFromDate={promotionBonusForm.startDate}
                    existToDate={promotionBonusForm.endDate}
                  />
                </Typography.Text>
              </div>
            </Col>
            <Col xs={24} xl={8}>
              <Typography.Text className="flex flex-col gap-1">
                <span className="font-semibold">Applied Centers</span>
                <AppliedCenters
                  onChangeCenter={(locations: string[]) => {
                    setPromotionBonusForm({ ...promotionBonusForm, appliedCenter: locations });
                  }}
                  existCenters={promotionBonusForm.appliedCenter}
                />
              </Typography.Text>
            </Col>
            <Col xs={24} xl={8}>
              <Typography.Text className="flex flex-col gap-1">
                <span className="font-semibold">Donor Group</span>
                <Radio.Group
                  className="flex flex-col"
                  style={{ width: 'fit-content' }}
                  options={targetGroupOptions}
                  onChange={(e: RadioChangeEvent) => {
                    setTargetGroup(e.target.value);
                  }}
                  value={targetGroup}
                />
              </Typography.Text>
            </Col>
          </Row>
          <div>
            {(() => {
              switch (targetGroup) {
                case PREDONORSTONEWDONORS:
                  return (
                    <PreDonorsToNew
                      conditionData={
                        promotionBonusForm.campaignType === PREDONORSTONEWDONORS ? promotionBonusForm.condition : []
                      }
                      onChangeData={(data: EntityCondition[]) => {
                        setNewConditionData(data);
                      }}
                    />
                  );
                case APPLICANTDONORS:
                  return (
                    <ApplicantDonors
                      conditionData={
                        promotionBonusForm.campaignType === APPLICANTDONORS ? promotionBonusForm.condition : []
                      }
                      onChangeData={(data: EntityCondition[]) => {
                        setNewConditionData(data);
                      }}
                    />
                  );
                case RETENTIONDONORS:
                  return (
                    <RetentionDonors
                      conditionData={
                        promotionBonusForm.campaignType === RETENTIONDONORS ? promotionBonusForm.condition : []
                      }
                      onChangeData={(data: EntityCondition[]) => {
                        setNewConditionData(data);
                      }}
                    />
                  );
                case LAPSEDDONORS:
                  return (
                    <LapsedDonors
                      conditionData={
                        promotionBonusForm.campaignType === LAPSEDDONORS ? promotionBonusForm.condition : []
                      }
                      onChangeData={(data: EntityCondition[]) => {
                        setNewConditionData(data);
                      }}
                    />
                  );

                case DEFERRALDONORS:
                  return (
                    <DeferralDonors
                      conditionData={
                        promotionBonusForm.campaignType === DEFERRALDONORS ? promotionBonusForm.condition : []
                      }
                      onChangeData={(data: EntityCondition[]) => {
                        setNewConditionData(data);
                      }}
                    />
                  );
                case CUSTOMEGROUP:
                  return (
                    <CustomGroup
                      conditionData={
                        promotionBonusForm.campaignType === CUSTOMEGROUP ? promotionBonusForm.condition : []
                      }
                      onChangeData={(data: EntityCondition[]) => {
                        setNewConditionData(data);
                      }}
                    />
                  );
                default:
                  return null;
              }
            })()}
          </div>
          {promotionBonusForm.status === 'expired' ? undefined : (
            <div className="flex items-center justify-center">
              <Tooltip
                title={fieldErrors(promotionBonusForm, newConditionData, targetGroup).join(', ')}
                defaultOpen={!!fieldErrors(promotionBonusForm, newConditionData, targetGroup).length}
                color={'#ef5350'}
                placement={'right'}
              >
                <Button
                  size={'large'}
                  disabled={!!fieldErrors(promotionBonusForm, newConditionData, targetGroup).length}
                  onClick={handleSubmit}
                  loading={buttonLoading}
                >
                  Update
                </Button>
              </Tooltip>
            </div>
          )}
        </div>
      </Spin>
    </div>
  );
};

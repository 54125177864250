import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { popMessage } from '../../../utils/pop-message.util';
import { Choice, Question, Survey } from 'bridge/survey';
import {
  Alert,
  Button,
  Card,
  DatePicker,
  Divider,
  Dropdown,
  Input,
  InputNumber,
  Popconfirm,
  Select,
  Space,
  Switch,
  Tabs,
  Tooltip,
  Typography,
} from 'antd';
import { underLinedInputClass } from '../../../components/inputs/editable-attribute.component';
import { supportingLanguageMap, supportingLanguages } from '../../../constants/language.constant';
import dayjs from 'dayjs';
import { TargetDonorFilter } from '../shared-component/target-donor-filter.component';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  DragStart,
  DropResult,
} from '@erom/react-beautiful-dnd';
import { StrictModeDroppable } from '../../../utils/strict-mode-dnd.util';
import { MenuInfo } from 'rc-menu/lib/interface';
import { QuestionLibrary } from './component/question-library.component';
import OutsideClickHandler from 'react-outside-click-handler';
import { QuestionPreview } from './component/question-preview.component';
import { SurveyPreview } from './component/survey-preview.component';
import httpClient from '../../../utils/http-client.util';
import { BackendAPI } from '../../../constants/backend-api.enum';

type Props = {
  mode: 'edit' | 'template' | 'new';
};
export const UpsertSurveyPage = ({ mode }: Props) => {
  const [theSurvey, setTheSurvey] = useState<Partial<Survey>>({});
  const [loading, setLoading] = useState(false);
  const [alreadyPublished, setAlreadyPublished] = useState(false);
  const [currentEditLanguage, setCurrentEditingLanguage] = useState('');
  const [currentDraggingFrom, setCurrentDraggingFrom] = useState('');
  const [focusingQuestionIndex, setFocusingQuestionIndex] = useState(-1);
  const [publishDateChanged, setPublishDateChanged] = useState(false);
  const [surveyCompositionStatus, setSurveyCompositionStatus] = useState({ filterNotDone: false, emptyEntry: false });

  const { surveyId } = useParams();
  const routerLocation = useLocation();
  const navigation = useNavigate();

  const addNewQuestionButtonItems = {
    items: [
      {
        label: 'Create New Question With:',
        key: 'disabled',
        disabled: true,
      },
      {
        label: 'Only One Answer Allowed',
        key: 'choose-one',
        icon: <i className={'ri-radio-button-line'} />,
      },
      {
        label: 'Many Answers Allowed',
        key: 'choose-many',
        icon: <i className={'ri-checkbox-line'} />,
      },
      {
        label: 'Text-based Answer',
        key: 'free-input',
        icon: <i className={'ri-input-field'} />,
      },
    ],
    onClick: (e: MenuInfo): void => {
      const questions = [...(theSurvey.questions || [])];
      questions.push({
        saveMe: true,
        type: e.key as Question['type'],
        question: Object.keys(theSurvey.title || {})
          .map((lang) => ({ [lang]: '' }))
          .reduce((acc, cur) => ({ ...acc, ...cur }), {}),
        choices: [],
      });
      setTheSurvey({ ...theSurvey, questions });
    },
  };

  const addNewQuestionFromLib = (q: Question) => {
    const questions = [...(theSurvey.questions || [])];
    questions.push(q);
    setTheSurvey({ ...theSurvey, questions });
  };

  const questionDroppableStyle = (isDraggingOver: boolean) => ({
    minHeight: '150px',
    background: 'var(--secondary-background)',
    width: '100%',
    padding: '8px',
    border: isDraggingOver ? '4px dashed lightgreen' : 'none',
  });

  const choiceDroppableStyle = (isDraggingOver: boolean) => ({
    border: isDraggingOver ? '2px dashed lightgreen' : 'none',
  });

  const questionDraggableStyle = (
    questionDraggableProvided: DraggableProvided,
    questionDraggableStateSnapshot: DraggableStateSnapshot
  ) => ({
    ...questionDraggableProvided.draggableProps.style,
    height: questionDraggableStateSnapshot.isDragging ? '30px' : 'auto',
    'overflow-y': questionDraggableStateSnapshot.isDragging ? 'hidden' : 'unset',
  });

  const addChoice = (questionIndex: number) => {
    const questions = [...(theSurvey.questions || [])];
    const choices = questions[questionIndex].choices || [];
    choices.push({ content: {} });
    questions[questionIndex].choices = choices;
    setTheSurvey({ ...theSurvey, questions });
  };

  const deleteChoice = (choiceId: string) => {
    const [questionIndex, choiceIndex] = choiceId
      .split('-')
      .map((e) => parseInt(e))
      .filter((e) => !isNaN(e));
    const questions = [...(theSurvey.questions || [])];
    const choices = [...(questions[questionIndex].choices || [])];
    choices.splice(choiceIndex, 1);
    questions[questionIndex].choices = choices;
    setTheSurvey({ ...theSurvey, questions });
  };

  const onDragStart = (start: DragStart) => {
    const currentDroppableId = start.draggableId.replace(/-\d+$/, '') + 's';
    console.log(currentDroppableId);
    setCurrentDraggingFrom(currentDroppableId);
  };

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    const questions = [...(theSurvey.questions || [])];
    if (source.droppableId === destination.droppableId) {
      if (source.droppableId === 'survey-contents') {
        const [removed] = questions.splice(source.index, 1);
        questions.splice(destination.index, 0, removed);
      } else {
        const questionIndex = parseInt(source.droppableId.split('-')[1]);
        const choices = [...(questions[questionIndex].choices || [])];
        const [removed] = choices.splice(source.index, 1);
        choices.splice(destination.index, 0, removed);
        questions[questionIndex].choices = choices;
      }
    } else {
      // only support same droppable area
      return;
    }
    setTheSurvey({ ...theSurvey, questions });
  };

  const submitSurvey = async () => {
    try {
      let msg;

      if (mode === 'edit') {
        await httpClient.put(`${BackendAPI.SURVEY}/${surveyId}`, theSurvey);
        msg = 'Survey Updated. Redirect back to survey list in 3 seconds';
      } else {
        await httpClient.post(BackendAPI.SURVEY, theSurvey);
        msg = 'Survey Created. Redirect back to survey list in 3 seconds';
      }

      popMessage.success({
        content: msg,
        duration: 3,
        onCountdownEnd: () => navigation('../'),
      });
    } catch (e) {
      popMessage.error('Failed to submit survey');
    }
  };

  const checkTitleEmpty = () => {
    return !theSurvey.title || Object.values(theSurvey.title).some((v) => !v);
  };
  const checkQuestionEmpty = () => {
    return (
      !theSurvey.questions ||
      theSurvey.questions.length === 0 ||
      theSurvey.questions.some((q) => !q.question || Object.values(q.question).some((v) => !v))
    );
  };

  const checkChoiceEmpty = () => {
    return (
      !theSurvey.questions ||
      theSurvey.questions.length === 0 ||
      theSurvey.questions.some(
        (q) =>
          q.type !== 'free-input' &&
          (!q.choices ||
            q.choices.length === 0 ||
            q.choices.some((c) => !c.content || Object.values(c.content).some((v) => !v)))
      )
    );
  };

  useEffect(() => {
    if (mode !== 'new') {
      const inStateSurvey: Survey = routerLocation.state?.survey || null;
      if (!inStateSurvey || inStateSurvey.surveyId !== surveyId) {
        (async () => {
          try {
            setLoading(true);
            const targetingSurvey = await httpClient.get<Survey>(`${BackendAPI.SURVEY}/${surveyId}`);
            setTheSurvey(targetingSurvey.data);
          } catch (e) {
            popMessage.error('Unable to fetch survey data');
          } finally {
            setLoading(false);
          }
        })();
      } else {
        setTheSurvey(inStateSurvey);
        window.history.replaceState({}, '');
      }
    }
    if (mode === 'edit') {
      setSurveyCompositionStatus({ filterNotDone: false, emptyEntry: false });
    }
  }, []);

  useEffect(() => {
    if (mode !== 'edit' && theSurvey.surveyId) {
      delete theSurvey.surveyId;
      delete theSurvey.publishDate;
      delete theSurvey.expirationDate;
    }
    const filterNotDone = !theSurvey.participantFilter;
    if (mode === 'edit') {
      if (theSurvey && theSurvey.publishDate && !publishDateChanged) {
        setAlreadyPublished(new Date(theSurvey.publishDate).getTime() <= new Date().getTime());
      }
    }
    if (theSurvey && theSurvey.title && !currentEditLanguage) {
      setCurrentEditingLanguage(Object.keys(theSurvey.title)[0]);
    }

    const emptyEntry = checkTitleEmpty() || checkQuestionEmpty() || checkChoiceEmpty();

    setSurveyCompositionStatus({ filterNotDone, emptyEntry });
  }, [theSurvey, publishDateChanged]);

  return (
    <div className={'bg-primary-foreground p-3'}>
      {loading ? (
        <div className={'fixed top-0 left-0 w-full h-full flex justify-center items-center'}>
          <i className={'ri-loader-4-line text-6xl text-white animate-spin'} />
        </div>
      ) : (
        <div className={'flex justify-between items-center'}>
          <div className={'w-[75%] h-[calc(100vh-30px)] flex flex-col'}>
            <Typography.Title level={3} className={'flex items-center'}>
              <Link to={'../'} className={'!text-primary hover:!opacity-55'}>
                Survey
              </Link>
              <Divider type={'vertical'} className={'transform rotate-12'} />
              <span className="text-[#f84525] rounded-md">{mode !== 'edit' ? 'New' : 'Edit'}</span>
            </Typography.Title>
            {alreadyPublished && (
              <Alert message={'Survey is published, you cannot edit it'} type={'warning'} showIcon className={'mb-2'} />
            )}
            <div className={'flex-grow-[1] px-4'}>
              {theSurvey.title ? (
                <Tabs activeKey={currentEditLanguage} onChange={(key) => setCurrentEditingLanguage(key)}>
                  {Object.keys(theSurvey.title).map((lang) => (
                    <Tabs.TabPane key={lang} tab={supportingLanguageMap.get(lang)}>
                      <div className={'mb-2 flex items-end'}>
                        <div className={'w-[80%]'}>
                          <div>
                            <Typography.Text type="secondary">Survey Title</Typography.Text>
                          </div>
                          <Input
                            value={theSurvey.title ? theSurvey.title[lang] : ''}
                            variant="borderless"
                            placeholder={`Please Provide Survey Title in ${supportingLanguageMap.get(lang)}`}
                            className={`${underLinedInputClass} w-[100%]`}
                            style={{ borderBottomStyle: 'solid' }}
                            disabled={alreadyPublished}
                            onChange={(e) => {
                              if (theSurvey.title) {
                                theSurvey.title[lang] = e.target.value;
                              } else {
                                theSurvey.title = { [lang]: e.target.value };
                              }
                              setTheSurvey({ ...theSurvey });
                            }}
                          />
                        </div>
                        <Divider type={'vertical'} />
                        <div className={'flex-grow-[1]'}></div>
                      </div>
                      <div className={'h-[calc(100vh-200px)] overflow-y-auto'}>
                        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
                          <StrictModeDroppable
                            key={'survey-contents'}
                            droppableId={'survey-contents'}
                            isDropDisabled={currentDraggingFrom !== 'survey-contents'}
                          >
                            {(dropProvided, dropSnapshot) => (
                              <div
                                ref={dropProvided.innerRef}
                                {...dropProvided.droppableProps}
                                style={questionDroppableStyle(dropSnapshot.isDraggingOver)}
                              >
                                {theSurvey.questions?.map((q, qIndex) => (
                                  <Draggable
                                    key={`q-${qIndex}`}
                                    draggableId={`survey-content-${qIndex}`}
                                    index={qIndex}
                                    isDragDisabled={alreadyPublished}
                                  >
                                    {(
                                      questionDraggableProvided: DraggableProvided,
                                      questionDraggableStateSnapshot: DraggableStateSnapshot
                                    ) => (
                                      <div
                                        ref={questionDraggableProvided.innerRef}
                                        {...questionDraggableProvided.draggableProps}
                                        style={questionDraggableStyle(
                                          questionDraggableProvided,
                                          questionDraggableStateSnapshot
                                        )}
                                      >
                                        <OutsideClickHandler onOutsideClick={() => setFocusingQuestionIndex(-1)}>
                                          <Card
                                            hoverable
                                            className={'cursor-auto group mb-2 pt-0 pb-5'}
                                            onClick={() => {
                                              setFocusingQuestionIndex(qIndex);
                                            }}
                                          >
                                            <div
                                              className={'absolute top-1 left-1'}
                                              style={{ opacity: questionDraggableStateSnapshot.isDragging ? 100 : 0 }}
                                            >
                                              Question {qIndex + 1}
                                            </div>
                                            <div
                                              {...questionDraggableProvided.dragHandleProps}
                                              className={'absolute top-0 left-0 right-0 text-center'}
                                            >
                                              <i className={'ri-draggable text-lg'} />
                                            </div>
                                            <div className={'flex'}>
                                              <div className={'w-[70%] pr-2'}>
                                                <div className={'mb-2'}>
                                                  <span className={`text-gray-300 absolute top-7 left-2`}>
                                                    {qIndex + 1}.
                                                  </span>
                                                  <Input
                                                    value={q.question ? q.question[lang] : ''}
                                                    variant="borderless"
                                                    className={`${underLinedInputClass} w-[100%]`}
                                                    style={{ borderBottomStyle: 'solid' }}
                                                    disabled={alreadyPublished}
                                                    autoFocus
                                                    placeholder={`Question of ${q.type} answer`}
                                                    onChange={(e) => {
                                                      if (q.question) {
                                                        q.question[lang] = e.target.value;
                                                      } else {
                                                        q.question = { [lang]: e.target.value };
                                                      }
                                                      setTheSurvey({ ...theSurvey });
                                                    }}
                                                  />
                                                </div>
                                                <div className={'mb-2'}>
                                                  {q.type !== 'free-input' ? (
                                                    <div>
                                                      <Typography.Text type="secondary" className={'block'}>
                                                        Choices
                                                      </Typography.Text>
                                                      <StrictModeDroppable
                                                        droppableId={`question-${qIndex}-choices`}
                                                        isDropDisabled={
                                                          currentDraggingFrom !== `question-${qIndex}-choices`
                                                        }
                                                      >
                                                        {(choicesDropProvided, choiceDropSnapshot) => (
                                                          <div
                                                            ref={choicesDropProvided.innerRef}
                                                            {...choicesDropProvided.droppableProps}
                                                            style={choiceDroppableStyle(
                                                              choiceDropSnapshot.isDraggingOver
                                                            )}
                                                          >
                                                            {q.choices?.map((c, cIndex) => (
                                                              <Draggable
                                                                key={`q-${qIndex}-c-${cIndex}`}
                                                                draggableId={`question-${qIndex}-choice-${cIndex}`}
                                                                index={cIndex}
                                                                isDragDisabled={alreadyPublished}
                                                              >
                                                                {(
                                                                  choiceDragProvided: DraggableProvided,
                                                                  _: DraggableStateSnapshot
                                                                ) => (
                                                                  <div
                                                                    ref={choiceDragProvided.innerRef}
                                                                    {...choiceDragProvided.draggableProps}
                                                                    className={'mb-4 ml-2 group/choices relative'}
                                                                  >
                                                                    <i
                                                                      {...choiceDragProvided.dragHandleProps}
                                                                      className={
                                                                        'absolute ri-draggable opacity-0 -left-4 top-3 group-hover/choices:opacity-100'
                                                                      }
                                                                    />
                                                                    <div className={'flex items-end'}>
                                                                      {q.type === 'choose-one' ? (
                                                                        <i className={'ri-radio-button-line'} />
                                                                      ) : (
                                                                        <i className={'ri-checkbox-line'} />
                                                                      )}
                                                                      <Input
                                                                        value={c.content ? c.content[lang] : ''}
                                                                        variant="borderless"
                                                                        className={`${underLinedInputClass} w-fit ml-1`}
                                                                        style={{ borderBottomStyle: 'solid' }}
                                                                        disabled={alreadyPublished}
                                                                        onChange={(e) => {
                                                                          if (c.content) {
                                                                            c.content[lang] = e.target.value;
                                                                          } else {
                                                                            c.content = { [lang]: e.target.value };
                                                                          }
                                                                          setTheSurvey({ ...theSurvey });
                                                                        }}
                                                                      />
                                                                      <i
                                                                        style={{
                                                                          pointerEvents: alreadyPublished
                                                                            ? 'none'
                                                                            : 'auto',
                                                                          opacity: alreadyPublished ? 0 : undefined,
                                                                        }}
                                                                        className={
                                                                          'ri-delete-bin-3-line relative -top-1 opacity-0 text-red-500 cursor-pointer group-hover/choices:opacity-100'
                                                                        }
                                                                        onClick={() =>
                                                                          deleteChoice(`q-${qIndex}-c-${cIndex}`)
                                                                        }
                                                                      />
                                                                      {q.type === 'choose-many' && (
                                                                        <>
                                                                          <div className={'flex flex-col items-center'}>
                                                                            <div>
                                                                              <Typography.Text type="secondary">
                                                                                Exclusive
                                                                              </Typography.Text>
                                                                            </div>
                                                                            <Switch
                                                                              disabled={alreadyPublished}
                                                                              checked={c.exclusive}
                                                                              size={'small'}
                                                                              onChange={(checked) => {
                                                                                c.exclusive = checked;
                                                                                setTheSurvey({ ...theSurvey });
                                                                              }}
                                                                            />
                                                                          </div>
                                                                          <Divider type={'vertical'} />
                                                                        </>
                                                                      )}
                                                                      <div className={'flex flex-col items-center'}>
                                                                        <div>
                                                                          <Typography.Text type="secondary">
                                                                            Extra Input
                                                                          </Typography.Text>
                                                                        </div>
                                                                        <Switch
                                                                          size={'small'}
                                                                          disabled={alreadyPublished}
                                                                          checked={!!c.withInput}
                                                                          onChange={(checked) => {
                                                                            if (checked) {
                                                                              c.withInput = {
                                                                                placeholder: { [lang]: '' },
                                                                              };
                                                                            } else {
                                                                              c.withInput = undefined;
                                                                            }
                                                                            setTheSurvey({ ...theSurvey });
                                                                          }}
                                                                        />
                                                                      </div>
                                                                      {
                                                                        // input for extra input placeholder
                                                                        c.withInput && (
                                                                          <Input
                                                                            value={
                                                                              c.withInput.placeholder
                                                                                ? c.withInput.placeholder[lang]
                                                                                : ''
                                                                            }
                                                                            variant="borderless"
                                                                            placeholder={'Extra Input Placeholder'}
                                                                            className={`${underLinedInputClass} w-fit`}
                                                                            style={{ borderBottomStyle: 'solid' }}
                                                                            disabled={alreadyPublished}
                                                                            onChange={(e) => {
                                                                              if (c.withInput) {
                                                                                if (c.withInput.placeholder) {
                                                                                  c.withInput.placeholder[lang] =
                                                                                    e.target.value;
                                                                                } else {
                                                                                  c.withInput.placeholder = {
                                                                                    [lang]: e.target.value,
                                                                                  };
                                                                                }
                                                                                setTheSurvey({ ...theSurvey });
                                                                              }
                                                                            }}
                                                                          />
                                                                        )
                                                                      }
                                                                    </div>
                                                                  </div>
                                                                )}
                                                              </Draggable>
                                                            ))}
                                                            {choicesDropProvided.placeholder}
                                                          </div>
                                                        )}
                                                      </StrictModeDroppable>
                                                      <Button
                                                        disabled={alreadyPublished}
                                                        block
                                                        size={'small'}
                                                        onClick={() => addChoice(qIndex)}
                                                      >
                                                        Add Choice
                                                      </Button>
                                                    </div>
                                                  ) : (
                                                    <div className={'flex'}>
                                                      <Typography.Text type="secondary">
                                                        Text Area Placeholder
                                                      </Typography.Text>
                                                      <Input
                                                        value={q.placeholder ? q.placeholder[lang] : ''}
                                                        variant="borderless"
                                                        placeholder={'Placeholder'}
                                                        className={`${underLinedInputClass} w-[100%]`}
                                                        style={{ borderBottomStyle: 'solid' }}
                                                        disabled={alreadyPublished}
                                                        onChange={(e) => {
                                                          if (q.placeholder) {
                                                            q.placeholder[lang] = e.target.value;
                                                          } else {
                                                            q.placeholder = { [lang]: e.target.value };
                                                          }
                                                          setTheSurvey({ ...theSurvey });
                                                        }}
                                                      />
                                                    </div>
                                                  )}
                                                </div>
                                              </div>
                                              <div className={'w-[30%] p-2 bg-secondary-background question-preview'}>
                                                <QuestionPreview
                                                  q={q}
                                                  qIndex={qIndex}
                                                  currentEditLanguage={currentEditLanguage}
                                                />
                                              </div>
                                            </div>
                                            <Space
                                              className={
                                                'absolute mx-6 mb-1 bottom-2 left-0 right-0 opacity-0 group-hover:!opacity-100 flex'
                                              }
                                              style={{
                                                opacity: focusingQuestionIndex === qIndex ? 1 : 0,
                                              }}
                                            >
                                              <div>
                                                <Typography.Text type={'secondary'}>Type:</Typography.Text>
                                                <Select
                                                  disabled={alreadyPublished}
                                                  popupMatchSelectWidth={false}
                                                  variant="borderless"
                                                  className={`${underLinedInputClass}`}
                                                  style={{ borderBottomStyle: 'solid' }}
                                                  options={addNewQuestionButtonItems.items
                                                    .filter((item) => !item.disabled)
                                                    .map((item) => ({
                                                      value: item.key,
                                                      label: item.label,
                                                    }))}
                                                  size={'small'}
                                                  value={q.type}
                                                  onChange={(e) => {
                                                    q.type = e;
                                                    setTheSurvey({ ...theSurvey });
                                                  }}
                                                />
                                              </div>
                                              <Button
                                                disabled={alreadyPublished}
                                                shape={'round'}
                                                size={'small'}
                                                icon={<i className={'ri-file-copy-line'} />}
                                                onClick={() => {
                                                  const questions = [...(theSurvey.questions || [])];
                                                  questions.push({ ...JSON.parse(JSON.stringify(q)), saveMe: true });
                                                  setTheSurvey({ ...theSurvey, questions });
                                                }}
                                              >
                                                Duplicate
                                              </Button>
                                              <Popconfirm
                                                title={'Delete This Question?'}
                                                onConfirm={() => {
                                                  const questions = [...(theSurvey.questions || [])];
                                                  questions.splice(qIndex, 1);
                                                  setTheSurvey({ ...theSurvey, questions });
                                                }}
                                              >
                                                <Button
                                                  disabled={alreadyPublished}
                                                  size={'small'}
                                                  danger
                                                  icon={<i className={'ri-delete-bin-3-line'} />}
                                                >
                                                  Delete
                                                </Button>
                                              </Popconfirm>
                                            </Space>
                                            {q.saveMe && (
                                              <Tooltip
                                                title={'This question will be saved to library after survey submission'}
                                              >
                                                <div className="w-3 h-3 absolute bottom-4 right-4 flex justify-center items-center text-sky-200 rounded-lg border-sky-200 bg-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_15px_#08f,0_0_30px_#08f]" />
                                              </Tooltip>
                                            )}
                                          </Card>
                                        </OutsideClickHandler>
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                                {dropProvided.placeholder}
                                <Dropdown.Button
                                  type={'primary'}
                                  className={'justify-center mt-8'}
                                  trigger={['click']}
                                  menu={addNewQuestionButtonItems}
                                  buttonsRender={([leftButton, rightButton]) => [
                                    <QuestionLibrary onAddToSurvey={addNewQuestionFromLib}>
                                      {leftButton}
                                    </QuestionLibrary>,
                                    React.cloneElement(rightButton as React.ReactElement<any, string>, {
                                      icon: 'or',
                                      type: 'default',
                                    }),
                                  ]}
                                >
                                  Append Question From Library
                                </Dropdown.Button>
                              </div>
                            )}
                          </StrictModeDroppable>
                        </DragDropContext>
                      </div>
                    </Tabs.TabPane>
                  ))}
                </Tabs>
              ) : (
                <Alert message={'Please select a language to start'} type="info" showIcon />
              )}
            </div>
          </div>
          <div className={'w-[25%] h-[calc(100vh-130px)] p-2'}>
            <Card type={'inner'} hoverable className={'mb-2'}>
              <div className={'mb-4'}>
                <div>
                  <Typography.Text type="secondary">Languages</Typography.Text>
                </div>
                <Select
                  mode={'multiple'}
                  showSearch
                  variant="borderless"
                  value={Object.keys(theSurvey?.title || {})}
                  className={`${underLinedInputClass} w-[100%]`}
                  style={{ borderBottomStyle: 'solid', flex: 1 }}
                  options={supportingLanguages}
                  disabled={alreadyPublished}
                  onChange={(selected) => {
                    if (!currentEditLanguage) {
                      setCurrentEditingLanguage(selected[0]);
                    }
                    const existingTitle = theSurvey.title || {};

                    const newTitle: Survey['title'] = {};
                    selected.forEach((s: string) => {
                      if (!existingTitle[s]) {
                        newTitle[s] = '';
                      } else {
                        newTitle[s] = existingTitle[s];
                      }
                    });
                    theSurvey.title = newTitle;

                    theSurvey.questions?.forEach((q) => {
                      const existingQuestion = q.question || {};
                      const newQuestion: Question['question'] = {};
                      selected.forEach((s: string) => {
                        if (!existingQuestion[s]) {
                          newQuestion[s] = '';
                        } else {
                          newQuestion[s] = existingQuestion[s];
                        }
                      });
                      q.question = newQuestion;
                      q.choices?.forEach((c) => {
                        const existingChoice = c.content || {};
                        const newChoice: Choice['content'] = {};
                        selected.forEach((s: string) => {
                          if (!existingChoice[s]) {
                            newChoice[s] = '';
                          } else {
                            newChoice[s] = existingChoice[s];
                          }
                        });
                        c.content = newChoice;
                      });
                    });
                    setTheSurvey({ ...theSurvey });
                  }}
                />
              </div>
              <div className={'mb-4 text-center'}>
                {!alreadyPublished ? (
                  <>
                    <div>
                      <Typography.Text type="secondary">Publish Immediately</Typography.Text>
                    </div>
                    <Switch
                      checkedChildren={'Yes'}
                      unCheckedChildren={'No'}
                      checked={!!theSurvey.publishDate}
                      disabled={alreadyPublished}
                      onChange={(checked) => {
                        theSurvey.publishDate = checked ? new Date() : undefined;
                        setTheSurvey({ ...theSurvey });
                        setPublishDateChanged(true);
                      }}
                    />
                  </>
                ) : (
                  <div>
                    <Typography.Text type="secondary">Publish Date</Typography.Text>
                    <div>
                      <Typography.Text>{dayjs(theSurvey.publishDate).format('YYYY-MM-DD hh:mm a')}</Typography.Text>
                    </div>
                  </div>
                )}
              </div>
              <div className={'mb-4'}>
                <div>
                  <Typography.Text type="secondary">Expiration Date</Typography.Text>
                </div>
                <DatePicker
                  variant="borderless"
                  placeholder="Never Expires"
                  className={`${underLinedInputClass} w-[100%]`}
                  style={{ borderBottomStyle: 'solid', flex: 1 }}
                  disabled={alreadyPublished}
                  allowClear
                  value={theSurvey.expirationDate ? dayjs(theSurvey.expirationDate) : undefined}
                  disabledDate={(current) => current < dayjs()}
                  onChange={(selected) => {
                    theSurvey.expirationDate = selected ? selected.toDate() : undefined;
                    setTheSurvey({ ...theSurvey });
                  }}
                />
              </div>
              <div className={'mb-1'}>
                <div>
                  <Typography.Text type="secondary">Reward Points For Completion</Typography.Text>
                </div>
                <InputNumber
                  value={theSurvey.rewardWithPoints || undefined}
                  variant="borderless"
                  size="middle"
                  className={`${underLinedInputClass} w-[100%]`}
                  style={{ borderBottomStyle: 'solid' }}
                  disabled={alreadyPublished}
                  placeholder={'No Reward Points'}
                  min={0}
                  onChange={(e) => {
                    if (e) {
                      theSurvey.rewardWithPoints = e;
                      setTheSurvey({ ...theSurvey });
                    } else {
                      theSurvey.rewardWithPoints = false;
                      setTheSurvey({ ...theSurvey });
                    }
                  }}
                />
              </div>
            </Card>
            <Card type={'inner'} hoverable>
              <TargetDonorFilter
                existingFilter={theSurvey.participantFilter}
                disabled={alreadyPublished}
                onConfirm={(newFilter) => {
                  setTheSurvey({ ...theSurvey, participantFilter: newFilter });
                }}
                title={'Participant Filter'}
              />
            </Card>
            <SurveyPreview
              survey={theSurvey as Survey}
              disabled={surveyCompositionStatus.emptyEntry || surveyCompositionStatus.filterNotDone || alreadyPublished}
              confirmButtonText={mode === 'edit' ? 'Update Survey' : 'Create New Survey'}
              confirmButtonType={mode === 'edit' ? 'primary' : 'danger'}
              confirmWithAction={submitSurvey}
            >
              <Tooltip
                open={surveyCompositionStatus.emptyEntry || surveyCompositionStatus.filterNotDone ? undefined : false}
                placement={'left'}
                title={
                  <>
                    <div>{surveyCompositionStatus.emptyEntry && 'Survey has empty entry'}</div>
                    <div>{surveyCompositionStatus.filterNotDone && 'Participant Filter is not set'}</div>
                  </>
                }
              >
                <Button
                  className={`mt-6 float-right shadow-2xl disabled:!bg-gray-300 disabled:!text-gray-400 disabled:!border-none`}
                  type={'primary'}
                  shape={'round'}
                  danger={mode !== 'edit'}
                  size={'large'}
                  disabled={
                    surveyCompositionStatus.emptyEntry || surveyCompositionStatus.filterNotDone || alreadyPublished
                  }
                >
                  {mode === 'edit' ? 'Update Survey' : 'Create New Survey'}
                </Button>
              </Tooltip>
            </SurveyPreview>
          </div>
        </div>
      )}
    </div>
  );
};

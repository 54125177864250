import { Button, Checkbox, Divider, Input, Modal, Select, Tooltip, Typography } from 'antd';
import CoverImageAndThumbnailsUpload from './components/cover-image-and-thumbnails-upload.component';
import { TextWithIcon, underLinedInputClass } from '../../components/inputs/editable-attribute.component';
import { LocationServiceConfig } from './components/location-service-config.component';
import { OperationHoursSetting } from './components/operation-hours-setting.component';
import React, { useState } from 'react';
import { FacilityLocation } from 'bridge/location';
import { popMessage } from '../../utils/pop-message.util';
import httpClient from '../../utils/http-client.util';
import { BackendAPI } from '../../constants/backend-api.enum';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import { Link, useNavigate } from 'react-router-dom';
import { USStates, USTimezone } from '../../constants/united-states.constant';

export const CreateLocationPage = () => {
  const defaultLocationData: FacilityLocation = {
    _id: '',
    locationId: '',
    name: '',
    address: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      country: 'USA',
      zipcode: '',
    },
    phone: {
      countryCode: '1',
      number: '',
    },
    operationHours: {
      sun: false,
      mon: false,
      tue: false,
      wed: false,
      thu: false,
      fri: false,
      sat: false,
    },
    emails: [''],
    coordinates: ['', ''],
    tz: '' as any,
    status: 'active',
    coverImage: '',
    thumbnails: [],
    services: [],
    exceptionDays: {},
    appointmentPolicy: {},
    queuePolicy: {},
  };
  const [newLocationData, setNewLocationData] = useState<FacilityLocation>(defaultLocationData);
  const [availableServices, setAvailableServices] = useState<TextWithIcon[]>([]);
  const [loading, setLoading] = useState(false);
  const [idValidationStatus, setIdValidationStatus] = useState<'checking' | 'good' | 'duplicated' | 'too-short'>(
    'too-short'
  );
  const [idValidationDebouncing, setIdValidationDebouncing] = useState<NodeJS.Timeout>();

  const navigate = useNavigate();

  let lastValue = '';

  const handleLocationIdValidate = (newValue: string) => {
    if (newValue && newValue.length > 3) {
      if (idValidationDebouncing) {
        clearTimeout(idValidationDebouncing);
        setIdValidationDebouncing(undefined);
      }
      lastValue = newValue;
      setIdValidationDebouncing(
        setTimeout(async () => {
          setIdValidationStatus('checking');
          const result = await httpClient.get<'good' | 'duplicated'>(`${BackendAPI.LOCATION}/is/${newValue}/a-good-id`);
          if (lastValue === newValue) {
            setIdValidationStatus(result.data);
          }
        }, 400)
      );
    } else {
      setIdValidationStatus('too-short');
    }
  };

  const fieldStatusIcon = (overridingStatus?: string) => {
    switch (overridingStatus || idValidationStatus) {
      case 'good':
        return <i className="ri-check-line text-emerald-500 mr-1"></i>;
      case 'checking':
        return <i className={'ri-loader-3-line text-blue-500 animate-spin'} />;
      case 'duplicated':
        return (
          <Tooltip color={'#ef5350'} title={'not unique'} defaultOpen={true}>
            <i className="ri-flag-line text-red-500 error-input animate-bounce mr-1" />
          </Tooltip>
        );
      case 'too-short':
        return (
          <Tooltip color={'#ef5350'} title={'too short'}>
            <i className="ri-flag-line text-red-500 error-input animate-bounce mr-1" />
          </Tooltip>
        );
      case 'required':
        return (
          <Tooltip color={'#ef5350'} title={'This field is required'}>
            <i className="ri-flag-line text-red-500 error-input animate-bounce mr-1" />
          </Tooltip>
        );
    }
  };

  const operationHoursChange = async (
    date: keyof FacilityLocation['operationHours'],
    newHours: [string, string][] | false
  ) => {
    setNewLocationData({ ...newLocationData, operationHours: { ...newLocationData.operationHours, [date]: newHours } });
  };

  const selectedServiceChange = (selectedServices: string[]) => {
    setNewLocationData({ ...newLocationData, services: selectedServices });
  };

  const fieldErrors = () => {
    const errors = [];
    if (!newLocationData.coverImage) {
      errors.push('No Cover Image');
    }
    if (!newLocationData.thumbnails.length) {
      errors.push('No Thumbnails');
    }
    if (!newLocationData.services.length) {
      errors.push('No Service Selected');
    }
    if (document.querySelector('.error-input')) {
      errors.push('Required Field Not Fulfilled');
    }
    return errors;
  };

  const createNewLocation = async () => {
    try {
      setLoading(true);
      await httpClient.put(`${BackendAPI.LOCATION}`, { ...newLocationData });
      const successPop = Modal.success({
        footer: null,
        closable: false,
        content: (
          <div className={'flex flex-col'}>
            <Typography.Title level={5}>Location Created 🎉</Typography.Title>
            <div className={'flex items-center'}>
              <Typography.Text type={'secondary'} className={'mr-1'}>
                navigating to the location detail in
              </Typography.Text>
              <CountdownCircleTimer
                isPlaying
                duration={5}
                size={20}
                strokeWidth={2}
                colors={'rgb(30,222,153)'}
                trailColor={'#ffffff'}
              >
                {({ remainingTime }) => <Typography.Text type={'secondary'}>{remainingTime}</Typography.Text>}
              </CountdownCircleTimer>{' '}
              <Typography.Text type={'secondary'} className={'ml-1'}>
                seconds
              </Typography.Text>
            </div>
          </div>
        ),
      });
      setTimeout(() => {
        successPop.destroy();
        navigate('../' + newLocationData.locationId);
      }, 5000);
    } catch (e) {
      popMessage.error('Unable to create location');
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Typography.Title level={3} className={'flex items-center'}>
        <Link to={'../'} className={'!text-primary hover:!opacity-55'}>
          Location
        </Link>
        <Divider type={'vertical'} className={'transform rotate-12'} />
        <span className="text-[#f84525] rounded-md">New</span>
      </Typography.Title>
      <div className="bg-primary-foreground p-3">
        <div className="flex gap-8">
          <div className="w-[50%]">
            {
              <CoverImageAndThumbnailsUpload
                onCoverImageChange={(newImageUrl) => {
                  setNewLocationData({ ...newLocationData, coverImage: newImageUrl });
                }}
                onThumbnailImagesChange={(newThumbnails) => {
                  setNewLocationData({ ...newLocationData, thumbnails: [...newThumbnails] });
                }}
              />
            }
          </div>
          <div className="flex-grow-[1]">
            <div>
              <div>
                <Typography.Text type="secondary">Location Id</Typography.Text>
              </div>
              <Input
                name={'locationId'}
                variant="borderless"
                size="middle"
                className={`${underLinedInputClass} w-[100%]`}
                style={{ borderBottomStyle: 'solid' }}
                disabled={loading}
                onChange={(e) => {
                  handleLocationIdValidate(e.target.value);
                  setNewLocationData({ ...newLocationData, locationId: e.target.value });
                }}
                prefix={fieldStatusIcon()}
              />
            </div>
            <div>
              <div>
                <Typography.Text type="secondary">Location Name</Typography.Text>
              </div>
              <Input
                name={'locationName'}
                value={newLocationData.name}
                variant="borderless"
                size="middle"
                className={`${underLinedInputClass} w-[100%]`}
                style={{ borderBottomStyle: 'solid' }}
                onChange={(e) => {
                  setNewLocationData({ ...newLocationData, name: e.target.value });
                }}
                disabled={loading}
                prefix={fieldStatusIcon(newLocationData.name ? 'good' : 'required')}
              />
            </div>
            <h5 className="mt-2 text-gray-400">Location Address</h5>
            <div className={'w-[calc(100%-24px)] ml-[24px]'}>
              <div>
                <Typography.Text type="secondary">Line 1</Typography.Text>
              </div>
              <Input
                name={'locationAddressLine1'}
                value={newLocationData.address?.line1 || ''}
                variant="borderless"
                size="middle"
                className={`${underLinedInputClass} w-[100%]`}
                style={{ borderBottomStyle: 'solid' }}
                disabled={loading}
                onChange={(e) => {
                  const address: FacilityLocation['address'] = {
                    ...newLocationData.address,
                    line1: e.target.value,
                  };
                  setNewLocationData({ ...newLocationData, address });
                }}
                prefix={fieldStatusIcon(newLocationData.address.line1 ? 'good' : 'required')}
              />
            </div>
            <div className={'w-[calc(100%-24px)] ml-[24px]'}>
              <div>
                <Typography.Text type="secondary">Line 2</Typography.Text>
              </div>
              <Input
                name={'locationAddressLine2'}
                value={newLocationData.address?.line2 || ''}
                variant="borderless"
                size="middle"
                className={`${underLinedInputClass} w-[100%]`}
                style={{ borderBottomStyle: 'solid' }}
                disabled={loading}
                onChange={(e) => {
                  const address: FacilityLocation['address'] = {
                    ...newLocationData.address,
                    line2: e.target.value,
                  };
                  setNewLocationData({ ...newLocationData, address });
                }}
                prefix={fieldStatusIcon('good')}
              />
            </div>
            <div className={'w-[calc(100%-24px)] ml-[24px]'}>
              <div>
                <Typography.Text type="secondary">City</Typography.Text>
              </div>
              <Input
                name={'locationAddressCity'}
                value={newLocationData.address?.city || ''}
                variant="borderless"
                size="middle"
                className={`${underLinedInputClass} w-[100%]`}
                style={{ borderBottomStyle: 'solid' }}
                disabled={loading}
                onChange={(e) => {
                  const address: FacilityLocation['address'] = {
                    ...newLocationData.address,
                    city: e.target.value,
                  };
                  setNewLocationData({ ...newLocationData, address });
                }}
                prefix={fieldStatusIcon(newLocationData.address.city ? 'good' : 'required')}
              />
            </div>
            <div className={'w-[calc(100%-24px)] ml-[24px]'}>
              <div>
                <Typography.Text type="secondary">State</Typography.Text>
              </div>
              <div className={'flex items-end'}>
                <div
                  className={`${underLinedInputClass} text-[14px] pl-3`}
                  style={{ borderBottomStyle: 'solid', display: 'flex' }}
                >
                  {fieldStatusIcon(newLocationData.address.state ? 'good' : 'required')}
                </div>
                <Select
                  defaultValue={''}
                  showSearch
                  variant="borderless"
                  disabled={loading}
                  className={`${underLinedInputClass} w-[100%]`}
                  style={{ borderBottomStyle: 'solid', flex: 1 }}
                  loading={loading}
                  options={USStates.map((s) => ({ label: s, value: s }))}
                  onChange={(selected) => {
                    const address: FacilityLocation['address'] = {
                      ...newLocationData.address,
                      state: selected,
                    };
                    setNewLocationData({ ...newLocationData, address });
                  }}
                />
              </div>
            </div>
            <div className={'w-[calc(100%-24px)] ml-[24px]'}>
              <div>
                <Typography.Text type="secondary">Country</Typography.Text>
              </div>
              <Input
                name={'locationAddressCountry'}
                value={newLocationData.address?.country || 'USA'}
                variant="borderless"
                size="middle"
                className={`${underLinedInputClass} w-[100%]`}
                style={{ borderBottomStyle: 'solid' }}
                disabled={true}
                onChange={(e) => {
                  const address: FacilityLocation['address'] = {
                    ...newLocationData.address,
                    country: e.target.value,
                  };
                  setNewLocationData({ ...newLocationData, address });
                }}
                prefix={fieldStatusIcon(newLocationData.address.country ? 'good' : 'required')}
              />
            </div>
            <div className={'w-[calc(100%-24px)] ml-[24px]'}>
              <div>
                <Typography.Text type="secondary">Zip/ Postal code</Typography.Text>
              </div>
              <Input
                name={'locationAddressZip'}
                value={newLocationData.address?.zipcode || ''}
                variant="borderless"
                size="middle"
                className={`${underLinedInputClass} w-[100%]`}
                style={{ borderBottomStyle: 'solid' }}
                disabled={loading}
                onChange={(e) => {
                  const address: FacilityLocation['address'] = {
                    ...newLocationData.address,
                    zipcode: e.target.value,
                  };
                  setNewLocationData({ ...newLocationData, address });
                }}
                prefix={fieldStatusIcon(newLocationData.address.zipcode ? 'good' : 'required')}
              />
            </div>
            <div className={'w-[calc(100%-24px)] ml-[24px]'}>
              <div>
                <Typography.Text type="secondary">Coordinates</Typography.Text>
              </div>
              <div className={'flex'}>
                <div className={'mr-2 w-[30%]'}>
                  <Typography.Text type="secondary" className={'text-xs'}>
                    Longitude
                  </Typography.Text>
                  <Input
                    name={'longitude'}
                    value={newLocationData.coordinates ? newLocationData.coordinates[0] : ''}
                    variant="borderless"
                    size="middle"
                    className={`${underLinedInputClass} w-[100%]`}
                    style={{ borderBottomStyle: 'solid' }}
                    disabled={loading}
                    onChange={(e) => {
                      const coordinates = [e.target.value, newLocationData.coordinates[1]] as [string, string];
                      setNewLocationData({ ...newLocationData, coordinates });
                    }}
                    prefix={fieldStatusIcon(newLocationData.coordinates[0] ? 'good' : 'required')}
                  />
                </div>
                <div className={'flex-grow-[1]'}>
                  <Typography.Text type="secondary" className={'text-xs'}>
                    Latitude
                  </Typography.Text>
                  <Input
                    name={'latitude'}
                    value={newLocationData.coordinates ? newLocationData.coordinates[1] : ''}
                    variant="borderless"
                    size="middle"
                    className={`${underLinedInputClass} w-[100%]`}
                    style={{ borderBottomStyle: 'solid' }}
                    disabled={loading}
                    onChange={(e) => {
                      const coordinates = [newLocationData.coordinates[0], e.target.value] as [string, string];
                      setNewLocationData({ ...newLocationData, coordinates });
                    }}
                    prefix={fieldStatusIcon(newLocationData.coordinates[1] ? 'good' : 'required')}
                  />
                </div>
              </div>
            </div>
            <div>
              <div>
                <Typography.Text type="secondary">Timezone</Typography.Text>
              </div>
              <div className={'flex items-end'}>
                <div
                  className={`${underLinedInputClass} text-[14px] pl-3`}
                  style={{ borderBottomStyle: 'solid', display: 'flex' }}
                >
                  {fieldStatusIcon(newLocationData.tz ? 'good' : 'required')}
                </div>
                <Select
                  defaultValue={''}
                  showSearch
                  variant="borderless"
                  disabled={loading}
                  className={`${underLinedInputClass} w-[100%]`}
                  style={{ borderBottomStyle: 'solid', flex: 1 }}
                  options={USTimezone.map((tz) => ({ label: tz, value: tz }))}
                  onChange={(selected) => {
                    setNewLocationData({ ...newLocationData, tz: selected as any });
                  }}
                />
              </div>
            </div>
            <div>
              <div>
                <Typography.Text type="secondary">Emails</Typography.Text>
              </div>
              {(newLocationData.emails || ['']).map((email, i) => (
                <div key={'email' + i}>
                  <Input
                    name={'locationEmail' + i}
                    value={email}
                    variant="borderless"
                    size="middle"
                    className={`${underLinedInputClass} w-[100%]`}
                    style={{ borderBottomStyle: 'solid' }}
                    disabled={loading}
                    onChange={(e) => {
                      const newEmails = newLocationData.emails || [''];
                      newEmails.splice(i, 1, e.target.value);
                      setNewLocationData({ ...newLocationData, emails: newEmails });
                    }}
                    prefix={fieldStatusIcon(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) ? 'good' : 'required')}
                    suffix={
                      i !== 0 && (
                        <i
                          className={'ri-delete-bin-3-line text-red-500 cursor-pointer'}
                          onClick={() => {
                            const newEmails = newLocationData.emails || [''];
                            newEmails.splice(i, 1);
                            setNewLocationData({ ...newLocationData, emails: newEmails });
                          }}
                        ></i>
                      )
                    }
                  />
                </div>
              ))}
              <Button
                block
                disabled={!!(newLocationData.emails || ['']).filter((e) => e === '').length}
                size={'small'}
                className={'mt-1'}
                onClick={() => {
                  const oldEmails = newLocationData.emails || [];
                  setNewLocationData({ ...newLocationData, emails: [...oldEmails, ''] });
                }}
              >
                + Email
              </Button>
            </div>
            <div>
              <div>
                <Typography.Text type="secondary">Phone</Typography.Text>
              </div>
              <div className={'flex'}>
                <div className={'mr-2 w-[30%]'}>
                  <Typography.Text type="secondary" className={'text-xs'}>
                    Country Code
                  </Typography.Text>
                  <Input
                    name={'phone-country-code'}
                    value={newLocationData.phone?.countryCode || ''}
                    variant="borderless"
                    size="middle"
                    className={`${underLinedInputClass} w-[100%]`}
                    style={{ borderBottomStyle: 'solid' }}
                    disabled={loading}
                    onChange={(e) => {
                      const phone: FacilityLocation['phone'] = {
                        ...newLocationData.phone,
                        countryCode: e.target.value,
                      };
                      setNewLocationData({ ...newLocationData, phone });
                    }}
                    prefix={fieldStatusIcon(newLocationData.phone.countryCode ? 'good' : 'required')}
                  />
                </div>
                <div className={'flex-grow-[1]'}>
                  <Typography.Text type="secondary" className={'text-xs'}>
                    Number
                  </Typography.Text>
                  <Input
                    name={'phone-country-code'}
                    value={newLocationData.phone?.number || ''}
                    variant="borderless"
                    size="middle"
                    className={`${underLinedInputClass} w-[100%]`}
                    style={{ borderBottomStyle: 'solid' }}
                    disabled={loading}
                    onChange={(e) => {
                      const phone: FacilityLocation['phone'] = {
                        ...newLocationData.phone,
                        number: e.target.value,
                      };
                      setNewLocationData({ ...newLocationData, phone });
                    }}
                    prefix={fieldStatusIcon(newLocationData.phone.number ? 'good' : 'required')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Divider>
          <Typography.Text type={'secondary'} strong>
            Services
          </Typography.Text>
          <LocationServiceConfig onChange={setAvailableServices}>
            <i className="ri-settings-3-line ml-2 text-gray-400 hover:text-gray-600" />
          </LocationServiceConfig>
        </Divider>
        <div className="flex justify-center">
          <Checkbox.Group
            options={availableServices.map((s) => ({
              label: (
                <div className={'flex justify-start items-center'}>
                  <span className={'material-icons text-[20px] mr-1'}>{s.icon}</span>
                  <span> {s.value || ''}</span>
                </div>
              ),
              value: s.value,
            }))}
            defaultValue={[]}
            onChange={selectedServiceChange}
          />
        </div>
        <Divider>
          <Typography.Text type={'secondary'} strong>
            Operation Hours
          </Typography.Text>
        </Divider>
        <div>
          <Button className="float-end mb-2" disabled>
            Set Holiday and Exception Days After Location Creation
          </Button>
          <OperationHoursSetting currentHours={newLocationData.operationHours} onChange={operationHoursChange} />
        </div>
      </div>
      <div className={'bg-primary-foreground mt-1 p-3 text-center'}>
        <Tooltip
          title={fieldErrors().join(', ')}
          defaultOpen={!!fieldErrors().length}
          color={'#ef5350'}
          placement={'right'}
        >
          <Button size={'large'} disabled={!!fieldErrors().length} onClick={createNewLocation} loading={loading}>
            Create
          </Button>
        </Tooltip>
      </div>
    </>
  );
};

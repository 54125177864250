import { Button, Card, Col, Divider, Input, InputNumber, Radio, Row, Select, Tabs, Tooltip, Typography } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EntityFeeStructure } from 'bridge/fee-structure';
import { RadioChangeEvent } from 'antd/lib';
import { FromToDate } from '../components/between-date';
import { AppliedCenters } from '../components/applied-centers';
import httpClient from '../../../utils/http-client.util';
import { BackendAPI } from '../../../constants/backend-api.enum';
import { popMessage } from '../../../utils/pop-message.util';
import { FeeItem, OutputObject, validateFees } from '../functions/utils';
import { APPLICANT, QUALIFIED } from '../../../utils/enum';
import { valueType } from 'antd/es/statistic/utils';

export const FeeStructureCreatePage = () => {
  const defaultFeeStructureData: EntityFeeStructure = {
    _id: "",
    feeStructureId: "",
    feeStructureName: "",
    appliedCenter: [],
    startDate: "",
    endDate: "",
    feeStructureType: "",
    feeDonationDuration: "",
    fees: {},
    status: "",
  }

  const navigate = useNavigate();

  const [feeStructureFrom, setFeeStructureFrom] = useState<EntityFeeStructure>(defaultFeeStructureData)
  const [tabActive, setTabActive] = useState<number>(0);
  const [donorGroup, setDonorGroup] = useState<string>(APPLICANT);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false)
  const [applicantDonation, setApplicantDonation] = useState<string>('')
  const [qualifiedDonation, setQualifiedDonation] = useState<string>('')
  const [fees, setFees] = useState<FeeItem[]>([
    { "690": [''] },
    { "825": [''] },
    { "880": [''] },
  ])

  const fieldErrors = () => {
    const errors = [];
    let checkFees = validateFees(fees);
    if (!feeStructureFrom.feeStructureName.length) {
      errors.push('Fee Structure Name not allow to be empty');
    }
    if (feeStructureFrom.appliedCenter.length <= 0) {
      errors.push('Applied Center not allow to be empty');
    }
    if (!feeStructureFrom.startDate.length || !feeStructureFrom.endDate.length) {
      errors.push('Fee Structure Time not allow to be empty');
    }
    if ((donorGroup === APPLICANT && !applicantDonation.length)) {
      errors.push('Fee Structure Durations not allow to be empty');
    }
    if ((donorGroup === QUALIFIED && !qualifiedDonation.length)) {
      errors.push('Fee Structure Durations not allow to be empty');
    }
    if (checkFees.includes(true)) {
      errors.push('Fee Structure Donations not allow to be empty');
    }
    return errors;
  };

  const handleAddField = () => {
    let newFeeDonate = [...fees]
    let targetField = { ...newFeeDonate[tabActive] }
    let targetKey = Object.keys(targetField)[0];
    targetField = { ...targetField, [targetKey]: [...targetField[targetKey], ''] };
    newFeeDonate[tabActive] = targetField;
    setFees(newFeeDonate);
  };

  const handleChangeValue = (value: valueType | null, index: number) => {
    let targetKey = Object.keys(fees[tabActive])[0];
    let newTabValue = [...fees];
    newTabValue[tabActive][targetKey][index] = value === null ? '' : String(value);
    setFees(newTabValue);
  }

  const handleDeleteDonation = (index: number) => {
    let targetKey = Object.keys(fees[tabActive])[0];
    if (fees[tabActive][targetKey].length > 1) {
      fees[tabActive][targetKey].splice(index, 1);
      setFees([...fees]);
    }
  };

  const handleSubmit = async () => {
    let feesDonations = OutputObject(fees)
    feeStructureFrom.feeDonationDuration = donorGroup === APPLICANT ? applicantDonation : qualifiedDonation
    feeStructureFrom.fees = feesDonations
    feeStructureFrom.feeStructureType = donorGroup
    try {
      setButtonLoading(true);
      let res = await httpClient.post(`${BackendAPI.FEESTRUCTURE}/create`, feeStructureFrom);
      if (res.data?.status === 200) {
        popMessage.success('Fee Structure Created Successfully');
        navigate(-1)
      } else {
        popMessage.warning(res.data?.message);
      }
    } catch (e) {
      popMessage.error('Unable to create Fee Structure');
    } finally {
      setButtonLoading(false);
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <Typography.Title level={3}>
          <span onClick={() => navigate(-1)}>Fee Structure</span>
          <Divider type={'vertical'} />
          <span>Create</span>
        </Typography.Title>
      </div>
      <div
        className="flex flex-col gap-[30px]"
        style={{ backgroundColor: 'var(--primary-foreground)', borderRadius: '5px', padding: '20px 40px' }}
      >
        <Row gutter={[30, 30]}>
          <Col xs={24} xl={16}>
            <div className='flex flex-col gap-4'>
              <Typography.Text className="flex flex-col gap-1">
                <span className="font-semibold">Fee Structure Name</span>
                <Input value={feeStructureFrom.feeStructureName} onChange={(event) => setFeeStructureFrom({ ...feeStructureFrom, feeStructureName: event.target.value })} />
              </Typography.Text>
              <Typography.Text className="flex flex-col gap-1">
                <span className="font-semibold">Fee Structure Time</span>
                <FromToDate
                  onChangeFromDate={(date: string) => {
                    setFeeStructureFrom({ ...feeStructureFrom, startDate: date })
                  }}
                  onChangeToDate={(date: string) => {
                    setFeeStructureFrom({ ...feeStructureFrom, endDate: date })
                  }}
                  existFromDate=""
                  existToDate=""
                />
              </Typography.Text>
            </div>
          </Col>
          <Col xs={24} xl={8}>
            <Typography.Text className="flex flex-col gap-1">
              <span className="font-semibold">Applied Centers</span>
              <AppliedCenters
                onChangeCenter={(locations: string[]) => {
                  setFeeStructureFrom({ ...feeStructureFrom, appliedCenter: locations })
                }}
                existCenters={null}
              />
            </Typography.Text>
          </Col>
          <Col xs={24} xl={8}>
            <div className='flex flex-col gap-4'>
              <Typography.Text className="flex flex-col gap-1">
                <span className="font-semibold">Donor Group</span>
                <Radio.Group
                  onChange={(e: RadioChangeEvent) => {
                    setDonorGroup(e.target.value);
                  }}
                  value={donorGroup}
                  className="flex flex-col"
                >
                  <Radio value={APPLICANT}>Applicant Donors</Radio>
                  <Radio value={QUALIFIED}>Qualified Donors</Radio>
                </Radio.Group>
              </Typography.Text>
              <Typography.Text className="flex flex-row gap-4 items-center span font-semibold">
                {donorGroup === APPLICANT ? (
                  <>
                    <span>Make donations in</span>
                    <Input style={{ width: '50px' }} value={applicantDonation} onChange={(event) => setApplicantDonation(event.target.value)} />
                    <span>days</span>
                  </>
                ) : (
                  <>
                    <span>Make donations in a</span>
                    <Select
                      defaultValue="week"
                      value={qualifiedDonation}
                      style={{ width: 120 }}
                      onChange={(value: string) => setQualifiedDonation(value)}
                      options={[
                        { value: 'week', label: 'Week' },
                        { value: 'month', label: 'Month' },
                      ]}
                    />
                  </>
                )}
              </Typography.Text>
            </div>
          </Col>
        </Row>
        <Tabs
          type="card"
          activeKey={String(tabActive)}
          onChange={(key: string) => setTabActive(Number(key))}
          items={fees.map((item: FeeItem, index: number) => {
            return {
              label: `${Object.keys(item)[0]}ml`,
              key: String(index),
              children: (
                <div className="flex flex-col gap-4" style={{ maxWidth: 600 }}>
                  {
                    fees[index][Object.keys(item)[0]].map((it: valueType | null, ind: number) => (
                      <Card
                        size="small"
                        title={`Donation ${ind + 1}`}
                        key={ind}
                        extra={
                          fees[index][Object.keys(item)[0]].length > 1 ? (
                            <CloseOutlined onClick={() => handleDeleteDonation(ind)} />
                          ) : null
                        }
                      >
                        <InputNumber
                          className='w-full'
                          value={it ? it : undefined}
                          formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={(value) => value!.replace(/\$\s?|(,*)/g, '')}
                          onChange={(value) => handleChangeValue(value, ind)}
                          controls={false}
                        />
                      </Card>

                    ))
                  }
                </div>
              )
            }
          })}
        />
        <Button type="dashed" onClick={handleAddField} block style={{ maxWidth: 600 }}>
          + Add Item
        </Button>
        <div className="flex items-center justify-center">
          <Tooltip title={fieldErrors().join(', ')} defaultOpen={!!fieldErrors().length} color={'#ef5350'}
            placement={'right'}>
            <Button size={'large'} disabled={!!fieldErrors().length} onClick={handleSubmit} loading={buttonLoading}>
              Create
            </Button>
          </Tooltip>
        </div>
      </div>
    </div >
  );
};

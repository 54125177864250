export enum BackendAPI {
  PING = '/api/authentication/ping',
  LOGIN = '/api/authentication/login',
  LOGOUT = '/api/authentication/logout',
  LOCATION = '/api/location',
  APPOINTMENT = '/api/appointment',
  CHATBOX = '/api/chatbox',
  CONTACTUS = '/api/contact-us',
  NEWSPOST = '/api/news-post',
  FEESTRUCTURE = '/api/fee-structure',
  PROMOTIONBONUS = '/api/promotion-bonus',
  SURVEY = '/api/survey',
  DONOR_FILTER = '/api/donor-filter',
  ANNOUNCEMENT = '/api/announcement',
  NOTIFICATION = '/api/notification',
  DONOR = '/api/donor',
  CUSTOMER_SERVICE = '/api/customer-service',
  RIGHT_ROLE_MANAGEMENT = '/api/right-role-management',
}

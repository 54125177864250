import React, { useState } from 'react';
import '../chatbox.css';
import { Button, Form, Input, Spin } from 'antd';
import httpClient from '../../../../utils/http-client.util';
import { BackendAPI } from '../../../../constants/backend-api.enum';
import { defaultLanguage } from '../../../../constants/language.constant';
import { popMessage } from '../../../../utils/pop-message.util';
import { MinimalTreeItemData } from 'bridge/chatboxpreset';
import { TreeItemComponentProps } from 'dnd-kit-sortable-tree';

type Prop = {
  language: string;
  data: TreeItemComponentProps<MinimalTreeItemData>;
  handleDelete: Function;
  isItemsChanges: boolean;
  handleAddChatbox: Function;
  handleCancelDrag: Function;
  dragMoveChanges: boolean;
  fetchChatbox: Function;
};

type options = {
  [key: string]: {
    [key: string]: string;
  };
};

export const ChatBox = ({
  language,
  data,
  handleDelete,
  isItemsChanges,
  handleAddChatbox,
  handleCancelDrag,
  dragMoveChanges,
  fetchChatbox,
}: Prop) => {
  const { id, parentId, text, description, allowDelete, additionalService, depth, canHaveChildren, index } = data.item;

  const [chatBoxValue, setChatBoxValue] = useState<options>({ text: {}, description: {} });
  const [actionType, setActionType] = useState(text === undefined && description === undefined ? 'addNew' : 'card');
  const [isLoading, setIsLoading] = useState(false);

  let textValue: string = '';
  let descriptionValue: string = '';
  if (text !== undefined && description !== undefined) {
    textValue = text === null ? '' : text[language] ? text[language] : text[defaultLanguage];
    descriptionValue =
      description === null ? '' : description[language] ? description[language] : description[defaultLanguage];
  }

  const [textNewValue, setTextNewValue] = useState<string>('');
  const [descriptionNewValue, setDescriptionNewValue] = useState<string>('');

  const handleChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    let data = { ...chatBoxValue };
    data[name] = { [language]: value };
    setChatBoxValue(data);
  };

  const handleCreateChatbox = async () => {
    let payload = {
      parentId: parentId ? parentId : null,
      text: chatBoxValue.text,
      description: chatBoxValue.description,
      index: index,
      depth: depth,
      additionalService: null,
      allowDelete: true,
    };
    await httpClient.post(`${BackendAPI.CHATBOX}/create`, payload).then((res) => {
      if (res.status === 200) {
        handleCancelDrag();
        popMessage.success('Created Successfully');
      }
    });
  };

  const handleEditChatbox = async () => {
    setActionType('edit');
    setTextNewValue(textValue);
    setDescriptionNewValue(descriptionValue);
  };

  const handleChangeNewValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    if (name === 'text') {
      setTextNewValue(value);
    }
    if (name === 'description') {
      setDescriptionNewValue(value);
    }
  };

  const handleUpdateValue = async () => {
    setIsLoading(true);
    let newText = { ...text };
    newText[language] = textNewValue;
    let newDescription = { ...description };
    newDescription[language] = descriptionNewValue;
    const payload = {
      id: id,
      parentId: parentId,
      text: newText,
      description: newDescription,
      depth: depth,
      additionalService: additionalService,
      allowDelete: allowDelete,
    };

    await httpClient
      .post(`${BackendAPI.CHATBOX}/updatePreset`, payload)
      .then((res) => {
        if (res.status === 200) {
          setActionType('card');
          setIsLoading(false);
          fetchChatbox();
          popMessage.success('Updated Successfully');
        }
      })
      .catch((err) => {
        return false;
      });
  };

  return (
    <div className="w-full">
      <Spin spinning={isLoading}>
        {(() => {
          switch (actionType) {
            case 'card':
              return (
                <div className="chatbox-layout w-full flex flex-row items-center justify-between">
                  <div className="flex flex-col">
                    <label className="chatbox-title text-one-line">{textNewValue ? textNewValue : textValue}</label>
                    <span className="chatbox-description text-one-line">
                      {descriptionNewValue ? descriptionNewValue : descriptionValue}
                    </span>
                  </div>
                  <div
                    className={`flex flex-row items-center gap-4 cursor-pointer ${dragMoveChanges || isItemsChanges ? 'disabledDiv' : ''}`}
                  >
                    <i
                      className="ri-edit-box-line text-[24px] cursor-pointer"
                      onClick={() => handleEditChatbox()}
                    ></i>
                    <i
                      className={`ri-add-box-line text-[24px] cursor-pointer ${!canHaveChildren || language !== defaultLanguage ? 'disabledDiv' : ''}`}
                      onClick={() => handleAddChatbox(id)}
                    ></i>
                    {allowDelete === false ? null : (
                      <i
                        onClick={() => handleDelete(data.item)}
                        className={`ri-delete-bin-line text-[24px] text-red-500 ${language !== defaultLanguage ? 'disabledDiv' : ''}`}
                      ></i>
                    )}
                  </div>
                </div>
              );
            case 'edit':
              return (
                <InputForm
                  handleSave={handleUpdateValue}
                  handleCancel={() => setActionType('card')}
                  onChange={handleChangeNewValue}
                  text={textNewValue}
                  description={descriptionNewValue}
                />
              );
            case 'addNew':
              return (
                <InputForm
                  handleSave={handleCreateChatbox}
                  handleCancel={handleCancelDrag}
                  onChange={handleChangeValue}
                  text={chatBoxValue.text ? chatBoxValue.text[language] : ''}
                  description={chatBoxValue.description ? chatBoxValue.description[language] : ''}
                />
              );
            default:
              return null;
          }
        })()}
      </Spin>
    </div>
  );
};

const InputForm = ({
  handleSave,
  handleCancel,
  onChange,
  text,
  description,
}: {
  handleSave: Function;
  handleCancel: Function;
  onChange: any;
  text: string;
  description: string;
}) => {
  return (
    <div className="chatbox-layout w-full flex flex-row items-center gap-4">
      <Form className="flex flex-col gap-2 w-full">
        <Form.Item style={{ marginBottom: '0' }}>
          <Input placeholder="Title" name="text" value={text} onChange={onChange} />
        </Form.Item>
        <Form.Item style={{ marginBottom: '0' }}>
          <Input placeholder="Description" name="description" value={description} onChange={onChange} />
        </Form.Item>
      </Form>
      <div className="flex flex-row gap-4">
        <Button onClick={() => handleCancel()}>Cancel</Button>
        <Button type={'primary'} onClick={() => handleSave()}>
          Save
        </Button>
      </div>
    </div>
  );
};

import React from 'react';
import iphoneBg from './iphone-bg.png';
import { Notification } from 'bridge/notification';
import { Card } from 'antd';

type Props = {
  notification: Partial<Notification>;
};
const NotificationPreview = ({ notification }: Props) => {
  return (
    <div
      className="w-[310px] h-full max-h-[610px] rounded-2xl py-4 relative"
      style={{ backgroundImage: `url(${iphoneBg})`, backgroundSize: 'cover' }}
    >
      <div className="flex justify-between items-center h-3 text-white mb-1">
        <div className={'mt-0.5 opacity-0'}>10:45</div>

        <div className={'flex justify-between items-center rounded-3xl p-2 h-6 w-28 ml-10 mr-2 bg-black'}>
          <div className="h-3 w-3 bg-gray-900 rounded-full" />
          <div className="h-3 w-3 bg-gray-900 rounded-full" />
        </div>

        <div className={'flex space-x-1 items-end opacity-0'}>
          <div className="h-1 w-0.5 bg-white"></div>
          <div className="h-2 w-0.5 bg-white"></div>
          <div className="h-3 w-0.5 bg-gray-300"></div>
          <div className="h-4 w-0.5 bg-gray-300"></div>
          <div className="h-5 w-0.5 bg-gray-300"></div>
        </div>
        <div>
          <i className="ri-wifi-line text-white text-xl opacity-0"></i>
        </div>
        <div>
          <i className="ri-battery-low-line text-white text-xl opacity-0"></i>
        </div>
      </div>
      <Card
        type={'inner'}
        className="h-fit rounded-xl m-4 relative border-none"
        style={{ backgroundColor: 'rgb(255 255 255 / 40%)', backdropFilter: 'blur(8px)' }}
      >
        <div className={'flex items-center justify-between w-full space-x-2'}>
          <div className={'w-[20%]'}>
            <div
              className={
                'w-10 h-10 font-semibold rounded-lg text-center bg-white text-xs flex justify-center items-center'
              }
            >
              APP ICON
            </div>
          </div>
          <div className={'w-[80%]'}>
            <div className={'text-sm flex w-full justify-between'}>
              <div className={'font-semibold'}>{notification.title || 'Notification'}</div>
              <div className={'text-xs text-gray-500'}>now</div>
            </div>
            <div className={'text-xs overflow-y-hidden w-full max-h-20 break-words'}>{notification.message}</div>
          </div>
        </div>
      </Card>
      <div className={'absolute left-1/2 transform -translate-x-1/2 bottom-0.5 w-[125px] h-1 rounded-full bg-white'} />
    </div>
  );
};

export default NotificationPreview;

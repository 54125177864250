import { Button, Select } from 'antd'
import { FacilityLocation } from 'bridge/location';
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { popMessage } from '../../../utils/pop-message.util';
import httpClient from '../../../utils/http-client.util';
import { BackendAPI } from '../../../constants/backend-api.enum';
import { SelectProps } from 'antd/lib';
import "./applied-centers.css"

export type EntitySelectCenter = {
    value: string,
    label: string,
}

type Prop = {
    onChangeCenter?: (locations: string[]) => void;
    existCenters: string[] | null;
};

export const AppliedCenters = ({ onChangeCenter, existCenters }: Prop) => {

    const [locationOptions, setLocationOptions] = useState<EntitySelectCenter[]>([]);
    const [centerData, setCenterData] = useState<string[] | null>([])

    useEffect(() => {
        setCenterData(existCenters)
    }, [existCenters])

    useEffect(() => {
        (async () => {
            try {
                const allLocationsRequest = await httpClient.get<FacilityLocation[]>(BackendAPI.LOCATION);
                if (allLocationsRequest.data) {
                    let newLocation: EntitySelectCenter[] = allLocationsRequest.data.map((item) => {
                        return {
                            value: item.name,
                            label: item.name,
                        }
                    })
                    setLocationOptions(newLocation)
                }
            } catch (e) {
                popMessage.error('Unable to fetch locations data');
            }
        })();
    }, []);

    const selectProps = useSelectAll({
        showSelectAll: true,
        value: centerData,
        onChange: (value) => {
            setCenterData(value)
            if (onChangeCenter) {
                onChangeCenter(value)
            }
        },
        options: locationOptions
    });

    return (
        <div>
            <Select
                {...selectProps}
                className='custom-select-center w-full'
                mode="multiple"
            />
        </div>
    )
}


export function useSelectAll({
    showSelectAll = true,
    options = [],
    value,
    onChange,
}: { showSelectAll?: boolean } & SelectProps) {
    const handleSelectAll = useCallback(() => {
        onChange?.(
            options.map((option) => option.value),
            options
        );
    }, [onChange, options]);

    const handleUnselectAll = useCallback(() => {
        onChange?.([], []);
    }, [onChange]);

    const enchancedOptions = useMemo(() => {
        if (!showSelectAll) return options;

        return [
            {
                label: !value?.length ? (
                    <Button type="link" onClick={() => handleSelectAll()}>
                        Select All
                    </Button>
                ) : (
                    <Button type="link" onClick={() => handleUnselectAll()}>
                        Unselect All
                    </Button>
                ),
                options,
            },
        ];
    }, [handleSelectAll, handleUnselectAll, options, showSelectAll, value?.length]);

    return {
        options: enchancedOptions,
        value,
        onChange,
    };
}
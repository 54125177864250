import { Button, Card, Input, Radio, Space, Typography } from 'antd';
import { EntityCondition } from 'bridge/promotion-bonus';
import React, { useEffect, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { RadioChangeEvent } from 'antd/lib';

export const ApplicantDonors = ({ onChangeData, conditionData }: { onChangeData?: (data: EntityCondition[]) => void, conditionData: EntityCondition[] }) => {
  const [applicantDonorConditions, setApplicantDonorConditions] = useState<EntityCondition[]>([
    {
      duration: ['', ''],
      donations: [
        {
          type: '',
          value: '',
        },
      ],
    },
  ]);
  let donationType = ["money", "point"]

  useEffect(() => {
    if (onChangeData) {
      onChangeData(applicantDonorConditions)
    }
  }, [applicantDonorConditions])

  useEffect(() => {
    if (conditionData && conditionData.length > 0) {
      setApplicantDonorConditions(conditionData)
    }
  }, [conditionData])

  const handleAddCondition = () => {
    setApplicantDonorConditions([...applicantDonorConditions, {
      duration: ['', ''],
      donations: [
        {
          type: '',
          value: '',
        },
      ]
    }])
  };

  const handleDeleteCondition = (index: number) => {
    if (applicantDonorConditions.length > 1) {
      applicantDonorConditions.splice(index, 1)
      setApplicantDonorConditions([...applicantDonorConditions])
    }
  }

  const handleAddDonation = (index: number) => {
    let newCondition = [...applicantDonorConditions];
    let targetField = { ...newCondition[index] }
    targetField = {
      ...targetField, donations: [...targetField.donations, {
        type: "",
        value: ""
      }]
    }
    newCondition[index] = targetField
    setApplicantDonorConditions(newCondition)
  }

  const handleSelectDonation = (event: RadioChangeEvent, indexCondition: number, indexDonation: number) => {
    let newCondition = [...applicantDonorConditions];
    newCondition[indexCondition].donations[indexDonation].value = ""
    newCondition[indexCondition].donations[indexDonation].type = event.target.value
    setApplicantDonorConditions(newCondition)
  }

  const handleDeleteDonation = (indexCondition: number, indexDonation: number) => {
    if (applicantDonorConditions[indexCondition].donations.length > 1) {
      applicantDonorConditions[indexCondition].donations.splice(indexDonation, 1)
      setApplicantDonorConditions([...applicantDonorConditions])
    }
  }

  return (
    <div>
      <div className="flex flex-col gap-4" style={{ maxWidth: 600 }}>
        {
          applicantDonorConditions.map((item, index) => (
            <Card
              size="small"
              title={`Condition #${index + 1}`}
              key={index}
              extra={
                applicantDonorConditions.length > 1 ? <CloseOutlined onClick={() => handleDeleteCondition(index)} /> : null
              }
              className='condition-item'
              style={{ backgroundColor: '#fafafa' }}
            >
              <div className='flex flex-col gap-4'>
                <Typography.Text className="flex flex-row gap-4 items-center span font-semibold">
                  <span>Make donations in</span>
                  <Input
                    style={{ width: '50px' }}
                    value={item.duration[0]}
                    onChange={(event) => {
                      let condition = [...applicantDonorConditions];
                      let targetField = { ...condition[index] }
                      targetField.duration[0] = event.target.value;
                      condition[index] = targetField
                      setApplicantDonorConditions(condition)
                    }} />
                  <span>-</span>
                  <Input
                    style={{ width: '50px' }}
                    value={item.duration[1]}
                    onChange={(event) => {
                      let condition = [...applicantDonorConditions];
                      let targetField = { ...condition[index] }
                      targetField.duration[1] = event.target.value;
                      condition[index] = targetField
                      setApplicantDonorConditions(condition)
                    }} />
                  <span>days since 1st donation</span>
                </Typography.Text>
                {
                  item.donations.map((it, ind) => (
                    <div className='d-flex flex-row align-items-center gap-2'>
                      <Card
                        size="small"
                        title={`Donation #${ind + 1}`}
                        key={ind}
                        extra={
                          item.donations.length > 1 ? <CloseOutlined onClick={() => handleDeleteDonation(index, ind)} /> : null
                        }
                      >
                        <Space direction="vertical" className='flex flex-row'>
                          {
                            donationType.map((itemDonation, indexDonation) => (
                              <Radio checked={it.type === itemDonation} onChange={(event) => handleSelectDonation(event, index, ind)} value={itemDonation} className='flex flex-row items-center'>
                                <div className='flex flex-row items-center gap-4'>
                                  <Input
                                    name={itemDonation}
                                    disabled={item.donations[ind].type === itemDonation ? false : true}
                                    value={item.donations[ind].type === itemDonation ? applicantDonorConditions[index].donations[ind].value : ""}
                                    onChange={(event) => {
                                      let condition = [...applicantDonorConditions];
                                      let targetField = { ...condition[index] }
                                      let donation = [...targetField.donations]
                                      let target = { ...donation[ind] }
                                      target = { ...target, value: event.target.value }
                                      donation[ind] = target
                                      condition[index].donations = donation
                                      setApplicantDonorConditions(condition)
                                    }} />
                                  <span>{itemDonation === "money" ? "$" : "pts"}</span>
                                </div>
                              </Radio>
                            ))
                          }
                        </Space>
                      </Card>
                    </div>
                  ))
                }
                <Button type="dashed" onClick={() => handleAddDonation(index)} block>
                  + Add Donation
                </Button>
              </div>
            </Card>
          ))
        }
        <Button type="dashed" onClick={handleAddCondition} block style={{ maxWidth: 600 }}>
          + Add Item
        </Button>
      </div>
    </div>
  );
};

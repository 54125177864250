import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Badge, Card, List, Modal, Radio, Select, Typography } from 'antd';
import { NewsPost } from 'bridge/news-post';
import httpClient from '../../../../utils/http-client.util';
import { BackendAPI } from '../../../../constants/backend-api.enum';
import NewsPostsPreviewComponent from '../content-renderers/when-preview';
import { supportingLanguageMap } from '../../../../constants/language.constant';
import { NewsPostTagSetting } from '../component/tag-setting.component';
import dayjs from 'dayjs';
import { underLinedInputClass } from '../../../../components/inputs/editable-attribute.component';

export const NewsPostList = () => {
  const navigate = useNavigate();
  const [previewPost, setPreviewPost] = useState<NewsPost>();
  const [newsPosts, setNewsPosts] = useState<Array<NewsPost>>([]);
  const [loading, setLoading] = useState(true);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [tagFilterMode, setTagFilterMode] = useState<'and' | 'or'>('and');
  const [allTags, setAllTags] = useState<string[]>([]);

  useEffect(() => {
    // fetch news posts
    (async () => {
      const response = await httpClient.get<NewsPost[]>(`${BackendAPI.NEWSPOST}`);
      setNewsPosts(response.data.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()));
      setLoading(false);
    })();
  }, []);
  return (
    <div>
      <div className={'flex items-start my-2'}>
        <Card hoverable title={'Create New Post'} onClick={() => navigate('new')}
              className={'w-fit'}>
          Click To Create New Post
        </Card>
        <Card className={'ml-3 w-[35%]'}>
          <Typography.Text type={'secondary'} className={'flex justify-between items-end mb-2.5'}>
            <span><i className={'ri-hashtag'} /> Filter By Tags</span>
            <Radio.Group value={tagFilterMode} size={'small'}
                         buttonStyle="solid"
                         onChange={(value) => {
                           setTagFilterMode(value.target.value);
                         }}>
              <Radio.Button value="and">AND</Radio.Button>
              <Radio.Button value="or">OR</Radio.Button>
            </Radio.Group>
            <NewsPostTagSetting onChange={setAllTags}>
              <span className={'hover:text-blue-500'}>
                <i className={'ri-settings-3-line'} />
                Manage Tags
              </span>
            </NewsPostTagSetting>
          </Typography.Text>
          <Select
            variant={'borderless'} maxTagCount={'responsive'}
            className={`${underLinedInputClass} w-[100%] mb-3`}
            style={{ borderBottomStyle: 'solid' }}
            placeholder={'Select tags'} mode={'multiple'}
            options={allTags.map(tag => ({ label: tag, value: tag }))} value={selectedTags}
            onChange={setSelectedTags} allowClear
          />
        </Card>
      </div>


      <List
        loading={loading}
        grid={{
          gutter: 16,
          xs: 1,
          sm: 1,
          md: 2,
          lg: 3,
          xl: 4,
          xxl: 6,
        }}
        dataSource={
          newsPosts.filter(post => {
            if (selectedTags.length === 0) {
              return true;
            }
            if (tagFilterMode === 'and') {
              return selectedTags.every(tag => post.tags.includes(tag));
            }
            return selectedTags.some(tag => post.tags.includes(tag));
          }) || []
        }
        renderItem={(item) => (
          <List.Item>
            {
              Object.keys(item.localizedContent).map((lang, i) =>
                <div key={i} className={'absolute z-20 right-3'} style={{ top: i * 25 }}>
                  <Badge count={supportingLanguageMap.get(lang)} />
                </div>)
            }
            <Card hoverable className={'relative'} onClick={() => {
              setPreviewPost(item);
            }}
                  cover={<div className={'relative !flex justify-center'}>
                    <img alt="coverImage"
                         style={{ objectFit: 'contain', objectPosition: 'top', height: '175px' }}
                         src={item.coverImage} />
                    {item.expiryTS && new Date(item.expiryTS) < new Date() &&
                      <div
                        className="absolute inset-0 flex items-center text-center bg-gray-400 bg-opacity-85 justify-center text-2xl text-white">
                        Expired
                      </div>
                    }
                  </div>}
                  actions={[
                    <div onClick={e => {
                      e.stopPropagation();
                      navigate(`edit/${item.newsId}`, { state: { newsPost: item } });
                    }}>
                      <i className={'ri-edit-2-line'} /> Edit
                    </div>,
                    <div onClick={e => {
                      e.stopPropagation();
                      navigate(`template/${item.newsId}`, { state: { newsPost: item } });
                    }}>
                      <i className={'ri-file-copy-line'} /> Use as template
                    </div>,
                  ]}
            >
              <Card.Meta title={item.localizedContent[item.defaultLanguage].title}
                         description={
                           <>
                             <div className={'mb-2 w-[100%] text-ellipsis text-nowrap overflow-hidden'}>
                               {
                                 item.localizedContent[item.defaultLanguage].subtitle
                               }
                             </div>
                             <div className={'flex justify-between'}>
                               <div>
                                 <span className={'mr-3'}>
                                   <i className={'ri-eye-line mr-1'} />
                                   {item.statistics.read}
                                 </span>
                                 <span>
                                   <i className={'ri-heart-2-line mr-1'} />
                                   {item.statistics.like}
                                 </span>
                               </div>
                               <div className={'italic'}>
                                 {item.newsId}
                               </div>
                             </div>
                           </>
                         } />
            </Card>
          </List.Item>
        )}
      />

      <Modal
        destroyOnClose
        open={!!previewPost}
        footer={null}
        width={438}
        title={'Full Size Preview'}
        onCancel={() => setPreviewPost(undefined)}
        style={{ top: 0 }}
      >
        {
          previewPost &&
          <NewsPostsPreviewComponent currentPost={previewPost}
                                     currentLanguage={previewPost.defaultLanguage}
                                     scaleRatio={1} />
        }
      </Modal>
    </div>
  );
};

import { Button, Modal } from 'antd';
import { PropsWithChildren, useEffect, useState } from 'react';
import httpClient from '../../../utils/http-client.util';
import { BackendAPI } from '../../../constants/backend-api.enum';
import { popMessage } from '../../../utils/pop-message.util';
import { EditableAttribute, EditableAttributeDateType } from '../../../components/inputs/editable-attribute.component';

export const HolidayConfig = ({ children, onChange }: PropsWithChildren & {
  onChange?: (newHolidays: string[]) => void
}) => {
  const [open, setOpen] = useState(false);
  const [holidays, setHolidays] = useState<string[]>([]);
  const [addingHoliday, setAddingHoliday] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (open) {
      fetchData();
    }
  }, [open]);

  const fetchData = async () => {
    try {
      const holidaysResponse = await httpClient.get<string[]>(`${BackendAPI.LOCATION}/holidays`);
      setHolidays(holidaysResponse.data);
      if (onChange) {
        onChange(holidaysResponse.data);
      }
    } catch (e) {
      popMessage.error('unable to load holiday list');
    }
  };

  const toggleModal = () => {
    setOpen(!open);
    setAddingHoliday(false);
  };

  const remove = (index: number) => {
    holidays.splice(index, 1);
    setHolidays([...holidays]);
  };

  const add = (newValue: EditableAttributeDateType | EditableAttributeDateType[], continuous?: boolean) => {
    setAddingHoliday(false);
    if (newValue) {
      setHolidays([...holidays, newValue as string]);
    }
    setTimeout(() => setAddingHoliday(!!continuous));
  };

  const modify = (newValue: EditableAttributeDateType | EditableAttributeDateType[], index: number) => {
    if (newValue) {
      const holidayList = [...holidays];
      holidayList[index] = newValue as string;
      setHolidays(holidayList);
    }
  };

  const onOk = () => {
    setLoading(true);
    setTimeout(async () => {
      // wrap in time out to make sure update is the last step to execute
      try {
        await httpClient.post<string[]>(`${BackendAPI.LOCATION}/holidays`, [...holidays]);
        popMessage.success('Successfully updated holidays');
        if (onChange) {
          onChange(holidays);
        }
        setOpen(false);
      } catch (e) {
        popMessage.error('Unable to update holiday list');
      } finally {
        setLoading(false);
      }
    });
  };

  return (
    <>
      {
        <span className="cursor-pointer" onClick={toggleModal}>
          {children}
        </span>
      }
      <Modal open={open} onCancel={toggleModal} onOk={onOk}
             confirmLoading={loading} okText={'Save'}
             destroyOnClose title={'Holiday Configuration'}
             maskClosable={false}>
        <h5 className="text-gray-500">Currently Defined Holidays:</h5>
        {
          holidays.map((s, i) =>
            <div className={'flex justify-start items-center'} key={`location-holiday-${i}`}>
              <EditableAttribute indent canEdit={{ onSave: (newValue) => modify(newValue, i) }}
                                 value={s} label={''}
                                 type={'text'} />
              <i className="ri-delete-bin-3-line ms-6 text-red-500 hover:text-red-400 cursor-pointer mt-1"
                 onClick={() => remove(i)} />
            </div>,
          )
        }
        {
          addingHoliday &&
          <EditableAttribute canEdit={{
            onSave: add, defaultInEditing: true,
            onEnterKey: (newValue) => add(newValue, true),
            onCancel: () => setAddingHoliday(false),
          }} value={''} label={'New holiday (press Enter for fast input)'}
                             type={'text'} />
        }
        <Button className="mt-2" onClick={() => setAddingHoliday(true)}>+ New Holiday</Button>
      </Modal>
    </>
  );
};
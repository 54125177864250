import { Link, useLocation, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import httpClient from '../../utils/http-client.util';
import { BackendAPI } from '../../constants/backend-api.enum';
import { ExceptionDay, FacilityLocation } from 'bridge/location';
import {
  EditableAttribute,
  EditableAttributeProp,
  TextWithIcon,
} from '../../components/inputs/editable-attribute.component';
import { Alert, Button, Checkbox, Divider, Typography } from 'antd';
import CoverImageAndThumbnailsUpload from './components/cover-image-and-thumbnails-upload.component';
import { LocationServiceConfig } from './components/location-service-config.component';
import { OperationHoursSetting } from './components/operation-hours-setting.component';
import { ExceptionDaySetting } from './components/exception-day-setting.component';
import { popMessage } from '../../utils/pop-message.util';
import { USStates, USTimezone } from '../../constants/united-states.constant';

export const LocationPage = () => {
  const { locationId } = useParams();
  const routerLocation = useLocation();

  const [facilityInfo, setFacilityInfo] = useState<FacilityLocation | undefined>(undefined);
  const [availableServices, setAvailableServices] = useState<TextWithIcon[]>([]);

  const locationAttributes1: Array<EditableAttributeProp> = [
    {
      label: 'Location Id',
      value: facilityInfo?.locationId || '',
      type: 'text',
    },
    {
      label: 'Location Name',
      value: facilityInfo?.name || '',
      type: 'text',
      canEdit: {
        onSave: async (newValue) => {
          if (facilityInfo) {
            const payload = { name: newValue as string };
            await updateDBRecord(payload);

            setFacilityInfo({ ...facilityInfo, ...payload });
            return `${facilityInfo.locationId} location name has been updated to: ${newValue}`;
          } else {
            throw new Error('System Error!');
          }
        },
      },
    },
  ];
  const locationAttributes2: Array<EditableAttributeProp> = [
    {
      label: 'Timezone',
      value: facilityInfo?.tz || '',
      type: 'select',
      selectOptions: USTimezone.map((s) => ({ label: s, value: s })),
      canEdit: {
        onSave: async (newValue) => {
          if (facilityInfo) {
            const payload = { tz: newValue as any };
            await updateDBRecord(payload);
            setFacilityInfo({ ...facilityInfo, ...payload });
            return `${facilityInfo.locationId} location timezone has been updated to: ${newValue}`;
          } else {
            throw new Error('System Error!');
          }
        },
      },
    },
    {
      label: 'Email',
      value: facilityInfo?.emails || [],
      type: 'multi-text',
      canEdit: {
        onSave: async (newValue) => {
          if (facilityInfo) {
            const payload = { emails: newValue as string[] };
            await updateDBRecord(payload);
            setFacilityInfo({ ...facilityInfo, ...payload });
            return `${facilityInfo.locationId} location emails has been updated to: ${newValue}`;
          } else {
            throw new Error('System Error!');
          }
        },
      },
    },
    {
      label: 'Phone Number',
      value: [`${facilityInfo?.phone?.countryCode || ''}`, `${facilityInfo?.phone?.number || ''}`],
      type: 'map',
      mapLabels: ['Country Code', 'Number'],
      canEdit: {
        onSave: async (newValue) => {
          if (facilityInfo) {
            const countryCodeFromValue = (newValue as string[])[0];
            const newCountryCode = (countryCodeFromValue.match(/\d+(\.\d+)?/g) || []).join('');

            const numberFromValue = (newValue as string[])[1];
            const newNumber = (numberFromValue.match(/\d+(\.\d+)?/g) || []).join('');

            const payload = { phone: { countryCode: newCountryCode || '1', number: newNumber || '0000000000' } };

            await updateDBRecord(payload);
            setFacilityInfo({
              ...facilityInfo,
              ...payload,
            });
            return `${facilityInfo.locationId} location phone number has been updated to: +${newCountryCode} ${newNumber}`;
          } else {
            throw new Error('System Error!');
          }
        },
      },
    },
  ];
  const locationAddressAttributes: Array<EditableAttributeProp> = [
    {
      label: 'Line 1',
      indent: true,
      value: facilityInfo?.address?.line1 || '',
      type: 'text',
      canEdit: {
        onSave: async (newValue) => {
          if (facilityInfo) {
            const payload = { address: { ...facilityInfo.address, line1: newValue as string } };
            await updateDBRecord(payload);
            setFacilityInfo({ ...facilityInfo, ...payload });
            return `${facilityInfo.locationId} location address line 1 has been updated to: ${newValue}`;
          } else {
            throw new Error('System Error!');
          }
        },
      },
    },
    {
      label: 'Line 2',
      indent: true,
      value: facilityInfo?.address?.line2 || '',
      type: 'text',
      canEdit: {
        onSave: async (newValue) => {
          if (facilityInfo) {
            const payload = { address: { ...facilityInfo.address, line2: newValue as string } };
            await updateDBRecord(payload);
            setFacilityInfo({ ...facilityInfo, ...payload });
            return `${facilityInfo.locationId} location address line 2 has been updated to: ${newValue}`;
          } else {
            throw new Error('System Error!');
          }
        },
      },
    },
    {
      label: 'City',
      indent: true,
      value: facilityInfo?.address?.city || '',
      type: 'text',
      canEdit: {
        onSave: async (newValue) => {
          if (facilityInfo) {
            const payload = { address: { ...facilityInfo.address, city: newValue as string } };
            await updateDBRecord(payload);
            setFacilityInfo({ ...facilityInfo, ...payload });
            return `${facilityInfo.locationId} location city has been updated to: ${newValue}`;
          } else {
            throw new Error('System Error!');
          }
        },
      },
    },
    {
      label: 'State',
      indent: true,
      value: facilityInfo?.address?.state || '',
      type: 'select',
      selectOptions: USStates.map((s) => ({ label: s, value: s })),
      canEdit: {
        onSave: async (newValue) => {
          if (facilityInfo) {
            const payload = { address: { ...facilityInfo.address, state: newValue as string } };
            await updateDBRecord(payload);

            setFacilityInfo({ ...facilityInfo, ...payload });
            return `${facilityInfo.locationId} location state has been updated to: ${newValue}`;
          } else {
            throw new Error('System Error!');
          }
        },
      },
    },
    {
      label: 'Zip/ Postal code',
      indent: true,
      value: facilityInfo?.address?.zipcode || '',
      type: 'text',
      canEdit: {
        onSave: async (newValue) => {
          if (facilityInfo) {
            const payload = { address: { ...facilityInfo.address, zipcode: newValue as string } };
            await updateDBRecord(payload);

            setFacilityInfo({ ...facilityInfo, ...payload });
            return `${facilityInfo.locationId} location zipcode has been updated to: ${newValue}`;
          } else {
            throw new Error('System Error!');
          }
        },
      },
    },
    {
      label: 'Country',
      indent: true,
      value: facilityInfo?.address?.country || 'USA',
      type: 'text',
    },
    {
      label: 'Coordinates',
      indent: true,
      value: facilityInfo?.coordinates || ['', ''],
      type: 'map',
      mapLabels: ['Longitude', 'Latitude'],
      canEdit: {
        onSave: async (newValue) => {
          if (facilityInfo) {
            const payload = { coordinates: newValue as [string, string] };
            await updateDBRecord(payload);
            setFacilityInfo({ ...facilityInfo, ...payload });
            return `${facilityInfo.locationId} location coordinates has been updated to: [${newValue as [string, string]}]`;
          } else {
            throw new Error('System Error!');
          }
        },
      },
    },
  ];

  useEffect(() => {
    const inStateFacility: FacilityLocation = routerLocation.state?.facilityLocation || null;
    if (!inStateFacility || inStateFacility.locationId !== locationId) {
      (async () => {
        const locationResponse = await httpClient.get<FacilityLocation>(`${BackendAPI.LOCATION}/${locationId}`);
        setFacilityInfo(locationResponse.data);
      })();
    } else {
      setFacilityInfo(inStateFacility);
      window.history.replaceState({}, '');
    }
  }, []);

  const selectedServiceChange = async (services: string[]) => {
    if (facilityInfo) {
      const payload = { services };

      try {
        await updateDBRecord(payload);
        popMessage.success('Successfully updated services');
        setFacilityInfo({ ...facilityInfo, services });
      } catch (e) {
        popMessage.error('Unable to updated services');
      }
    }
  };

  const exceptionDayChange = async (date: string, newExceptionDay?: ExceptionDay) => {
    if (facilityInfo) {
      const theDay = (facilityInfo.exceptionDays || {})[date];
      if (theDay) {
        if (!newExceptionDay) {
          delete facilityInfo.exceptionDays[date];
        } else {
          facilityInfo.exceptionDays[date] = newExceptionDay;
        }
      } else {
        if (newExceptionDay) {
          facilityInfo.exceptionDays = {
            ...facilityInfo.exceptionDays,
            [date]: newExceptionDay,
          };
        }
      }

      setFacilityInfo({ ...facilityInfo });
    }
  };

  const operationHoursChange = async (
    date: keyof FacilityLocation['operationHours'],
    newHours: [string, string][] | false
  ) => {
    if (facilityInfo) {
      const payload = {
        operationHours: {
          ...facilityInfo.operationHours,
          [date]: newHours,
        },
      };

      try {
        await updateDBRecord(payload);
        popMessage.success('Successfully updated operation hours on ' + date + ': ' + JSON.stringify(newHours));
      } catch (e) {
        popMessage.error('Unable to change operation hours');
      }

      setFacilityInfo({ ...facilityInfo, ...payload });
    }
  };

  const updateDBRecord = async (newData: Partial<FacilityLocation>) => {
    await httpClient.post(`${BackendAPI.LOCATION}/${locationId}`, { ...newData });
  };

  return facilityInfo ? (
    <>
      <Typography.Title level={3} className={'flex items-center'}>
        <Link to={'../'} className={'!text-primary hover:!opacity-55'}>
          Location
        </Link>
        <Divider type={'vertical'} className={'transform rotate-12'} />
        <span className="text-[#f84525] rounded-md">Detail</span>
      </Typography.Title>
      {facilityInfo.status === 'inactive' && <Alert message={'This Location is Locked'} banner type={'error'}></Alert>}
      <div className="bg-primary-foreground p-3">
        <div className="flex gap-8">
          <div className="w-[50%]">{facilityInfo && <CoverImageAndThumbnailsUpload location={facilityInfo} />}</div>
          <div className="flex-grow-[1]">
            {locationAttributes1.map((la, i) => (
              <EditableAttribute key={'location-attr-1-' + i} {...la} />
            ))}
            <h5 className="mt-2 text-gray-400">Location Address</h5>
            {locationAddressAttributes.map((la, i) => (
              <EditableAttribute key={'location-attr-2-' + i} {...la} />
            ))}
            {locationAttributes2.map((la, i) => (
              <EditableAttribute key={'location-attr-3-' + i} {...la} />
            ))}
          </div>
        </div>
        <Divider>
          <Typography.Text type={'secondary'} strong>
            Services
          </Typography.Text>
          <LocationServiceConfig onChange={setAvailableServices}>
            <i className="ri-settings-3-line ml-2 text-gray-400 hover:text-gray-600" />
          </LocationServiceConfig>
        </Divider>
        <div className="flex justify-center">
          <Checkbox.Group
            options={availableServices.map((s) => ({
              label: (
                <div className={'flex justify-start items-center'}>
                  <span className={'material-icons text-[20px] mr-1'}>{s.icon}</span>
                  <span>{s.value || ''}</span>
                </div>
              ),
              value: s.value,
            }))}
            defaultValue={facilityInfo.services || []}
            onChange={selectedServiceChange}
          />
        </div>
        <Divider>
          <Typography.Text type={'secondary'} strong>
            Operation Hours
          </Typography.Text>
        </Divider>
        <div>
          {facilityInfo && (
            <ExceptionDaySetting location={facilityInfo} onChange={exceptionDayChange}>
              <Button className="float-end mb-2">Set Holiday and Exception Days</Button>
            </ExceptionDaySetting>
          )}
          <OperationHoursSetting currentHours={facilityInfo.operationHours} onChange={operationHoursChange} />
        </div>
      </div>
    </>
  ) : (
    <div className="text-center mt-64">
      <div role="status">
        <svg
          aria-hidden="true"
          className="inline w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

import { EntityChatbox, MinimalTreeItemData } from 'bridge/chatboxpreset';
import { TreeItem } from 'dnd-kit-sortable-tree';
import { FlattenedItem } from 'dnd-kit-sortable-tree/dist/types';

export const buildTree = (data: FlattenedItem<MinimalTreeItemData>[], parentId: string | number | null = null) => {
  let result: TreeItem<MinimalTreeItemData>[] = [];
  data.forEach((obj: FlattenedItem<MinimalTreeItemData>) => {
    if (obj.parentId === parentId) {
      const children = buildTree(data, obj.id);
      if (children.length) {
        obj.children = children;
      }
      result.push(obj);
    }
  });
  return result;
};

export const itemsPayload = (data: MinimalTreeItemData) => {
  let newObj = {};
  if (data) {
    newObj = {
      id: data._id ? data._id : data.id,
      parentId: data.parentId,
      text: data.text,
      description: data.description,
      depth: data.depth,
      index: data.index,
      additionalService: data.additionalService,
      allowDelete: data.allowDelete,
    };
  }

  return newObj;
};

export const addIndexProperty = (data: TreeItem<MinimalTreeItemData>[]) => {
  let index = 0;
  return data.map(item => {
    if (item.children) {
      item.children = addIndexProperty(item.children);
    }
    item.index = index++;
    return item;
  });
}

export const sortArrayByIndex = (data: TreeItem<MinimalTreeItemData>[]) => {
  data.forEach(item => {
    if (item.children) {
      item.children.sort((a, b) => (a.index || 0) - (b.index || 0));
      sortArrayByIndex(item.children);
    }
  });

  return data;
}
import { ReactElement } from 'react';
import { LocationListPage } from '../pages/location/list.page';
import { LocationPage } from '../pages/location/[$locationId].page';
import { LocationSettingPage } from '../pages/location/[$locationId].setting.page';
import { Navigate } from 'react-router-dom';
import { CreateLocationPage } from '../pages/location/new.page';
import { ChatBoxPresetPage } from '../pages/contact-us/chatbox-preset/chatbox-preset.page';
import { ContactSetupPage } from '../pages/contact-us/contact-setup/contact-us.page';
import { FeeStructureListPage } from '../pages/fee-configure/fee-structure/list.page';
import { FeeStructureCreatePage } from '../pages/fee-configure/fee-structure/create.page';
import { PromotionBonusListPage } from '../pages/fee-configure/promotion-bonus/list.page';
import { PromotionBonusCreatePage } from '../pages/fee-configure/promotion-bonus/create.page';
import { NewsPost } from '../pages/donor-engagement/news-post/news-post.page';
import { NewsPostList } from '../pages/donor-engagement/news-post/pages/list.page';
import { NewsPostCompose } from '../pages/donor-engagement/news-post/pages/[$newsPostId].page';
import { FeeStructureDetailsPage } from '../pages/fee-configure/fee-structure/[$id].page';
import { PromotionBonusDetailsPage } from '../pages/fee-configure/promotion-bonus/[$id].page';
import { SurveyListPage } from '../pages/donor-engagement/survey/list.page';
import { UpsertSurveyPage } from '../pages/donor-engagement/survey/[$surveyId].page';

import { AppointmentListPage } from '../pages/appointment/list.page';
import { SurveyStatistics } from '../pages/donor-engagement/survey/statistics.page';
import { Announcement } from '../pages/donor-engagement/announcement/announcement.page';
import { Notification } from '../pages/donor-engagement/notification/notification.page';
import { CustomerService } from '../pages/customer-service/customer-service.page';
import { RightsAndRolesPage } from '../pages/right-and-role/right-and-role.page';
import { useBoundStore } from '../states/bound.store';

export type AppRoute = {
  path: string;
  sideNav?: {
    group: string;
    displayName: string;
    icon: ReactElement;
    showBadge?: {
      logic: (data: any) => boolean;
      storeSlice: string;
    };
  };
  component?: ReactElement;
  canAccess?: () => boolean;
  children?: AppRoute[];
};

export const applicationRoutes: AppRoute[] = [
  {
    path: '/',
    component: <></>,
  },
  {
    path: '/donor',
    children: [
      {
        path: 'search',
        sideNav: {
          group: 'User Management',
          displayName: 'Search Donor',
          icon: <i className="ri-user-search-line"></i>,
        },
        component: <></>,
      },
      {
        path: 'group',
        sideNav: {
          group: 'User Management',
          displayName: 'Donor Group',
          icon: <i className="ri-group-line"></i>,
        },
      },
      {
        path: ':id',
        children: [
          {
            path: 'detail',
          },
        ],
      },
    ],
  },
  {
    path: '/appointment',
    sideNav: {
      group: 'Appointment Management',
      displayName: 'Appointments',
      icon: <i className="ri-calendar-todo-fill"></i>,
    },
    children: [
      {
        path: '',
        component: <AppointmentListPage />,
      },
      {
        path: 'new',
        component: <>new appointment</>,
      },
    ],
  },
  {
    path: '/news-posts',
    sideNav: {
      group: 'Donor Engagement',
      displayName: 'News Post',
      icon: <i className="ri-newspaper-line"></i>,
    },
    component: <NewsPost />,
    canAccess: () => useBoundStore.getState().user?.role.rights.includes('News Post') || false,
    children: [
      {
        path: '',
        component: <NewsPostList />,
      },
      {
        path: 'edit/:newsPostId',
        component: <NewsPostCompose mode={'edit'} />,
      },
      {
        path: 'template/:newsPostId',
        component: <NewsPostCompose mode={'template'} />,
      },
      {
        path: 'new',
        component: <NewsPostCompose mode={'new'} />,
      },
    ],
  },
  {
    path: '/surveys',
    sideNav: {
      group: 'Donor Engagement',
      displayName: 'Surveys',
      icon: <i className="ri-survey-line" />,
    },
    canAccess: () => useBoundStore.getState().user?.role.rights.includes('Survey') || false,
    children: [
      {
        path: '',
        component: <SurveyListPage />,
      },
      {
        path: 'edit/:surveyId',
        component: <UpsertSurveyPage mode={'edit'} />,
      },
      {
        path: 'template/:surveyId',
        component: <UpsertSurveyPage mode={'template'} />,
      },
      {
        path: 'new',
        component: <UpsertSurveyPage mode={'new'} />,
      },
      {
        path: ':surveyId/statistics',
        component: <SurveyStatistics />,
      },
    ],
  },
  {
    path: '/announcement',
    sideNav: {
      group: 'Donor Engagement',
      displayName: 'Announcement',
      icon: <i className="ri-megaphone-line"></i>,
    },
    canAccess: () => useBoundStore.getState().user?.role.rights.includes('Announcement') || false,
    component: <Announcement />,
  },
  {
    path: '/notification',
    sideNav: {
      group: 'Donor Engagement',
      displayName: 'Notification',
      icon: <i className="ri-notification-line"></i>,
    },
    canAccess: () => useBoundStore.getState().user?.role.rights.includes('Notification') || false,
    component: <Notification />,
  },
  {
    path: '/location',
    sideNav: {
      group: 'Location Management',
      displayName: 'Locations',
      icon: <i className="ri-building-2-line"></i>,
    },
    canAccess: () => useBoundStore.getState().user?.role.rights.includes('Location Management') || false,
    children: [
      {
        path: '',
        component: <Navigate to={'list'} />,
      },
      {
        path: 'list',
        component: <LocationListPage />,
      },
      {
        path: 'new',
        component: <CreateLocationPage />,
      },
      {
        path: ':locationId',
        component: <LocationPage />,
      },
      {
        path: 'setting/:locationId',
        component: <LocationSettingPage />,
      },
    ],
  },
  {
    path: '/fee-structure',
    sideNav: {
      group: 'Fee Configurator',
      displayName: 'Fee Structure',
      icon: <i className="ri-wallet-2-line"></i>,
    },
    canAccess: () => useBoundStore.getState().user?.role.rights.includes('Fee Structure') || false,
    children: [
      {
        path: '',
        component: <Navigate to={'list'} />,
      },
      {
        path: 'list',
        component: <FeeStructureListPage />,
      },
      {
        path: 'create',
        component: <FeeStructureCreatePage />,
      },
      {
        path: ':feeStructureId',
        component: <FeeStructureDetailsPage />,
      },
    ],
  },
  {
    path: '/promotion-bonus',
    sideNav: {
      group: 'Fee Configurator',
      displayName: 'Promotion Bonus',
      icon: <i className="ri-discount-percent-line"></i>,
    },
    canAccess: () => useBoundStore.getState().user?.role.rights.includes('Promotion Bonus') || false,
    children: [
      {
        path: '',
        component: <Navigate to={'list'} />,
      },
      {
        path: 'list',
        component: <PromotionBonusListPage />,
      },
      {
        path: 'create',
        component: <PromotionBonusCreatePage />,
      },
      {
        path: ':campaignId',
        component: <PromotionBonusDetailsPage />,
      },
    ],
  },
  {
    path: '/contacts-setup',
    sideNav: {
      group: 'Contact Us',
      displayName: 'Contact Setup',
      icon: <i className="ri-contacts-line"></i>,
    },
    canAccess: () => useBoundStore.getState().user?.role.rights.includes('Contact Setup') || false,
    component: <ContactSetupPage />,
  },
  {
    path: '/content-chatbot',
    sideNav: {
      group: 'Contact Us',
      displayName: 'Chatbox Preset',
      icon: <i className="ri-message-2-line"></i>,
    },
    canAccess: () => useBoundStore.getState().user?.role.rights.includes('Chatbox Preset') || false,
    component: <ChatBoxPresetPage />,
  },
  {
    path: '/customer-service',
    sideNav: {
      group: 'Contact Us',
      displayName: 'Customer Service',
      icon: <i className="ri-customer-service-line"></i>,
    },
    canAccess: () => useBoundStore.getState().user?.role.rights.includes('Customer Service') || false,
    component: <CustomerService />,
  },
  {
    path: '/right-and-role-management',
    sideNav: {
      group: 'System Configuration',
      displayName: 'Rights and Roles',
      icon: <i className="ri-door-open-line" />,
    },
    canAccess: () => useBoundStore.getState().user?.role.rights.includes('Role Configuration') || false,
    component: <RightsAndRolesPage />,
  },
];

import { Button, DatePicker, Form, Input, Select, Typography } from 'antd';
import { underLinedInputClass } from '../../../../components/inputs/editable-attribute.component';
import React from 'react';
import { Announcement } from 'bridge/announcement';
import httpClient from '../../../../utils/http-client.util';
import { BackendAPI } from '../../../../constants/backend-api.enum';
import { popMessage } from '../../../../utils/pop-message.util';
import AnnouncementPreview from '../component/announcement-preview.component';

type Props = {
  afterNewAnnouncement?: () => void;
};
export const SendAnnouncement = ({ afterNewAnnouncement }: Props) => {
  const [sendingAnnouncement, setSendingAnnouncement] = React.useState<Partial<Announcement>>({ details: [] });
  const [submitting, setSubmitting] = React.useState<boolean>(false);

  const addDetail = () => {
    setSendingAnnouncement({
      ...sendingAnnouncement,
      details: [
        ...(sendingAnnouncement.details ?? []),
        {
          type: 'title',
          content: '',
        },
      ],
    });
  };

  const removeDetail = (index: number) => {
    setSendingAnnouncement({
      ...sendingAnnouncement,
      details: sendingAnnouncement.details?.filter((_, i) => i !== index),
    });
  };

  const onFinish = async (values: any) => {
    if (values) {
      setSubmitting(true);
      try {
        const payload = {
          ...sendingAnnouncement,
          validateDateRange: [values.validateDateRange[0].toDate(), values.validateDateRange[1].toDate()],
        };
        await httpClient.post(BackendAPI.ANNOUNCEMENT, payload);
        popMessage.success('Announcement created successfully');
        if (afterNewAnnouncement) {
          afterNewAnnouncement();
        }
      } catch (e) {
        popMessage.error('Failed to create announcement');
      } finally {
        setSubmitting(false);
      }
    }
  };

  return (
    <div className={'flex justify-between space-x-6 h-fill'}>
      <div className={'w-[72%]'}>
        <Form onFinish={onFinish} disabled={submitting}>
          <div>
            <Typography.Text type="secondary">Announcement Title</Typography.Text>
          </div>
          <Form.Item name={'title'} rules={[{ required: true, message: 'Please provide title!' }]}>
            <Input
              value={sendingAnnouncement.title}
              variant="borderless"
              className={`${underLinedInputClass} w-[100%]`}
              style={{ borderBottomStyle: 'solid' }}
              onChange={(e) => {
                setSendingAnnouncement({ ...sendingAnnouncement, title: e.target.value });
              }}
            />
          </Form.Item>

          <div>
            <Typography.Text type="secondary">Subtitle</Typography.Text>
          </div>
          <Form.Item name={'subtitle'} rules={[{ required: true, message: 'Please provide subtitle!' }]}>
            <Input
              value={sendingAnnouncement.subtitle}
              variant="borderless"
              className={`${underLinedInputClass} w-[100%]`}
              style={{ borderBottomStyle: 'solid' }}
              onChange={(e) => {
                setSendingAnnouncement({ ...sendingAnnouncement, subtitle: e.target.value });
              }}
            />
          </Form.Item>

          <div className={'m-2 shadow rounded border p-4'}>
            <div>
              <Typography.Text type="secondary">Details</Typography.Text>
            </div>
            {sendingAnnouncement.details?.map((detail, index) => (
              <Form.Item
                name={`details[${index}]`}
                key={index}
                rules={[{ required: true, message: 'Please provide detail!' }]}
              >
                <Input
                  value={detail.content}
                  variant="borderless"
                  className={`${underLinedInputClass} w-[100%]`}
                  style={{ borderBottomStyle: 'solid' }}
                  onChange={(e) => {
                    setSendingAnnouncement({
                      ...sendingAnnouncement,
                      details: sendingAnnouncement.details?.map((d, i) =>
                        i === index ? { ...d, content: e.target.value } : d
                      ),
                    });
                  }}
                  addonBefore={
                    <Select
                      value={detail.type}
                      variant="borderless"
                      options={[
                        { label: 'Title', value: 'title' },
                        { label: 'Bullet', value: 'bullet' },
                      ]}
                      style={{ borderBottomStyle: 'solid' }}
                      onChange={(e) => {
                        setSendingAnnouncement({
                          ...sendingAnnouncement,
                          details: sendingAnnouncement.details?.map((d, i) => (i === index ? { ...d, type: e } : d)),
                        });
                      }}
                    />
                  }
                  addonAfter={
                    <Button type="link" className={'text-red-500'} onClick={() => removeDetail(index)}>
                      Remove
                    </Button>
                  }
                />
              </Form.Item>
            ))}

            <Form.Item className={'text-center'}>
              <Button
                type="dashed"
                onClick={() => addDetail()}
                style={{ width: '20%' }}
                icon={<i className={'ri-add-box-line'} />}
              >
                Add Details
              </Button>
            </Form.Item>
          </div>

          <div>
            <Typography.Text type="secondary">Validated Range</Typography.Text>
          </div>
          <Form.Item
            name="validateDateRange"
            rules={[{ required: true, message: 'Please provide validation date range' }]}
          >
            <DatePicker.RangePicker />
          </Form.Item>

          <div className={'flex justify-end'}>
            <Button type="primary" htmlType={'submit'}>
              Send Announcement
            </Button>
          </div>
        </Form>
      </div>
      <div className={'w-[28%]'}>
        <AnnouncementPreview announcement={sendingAnnouncement} />
      </div>
    </div>
  );
};

import { Tabs, TabsProps, Typography } from 'antd';
import React from 'react';
import { AnnouncementHistory } from './tabs/history.tab';
import { SendAnnouncement } from './tabs/send-announcement.tab';

export const Announcement = () => {
  const [activeTab, setActiveTab] = React.useState<string>('send');

  const tabItems: TabsProps['items'] = [
    {
      key: 'send',
      label: 'Send Announcement',
      children: (
        <div className={'bg-primary-foreground rounded-lg p-4'}>
          <SendAnnouncement afterNewAnnouncement={() => setActiveTab('history')} />
        </div>
      ),
    },
    {
      key: 'history',
      label: 'History',
      children: (
        <div className={'bg-primary-foreground rounded-lg p-4'}>
          <AnnouncementHistory />
        </div>
      ),
    },
  ];
  return (
    <div>
      <Typography.Title level={3} className={'flex items-center'}>
        Announcement
      </Typography.Title>
      <Tabs items={tabItems} activeKey={activeTab} destroyInactiveTabPane onChange={(e) => setActiveTab(e)} />
    </div>
  );
};

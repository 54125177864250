import React, { useEffect, useState } from 'react';
import { Avatar, Badge, Divider, Layout, Select, Space, Tooltip, Typography } from 'antd';
import { useBoundStore } from '../../states/bound.store';
import httpClient from '../../utils/http-client.util';
import { Donor } from 'bridge/donor';
import { BackendAPI } from '../../constants/backend-api.enum';
import { Conversation } from 'bridge/chat-message';
import VirtualList from 'rc-virtual-list';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { MessageStreamDisplayComponent } from './component/message-stream-display.component';
import { MessageSendingComponent } from './component/message-sending.component';
import { getDonorFullName } from '../../helpers/formatHelper';
import './customer-service.page.css';

const { Header, Content, Footer, Sider } = Layout;

export const CustomerService = () => {
  const [chatBoxContainer, setChatBoxContainer] = useState<HTMLElement | null>(null);
  const [donorSearchResult, setDonorSearchResult] = useState<Donor[]>([]);
  const [conversationList, setConversationList] = useState<Conversation[]>([]);
  const [sortBy, setSortBy] = useState('latest');
  const [donorQueryDebouncing, setDonorQueryDebouncing] = useState<NodeJS.Timeout>();
  const [currentConversation, setCurrentConversation] = useState<Conversation>();

  const availableConversations = useBoundStore((state) => state.conversations);
  const startConversation = useBoundStore((state) => state.startConversation);
  const currentConversationDonorId = useBoundStore((state) => state.currentConversationDonorId);

  let currentDonorQuery = '';

  const handleConversationSearch = (newValue: string) => {
    if (newValue && newValue.length > 3) {
      if (donorQueryDebouncing) {
        clearTimeout(donorQueryDebouncing);
        setDonorQueryDebouncing(undefined);
      }
      currentDonorQuery = newValue;

      setDonorQueryDebouncing(
        setTimeout(async () => {
          const result = await httpClient.get(`${BackendAPI.DONOR}/search?query=${newValue}`);
          if (currentDonorQuery === newValue) {
            setDonorSearchResult(result.data);
          }
        }, 300)
      );
    } else {
      setDonorSearchResult([]);
    }
  };
  const getSortFunc = (type: string) => {
    const isOngoingConversation = () =>
      availableConversations.find((c) => c.donor.donorId === currentConversationDonorId);

    const nameSort = (a: Conversation, b: Conversation) =>
      getDonorFullName(a.donor).toLowerCase().localeCompare(getDonorFullName(b.donor).toLowerCase());
    const unreadSort = (a: Conversation, b: Conversation) =>
      a.hasUnreadMessage === b.hasUnreadMessage ? 0 : a.hasUnreadMessage ? -1 : 1;
    const timeSort = (a: Conversation, b: Conversation) => {
      if (!a.latestMessageTime || !b.latestMessageTime) {
        if (!a.latestMessageTime) {
          return -1;
        } else {
          return 1;
        }
      }
      return new Date(b.latestMessageTime).getTime() - new Date(a.latestMessageTime).getTime();
    };

    return (a: Conversation, b: Conversation) => {
      const aFixTop = isOngoingConversation();
      const bFixTop = isOngoingConversation();

      if (aFixTop) {
        return 1;
      } else if (bFixTop) {
        return -1;
      }

      let result = unreadSort(a, b);
      if (result === 0) {
        if (type === 'name') {
          result = nameSort(a, b);
          if (result === 0) {
            result = timeSort(a, b);
          }
        } else if (type === 'latest') {
          result = timeSort(a, b);
          if (result === 0) {
            result = nameSort(a, b);
          }
        }
      }
      return result;
    };
  };

  const chooseConversation = async (donor: Donor) => {
    startConversation(donor);
  };

  useEffect(() => {
    if (currentConversationDonorId) {
      setCurrentConversation(availableConversations.find((c) => c.donor.donorId === currentConversationDonorId));
    }
  }, [currentConversationDonorId]);

  useEffect(() => {
    setConversationList(availableConversations);
  }, [availableConversations]);

  return (
    <div className={'message-board -m-6'}>
      <Layout hasSider style={{ height: 'calc(100vh - 58px)', border: '1px solid #dfdfdf' }}>
        <Layout>
          <Header>
            <Typography.Text style={{ fontSize: '18px', color: 'white' }}>
              {!!currentConversation ? (
                <>
                  Conversation with
                  <Tooltip title={'to detail page'}>
                    <Link
                      to={`/donor/${currentConversation.donor.donorId}`}
                      style={{ fontSize: '18px', color: 'white', marginInline: 4, borderBottom: '1px solid #fff' }}
                    >
                      {getDonorFullName(currentConversation.donor)}
                    </Link>
                  </Tooltip>
                </>
              ) : (
                <>Select a conversation</>
              )}
            </Typography.Text>
          </Header>
          <Content
            style={{
              margin: '16px 12px 0',
              padding: '0 16px',
              overflowY: 'auto',
            }}
            ref={setChatBoxContainer}
          >
            <MessageStreamDisplayComponent chatBoxContainer={chatBoxContainer} />
          </Content>
          <Footer>
            <MessageSendingComponent />
          </Footer>
        </Layout>
        <Sider theme={'light'} width={333}>
          <Space direction={'vertical'} style={{ width: '100%', padding: 8 }}>
            <div>
              <Select
                showSearch
                allowClear
                placeholder={'Search Name, Phone or Email'}
                style={{ width: '100%' }}
                defaultActiveFirstOption={false}
                suffixIcon={null}
                filterOption={false}
                notFoundContent={null}
                onSearch={handleConversationSearch}
                onChange={(_, option) =>
                  chooseConversation((option as { label: string; value: string; donor: Donor }).donor)
                }
                options={donorSearchResult.map((r) => ({
                  label: `${r.firstName} ${r.lastName}`,
                  value: r.donorId,
                  donor: r,
                }))}
                optionRender={(option, i) => (
                  <div className="flex items-center" key={`${i}`}>
                    <Avatar size={30} className={'mr-4'}>
                      {[option.data.donor.firstName, option.data.donor.lastName]
                        .map((n: string) => n.slice(0, 1))
                        .join('')
                        .toUpperCase()}
                    </Avatar>
                    <div>
                      <Space>
                        <Typography.Text strong>{option.data.label}</Typography.Text>
                        <Typography.Text type={'secondary'}>
                          {(option.data.donor.phone || '')
                            .replace(/\D+/g, '')
                            .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')}
                        </Typography.Text>
                      </Space>
                      <div>
                        <Typography.Text type={'secondary'}>{option.data.donor.email}</Typography.Text>
                      </div>
                    </div>
                  </div>
                )}
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
              <span style={{ marginRight: 4 }}>Sort by:</span>
              <Select
                defaultValue={sortBy}
                style={{ width: 150 }}
                options={[
                  { value: 'latest', label: 'Latest Message' },
                  { value: 'name', label: 'Name' },
                ]}
                onChange={(value) => setSortBy(value)}
              />
            </div>
            <Divider style={{ margin: 0 }} />
          </Space>

          <div style={{ overflow: 'auto', height: 'calc(100% - 99px)' }}>
            <div>
              <VirtualList
                data={conversationList.sort(getSortFunc(sortBy))}
                itemKey={'donor.donorId'}
                height={window.innerHeight - 99 - 60}
                itemHeight={45}
              >
                {(c) => (
                  <div
                    key={c.donor.donorId}
                    className={`flex items-center cursor-pointer py-1 px-4 mx-2 rounded-lg hover:bg-neutral-200 
                    ${currentConversation && currentConversation.donor.donorId === c.donor.donorId ? 'bg-blue-100' : ''}`}
                    onClick={() => chooseConversation(c.donor)}
                  >
                    <Badge dot={c.hasUnreadMessage}>
                      <Avatar shape={'square'} style={{ backgroundColor: '#f56a00' }}>
                        {[c.donor.firstName[0], c.donor.lastName[0]].join('').toUpperCase()}
                      </Avatar>
                    </Badge>
                    <Space
                      style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}
                    >
                      <div style={{ fontSize: 14, marginLeft: 8 }}>{getDonorFullName(c.donor)}</div>
                      <Space.Compact direction={'vertical'} style={{ alignItems: 'end' }}>
                        <Typography.Text type={'secondary'} style={{ fontSize: 12 }}>
                          {c.latestMessageTime ? dayjs(c.latestMessageTime).format('hh:mma') : ''}
                        </Typography.Text>
                        <Typography.Text type={'secondary'} style={{ fontSize: 12 }}>
                          {c.latestMessageTime ? dayjs(c.latestMessageTime).format('YYYY-MM-DD') : ''}
                        </Typography.Text>
                      </Space.Compact>
                    </Space>
                  </div>
                )}
              </VirtualList>
            </div>
          </div>
        </Sider>
      </Layout>
    </div>
  );
};

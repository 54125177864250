import { EntityCondition, EntityPromotionBonus } from "bridge/promotion-bonus";
import { APPLICANTDONORS, CUSTOMEGROUP, DEFERRALDONORS, LAPSEDDONORS, PREDONORSTONEWDONORS } from "../../../utils/enum";

export type FeeItem = {
    [key: string]: string[];
}

export type FiltersType = {
    text: string;
    value: string;
}


interface OutputObjectType {
    [key: string]: { [key: string]: string };
}

export const validateFees = (data: FeeItem[]) => {
    let check: any = [];

    data.forEach(item => {
        const key = Object.keys(item)[0];
        const isNull = item[key].includes("")
        check.push(isNull);
    })

    return check
}

export const OutputObject = (data: FeeItem[]) => {
    const outputObject: OutputObjectType = {};

    data.forEach(item => {
        const key = Object.keys(item)[0];
        const values = item[key].filter(value => value !== "");
        outputObject[key] = {};
        values.forEach((value, index) => {
            outputObject[key][index] = value;
        });
    });

    return outputObject
}
export const OutputArray = (data: OutputObjectType) => {
    let outputArray = []
    outputArray = Object.keys(data).map(key => ({
        [key]: Object.values(data[key])
    }))

    return outputArray
}


export const UniqueArray = (arr: FiltersType[]) => {
    const uniqueArray: FiltersType[] = [];
    const seen = new Set();
    arr.forEach((obj: FiltersType) => {
        const key = obj.text;
        if (!seen.has(key)) {
            seen.add(key);
            uniqueArray.push(obj);
        }
    });
    return uniqueArray;
};

export const mergeArrays = (incomeArray: FeeItem[], existArray: FeeItem[]) => {
    let newIcomeArray = incomeArray.filter((item) => {
        const key = Object.keys(item)[0];
        return item[key].length > 0
    })
    let result = [...existArray];

    newIcomeArray.forEach(incomeObject => {
        const key = Object.keys(incomeObject)[0];
        const index = result.findIndex(existObject => Object.keys(existObject)[0] === key);

        if (index !== -1) {
            result[index][key] = incomeObject[key];
        } else {
            result.push(incomeObject);
        }

    });

    return result;
}
export const validateConditons = (element: EntityCondition, targetGroup: string, errors: string[], index: number) => {
    let isNotEmpty = element.duration.every(item => item !== '') && element.donations.every((item) => item.value !== "" && item.type !== "")
    if (targetGroup === DEFERRALDONORS) {
        if (!isNotEmpty || !element.deferralType) {
            errors.push(`Input Field is empty at Condition ${index + 1}`)
            return false
        }
    }
    if (!isNotEmpty) {
        errors.push(`Input Field is empty at Condition ${index + 1}`)
        return false
    }
}

export const fieldErrors = (promotionBonusForm: EntityPromotionBonus, newConditionData: EntityCondition[], targetGroup: string) => {
    const errors: string[] = [];
    let donorGroup = [PREDONORSTONEWDONORS, APPLICANTDONORS, LAPSEDDONORS];
    if (!promotionBonusForm.campaignName.length) {
        errors.push('Campaign Name not allow to be empty');
    }
    if (!promotionBonusForm.campaignDescription.length) {
        errors.push('Campaign Description not allow to be empty');
    }
    if (promotionBonusForm.appliedCenter.length <= 0) {
        errors.push('Applied Center not allow to be empty');
    }
    if (!promotionBonusForm.startDate.length || !promotionBonusForm.endDate.length) {
        errors.push('Fee Structure Time not allow to be empty');
    }

    if (newConditionData.length > 0) {
        // Conditons for Updates Promotion Bonus
        newConditionData.forEach((element, index) => {
            if (element) {
                validateConditons(element, targetGroup, errors, index)
            }
        });
    } else {
        // Conditons for Create Promotion Bonus
        if (promotionBonusForm.condition.length > 0) {
            promotionBonusForm.condition.forEach((element, index) => {
                if (element) {
                    validateConditons(element, targetGroup, errors, index)
                }
            });
        }
    }
    
    if (targetGroup === CUSTOMEGROUP) {
        promotionBonusForm.donorGroup.forEach((element, index) => {
            if (element) {
                let isNotEmpty = element.donorGroupId !== '' && element.name !== ''
                if (!isNotEmpty) {
                    errors.push(`Please Select Donor Group`)
                    return false
                }
            }
        })
    }
    if (donorGroup.includes(targetGroup) && promotionBonusForm.condition.length > 0) {
        let condition = promotionBonusForm.condition
        condition.forEach((condi, index) => {
            if (Number(condi.duration[0]) > Number(condi.duration[1])) {
                errors.push(`From day is over To day of Condition ${index + 1}`)
                return false
            }
            if (Number(condition[index - 1]?.duration[1]) >= Number(condition[index]?.duration[0])) {
                errors.push(`Should not Overlapped period of days for Condition ${index + 1}`)
                return false
            }
        })
    }
    return errors;
}
import { Question } from 'bridge/survey';
import { Checkbox, Input, Radio, Space, Typography } from 'antd';
import React, { useState } from 'react';

export const QuestionPreview = ({
  q,
  currentEditLanguage,
  qIndex,
}: {
  q: Omit<Question, '_id'> & { saveMe?: boolean };
  currentEditLanguage: string;
  qIndex: number;
}) => {
  const [previewChosenChoices, setPreviewChosenChoices] = useState<string[]>([]);

  return (
    <div className={'mb-2'}>
      <Typography.Text strong className={'block'}>
        {qIndex + 1}. {q.question[currentEditLanguage] || `Question:`}
      </Typography.Text>
      {q.type === 'free-input' ? (
        <Input.TextArea placeholder={q.placeholder ? q.placeholder[currentEditLanguage] : ''} />
      ) : (
        <>
          {q.type === 'choose-one' ? (
            <Radio.Group>
              <Space direction="vertical">
                {q.choices?.map((c, cIndex) => (
                  <div key={`p-q-${qIndex}-c-${cIndex}`}>
                    <Radio
                      key={cIndex}
                      checked={previewChosenChoices.includes(`p-q-${qIndex}-c-${cIndex}`)}
                      value={`p-q-${qIndex}-c-${cIndex}`}
                      onClick={() => {
                        const chosenChoicesNotInThisQuestion = previewChosenChoices.filter(
                          (p) => !p.startsWith(`p-q-${qIndex}`)
                        );
                        setPreviewChosenChoices([...chosenChoicesNotInThisQuestion, `p-q-${qIndex}-c-${cIndex}`]);
                      }}
                    >
                      {c.content[currentEditLanguage]}
                    </Radio>
                    {c.withInput && previewChosenChoices.includes(`p-q-${qIndex}-c-${cIndex}`) && (
                      <Input
                        className={'ml-6 w-fit block'}
                        placeholder={c.withInput.placeholder[currentEditLanguage]}
                      />
                    )}
                  </div>
                ))}
              </Space>
            </Radio.Group>
          ) : (
            <Space direction="vertical">
              {q.choices?.map((c, cIndex) => (
                <div key={`p-q-${qIndex}-c-${cIndex}`}>
                  <Checkbox
                    key={cIndex}
                    checked={previewChosenChoices.includes(`p-q-${qIndex}-c-${cIndex}`)}
                    value={`p-q-${qIndex}-c-${cIndex}`}
                    onChange={(event) => {
                      if (event.target.checked && q.choices) {
                        if (!c.exclusive) {
                          const exclusiveChoicesInThisQuestion: string[] = [];
                          q.choices?.forEach((c, eIndex) => {
                            if (c.exclusive) {
                              exclusiveChoicesInThisQuestion.push(`p-q-${qIndex}-c-${eIndex}`);
                            }
                          });
                          if (exclusiveChoicesInThisQuestion.length > 0) {
                            const chosenChoicesWithExclusiveChoiceRemoved = previewChosenChoices.filter(
                              (choiceId) => !exclusiveChoicesInThisQuestion.includes(choiceId)
                            );
                            setPreviewChosenChoices([
                              ...chosenChoicesWithExclusiveChoiceRemoved,
                              `p-q-${qIndex}-c-${cIndex}`,
                            ]);
                          } else {
                            setPreviewChosenChoices((prev) => [...prev, `p-q-${qIndex}-c-${cIndex}`]);
                          }
                        } else {
                          const chosenChoicesNotInThisQuestion = previewChosenChoices.filter(
                            (p) => !p.startsWith(`p-q-${qIndex}`)
                          );
                          setPreviewChosenChoices([...chosenChoicesNotInThisQuestion, `p-q-${qIndex}-c-${cIndex}`]);
                        }
                      } else {
                        setPreviewChosenChoices((prev) => prev.filter((p) => p !== `p-q-${qIndex}-c-${cIndex}`));
                      }
                    }}
                  >
                    {c.content[currentEditLanguage]}
                  </Checkbox>
                  {c.withInput && previewChosenChoices.includes(`p-q-${qIndex}-c-${cIndex}`) && (
                    <Input className={'ml-6 w-fit block'} placeholder={c.withInput.placeholder[currentEditLanguage]} />
                  )}
                </div>
              ))}
            </Space>
          )}
        </>
      )}
    </div>
  );
};
